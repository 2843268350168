import React from 'react';
import Content from '../../page/Content';
import { ChangePasswordContainer } from './changePassword/ChangePasswordContainer';
import { ChangeProfileDetailsContainer } from './changeProfileDetails/ChangeProfileDetailsContainer';
import Grid from '@mui/material/Grid';
import { ChangeLocaleContainer } from './changeLocale/ChangeLocaleContainer';
import { PropTypes } from 'prop-types';

const content = (
  <Grid container spacing={2}>
    <Grid item lg={4} md={6}>
      <ChangeProfileDetailsContainer />
    </Grid>
    <Grid item lg={5} md={6}>
      <ChangePasswordContainer />
    </Grid>
    <Grid item lg={3} md={6} sm={12}>
      <ChangeLocaleContainer />
    </Grid>
  </Grid>
);

export function ProfileDisplay({ fetchProfileDetails }) {
  return (
    <Content title={'Mijn profiel'} content={content}>
      {fetchProfileDetails()}
    </Content>
  );
}

ProfileDisplay.propTypes = {
  fetchProfileDetails: PropTypes.func
};
