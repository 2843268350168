import { connect } from 'react-redux';
import ManageChannelDisplay from '../ManageChannelDisplay.jsx';
import { createChannelOfLocation } from './redux/createChannelActions';
import { channelsListOfLocationSelector } from 'components/content/location/manageLocation/channels/redux/channelsListSelectors';
import { devicesListOfLocationSelector } from 'components/content/location/manageLocation/devices/redux/devicesListSelectors';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { fetchDevicesOfLocation } from '../../devices/redux/devicesListActions.js';
import { fetchValidationProfilesList } from 'components/content/validationprofiles/redux/validationProfilesListActions.js';
import { validationProfilesListSelector } from 'components/content/validationprofiles/redux/validationProfilesListSelectors.js';
import {
  showDialog,
  hideDialog
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions';
import { selectedChannelsSelector } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';

const mapStateToProps = (state, ownProps) => {
  return {
    editMode: false,
    viewModel: null,
    selectedChannels: selectedChannelsSelector(state),
    channels: channelsListOfLocationSelector(state),
    currentUser: currentUserSelector(state),
    devices: devicesListOfLocationSelector(state),
    validationProfiles: validationProfilesListSelector(state),
    locationId: ownProps.locationId
  };
};

const mapDispatchToProps = dispatch => ({
  handleSave(channel) {
    dispatch(createChannelOfLocation(channel));
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  fetchDevicesOfLocation(locationId) {
    dispatch(fetchDevicesOfLocation(locationId));
  },
  fetchValidationProfilesList() {
    dispatch(fetchValidationProfilesList());
  },
  showDialog() {
    dispatch(showDialog());
  },
  hideDialog() {
    dispatch(hideDialog());
  }
});

export const CreateChannelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageChannelDisplay);
