import { connect } from 'react-redux';
import { t } from 'i18next';
import LogItemModalDisplay from './ExportChartModalDisplay.jsx';
import { createExportChart } from './redux/createExportChartActions';
import { editExportChart } from './redux/editExportChartActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { contactsListSelector } from 'components/content/contacts/redux/contactsListSelectors.js';
import { fetchContactsList } from 'components/content/contacts/redux/contactsListActions.js';
import {
  addSelectedChannel,
  showDialog,
  clearSelectChannelDialogState
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions.js';
import {
  selectedChannelsSelector,
  selectedLocationsSelector
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';
import { getMeasurementChannelBySensightId } from 'apis/MeasurementServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: currentUserSelector(state),
    exportChart: ownProps.exportChart,
    contacts: contactsListSelector(state),
    selectedChannels: selectedChannelsSelector(state),
    selectedLocations: selectedLocationsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  clearDialog() {
    dispatch(clearSelectChannelDialogState());
  },
  fetchContactsList() {
    dispatch(fetchContactsList());
  },
  createExportChart(exportChart) {
    dispatch(createExportChart(exportChart));
  },
  editExportChart(exportChart) {
    dispatch(editExportChart(exportChart));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  openSelectChannelsDialog() {
    dispatch(showDialog());
  },
  enrichExportChartChannel(currentUser, exportChannel) {
    getMeasurementChannelBySensightId(
      currentUser,
      exportChannel.channelSensightId
    ).then(response => {
      // Door de manier waarop deze 'enrich' methode werkt, wordt alle originele data weggegooid. Wat in dit geval resulteerde in het ontbreken van aggregationType als veld
      response.data[0].aggregationType = exportChannel.aggregationType;
      dispatch(addSelectedChannel(response.data[0]));
    });
  }
});

const handleValidate = values => {
  let errors = {};

  if (values.name === undefined || values.name === null || values.name === '') {
    errors.name = t('general.validation.required');
  }
  return errors;
};

export const ExportChartModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(LogItemModalDisplay);
