export const getODataQuery = channelSensightId => {
  return (
    'channel?$expand=device($expand=deviceplacements($filter=startDateTime lt ' +
    new Date().toISOString() +
    ' and (endDateTime eq null or endDateTime gt ' +
    new Date().toISOString() +
    '); $expand=location))&$filter=(sensightid eq ' +
    channelSensightId +
    ')'
  );
};
