import { connect } from 'react-redux';
import { t } from 'i18next';
import LogItemModalDisplay from './LocationTypeModalDisplay.jsx';
import { createLocationType } from './redux/createLocationTypeActions';
import { editLocationType } from './redux/editLocationTypeActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = (state, ownProps) => {
  return {
    locationType: ownProps.locationType
  };
};

const mapDispatchToProps = dispatch => ({
  createLocationType(locationtypeInformation) {
    dispatch(createLocationType(locationtypeInformation));
  },
  editLocationType(locationtypeInformation) {
    dispatch(editLocationType(locationtypeInformation));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  }
});

const handleValidate = values => {
  let errors = {};

  if (values.name === undefined || values.name === null || values.name === '') {
    errors.logitemType = t('general.validation.required');
  }
  return errors;
};

export const LocationTypeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(LogItemModalDisplay);
