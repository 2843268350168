import { createSelector } from 'reselect';

export const devicePlacementsSelector = createSelector(
  state => state.get('devicePlacementsOfLocation'),
  devicePlacementsOfLocation => devicePlacementsOfLocation.toJS()
);

export const devicePlacementsOfDeviceSelector = createSelector(
  state => state.get('devicePlacementsOfDevice'),
  devicePlacementsOfDevice => devicePlacementsOfDevice.toJS()
);

export const devicePlacementSelector = createSelector(
  state => state.get('devicePlacement'),
  devicePlacement => devicePlacement.toJS()
);

export const availableDevicesSelector = createSelector(
  state => state.get('availableDevicesList'),
  availableDevicesList => availableDevicesList.toJS()
);

export const devicePlacementStartDateTimeSelector = createSelector(
  state => state.get('devicePlacementStartDateTime'),
  devicePlacementStartDateTime => devicePlacementStartDateTime
);

export const devicePlacementEndDateTimeSelector = createSelector(
  state => state.get('devicePlacementEndDateTime'),
  devicePlacementEndDateTime => devicePlacementEndDateTime
);
