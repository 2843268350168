import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState
} from 'react';
import { ThemeDetailsForm } from './forms/ThemeDetailsForm.js';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

function ManageThemeDisplay(
  { viewModel, setSaveButtonDisabled, upsertThemeDetails },
  ref
) {
  const themeDetailsFormRef = useRef();
  const [themeDetailsFormValid, setThemeDetailsFormValid] = useState(false);

  React.useEffect(() => {
    setSaveButtonDisabled(true);
    themeDetailsFormRef.current.validateForm();
  }, []);

  React.useEffect(() => {
    setSaveButtonDisabled(!themeDetailsFormValid);
  }, [themeDetailsFormValid]);

  const themeDetailsContent = (
    <ThemeDetailsForm
      themeDetailsFormRef={themeDetailsFormRef}
      upsertThemeDetails={upsertThemeDetails}
      setThemeDetailsFormValid={setThemeDetailsFormValid}
      themeDetailsViewmodel={viewModel}
    />
  );
  const formRef = useRef();

  const themeTabContent = (
    <>
      {themeDetailsContent}
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={true}
        onSubmit={() => {
          themeDetailsFormRef.current.handleSubmit();
        }}
      />
    </>
  );

  useImperativeHandle(ref, () => formRef);
  return <>{themeTabContent}</>;
}

export default forwardRef(ManageThemeDisplay);
