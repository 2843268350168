import axios from 'axios';
import configFile from '../App_config.json';
import { getActiveTenant } from 'utils/tenantManager.js';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

export const createContact = async (user, contactInformation) => {
  const config = createHeaderConfig(user);

  const data = {
    sensightId: contactInformation.sensightId,
    name: contactInformation.name,
    email: contactInformation.email,
    phoneNumber: contactInformation.phoneNumber,
    function: contactInformation.function,
    organizationId: contactInformation.organization.id
  };

  return axios.post(
    configFile.customercontactApiUrl + 'contact/',
    data,
    config
  );
};

export const createOrganization = async (user, organizationinformation) => {
  const config = createHeaderConfig(user);

  const data = {
    name: organizationinformation.name,
    city: organizationinformation.city,
    country: organizationinformation.country,
    phoneNumber: organizationinformation.phoneNumber,
    postCode: organizationinformation.postCode,
    address: organizationinformation.address,
    clientnumber: organizationinformation.clientnumber
  };

  return axios.post(
    configFile.customercontactApiUrl + 'organization/',
    data,
    config
  );
};

export const getOrganizations = async user => {
  const config = createHeaderConfig(user);
  return axios.get(configFile.customercontactApiUrl + 'organization/', config);
};

export const getContacts = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.customercontactApiUrl + 'contact/', config);
};

export const getContactsFromOrganization = async (user, organizationId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.customercontactApiUrl + 'contact/organization/' + organizationId,
    config
  );
};

export const editOrganization = async (user, organizationInformation) => {
  const config = createHeaderConfig(user);

  const data = organizationInformation;

  return axios.put(
    configFile.customercontactApiUrl + 'organization/' + data.id,
    data,
    config
  );
};

export const editContact = async (user, contactInformation) => {
  const config = createHeaderConfig(user);

  const data = {
    name: contactInformation.name,
    email: contactInformation.email,
    phoneNumber: contactInformation.phoneNumber,
    function: contactInformation.function,
    organizationId: contactInformation.organization.id
  };

  return axios.put(
    configFile.customercontactApiUrl + 'contact/' + contactInformation.id,
    data,
    config
  );
};

export const deleteContact = async (user, contactId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.customercontactApiUrl + 'contact/' + contactId,
    config
  );
};

export const deleteOrganization = async (user, organizationId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.customercontactApiUrl + 'organization/' + organizationId,
    config
  );
};
