import { SendChangePasswordMailApi } from 'apis/SendChangePasswordMailApi';
import { take, call, select, put } from 'redux-saga/effects';
import { SEND_CHANGE_PASSWORD_MAIL } from './changePasswordActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { t } from 'i18next';

export function* sendPasswordResetMailSaga() {
  yield take(SEND_CHANGE_PASSWORD_MAIL);
  const currentUser = yield select(currentUserSelector);
  yield call(SendChangePasswordMailApi, currentUser);
  yield put(createSnackbar(t('content.profile.changepassword.snackbar.text')));
}
