import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState
} from 'react';
import { TenantDetailsForm } from './forms/TenantDetailsForm.js';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

function ManageTenantDisplay(
  {
    viewModel,
    setSaveButtonDisabled,
    upsertTenantDetails,
    themes,
    fetchThemes
  },
  ref
) {
  const tenantDetailsFormRef = useRef();
  const [tenantDetailsFormValid, setTenantDetailsFormValid] = useState(false);

  React.useEffect(() => {
    fetchThemes();
    setSaveButtonDisabled(true);
    tenantDetailsFormRef.current.validateForm();
  }, []);

  React.useEffect(() => {
    setSaveButtonDisabled(!tenantDetailsFormValid);
  }, [tenantDetailsFormValid]);

  const tenantDetailsContent = (
    <TenantDetailsForm
      tenantDetailsFormRef={tenantDetailsFormRef}
      upsertTenantDetails={upsertTenantDetails}
      setTenantDetailsFormValid={setTenantDetailsFormValid}
      tenantDetailsViewmodel={viewModel}
      themes={themes}
    />
  );
  const formRef = useRef();

  const tenantTabContent = (
    <>
      {tenantDetailsContent}
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={true}
        onSubmit={() => {
          tenantDetailsFormRef.current.handleSubmit();
        }}
      />
    </>
  );

  useImperativeHandle(ref, () => formRef);
  return <>{tenantTabContent}</>;
}

export default forwardRef(ManageTenantDisplay);
