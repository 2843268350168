import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LinkIcon from '@mui/icons-material/Link';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  endOfDay,
  addMilliseconds,
  subMilliseconds,
  parseJSON
} from 'date-fns';

import { calculateDateOffset } from 'components/content/visualize/VisualizePeriods.js';
import ImdTooltip from 'components/imd-components/ImdTooltip';
import ImdButton from 'components/imd-components/ImdButton';
import { generateVisualisationLink } from 'utils/helperMethods';
import ManualEntryLink from './ManualEntryLink.jsx';

const GraphButtonsBar = ({ item, locations, fetchDashboardItemData }) => {
  const classes = useImdCardStyles();
  const { t } = useTranslation();
  const [selectionData, setSelectionData] = React.useState(
    item.visualizationSettings.selectionData
  );
  const user = useSelector(state => currentUserSelector(state));
  const date =
    item?.visualizationSettings?.selectionData?.visualizePeriod ===
    'currentYear'
      ? new Date(new Date().getFullYear(), 11, 31) // Set to end of current year - 11 = December
      : new Date();

  const nextDisabled =
    addMilliseconds(
      parseJSON(selectionData?.visualizeUntilTime),
      calculateDateOffset(selectionData?.visualizePeriod)
    ) > endOfDay(parseJSON(date));

  const nextItemTime = item => {
    if (nextDisabled) {
      return;
    }

    const fromTime = parseJSON(
      item.visualizationSettings.selectionData.visualizeFromTime
    );
    const untilTime = parseJSON(
      item.visualizationSettings.selectionData.visualizeUntilTime
    );

    item.visualizationSettings.selectionData = {
      ...item.visualizationSettings.selectionData,
      visualizeFromTime: addMilliseconds(
        fromTime,
        calculateDateOffset(
          item.visualizationSettings.selectionData.visualizePeriod
        )
      ).toISOString(),
      visualizeUntilTime: addMilliseconds(
        untilTime,
        calculateDateOffset(
          item.visualizationSettings.selectionData.visualizePeriod
        )
      ).toISOString()
    };
    updateAndFetch(item);
  };

  const returnToCurrentPeriod = item => {
    const today = new Date();

    item.visualizationSettings.selectionData = {
      ...item.visualizationSettings.selectionData,
      visualizeFromTime: subMilliseconds(
        today,
        calculateDateOffset(
          item.visualizationSettings.selectionData.visualizePeriod
        )
      ).toISOString(),
      visualizeUntilTime: today.toISOString()
    };

    updateAndFetch(item);
  };

  const previousItemTime = item => {
    const fromTime = parseJSON(
      item.visualizationSettings.selectionData.visualizeFromTime
    );
    const untilTime = parseJSON(
      item.visualizationSettings.selectionData.visualizeUntilTime
    );

    item.visualizationSettings.selectionData = {
      ...item.visualizationSettings.selectionData,
      visualizeFromTime: subMilliseconds(
        fromTime,
        calculateDateOffset(
          item.visualizationSettings.selectionData.visualizePeriod
        )
      ).toISOString(),
      visualizeUntilTime: subMilliseconds(
        untilTime,
        calculateDateOffset(
          item.visualizationSettings.selectionData.visualizePeriod
        )
      ).toISOString()
    };
    updateAndFetch(item);
  };

  const updateAndFetch = item => {
    setSelectionData(item.visualizationSettings.selectionData);
    fetchDashboardItemData(item);
  };

  const visualizeLinkDataHelper = async () => {
    let visualisation = {
      selectionDataParameter: item.visualizationSettings.selectionData,
      selectedChannels: item.visualizationSettings.selectedChannels,
      displayOption: item.visualizationSettings.displayOptions
    };

    return await generateVisualisationLink(user, visualisation);
  };

  return (
    <div className={classes.graphContainer}>
      <div className={classes.graphBar}>
        {(item.visualizationType === 1 ||
          item.visualizationType === 2 ||
          item.visualizationType === 5) && (
          <>
            {item.visualizationSettings.selectionData != null ? (
              <>
                <ImdTooltip
                  title={'Vorige periode'}
                  placement="top-start"
                  className={classes.linkItem}
                >
                  <div>
                    <ImdButton
                      className={classes.smallButton}
                      variant="contained"
                      size="small"
                      color="inherit"
                      onClick={() => previousItemTime(item)}
                    >
                      <ArrowLeftIcon />
                    </ImdButton>
                  </div>
                </ImdTooltip>
                <ImdTooltip
                  title={'Volgende periode'}
                  placement="top-start"
                  className={classes.linkItem}
                >
                  <div>
                    <ImdButton
                      className={classes.smallButton}
                      variant="contained"
                      size="small"
                      color="inherit"
                      onClick={() => nextItemTime(item)}
                      disabled={nextDisabled}
                    >
                      <ArrowRightIcon />
                    </ImdButton>
                  </div>
                </ImdTooltip>
                <ImdTooltip
                  title={'Actuele periode'}
                  placement="top-start"
                  className={classes.linkItem}
                >
                  <div>
                    <ImdButton
                      className={classes.smallButton}
                      variant="contained"
                      size="small"
                      color="inherit"
                      onClick={() => returnToCurrentPeriod(item)}
                    >
                      <HistoryIcon />
                    </ImdButton>
                  </div>
                </ImdTooltip>
              </>
            ) : null}
            {item.visualizationType !== 5 ? (
              <ImdTooltip
                title={t('content.visualize.title')}
                placement="top-start"
                className={classes.linkItem}
              >
                <div>
                  <ImdButton
                    className={classes.smallButton}
                    variant="contained"
                    size="small"
                    color="inherit"
                    onClick={async () => {
                      open(await visualizeLinkDataHelper(), '_blank');
                    }}
                  >
                    <LinkIcon />
                  </ImdButton>
                </div>
              </ImdTooltip>
            ) : null}
          </>
        )}
        <ManualEntryLink item={item} locations={locations} />
      </div>
    </div>
  );
};

const useImdCardStyles = makeStyles(() => ({
  graphContainer: {
    flex: '0 0 50%'
  },
  graphBar: {
    marginTop: '4px',
    paddingLeft: '24px',
    gap: '4px',
    display: 'flex'
  },
  smallButton: {
    minWidth: '32px',
    maxHeight: '30px'
  }
}));

GraphButtonsBar.propTypes = {
  item: PropTypes.object,
  locations: PropTypes.array,
  fetchDashboardItemData: PropTypes.func
};

export default GraphButtonsBar;
