import { createSelector } from 'reselect';

export const tenantsSelector = createSelector(
  state => state.get('tenantsList'),
  tenantsList => tenantsList.toJS()
);

export const tenantSelector = createSelector(
  state => state.get('tenant'),
  tenant => tenant.toJS()
);
