import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  Editing,
  Export,
  Paging,
  SearchPanel,
  StateStoring,
  ColumnFixing,
  Pager,
  Sorting
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { FilterRow } from 'devextreme-react/tree-list';
import { HeaderFilter } from 'devextreme-react/pivot-grid-field-chooser';
import { Workbook } from 'exceljs';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { check } from '../authorization/Can';
import { PropTypes } from 'prop-types';

export default function ImdDataTable({
  data,
  title,
  addPermission,
  editPermission,
  deletePermission,
  columns,
  hideActions,
  onSelectionChangedEvent,
  handleDeleteButton,
  handleViewButton,
  handleEditButton,
  handleAddButton,
  editing,
  displayViewButton = false,
  displayDeleteButton = true,
  userAllowedToEdit = true,
  stateStorageKey = 'default'
}) {
  const { t } = useTranslation();
  const userRole = getCurrentUserRole(
    useSelector(state => currentUserSelector(state))
  );
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  const [visibleColumns, setVisibleColumns] = useState([]);

  const exportFilename = title || 'Data';

  const filterColumns = columns => {
    if (!canEdit || !canDelete || hideActions || isMobile) {
      return columns.filter(column => {
        if (column.props && column.props.type !== 'buttons') {
          return column;
        }
      });
    }

    return columns;
  };

  React.useEffect(() => {
    if (grid.current.instance) {
      grid.current.instance.selectRows([], false);
    }
  }, [data]);

  React.useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem(stateStorageKey));
    const shouldShowItem = (dataField, current) => {
      if (storedState === null || storedState.columns === undefined) {
        return current;
      }

      const column = storedState.columns.find(
        column => column.dataField === dataField
      );
      return column !== undefined ? column.visible : current;
    };

    if (addPermission === undefined) {
      addPermission = 'datatable:add';
    }
    if (editPermission === undefined) {
      editPermission = 'datatable:edit';
    }
    if (deletePermission === undefined) {
      deletePermission = 'datatable:delete';
    }
    setCanAdd(check(userRole, addPermission));
    const mayEdit = check(userRole, editPermission);
    const mayDelete = check(userRole, deletePermission);
    setCanEdit(mayEdit);
    setCanDelete(mayDelete);

    let visibleColumns = [];
    if (columns?.props) {
      visibleColumns = columns.props.children.map(child => {
        return {
          ...child,
          props: {
            ...child.props,
            visible: shouldShowItem(child.props.dataField, child.props.visible)
          }
        };
      });

      visibleColumns = filterColumns(visibleColumns);
    }
    setVisibleColumns(visibleColumns);
  }, []);

  const [selected, setSelected] = useState();
  const onSelectionChanged = selectedRow => {
    const s = selectedRow.selectedRowsData[0];
    setSelected(s);
    if (onSelectionChangedEvent !== undefined) {
      onSelectionChangedEvent(s);
    }
  };

  const onToolbarPreparing = toolbar => {
    if (canDelete && displayDeleteButton) {
      toolbar.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        disabled: selected === undefined,
        locateInMenu: 'auto',
        options: {
          icon: 'trash',
          text: t('general.delete'),
          onClick: () => handleDeleteButton(selected),
          disabled: !userAllowedToEdit
        }
      });
    }

    if (displayViewButton) {
      toolbar.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        disabled: selected === undefined,
        locateInMenu: 'auto',
        options: {
          icon: 'textdocument',
          text: t('general.view'),
          onClick: () => handleViewButton(selected)
        }
      });
    }

    if (canEdit) {
      toolbar.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        disabled: selected === undefined,
        locateInMenu: 'auto',
        options: {
          icon: 'edit',
          text: t('general.edit'),
          onClick: () => handleEditButton(selected)
        }
      });
    }

    if (canAdd) {
      toolbar.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'add',
          width: 136,
          text: t('general.add'),
          onClick: () => handleAddButton(selected)
        }
      });
    }

    toolbar.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      options: {
        icon: 'refresh',
        text: t('general.reset.grid'),
        onClick: () => {
          setVisibleColumns(filterColumns(columns.props.children));
          grid.current.instance.state(null);
        }
      }
    });

    toolbar.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'xlsfile',
          text: t('general.exportexcel'),
          onClick: () => onExporting(grid.current.instance)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'columnchooser',
          text: t('general.columnchooser'),
          onClick: () => grid.current.instance.showColumnChooser()
        }
      }
    );
  };
  const configureEditing = () => {
    if (editing === undefined) {
      return (
        <Editing
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}
        />
      );
    } else {
      return (
        <Editing
          mode={editing.mode}
          allowAdding={editing.allowAdding}
          allowDeleting={editing.allowDeleting}
          allowUpdating={editing.allowUpdating}
        />
      );
    }
  };

  const onExporting = datagridComponent => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(exportFilename);

    exportDataGrid({
      component: datagridComponent,
      worksheet: worksheet
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(
          new Blob([buffer], { type: 'application/octet-stream' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${exportFilename}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
    datagridComponent.cancel = true;
  };

  const grid = useRef();

  const devextremeTable = (
    <div id={'imd-data-grid'}>
      <DataGrid
        ref={grid}
        dataSource={data}
        keyExpr={'id'}
        showBorders={false}
        selection={{ mode: 'single' }}
        hoverStateEnabled={true}
        onSelectionChanged={onSelectionChanged}
        onToolbarPreparing={onToolbarPreparing}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        columnAutoWidth={false}
        wordWrapEnabled={true}
        onRowDblClick={() => {
          if (userAllowedToEdit) {
            handleEditButton(selected);
          } else {
            handleViewButton(selected);
          }
        }}
      >
        <ColumnFixing enabled={true} />
        <Export enabled={false} />
        {configureEditing()}
        <FilterRow visible={true} applyFilter={true} />
        <HeaderFilter visible={false} />
        <SearchPanel
          visible={true}
          width={240}
          placeholder={t('general.search')}
        />
        <Paging enabled={true} defaultPageSize={15} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 15, 20]}
          showInfo={true}
        />
        <Sorting mode="multiple"></Sorting>
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={stateStorageKey}
        />
        {visibleColumns}
      </DataGrid>
    </div>
  );

  return <>{devextremeTable}</>;
}

ImdDataTable.propTypes = {
  columns: PropTypes.shape({
    filter: PropTypes.func,
    props: PropTypes.shape({
      children: PropTypes.array
    })
  }),
  userAllowedToEdit: PropTypes.bool,
  stateStorageKey: PropTypes.string
};
