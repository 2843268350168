import axios from 'axios';
import configFile from '../App_config.json';
import { getActiveTenant } from 'utils/tenantManager.js';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

const getTenantQueryParameter = () => {
  return `?tenant=${getActiveTenant()}`;
};

export const getLocationBySensightId = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.measurementServiceUrl +
      'location/sensightid/' +
      sensightId +
      getTenantQueryParameter(),
    config
  );
};

export const getLocationById = async (user, id) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.measurementServiceUrl +
      'location/' +
      id +
      getTenantQueryParameter(),
    config
  );
};

export const setLocationOnline = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.measurementServiceUrl +
      'location/' +
      locationId +
      '/setonline' +
      getTenantQueryParameter(),
    null,
    config
  );
};

export const setLocationOffline = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.measurementServiceUrl +
      'location/' +
      locationId +
      '/setoffline' +
      getTenantQueryParameter(),
    null,
    config
  );
};

export const hideLocation = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.measurementServiceUrl +
      'location/' +
      locationId +
      '/hide' +
      getTenantQueryParameter(),
    null,
    config
  );
};

export const unhideLocation = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.measurementServiceUrl +
      'location/' +
      locationId +
      '/unhide' +
      getTenantQueryParameter(),
    null,
    config
  );
};

export const updateLocation = async (user, locationData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.measurementServiceUrl + 'location/' + locationData.id,
    locationData,
    config
  );
};

export const getAllLocations = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.measurementServiceUrl + 'location/', config);
};

export const getAllValidationProfiles = async user => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.measurementServiceUrl + 'validationProfile',
    config
  );
};

export const getDeviceById = async (user, deviceId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.measurementServiceUrl + 'device/' + deviceId,
    config
  );
};

export const getAllDevices = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.measurementServiceUrl + 'device/', config);
};

export const deleteDevice = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.measurementServiceUrl + 'device/sensight/' + sensightId,
    config
  );
};

export const editDevice = async (user, deviceData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.measurementServiceUrl +
      'device/sensight/' +
      deviceData.sensightId,
    deviceData,
    config
  );
};

export const syncManualMeasurements = async (user, measurements) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.measurementServiceUrl + 'manual/',
    {
      manualMeasurements: measurements
    },
    config
  );
};

export const getMeasurementChannelBySensightId = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.measurementServiceUrl + 'channel/sensightid/' + sensightId,
    config
  );
};

export const editChannel = async (user, channelData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.measurementServiceUrl +
      'channel/sensight/' +
      channelData.sensightId,
    channelData,
    config
  );
};

export const deleteChannel = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.measurementServiceUrl + 'channel/sensight/' + sensightId,
    config
  );
};

export const getAlerts = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.measurementServiceUrl + 'alerts', config);
};

export const createAlert = async (user, alertInformation) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.measurementServiceUrl + 'alerts',
    alertInformation,
    config
  );
};

export const deleteAlert = async (user, alertId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.measurementServiceUrl + 'alerts/' + alertId,
    config
  );
};

export const updateAlert = async (user, alertInformation) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.measurementServiceUrl + 'alerts/' + alertInformation.id,
    alertInformation,
    config
  );
};

export const getAlertsByAlertIds = async (user, alertIds) => {
  const config = createHeaderConfig(user);

  let queryString = '?';
  for (let index = 0; index < alertIds.length; index++) {
    if (index !== 0) {
      queryString += '&';
    }

    queryString += 'alertIds=' + alertIds[index];
  }

  return axios.get(
    configFile.measurementServiceUrl + 'alerts/alertIds' + queryString,
    config
  );
};
