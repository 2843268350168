import { makeActionCreator } from 'utils/makeActionCreator';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const showDialog = makeActionCreator(SHOW_DIALOG);

export const HIDE_DIALOG = 'HIDE_DIALOG';
export const hideDialog = makeActionCreator(HIDE_DIALOG);

export const FETCH_LOCATIONS_REQUESTED = 'FETCH_LOCATIONS_REQUESTED';
export const fetchLocationsRequested = makeActionCreator(
  FETCH_LOCATIONS_REQUESTED,
  'requesting'
);

export const IS_FETCHING_CHANNEL_DIALOG_LOCATIONS =
  'IS_FETCHING_CHANNEL_DIALOG_LOCATIONS';
export const isFetchingChannelDialogLocations = makeActionCreator(
  IS_FETCHING_CHANNEL_DIALOG_LOCATIONS,
  'isFetchingChannelDialogLocations'
);

export const IS_FETCHING_CHANNEL_DIALOG_DEVICES =
  'IS_FETCHING_CHANNEL_DIALOG_DEVICES';
export const isFetchingChannelDialogDevices = makeActionCreator(
  IS_FETCHING_CHANNEL_DIALOG_DEVICES,
  'isFetchingChannelDialogDevices'
);

export const HAS_STARTED_FETCHING_LOCATIONS_AND_DEVICES =
  'HAS_STARTED_FETCHING_LOCATIONS_AND_DEVICES';
export const hasStartedFetchingLocationsAndDevices = makeActionCreator(
  HAS_STARTED_FETCHING_LOCATIONS_AND_DEVICES,
  'hasStartedFetchingLocationsAndDevices'
);

export const HAS_FINISHED_FETCHING_LOCATIONS_AND_DEVICES =
  'HAS_FINISHED_FETCHING_LOCATIONS_AND_DEVICES';
export const hasFinishedFetchingLocationsAndDevices = makeActionCreator(
  HAS_FINISHED_FETCHING_LOCATIONS_AND_DEVICES,
  'hasFinishedFetchingLocationsAndDevices'
);

export const IS_CLEARING_CHANNEL_DIALOG = 'IS_CLEARING_CHANNEL_DIALOG';
export const isClearingChannelDialog = makeActionCreator(
  IS_CLEARING_CHANNEL_DIALOG,
  'isClearingChannelDialog'
);

export const FETCH_DEVICES_REQUESTED = 'FETCH_DEVICES_REQUESTED';
export const fetchDevicesRequested = makeActionCreator(
  FETCH_DEVICES_REQUESTED,
  'requesting'
);

export const FETCH_DEVICES_SUCCEEDED = 'FETCH_DEVICES_SUCCEEDED';
export const fetchDevicesSucceeded = makeActionCreator(
  FETCH_DEVICES_SUCCEEDED,
  'devices'
);

export const FETCH_CHANNELS_REQUESTED = 'FETCH_CHANNELS_REQUESTED';
export const fetchChannelsRequested = makeActionCreator(
  FETCH_CHANNELS_REQUESTED
);

export const SET_VISUALIZATION_CHANNELS = 'SET_VISUALIZATION_CHANNELS';
export const setVisualizationChannels = makeActionCreator(
  SET_VISUALIZATION_CHANNELS,
  'channels'
);

export const DELETE_VISUALIZATION_CHANNEL = 'DELETE_VISUALIZATION_CHANNEL';
export const deleteVisualizationChannel = makeActionCreator(
  DELETE_VISUALIZATION_CHANNEL,
  'channel'
);

export const ADD_VISUALIZATION_CHANNEL = 'ADD_VISUALIZATION_CHANNEL';
export const addChannel = makeActionCreator(
  ADD_VISUALIZATION_CHANNEL,
  'channel'
);

export const SET_DISPLAYED_CHANNELS = 'SET_DISPLAYED_CHANNELS';
export const setDisplayedChannels = makeActionCreator(
  SET_DISPLAYED_CHANNELS,
  'channels'
);

export const DELETE_DISPLAYED_CHANNEL = 'DELETE_DISPLAYED_CHANNEL';
export const deleteDisplayedChannel = makeActionCreator(
  DELETE_DISPLAYED_CHANNEL,
  'channel'
);

export const ADD_DISPLAYED_CHANNEL = 'ADD_DISPLAYED_CHANNEL';
export const addDisplayedChannel = makeActionCreator(
  ADD_DISPLAYED_CHANNEL,
  'channel'
);

export const FILTER_DISPLAYED_CHANNELS = 'FILTER_DISPLAYED_CHANNELS';
export const filterDisplayedChannels = makeActionCreator(
  FILTER_DISPLAYED_CHANNELS,
  'query'
);

export const ADD_SELECTED_CHANNEL = 'ADD_SELECTED_CHANNEL';
export const addSelectedChannel = makeActionCreator(
  ADD_SELECTED_CHANNEL,
  'channel'
);

export const DELETE_SELECTED_CHANNEL = 'DELETE_SELECTED_CHANNEL';
export const deleteSelectedChannel = makeActionCreator(
  DELETE_SELECTED_CHANNEL,
  'channel'
);

export const SET_SELECTED_CHANNELS = 'SET_SELECTED_CHANNELS';
export const setSelectedChannels = makeActionCreator(
  SET_SELECTED_CHANNELS,
  'channels'
);

export const UPDATE_SELECTED_CHANNEL = 'UPDATE_SELECTED_CHANNEL';
export const updateSelectedChannel = makeActionCreator(
  UPDATE_SELECTED_CHANNEL,
  'channel'
);

export const RETRIEVE_SELECTED_CHANNELS = 'RETRIEVE_SELECTED_CHANNELS';
export const retrieveSelectedChannels = makeActionCreator(
  RETRIEVE_SELECTED_CHANNELS,
  'channels'
);

export const CLEAR_SELECT_CHANNEL_DIALOG_STATE =
  'CLEAR_SELECT_CHANNEL_DIALOG_STATE';
export const clearSelectChannelDialogState = makeActionCreator(
  CLEAR_SELECT_CHANNEL_DIALOG_STATE
);

export const CLEAR_SELECT_CHANNEL_DIALOG_STATE_WITHOUT_FETCH =
  'CLEAR_SELECT_CHANNEL_DIALOG_STATE_WITHOUT_FETCH';
export const clearSelectChannelDialogStateWithoutFetch = makeActionCreator(
  CLEAR_SELECT_CHANNEL_DIALOG_STATE_WITHOUT_FETCH
);

export const CLEAR_SELECT_CHANNEL_DIALOG_CHANNELS_STATE =
  'CLEAR_SELECT_CHANNEL_DIALOG_CHANNELS_STATE';
export const clearSelectChannelDialogChannelsState = makeActionCreator(
  CLEAR_SELECT_CHANNEL_DIALOG_CHANNELS_STATE
);

export const CLEAR_SELECTED_CHANNELS = 'CLEAR_SELECTED_CHANNELS';
export const clearSelectedChannels = makeActionCreator(CLEAR_SELECTED_CHANNELS);

export const SET_DEFAULTCHANNEL_CHARTTYPE = 'SET_DEFAULTCHANNEL_CHARTTYPE';
export const setDefaultChannelChartType = makeActionCreator(
  SET_DEFAULTCHANNEL_CHARTTYPE,
  'defaultChannelChartType'
);

export const SET_DEFAULTCHANNEL_AGGREGATIONTYPE =
  'SET_DEFAULTCHANNEL_AGGREGATIONTYPE';
export const setDefaultChannelAggregationType = makeActionCreator(
  SET_DEFAULTCHANNEL_AGGREGATIONTYPE,
  'defaultChannelAggregationType'
);
