import {
  UPSERT_SIMCARD_DETAILS,
  FETCH_SIMCARDS,
  CREATE_SIMCARD,
  EDIT_SIMCARD,
  DELETE_SIMCARD,
  createSimCardSucceeded,
  editSimCardSucceeded,
  deleteSimCardSucceeded,
  setSimCardsList
} from './simCardsActions';
import {
  takeEvery,
  select,
  put,
  take,
  actionChannel
} from 'redux-saga/effects';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  getAllSimCards,
  createSimCard,
  editSimCard,
  deleteSimCard
} from 'apis/FieldServiceApi';

export function* fetchSimCardsListSaga() {
  yield takeEvery(FETCH_SIMCARDS, executeList);
}

function* executeList() {
  try {
    const user = yield select(currentUserSelector);
    var fieldServiceSimCards = (yield getAllSimCards(user)).data;
    yield put(setSimCardsList(fieldServiceSimCards));
  } catch (e) {
    yield put(
      createSnackbar(t('content.simcards.fetchsimcardserror'), 'error')
    );
    console.log(e);
  }
}

export function* createSimCardSaga() {
  yield takeEvery(CREATE_SIMCARD, executeCreate);
}

export function* executeCreate() {
  try {
    const simCardDetailsChannel = yield actionChannel(UPSERT_SIMCARD_DETAILS);
    const formValues = yield take(simCardDetailsChannel);
    const technicalSimCardDetails = formValues.simCardDetails;
    const fieldServiceRequest = prepareFieldServiceRequest(
      technicalSimCardDetails
    );
    const user = yield select(currentUserSelector);
    const createdSimCard = (yield createSimCard(user, fieldServiceRequest))
      .data;
    yield put(createSnackbar(t('content.createsimcard.snackbar.text')));
    yield put(createSimCardSucceeded(createdSimCard));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* editSimCardSaga() {
  yield takeEvery(EDIT_SIMCARD, executeEdit);
}

function* executeEdit() {
  try {
    const simCardDetailsChannel = yield actionChannel(UPSERT_SIMCARD_DETAILS);
    const formValues = yield take(simCardDetailsChannel);
    const technicalSimCardDetails = formValues.simCardDetails;
    const fieldServiceRequest = prepareFieldServiceRequest(
      technicalSimCardDetails
    );
    const user = yield select(currentUserSelector);
    yield editSimCard(user, fieldServiceRequest);
    yield put(createSnackbar(t('content.editsimcard.snackbar.text')));
    yield put(editSimCardSucceeded(technicalSimCardDetails));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

function prepareFieldServiceRequest(technicalSimCardDetails) {
  return {
    id: technicalSimCardDetails.id,
    iccid: technicalSimCardDetails.iccid,
    msisdn: technicalSimCardDetails.msisdn,
    imsi: technicalSimCardDetails.imsi,
    mac: technicalSimCardDetails.mac,
    phoneNumber: technicalSimCardDetails.phoneNumber,
    ipAddress: technicalSimCardDetails.ipAddress,
    provider: technicalSimCardDetails.provider,
    ratePlan: technicalSimCardDetails.ratePlan,
    pin: technicalSimCardDetails.pin,
    puk: technicalSimCardDetails.puk
  };
}

export function* deleteSimCardSaga() {
  yield takeEvery(DELETE_SIMCARD, executeDelete);
}

function* executeDelete(action) {
  const simCard = action.deleteObject.selectedRow;
  const user = yield select(currentUserSelector);

  try {
    yield deleteSimCard(user, simCard.id);
    yield put(createSnackbar(t('content.deletesimcard.snackbar.text')));
    yield put(deleteSimCardSucceeded(simCard));
  } catch (exception) {
    console.error(exception);
  }
}
