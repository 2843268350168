import { createReducer } from 'utils/createReducer';
import {
  SET_CHART_DATA,
  SET_CHART_RENDERED,
  CLEAR_CHART_DATA,
  SET_CHANNEL_DATA,
  CLEAR_CHANNEL_DATA,
  SET_LOGITEM_DATA
} from './displayChartActions';
import { fromJS } from 'immutable';

export const chartData = createReducer(null, {
  [SET_CHART_DATA](state, { data }) {
    let renderKey = state.get('renderKey');

    return fromJS({
      renderKey: renderKey,
      measurements: data
    });
  },
  [CLEAR_CHART_DATA](state) {
    return state.merge({
      renderKey: 0,
      measurements: []
    });
  }
});

export const chartRendered = createReducer(null, {
  [SET_CHART_RENDERED](state, { value }) {
    return fromJS(value);
  }
});

export const channelData = createReducer(null, {
  [SET_CHANNEL_DATA](state, { data }) {
    return state.merge({
      channels: data
    });
  },
  [CLEAR_CHANNEL_DATA](state) {
    return state.merge({
      channels: []
    });
  }
});

export const logItemData = createReducer(null, {
  [SET_LOGITEM_DATA](state, { data }) {
    return fromJS(data);
  }
});
