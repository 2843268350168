import { connect } from 'react-redux';
import ThemesTableDisplay from './ThemesTableDisplay.jsx';
import { setTheme, deleteTheme, fetchThemes } from '../redux/themesActions';
import { themesSelector } from '../redux/themesSelectors.js';

const mapStateToProps = (state) => {
  return {
    themes: themesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteTheme(row) {
    dispatch(deleteTheme(row));
  },
  setTheme(row) {
    dispatch(setTheme(row));
  },
  fetchThemes() {
    dispatch(fetchThemes());
  }
});

export const ThemesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemesTableDisplay);
