import { connect } from 'react-redux';
import DevicesDisplay from './DevicesDisplay.jsx';
import { fetchDevices } from '../redux/devicesActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { devicesSelector } from '../redux/devicesSelectors';

const mapStateToProps = state => {
  return {
    devices: devicesSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDevices() {
    dispatch(fetchDevices());
  }
});

export const DevicesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesDisplay);
