import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { isEmptyObject } from 'utils/helperMethods';
import Grid from '@mui/material/Grid';
import ImdCard from 'components/imd-components/ImdCard';
import { CreateDetailsForm } from './createlocationforms/CreateDetailsForm';
import { CreateProjectForm } from './createlocationforms/CreateProjectForm';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

function CreateLocationDisplay(
  {
    // handleValidateLocationForm,
    createLocationDetails,
    createProjectDetails,
    setAutocompleteSelectedOrganizations,
    selectedOrganizations,
    setAutocompleteServiceContactPersons,
    selectedServiceContactPersons,
    setAutocompleteContactPerson,
    selectedContactPerson,
    onlineSwitchValue,
    setOnlineSwitchValue,
    hideSwitchValue,
    setHideSwitchValue,
    viewModel,
    setSaveButtonDisabled,
    fetchLocationDetails
  },
  ref
) {
  useEffect(() => {
    fetchLocationDetails();
  }, []);
  const { t } = useTranslation();
  const classes = useChangeProfileDetailsCardStyles();

  const projectFormRef = useRef();
  const locationFormRef = useRef();

  const locationContent = (
    <CreateDetailsForm
      locationTypes={viewModel.locationTypes}
      locationFormRef={locationFormRef}
      selectedContactPerson={selectedContactPerson}
      createLocationDetails={createLocationDetails}
      setAutocompleteContactPerson={setAutocompleteContactPerson}
      contacts={viewModel.contacts}
    />
  );

  const projectContent = (
    <CreateProjectForm
      organizations={viewModel.organizations}
      contacts={viewModel.contacts}
      projectFormRef={projectFormRef}
      onlineSwitchValue={onlineSwitchValue}
      selectedOrganizations={selectedOrganizations}
      setAutocompleteContactPerson={setAutocompleteContactPerson}
      hideSwitchValue={hideSwitchValue}
      setHideSwitchValue={setHideSwitchValue}
      setOnlineSwitchValue={setOnlineSwitchValue}
      selectedServiceContactPersons={selectedServiceContactPersons}
      createProjectDetails={createProjectDetails}
      setAutocompleteSelectedOrganizations={
        setAutocompleteSelectedOrganizations
      }
      setAutocompleteServiceContactPersons={
        setAutocompleteServiceContactPersons
      }
    />
  );

  const formRef = useRef();

  setSaveButtonDisabled(false);

  const locationTabContent = (
    <Grid container spacing={2} className={classes.tabContent}>
      <Grid item xs={12} md={8}>
        <ImdCard
          title={t('content.locationdashboard.card.locationDetails')}
          content={
            !isEmptyObject(location) ? locationContent : <p>Fetching...</p>
          }
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ImdCard
          title={t('content.locationdashboard.card.manage')}
          content={projectContent}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={false}
        onSubmit={() => {
          projectFormRef.current.handleSubmit();
          locationFormRef.current.handleSubmit();
        }}
      />
    </Grid>
  );

  useImperativeHandle(ref, () => formRef);

  return <>{locationTabContent}</>;
}

const useChangeProfileDetailsCardStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

export default forwardRef(CreateLocationDisplay);
