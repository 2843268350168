import { connect } from 'react-redux';
import CreateDeviceDisplay from './CreateDeviceDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  availableSimCardsSelector,
  onlineSwitchValueSelector,
  visibleSwitchValueSelector
} from '../redux/devicesSelectors.js';
import {
  createDevice,
  upsertTechnicalDeviceDetails,
  upsertTechnicalDeviceDescription,
  upsertFunctionalDeviceDetails,
  fetchAvailableSimCards,
  setDeviceOnlineSwitchValue,
  setDeviceVisibleSwitchValue
} from '../redux/devicesActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = state => {
  return {
    currentUser: currentUserSelector(state),
    availableSimCards: availableSimCardsSelector(state),
    onlineSwitchValue: onlineSwitchValueSelector(state),
    visibleSwitchValue: visibleSwitchValueSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  fetchAvailableSimCards() {
    dispatch(fetchAvailableSimCards());
  },
  createDevice() {
    dispatch(createDevice());
  },
  upsertFunctionalDeviceDetails(functionalDeviceDetails) {
    dispatch(upsertFunctionalDeviceDetails(functionalDeviceDetails));
  },
  upsertTechnicalDeviceDetails(technicalDeviceDetails) {
    dispatch(upsertTechnicalDeviceDetails(technicalDeviceDetails));
  },
  upsertTechnicalDeviceDescription(technicalDeviceDescription) {
    dispatch(upsertTechnicalDeviceDescription(technicalDeviceDescription));
  },
  setDeviceOnlineSwitchValue(switchValue) {
    dispatch(setDeviceOnlineSwitchValue(switchValue));
  },
  setDeviceVisibleSwitchValue(switchValue) {
    dispatch(setDeviceVisibleSwitchValue(switchValue));
  }
});

export const CreateDeviceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateDeviceDisplay);
