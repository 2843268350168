import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, IconButton } from '@mui/material';
import ImdButton from 'components/imd-components/ImdButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { VisualizationTypesDialog } from './dialogs/VisualizationTypesDialog.jsx';
import { SectionDialog } from './dialogs/SectionDialog.jsx';
import { v4 as uuidv4 } from 'uuid';
import ImdPopup from 'components/imd-components/ImdPopup';
import DashboardItemIcon from './DashboardItemIcon';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const MAX_COLUMNS = 12;

export default function CreateDashboardDisplay({
  dashboard,
  onSave,
  sections,
  addSection,
  removeSection,
  editSection,
  setSections,
  setSaveButtonDisabled,
  saveButtonDisabled,
  fetchDataScienceReports,
  dataScienceReports
}) {
  const classes = useImdCardStyles();
  const { t } = useTranslation();

  useEffect(() => {
    fetchDataScienceReports();
  }, []);
  useEffect(() => {
    setSections(dashboard.dashboardSections);
  }, [dashboard]);

  useEffect(() => {
    // Check of de sections nog wel geldige rowIndexen hebben
    if (sections.some(s => s.rowIndex > sections.length - 1)) {
      resetRowIndexes();
    }
    setCurrentSectionRowIndex(sections.length);
  }, [sections]);

  const initialStateEditingItem = {
    title: '',
    selectedVisualizationType: 0,
    selectedVisualizationSettings: null
  };

  const [selectedColumnSetting, setColumnSetting] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [editColumnsPopup, setEditColumnsPopup] = useState(false);
  const [currentSectionRowIndex, setCurrentSectionRowIndex] = useState(0);

  const [currentEditingItem, setCurrentEditingItem] = useState(
    initialStateEditingItem
  );
  const [currentEditingSection, setCurrentEditingSection] = useState(null);

  const [removeSectionPopup, setRemoveSectionPopup] = useState(false);
  const [sectionToRemove, setSectionToRemove] = useState(null);

  const [removeItemPopup, setRemoveItemPopup] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const prepareEditSection = section => {
    setCurrentEditingSection(section);
    setIsEditing(true);
  };

  const openEditItemDialog = sectionItem => {
    setCurrentEditingItem(sectionItem);
    setEditItem(true);
  };

  const editCurrentEditingItem = value => {
    const foundSection = sections.find(
      s => s.rowIndex === currentEditingItem.rowIndex
    );

    const itemIndex = foundSection.items.findIndex(
      i => i.id === currentEditingItem.id
    );

    foundSection.items[itemIndex].visualizationType =
      value.selectedVisualizationType;
    foundSection.items[itemIndex].visualizationSettings =
      value.selectedVisualizationSettings;
    foundSection.items[itemIndex].title = value.title;

    editSection(foundSection);
    setCurrentEditingItem(initialStateEditingItem);
  };

  const setSelectedColumnSetting = value => {
    if (saveButtonDisabled) {
      setSaveButtonDisabled(false);
    }

    setColumnSetting(value);
  };

  const newItem = props => {
    const itemId = uuidv4();
    return {
      id: itemId,
      visualizationType: 0,
      ...props
    };
  };

  const constructItemsArray = (rowIndex, selectedColumns) => {
    let items = [];

    for (let index = 0; index < selectedColumns; index++) {
      const item = newItem({ rowIndex });
      items.push(item);
    }

    return items;
  };

  const handlePopupOk = () => {
    setEditColumnsPopup(false);
    setIsEditing(false);
  };

  const handleColumnDialogSave = () => {
    if (currentEditingSection !== null) {
      //setEditColumnsPopup(true);
      let temp = { ...currentEditingSection };
      const extraColumns = selectedColumnSetting - temp.items.length;

      if (extraColumns === 0) {
        setIsEditing(false);
        return;
      } else if (extraColumns < 0) {
        // Haal laatste items op en check of deze data bevatten
        const usedItems = temp.items
          .slice(Math.max(temp.items.length + extraColumns, 0))
          .filter(i => i.visualizationType !== 0);

        if (usedItems.length > 0) {
          // Geef warning als dit het geval is
          setEditColumnsPopup(true);
          return;
        } else {
          temp.items = [...temp.items.slice(0, extraColumns)];
        }
      } else {
        const newItems = constructItemsArray(temp.rowIndex, extraColumns);
        temp.items = [...temp.items, ...newItems];
      }

      temp.numberOfItems = selectedColumnSetting;

      editSection(temp);
      setCurrentEditingSection(null);
      setEditColumnsPopup(false);
      setIsEditing(false);
    } else {
      const sectionId = uuidv4();
      const items = constructItemsArray(
        currentSectionRowIndex,
        selectedColumnSetting
      );
      addSection({
        id: sectionId,
        rowIndex: currentSectionRowIndex,
        numberOfItems: selectedColumnSetting,
        items: items
      });

      setCurrentSectionRowIndex(currentSectionRowIndex + 1);
      setIsEditing(false);
    }
  };

  const handleRemoveSection = section => {
    setSectionToRemove(section);
    setRemoveSectionPopup(true);
  };

  const handleRemoveSectionPopupOk = () => {
    removeSection(sectionToRemove);
    setSectionToRemove(null);
    setRemoveSectionPopup(false);
  };

  const handleRemoveSectionItem = (e, { sectionId, index }) => {
    setItemToRemove({ sectionId, index });
    setRemoveItemPopup(true);
    e.stopPropagation();
  };

  const handleRemoveSectionItemPopupOk = () => {
    const { sectionId, index } = itemToRemove;

    const section = sections.find(s => s.id === sectionId);
    section.items.splice(index, 1);
    section.numberOfItems--;
    editSection(section);

    setItemToRemove(null);
    setRemoveItemPopup(false);
  };

  const onDragEnd = e => {
    const { reason, destination, source } = e;

    if (reason !== 'DROP' || !destination || !source) {
      return;
    }

    const oldSection = sections.find(s => s.id === source?.droppableId);

    if (destination.droppableId === 'new-section') {
      const element = oldSection.items[source?.index];
      oldSection.items.splice(source?.index, 1);
      const sectionId = uuidv4();
      const rowIndex = currentSectionRowIndex;

      addSection({
        id: sectionId,
        rowIndex,
        numberOfItems: 1,
        items: [{ ...element, rowIndex }]
      });
      editSection(oldSection);
      setCurrentSectionRowIndex(rowIndex + 1);
      return;
    }

    const newSection = sections.find(s => s.id === destination?.droppableId);

    if (
      source?.droppableId !== destination?.droppableId &&
      newSection.items.length >= MAX_COLUMNS
    ) {
      return;
    }

    const element = {
      ...oldSection.items[source?.index],
      rowIndex: newSection.rowIndex
    };
    oldSection.items.splice(source?.index, 1);
    newSection.items.splice(destination?.index, 0, element);

    if (source?.droppableId !== destination?.droppableId) {
      oldSection.numberOfItems--;
      newSection.numberOfItems++;
      editSection(oldSection);
    }
    editSection(newSection);
  };

  const onAddNewItem = (e, sectionId) => {
    const section = sections.find(s => s.id === sectionId);
    const item = newItem({ rowIndex: section.rowIndex });
    section.items = [...section.items, item];
    section.numberOfItems++;
    editSection(section);
  };

  const SectionItem = ({ section, index }) => {
    const sectionItem = section.items[index];

    return (
      <Draggable key={index} draggableId={sectionItem.id} index={index}>
        {providedDraggable => (
          <Grid
            item
            xs
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
            className={classes.item}
          >
            <div
              key={'sectionItem' + index}
              className={classes.itemContent}
              onClick={() => openEditItemDialog(sectionItem)}
            >
              <IconButton
                color="primary"
                onClick={e =>
                  handleRemoveSectionItem(e, { sectionId: section.id, index })
                }
                className={classes.removeSectionItemButton}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
              <DashboardItemIcon sectionItem={sectionItem} />
            </div>
          </Grid>
        )}
      </Draggable>
    );
  };

  const NewSectionItem = ({ section }) => (
    <Grid item xs={1} className={classes.newSectionItem}>
      <div
        className={`${classes.itemContent} ${classes.newSectionItemContent}`}
        onClick={e => onAddNewItem(e, section.id)}
      >
        <DashboardItemIcon sectionItem={{ visualizationType: 0 }} />
      </div>
    </Grid>
  );

  const getSortedSections = () => {
    return sections.sort((a, b) => a.rowIndex - b.rowIndex);
  };

  const resetRowIndexes = () => {
    // Sorteer de sections op rowIndex en zet de rowindex gelijk aan de for loop index
    // Zodat de rowindexen weer kloppen (voor corrupte dashboards)
    for (let index = 0; index < getSortedSections().length; index++) {
      if (sections[index].rowIndex !== index) {
        console.dev(
          `Found a corrupt section index, resetting...`,
          sections[index]
        );
        editSection({
          ...sections[index],
          rowIndex: index
        });
      }
    }
  };

  const renderSelectedSections = () => {
    return getSortedSections().map(section => {
      return (
        <React.Fragment key={section.id}>
          <Grid container className={classes.sectionHeader}>
            <div key={section.id}>
              <IconButton
                color="primary"
                onClick={() => handleRemoveSection(section)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => prepareEditSection(section)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </div>
          </Grid>
          <Droppable
            key={section.id}
            droppableId={section.id}
            direction="horizontal"
          >
            {providedDroppable => (
              <Grid
                container
                className={classes.section}
                spacing={3}
                justifyContent="flex-end"
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
              >
                {section.items.map((item, index) => (
                  <SectionItem key={item.id} section={section} index={index} />
                ))}
                {providedDroppable.placeholder}
                {section.items.length < MAX_COLUMNS ? (
                  <NewSectionItem section={section} />
                ) : null}
              </Grid>
            )}
          </Droppable>
        </React.Fragment>
      );
    });
  };

  const renderNewSection = () => {
    return (
      <Droppable droppableId="new-section" direction="horizontal">
        {providedDroppable => (
          <>
            <Grid
              container
              className={classes.newSection}
              onClick={() => setIsEditing(true)}
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {providedDroppable.placeholder}
              <AddIcon className={classes.largeIconButton} />
            </Grid>
          </>
        )}
      </Droppable>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <>
        <Grid container>
          <div className={classes.header}>
            <div className={classes.headerDiv}>
              <h3 className={classes.headerText}>{dashboard.title}</h3>
              <ImdButton
                color={'primary'}
                variant="contained"
                className={classes.routeButton}
                startIcon={<SaveIcon />}
                onClick={() => onSave()}
              >
                {t('content.dashboard.createdashboard.save')}
              </ImdButton>
            </div>
          </div>
          {renderSelectedSections()}
          {renderNewSection()}
        </Grid>

        <ImdPopup
          open={editColumnsPopup}
          title={t('content.dashboard.createdashboard.editrowwarning')}
          content={t('content.dashboard.createdashboard.editrowinfo')}
          handleOk={() => handlePopupOk()}
          handleClose={() => handlePopupOk()}
          displayCancel={false}
        />

        <ImdPopup
          open={removeSectionPopup}
          title={t('content.dashboard.createdashboard.removesection')}
          content={t(
            'content.dashboard.createdashboard.removesectionconfirmation'
          )}
          handleOk={() => handleRemoveSectionPopupOk()}
          handleClose={() => setRemoveSectionPopup(false)}
        />

        <ImdPopup
          open={removeItemPopup}
          title={t('content.dashboard.createdashboard.removeitem')}
          content={t(
            'content.dashboard.createdashboard.removeitemconfirmation'
          )}
          handleOk={() => handleRemoveSectionItemPopupOk()}
          handleClose={() => setRemoveItemPopup(false)}
        />

        <SectionDialog
          open={isEditing}
          setOpen={setIsEditing}
          selectedColumnSetting={selectedColumnSetting}
          setSelectedColumnSetting={setSelectedColumnSetting}
          handleSave={() => handleColumnDialogSave()}
          saveButtonDisabled={saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
        />

        <VisualizationTypesDialog
          open={editItem}
          setEditItem={setEditItem}
          currentEditingItem={currentEditingItem}
          handleSave={value => editCurrentEditingItem(value)}
          saveButtonDisabled={saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
          dataScienceOptions={dataScienceReports}
        />
      </>
    </DragDropContext>
  );
}

const useImdCardStyles = makeStyles(theme => ({
  header: {
    marginBottom: '0px',
    width: '100%'
  },
  headerDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '8px'
  },
  headerText: {
    marginTop: '0px',
    marginBottom: '0px',
    width: '100%',
    lineHeight: '32px'
  },
  routeButton: {
    alignSelf: 'end',
    marginBottom: '4px'
  },
  sectionHeader: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row-reverse',
    borderRadius: '5px 5px 0px 0px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  largeIconButton: {
    width: '3em',
    height: '3em',
    marginTop: '8px',
    marginBottom: '8px',
    color: theme.palette.primary.main
  },
  container: {
    height: '100%'
  },
  section: {
    margin: 0,
    marginBottom: '8px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  item: {
    padding: '6px'
  },
  itemContent: {
    position: 'relative',
    backgroundColor: '#EEE',
    width: '100%',
    height: '12rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px 5px 5px 5px',
    '&:hover': {
      backgroundColor: '#DDD',
      cursor: 'pointer'
    }
  },
  removeSectionItemButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '0.5rem'
  },
  newSection: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px 5px 5px 5px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      cursor: 'pointer'
    }
  },
  inputField: {
    marginBottom: '8px'
  },
  newSectionItem: {
    opacity: 0.6
  },
  newSectionItemContent: {
    background: `repeating-linear-gradient(
      45deg,
      #EEE,
      #EEE 10px,
      #FFF 10px,
      #FFF 20px
    )`,
    '&:hover': {
      background: `repeating-linear-gradient(
        45deg,
        #DDD,
        #DDD 10px,
        #FFF 10px,
        #FFF 20px
      )`
    }
  },
  draggingOverItem: {
    background: 'red'
  }
}));
