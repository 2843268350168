import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import ImdCard from 'components/imd-components/ImdCard';
import { AlertDetailsForm } from './forms/AlertDetailsForm';
import { SubscriptionDetailsForm } from './forms/SubscriptionDetailsForm';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { SelectChannelDialogContainer } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/selectChannelDialogContainer';
import ImdButton from 'components/imd-components/ImdButton';
import { getLocationById, getDeviceById } from 'apis/MeasurementServiceApi';

function ManageAlertDisplay(
  {
    alertActiveSwitchValue,
    availableSubscribers,
    setSaveButtonDisabled,
    upsertAlert,
    upsertAlertDetails,
    upsertSubscriptionDetails,
    setSelectedSubscribers,
    setAlertActiveSwitchValue,
    alertViewmodel,
    selectedSubscribers,
    openSelectChannelsDialog,
    selectedChannels,
    selectedDevices,
    selectedLocations,
    addSelectedChannel,
    addSelectedDevice,
    addSelectedLocation,
    fetchSubscribers,
    subscribers,
    setSubscribers,
    currentUser
  },
  ref
) {
  const { t } = useTranslation();

  const classes = useSpecificCardStyles();
  const alertDetailsFormRef = useRef();
  const subscriptionDetailsFormRef = useRef();
  const [alertDetailsFormValid, setAlertDetailsFormValid] = useState(false);
  const [subscriptionDetailsFormValid, setSubscriptionDetailsFormValid] =
    useState(false);

  React.useEffect(() => {
    alertDetailsFormRef.current.validateForm();
    subscriptionDetailsFormRef.current.validateForm();
    setSaveButtonDisabled(true);
  }, []);

  React.useEffect(() => {
    setSaveButtonDisabled(
      !(alertDetailsFormValid && subscriptionDetailsFormValid)
    );
  }, [alertDetailsFormValid, subscriptionDetailsFormValid]);

  const alertDetailsForm = (
    <>
      <SelectChannelDialogContainer
        clearSelectChannelDialogStateImmediately
        defaultAggregation={false}
        chartSettings={false}
      />
      <ImdButton
        color={'primary'}
        variant="contained"
        onClick={() => openSelectChannelsDialog()}
      >
        {t('content.alerts.card.selectchannel')}
      </ImdButton>
      <p>{t('content.alerts.card.selectedchannel')}</p>
      <ul>
        {selectedChannels
          ? selectedChannels.map(selectedChannel => (
              <li key={selectedChannel.sensightId}>
                {selectedChannel.displayLabel}
              </li>
            ))
          : null}
      </ul>
      <AlertDetailsForm
        alertDetailsFormRef={alertDetailsFormRef}
        alertViewmodel={alertViewmodel}
        alertActiveSwitchValue={alertActiveSwitchValue}
        setAlertActiveSwitchValue={setAlertActiveSwitchValue}
        setAlertDetailsFormValid={setAlertDetailsFormValid}
        upsertAlertDetails={upsertAlertDetails}
        selectedChannels={selectedChannels}
        selectedDevices={selectedDevices}
        selectedLocations={selectedLocations}
      />
    </>
  );

  const subscriptionDetailsForm = (
    <SubscriptionDetailsForm
      subscriptionDetailsFormRef={subscriptionDetailsFormRef}
      selectedSubscribers={selectedSubscribers}
      upsertSubscriptionDetails={upsertSubscriptionDetails}
      setSelectedSubscribers={setSelectedSubscribers}
      setSubscriptionDetailsFormValid={setSubscriptionDetailsFormValid}
      availableSubscribers={availableSubscribers}
      id={alertViewmodel ? alertViewmodel.id : null}
      subscribers={subscribers}
    />
  );

  React.useEffect(() => {
    setSaveButtonDisabled(false);
    setSelectedSubscribers([]);
    if (alertViewmodel) {
      fetchSubscribers(alertViewmodel.id);
      addSelectedChannel(alertViewmodel.channel);
      getLocationById(
        currentUser,
        alertViewmodel.locationMeasurementServiceId
      ).then(locationResponse => {
        getDeviceById(currentUser, alertViewmodel.deviceId).then(
          deviceResponse => {
            addSelectedDevice(deviceResponse.data);
            addSelectedLocation(locationResponse.data);
          }
        );
      });
    }
  }, []);

  React.useEffect(() => {
    if (subscribers.length > 0) {
      if (!alertViewmodel) {
        setSubscribers([0]);
        return;
      }
      if (subscribers[0].alertId !== alertViewmodel.id) {
        setSubscribers([]);
        return;
      }
      const selectedSubIds = subscribers.map(sub => sub.sensightContactId);
      setSelectedSubscribers(selectedSubIds);
    }
  }, [subscribers]);

  const formRef = useRef();

  const alertCardContent = (
    <Grid container spacing={2} className={classes.tabContent}>
      <Grid item md={6} xs={10}>
        <ImdCard
          title={t('content.alerts.alertdetails')}
          content={alertDetailsForm}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <Grid item md={6} xs={10}>
        <ImdCard
          title={t('content.alerts.subscriptiondetails')}
          content={subscriptionDetailsForm}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={true}
        onSubmit={() => {
          alertDetailsFormRef.current.handleSubmit();
          subscriptionDetailsFormRef.current.handleSubmit();
          upsertAlert();
        }}
      />
    </Grid>
  );

  useImperativeHandle(ref, () => formRef);
  return <>{alertCardContent}</>;
}

const useSpecificCardStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

export default forwardRef(ManageAlertDisplay);
