import { takeEvery, select, put } from 'redux-saga/effects';
import {
  SET_PREVIOUS_VISUALIZE_PERIOD,
  SET_NEXT_VISUALIZE_PERIOD,
  SET_TODAY_VISUALIZE_PERIOD,
  setVisualizeFromTime,
  setVisualizeUntilTime,
  SET_CURRENT_VISUALIZE_PERIOD,
  SET_VISUALIZE_FROM_TIME,
  SET_VISUALIZE_UNTIL_TIME
} from './toolbarActions';
import {
  visualizeFromTimeSelector,
  visualizeUntilTimeSelector,
  visualizePeriodSelector
} from './toolbarSelectors';
import { chartRenderedSelector } from '../../displayChartComponent/redux/displayChartSelectors';
import { displayChart } from '../../displayChartComponent/redux/displayChartActions';

const visualizePeriodsInDays = {
  fourHours: 6,
  eightHours: 3,
  twelveHours: 2,
  twentyFourHours: 1,
  fourtyEightHours: 2,
  oneWeek: 7,
  oneMonth: 30,
  threeMonths: 90,
  currentYear: 365,
  oneYear: 365
};

export function* setVisualizeFromTimeSaga() {
  yield takeEvery(SET_VISUALIZE_FROM_TIME, renderChart);
}

export function* setVisualizeUntilTimeSaga() {
  yield takeEvery(SET_VISUALIZE_UNTIL_TIME, renderChart);
}

export function* setPreviousVisualizePeriodSaga() {
  yield takeEvery(SET_PREVIOUS_VISUALIZE_PERIOD, setPreviousVisualizePeriod);
}

export function* setNextVisualizePeriodSaga() {
  yield takeEvery(SET_NEXT_VISUALIZE_PERIOD, setNextVisualizePeriod);
}

export function* setTodayVisualizePeriodSaga() {
  yield takeEvery(SET_TODAY_VISUALIZE_PERIOD, updateVisualizePeriodWithNewPeriod);
}

export function* updateVisualizePeriodWithNewPeriodSaga() {
  yield takeEvery(
    SET_CURRENT_VISUALIZE_PERIOD,
    updateVisualizePeriodWithNewPeriod
  );
}

function* renderChart() {
  const chartRendered = yield select(chartRenderedSelector);
  if (chartRendered) {
    yield put(displayChart());
  }
}

function* setPreviousVisualizePeriod() {
  const fromTime = yield select(visualizeFromTimeSelector);
  const untilTime = yield select(visualizeUntilTimeSelector);
  const period = yield select(visualizePeriodSelector);

  let fromResult = new Date();
  let untilResult = new Date();

  if (period === 'currentYear') {
    const currentYear = fromTime.getFullYear() - 1;

    fromResult = new Date(currentYear, 0, 1);
    untilResult = new Date(currentYear, 11, 31, 23, 59, 59);
  } else {
    const dateOffset = calculateDateOffset(period);

    fromResult.setTime(fromTime.getTime() - dateOffset);
    untilResult.setTime(untilTime.getTime() - dateOffset);
  }
  yield put(setVisualizeFromTime(fromResult));
  yield put(setVisualizeUntilTime(untilResult));
}

function* setNextVisualizePeriod() {
  const fromTime = yield select(visualizeFromTimeSelector);
  const untilTime = yield select(visualizeUntilTimeSelector);
  const period = yield select(visualizePeriodSelector);

  let fromResult = new Date();
  let untilResult = new Date();

  if (period === 'currentYear') {
    const currentYear = fromTime.getFullYear() + 1;

    fromResult = new Date(currentYear, 0, 1);
    untilResult = new Date(currentYear, 11, 31, 23, 59, 59);
  } else {
    const dateOffset = calculateDateOffset(period);

    fromResult.setTime(fromTime.getTime() + dateOffset);
    untilResult.setTime(untilTime.getTime() + dateOffset);
  }

  yield put(setVisualizeFromTime(fromResult));
  yield put(setVisualizeUntilTime(untilResult));
}

function* updateVisualizePeriodWithNewPeriod() {
  const period = yield select(visualizePeriodSelector);

  let fromResult = new Date();
  let untilResult = new Date();

  if (period === 'currentYear') {
    const currentYear = fromResult.getFullYear();

    fromResult = new Date(currentYear, 0, 1);
    untilResult = new Date(currentYear, 11, 31, 23, 59, 59);
  } else {
    const dateOffset = calculateDateOffset(period);
    fromResult.setTime(fromResult.getTime() - dateOffset);
  }

  yield put(setVisualizeFromTime(fromResult));
  yield put(setVisualizeUntilTime(untilResult));
}

function calculateDateOffset(period) {
  const periodInDays = visualizePeriodsInDays[period];
  const baseEquation = 24 * 60 * 60 * 1000;

  if (
    period === 'fourHours' ||
    period === 'eightHours' ||
    period === 'twelveHours'
  ) {
    return baseEquation / periodInDays;
  }
  return baseEquation * periodInDays;
}
