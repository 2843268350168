import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  FETCH_CHANNELS_LIST_OF_LOCATION,
  setChannelsListOfLocation,
  DELETE_CHANNEL_OF_LOCATION,
  deleteChannelOfLocationSucceeded
} from './channelsListActions';
import { getODataFormattedDateTimeString } from 'utils/helperMethods';
import { Get } from 'apis/MeasurementODataApi';
import { deleteChannel } from 'apis/MeasurementServiceApi';
import { getAllChannelsByLocationSensightId } from 'apis/FieldServiceApi';
import { t } from 'i18next';
import { getCurrentUserRole } from 'utils/currentUserReader';

export function* channelOfLocationSagas() {
  yield takeEvery(FETCH_CHANNELS_LIST_OF_LOCATION, fetchChannels);
  yield takeEvery(DELETE_CHANNEL_OF_LOCATION, deleteChannelOfLocation);
}

function* fetchChannels(action) {
  try {
    const user = yield select(currentUserSelector);

    let currentDate = getODataFormattedDateTimeString(new Date());

    const query =
      'channel?$filter=device/deviceplacements/any(o: o/locationId eq ' +
      action.location +
      ' and o/startDateTime lt ' +
      currentDate +
      ' and (o/endDateTime gt ' +
      currentDate +
      ' or o/endDateTime eq null))&$expand=validationprofile,device($select=name)';

    const measurementServiceChannels = yield Get(user, query);
    const fieldServiceChannels = (yield getAllChannelsByLocationSensightId(
      user,
      action.locationSensightId
    )).data;
    let channelsList = [];

    const role = getCurrentUserRole(user);

    if (fieldServiceChannels.length > 0) {
      if (measurementServiceChannels.length > 0) {
        fieldServiceChannels.forEach(fieldServiceChannel => {
          const measureChannel = measurementServiceChannels.find(
            measureChannel =>
              fieldServiceChannel.sensightId === measureChannel.sensightId
          );
          if (measureChannel) {
            measureChannel.measurementServiceId = measureChannel.id;
            fieldServiceChannel.fieldServiceId = fieldServiceChannel.id;
            channelsList.push({ ...measureChannel, ...fieldServiceChannel });
          } else if (role !== 'Reader') {
            fieldServiceChannel.externalChannelId =
              'Geen kanaal met actief apparaat in MS';
            return channelsList.push(fieldServiceChannel);
          }
        });
      } else {
        channelsList = fieldServiceChannels;
      }
    }
    yield put(setChannelsListOfLocation(channelsList));
  } catch (e) {
    yield put(
      createSnackbar(t('content.channels.fetchchannelserror'), 'error')
    );
    console.log(e);
  }
}

function* deleteChannelOfLocation(action) {
  const channel = action.channel;
  const user = yield select(currentUserSelector);

  try {
    yield deleteChannel(user, channel.sensightId);
    yield put(createSnackbar(t('content.deletechannel.snackbar.text')));
    yield put(deleteChannelOfLocationSucceeded(channel));
  } catch (exception) {
    if (exception?.response?.data == '547') {
      yield put(
        createSnackbar(t('content.channels.channelHasMeasurements'), 'error')
      );
    } else {
      console.error(exception);
    }
  }
}
