import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import ImdCard from '../../../imd-components/ImdCard';
import ImdButton from '../../../imd-components/ImdButton';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from 'formik-mui';
import makeStyles from '@mui/styles/makeStyles';
import { isEmptyObject } from 'utils/helperMethods';
import imdTimeZoneMenuItems from 'components/imd-components/imdTimeZoneMenuItems.jsx';

export function ChangeLocaleDisplay({ profileDetails, changeLocaleSettings }) {
  const { t } = useTranslation();
  const classes = useChangeLocaleStyles();

  const form = ({ submitForm, dirty }) => {
    return (
      <Form>
        <Field
          name="selectedLanguage"
          type="select"
          label={t('content.profile.language')}
          select
          fullWidth
          className={classes.textField}
          component={TextField}
          selected={'en'}
          variant="standard"
        >
          <MenuItem value="nl">{t('content.profile.language.dutch')}</MenuItem>
          <MenuItem value="en">
            {t('content.profile.language.english')}
          </MenuItem>
          <MenuItem value="de">{t('content.profile.language.german')}</MenuItem>
          <MenuItem value="pt">
            {t('content.profile.language.portuguese')}
          </MenuItem>
        </Field>
        <Field
          name="selectedTimezone"
          type="select"
          label={t('content.profile.timezone.card.input.text')}
          select
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        >
          {imdTimeZoneMenuItems}
        </Field>
        <br />
        <ImdButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={submitForm}
          disabled={!dirty}
        >
          {t('content.profile.changelanguage.card.button.text')}
        </ImdButton>
      </Form>
    );
  };

  return (
    <ImdCard
      title={t('content.profile.changelanguage.card.title')}
      content={
        <div>
          {!isEmptyObject(profileDetails) ? (
            <Formik
              enableReinitialize={true}
              initialValues={{
                selectedTimezone: profileDetails.timeZone,
                selectedLanguage: profileDetails.language
              }}
              onSubmit={localeSettings => changeLocaleSettings(localeSettings)}
            >
              {form}
            </Formik>
          ) : (
            <p>Fetching....</p>
          )}
        </div>
      }
    />
  );
}

const useChangeLocaleStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1),
    width: '250px'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));
