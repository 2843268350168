import { makeActionCreator } from 'utils/makeActionCreator';
export const ADD_SECTION = 'ADD_SECTION';
export const addSection = makeActionCreator(ADD_SECTION, 'section');

export const REMOVE_SECTION = 'REMOVE_SECTION';
export const removeSection = makeActionCreator(REMOVE_SECTION, 'section');

export const EDIT_SECTION = 'EDIT_SECTION';
export const editSection = makeActionCreator(EDIT_SECTION, 'section');

export const SET_SECTIONS = 'SET_SECTIONS';
export const setSections = makeActionCreator(SET_SECTIONS, 'sections');

export const CLEAR_SECTIONS = 'CLEAR_SECTIONS';
export const clearSections = makeActionCreator(CLEAR_SECTIONS);
