import { takeEvery, select, put } from 'redux-saga/effects';
import { FETCH_ACTIONS_LIST, setActionsList } from './actionsListActions';
import { getActions } from 'apis/FieldServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchActionsListSaga() {
  yield takeEvery(FETCH_ACTIONS_LIST, execute);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var actionsList = (yield getActions(user, actionsList)).data;
  yield put(setActionsList(actionsList));
}
