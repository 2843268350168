import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../../page/Content';
import { AlertsTableContainer } from './AlertsTableContainer';

export default function AlertsDisplay({
  alerts,
  fetchAlerts,
  fetchContactsList
}) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchAlerts();
    fetchContactsList();
  }, []);

  const content = (
    <>
      <AlertsTableContainer alerts={alerts}></AlertsTableContainer>
    </>
  );
  return (
    <Content
      title={t('content.alerts.title')}
      secondTitle={t('navbar.manage')}
      content={content}
    ></Content>
  );
}
