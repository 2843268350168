import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@mui/styles';

export default function Content(props) {
  let classes = useStyles();
  if (props.isChild) {
    classes = useChildStyles();
  }

  return <div className={classes.root}>{props.content}</div>;
}

Content.propTypes = {
  content: PropTypes.any.isRequired
};

const useStyles = makeStyles({
  root: {
    display: 'grid'
  }
});

const useChildStyles = makeStyles({
  root: {
    width: '100%'
  }
});
