import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { editLogItem } from 'apis/FieldServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import {
  EDIT_LOGITEM_OF_LOCATION,
  editLogItemOfLocationSucceeded
} from './editLogItemActions';

export function* editLogItemSaga() {
  yield takeEvery(EDIT_LOGITEM_OF_LOCATION, execute);
}

function* execute(action) {
  let logitem = action.logitem;
  const user = yield select(currentUserSelector);

  try {
    yield editLogItem(user, logitem);

    yield put(createSnackbar(t('content.editlogitem.snackbar.text')));

    yield put(editLogItemOfLocationSucceeded(logitem));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}
