import { connect } from 'react-redux';
import { DashboardLocationsDisplay } from './DashboardLocationsDisplay.jsx';
import { fetchLocationsList } from 'components/content/locations/redux/locationsListActions';
import { locationsListSelector } from 'components/content/locations/redux/locationsListSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    allLocations: locationsListSelector(state),
    render: ownProps.render
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLocationsList() {
    dispatch(fetchLocationsList());
  },

  filterLocations(locations, min, max) {
    return filterLocations(locations, min, max);
  }
});

export const DashboardLocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLocationsDisplay);

const filterLocations = (locations, min, max) => {
  return locations.map(location => {
    if (
      location.percentageValidation >= min &&
      location.percentageValidation <= max &&
      location.online === true
    ) {
      return location;
    }
  });
};
