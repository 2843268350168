import { connect } from 'react-redux';
import DashboardItemDisplay from './DashboardItemDisplay.jsx';
import { dashboardMeasurementsSelector } from './redux/dashboardMeasurementsSelector.js';
import { fetchDashboardItemData } from './redux/dashboardItemActions.js';
import { setChartDisplayOptions } from 'components/content/visualize/toolbar/redux/toolbarActions.js';

const mapStateToProps = (state, { dashboard, item, renderKey, locations }) => {
  const measurements =
    dashboardMeasurementsSelector(state).find(d => d.id === item.id)
      ?.measurements || {};

  return {
    measurements,
    dashboard,
    item,
    renderKey,
    locations
  };
};

const mapDispatchToProps = dispatch => ({
  setChartDisplayOptions(displayOptions) {
    dispatch(setChartDisplayOptions(displayOptions));
  },
  fetchDashboardItemData(item) {
    dispatch(fetchDashboardItemData(item));
  }
});

export const DashboardItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardItemDisplay);
