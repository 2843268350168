import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState
} from 'react';
import { SimCardDetailsForm } from './forms/SimCardDetailsForm.js';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

function EditSimCardDisplay(
  { viewModel, editSimCard, setSaveButtonDisabled, upsertSimCardDetails },
  ref
) {
  const simCardDetailsFormRef = useRef();
  const [simCardDetailsFormValid, setSimCardDetailsFormValid] = useState(false);

  React.useEffect(() => {
    setSaveButtonDisabled(true);
    simCardDetailsFormRef.current.validateForm();
  }, []);

  React.useEffect(() => {
    setSaveButtonDisabled(!simCardDetailsFormValid);
  }, [simCardDetailsFormValid]);

  const simCardDetailsContent = (
    <SimCardDetailsForm
      simCardDetailsFormRef={simCardDetailsFormRef}
      upsertSimCardDetails={upsertSimCardDetails}
      setSimCardDetailsFormValid={setSimCardDetailsFormValid}
      simCardDetailsViewmodel={viewModel}
    />
  );
  const formRef = useRef();

  const simCardTabContent = (
    <>
      {simCardDetailsContent}
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={true}
        onSubmit={() => {
          simCardDetailsFormRef.current.handleSubmit();
          editSimCard(viewModel.id);
        }}
      />
    </>
  );

  useImperativeHandle(ref, () => formRef);
  return <>{simCardTabContent}</>;
}

export default forwardRef(EditSimCardDisplay);
