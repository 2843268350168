import { takeEvery, select, put } from 'redux-saga/effects';
import { FETCH_CONTACTS_LIST, setContactsList } from './contactsListActions';
import { getContacts } from 'apis/CustomerContactApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchContactsListSaga() {
  yield takeEvery(FETCH_CONTACTS_LIST, execute);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var contactsList = (yield getContacts(user, contactsList)).data;
  yield put(setContactsList(contactsList));
}
