import React, { useState, useEffect } from 'react';
import { isValid } from 'date-fns';
import { enUS, de, nl, pt } from 'date-fns/locale';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getI18n } from 'react-i18next';
import { PropTypes } from 'prop-types';

const ImdDatePicker = ({
  date,
  saveAction,
  id,
  minDate,
  maxDate,
  labelText,
  disabled,
  sx
}) => {
    const handleOnClose = () => {
    saveAction(date);
  };

  const language = getI18n().language;
  let languageImport = nl;

  switch (language) {
    case 'de':
      languageImport = de;
      break;
    case 'en':
      languageImport = enUS;
      break;
    case 'pt':
      languageImport = pt;
      break;
    default:
  }

  const [dateValue, setDateValue] = useState(date);

  useEffect(() => {
    // In case the props are changed from outside this component
    setDateValue(date);
  }, [date]);

  return (
    <LocalizationProvider
      adapterLocale={languageImport}
      dateAdapter={AdapterDateFns}
    >
      <DateTimePicker
        ampm={false}
        disableFuture={false}
        format="dd-MM-yyyy HH:mm"
        id={id}
        value={dateValue}
        onClose={handleOnClose}
        onChange={date => {
            setDateValue(date);
            if (isValid(date) || date === null) {
                saveAction(date);
            }
        }}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        renderInput={params => (
          <TextField
            {...params}
            name="devices"
            type="text"
            label={labelText}
            variant="standard"
            fullWidth
            sx={sx}
          />
        )}
        slotProps={{
          inputAdornment: {
            'aria-label': 'change date'
          }
        }}
      />
    </LocalizationProvider>
  );
};

ImdDatePicker.propTypes = {
  date: PropTypes.object.isRequired,
  saveAction: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  labelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  sx: PropTypes.object
};

export default ImdDatePicker;
