import { createReducer } from 'utils/createReducer';
import {
  SET_DASHBOARD_MEASUREMENTS,
  CLEAR_DASHBOARD_MEASUREMENTS
} from './dashboardMeasurementActions';
import { fromJS } from 'immutable';

export const dashboardMeasurements = createReducer(null, {
  [CLEAR_DASHBOARD_MEASUREMENTS]() {
    return fromJS([]);
  },
  [SET_DASHBOARD_MEASUREMENTS](
    state,
    { dashboardSectionItemId, measurements }
  ) {
    const index = state.findIndex(a => a.id === dashboardSectionItemId);

    const data = {
      id: dashboardSectionItemId,
      measurements
    };

    if (index >= 0) {
      state = state.set(index, data);
    } else {
      state = state.merge(data);
    }

    return state;
  }
});
