import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createLogItem } from 'apis/FieldServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { CREATE_LOGITEM, createLogItemSucceeded } from './createLogItemActions';

export function* createLogItemSaga() {
  yield takeEvery(CREATE_LOGITEM, execute);
}

function* execute(action) {
  let logitem = action.logitem;
  const user = yield select(currentUserSelector);

  try {
    const createdLogItemResult = yield createLogItem(user, logitem);
    const createdLogItem = createdLogItemResult.data;
    yield put(createLogItemSucceeded(createdLogItem));
    yield put(createSnackbar(t('content.createlogitem.snackbar.text')));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}
