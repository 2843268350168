import { connect } from 'react-redux';
import ContactsDisplay from './ContactsDisplay.jsx';
import { fetchContactsList } from './redux/contactsListActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { contactsListSelector } from './redux/contactsListSelectors';

const mapStateToProps = state => {
  return {
    contacts: contactsListSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchContactsList() {
    dispatch(fetchContactsList());
  }
});

export const ContactsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsDisplay);
