import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { TextField } from 'formik-mui';
import { isEmptyObject } from 'utils/helperMethods';

export const FunctionalDetailsForm = ({
  deviceFunctionalDetailsFormRef,
  deviceFunctionalViewmodel,
  upsertFunctionalDeviceDetails,
  onlineSwitchValue,
  visibleSwitchValue,
  setDeviceVisibleSwitchValue,
  setDeviceOnlineSwitchValue,
  setFuncDetailsFormValid
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormikWithRef
      ref={deviceFunctionalDetailsFormRef}
      initialValues={{
        measurementId: !deviceFunctionalViewmodel
          ? ''
          : deviceFunctionalViewmodel.measurementId ?? '',
        online: onlineSwitchValue ?? '',
        visible: visibleSwitchValue ?? '',
        externalDeviceId: deviceFunctionalViewmodel?.externalDeviceId ?? '',
        sensightId: deviceFunctionalViewmodel?.sensightId ?? ''
      }}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={values => {
        values.online = onlineSwitchValue;
        values.visible = visibleSwitchValue;
        upsertFunctionalDeviceDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setFuncDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form onSubmit={props.submitForm}>
            <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
              <Typography variant="body1" className={classes.switchLabel}>
                {t('content.devices.visible')}
              </Typography>
              <div className={classes.switchItem}>
                <Switch
                  checked={visibleSwitchValue}
                  onChange={() =>
                    setDeviceVisibleSwitchValue(!visibleSwitchValue)
                  }
                  value="hide"
                  color="primary"
                />
              </div>
            </div>
            <div className={classes.switchWrapper}>
              <Typography variant="body1" className={classes.switchLabel}>
                {t('content.devices.online')}
              </Typography>
              <div className={classes.switchItem}>
                <Switch
                  checked={onlineSwitchValue}
                  onChange={() =>
                    setDeviceOnlineSwitchValue(!onlineSwitchValue)
                  }
                  value="online"
                  color="primary"
                />
              </div>
            </div>
            <Field
              id="externalDeviceId"
              name="externalDeviceId"
              type="text"
              label={`${t('content.devices.externaldeviceid')} (${t(
                'content.general.autmatically.generated'
              )})`}
              fullWidth
              className={classes.lastInputField}
              component={TextField}
              value={props.values.externalDeviceId}
              disabled={true}
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  firstWrapper: {
    marginBottom: '8px'
  },
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  textField: {
    marginBottom: '8px'
  },
  lastInputField: {
    marginBottom: '16px'
  }
}));
