import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../page/Content';
import { ExportChartTableContainer } from './ExportChartTableContainer';

export default function ExportCharts() {
  const { t } = useTranslation();

  const content = <ExportChartTableContainer />;
  return (
    <Content
      title={t('content.exportcharts.title')}
      secondTitle={t('navbar.manage')}
      content={content}
    />
  );
}
