import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ImdTenantPicker from 'components/imd-components/ImdTenantPicker';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export default function TopBar(props) {
  const classes = useTopBarStyles();
  const { namespace } = useAuth0();
  const theme = useTheme();
  const currentUser = useSelector(state => currentUserSelector(state));

  let platformLogo =
    theme.platformLogo ??
    "https://imd-ma.nl/sites/default/files/assets/images/Foto's%20divers/Sensight-logo_0.png";
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        color="inherit"
        elevation={2}
      >
        <Toolbar variant="dense">
          <Link to="/">
            <img
              src={platformLogo}
              height="40px"
              style={{
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}
              className={classes.title}
              alt="Sensight Logo"
            />
          </Link>
          <Typography className={classes.toggleDrawer}>
            <IconButton onClick={props.toggleDrawer} size="large">
              <MenuIcon />
            </IconButton>
          </Typography>
          <ImdTenantPicker></ImdTenantPicker>
          <Button
            color="primary"
            className={classes.menuButton}
            component={Link}
            to={'/profile'}
          >
            {currentUser[namespace + 'name']}
          </Button>
          <img
            src={theme.logo}
            height="35"
            alt="Company logo"
            className={classes.companyLogo}
          />
          <IconButton
            component={Link}
            to={'/logout'}
            className={classes.logout}
            size="large"
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

TopBar.propTypes = {
  toggleDrawer: PropTypes.any.isRequired
};

const useTopBarStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  logout: {
    margin: theme.spacing(1)
  },
  menuButton: {
    textTransform: 'none',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  toggleDrawer: {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  hide: {
    display: 'none'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '12rem',
      marginLeft: '-78px !important',
      bottom: '10px',
      display: 'block'
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar
  },
  companyLogo: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));
export { useTopBarStyles };
