import {
  FETCH_SUBSCRIBERS,
  FETCH_ALERTS,
  UPSERT_ALERT_DETAILS,
  UPSERT_SUBSCRIPTION_DETAILS,
  CREATE_ALERT,
  EDIT_ALERT,
  DELETE_ALERT,
  createAlertSucceeded,
  editAlertSucceeded,
  deleteAlertSucceeded,
  setAlertsList,
  setSubscribers,
  FETCH_MY_ALERTS
} from './alertsActions';
import {
  takeEvery,
  put,
  all,
  take,
  actionChannel,
  select
} from 'redux-saga/effects';
import { t } from 'i18next';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  createAlert,
  getAlerts,
  updateAlert,
  deleteAlert,
  getLocationBySensightId,
  getAlertsByAlertIds
} from 'apis/MeasurementServiceApi';
import {
  createSubscription,
  getSubscriptionsByAlertId,
  deleteSubscription,
  getSubscriptionsByUserId
} from 'apis/NotificationServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchAlertsListSaga() {
  yield takeEvery(FETCH_ALERTS, executeList);
  yield takeEvery(FETCH_MY_ALERTS, executeMyList);
}

function* executeList() {
  try {
    const user = yield select(currentUserSelector);
    const alerts = (yield getAlerts(user)).data;
    yield put(setAlertsList(alerts));
  } catch (e) {
    yield put(createSnackbar(t('content.alerts.fetchalertserror'), 'error'));
    console.log(e);
  }
}

function* executeMyList() {
  try {
    const user = yield select(currentUserSelector);
    const subscriptions = (yield getSubscriptionsByUserId(user)).data;
    let alerts = [];
    if (subscriptions.length > 0) {
      const alertIds = subscriptions.map(sub => sub.alertId);

      alerts = (yield getAlertsByAlertIds(user, alertIds)).data;
    }
    yield put(setAlertsList(alerts));
  } catch (e) {
    yield put(createSnackbar(t('content.alerts.fetchalertserror'), 'error'));
    console.log(e);
  }
}

export function* createAlertSaga() {
  yield takeEvery(CREATE_ALERT, executeCreate);
}

const createSubscribers = function* (user, subscribers, alertId) {
  let error = false;
  for (var subscriber of subscribers) {
    try {
      const subscription = {
        alertId: alertId,
        sensightContactId: subscriber
      };
      yield createSubscription(user, subscription);
    } catch (exception) {
      console.error(exception);
      error = true;
    }
  }
  if (error) {
    yield put(
      createSnackbar(t('content.alerts.notallsubscriptionscreated'), 'warning')
    );
  }
};

const deleteSubscribers = function* (user, subscribers, alertId) {
  for (var subscriber of subscribers) {
    yield deleteSubscription(user, alertId, subscriber);
  }
};

export function* executeCreate() {
  const user = yield select(currentUserSelector);
  const alertDetailsChannel = yield actionChannel(UPSERT_ALERT_DETAILS);
  const subscriptionDetailsChannel = yield actionChannel(
    UPSERT_SUBSCRIPTION_DETAILS
  );

  const formValues = yield all([
    take(alertDetailsChannel),
    take(subscriptionDetailsChannel)
  ]);

  const alertDetails = formValues[0].upsertAlertDetails;

  if (!alertDetails.channel) {
    yield put(
      createSnackbar(
        t('content.visualize.snackbar.no_channels_selected'),
        'warning'
      )
    );
    return;
  }

  if (!alertDetails.device) {
    yield put(
      createSnackbar(
        t('content.visualize.snackbar.no_device_selected'),
        'warning'
      )
    );
    return;
  }

  if (!alertDetails.location) {
    yield put(
      createSnackbar(
        t('content.visualize.snackbar.no_location_selected'),
        'warning'
      )
    );
    return;
  }

  alertDetails.channelSensightId = alertDetails.channel.sensightId;
  alertDetails.deviceId = alertDetails.device.id;

  const measurementServiceLocation = (yield getLocationBySensightId(
    user,
    alertDetails.location.sensightId
  )).data[0];
  alertDetails.locationMeasurementServiceId = measurementServiceLocation.id;
  alertDetails.locationFieldServiceId = alertDetails.location.id;

  const subscriptionDetails =
    formValues[1].upsertSubscriptionDetails.subscribers;

  const createdAlert = (yield createAlert(user, alertDetails)).data.result;
  createdAlert.channel = alertDetails.channel;
  yield createSubscribers(user, subscriptionDetails, createdAlert.id);
  yield put(createSnackbar(t('content.alerts.create.snackbar.text')));
  yield put(createAlertSucceeded(createdAlert));
}

export function* editAlertSaga() {
  yield takeEvery(EDIT_ALERT, executeEdit);
}

function* executeEdit() {
  const user = yield select(currentUserSelector);

  const alertDetailsChannel = yield actionChannel(UPSERT_ALERT_DETAILS);
  const subscriptionDetailsChannel = yield actionChannel(
    UPSERT_SUBSCRIPTION_DETAILS
  );

  const formValues = yield all([
    take(alertDetailsChannel),
    take(subscriptionDetailsChannel)
  ]);

  const alertDetails = formValues[0].upsertAlertDetails;
  if (!alertDetails.channel) {
    yield put(
      createSnackbar(
        t('content.visualize.snackbar.no_channels_selected'),
        'warning'
      )
    );
    return;
  }

  if (!alertDetails.device) {
    yield put(
      createSnackbar(
        t('content.visualize.snackbar.no_device_selected'),
        'warning'
      )
    );
    return;
  }

  if (!alertDetails.location) {
    yield put(
      createSnackbar(
        t('content.visualize.snackbar.no_locations_selected'),
        'warning'
      )
    );
    return;
  }

  alertDetails.channelSensightId = alertDetails.channel.sensightId;
  alertDetails.deviceId = alertDetails.device.id;

  const measurementServiceLocation = (yield getLocationBySensightId(
    user,
    alertDetails.location.sensightId
  )).data[0];

  alertDetails.locationMeasurementServiceId = measurementServiceLocation.id;
  alertDetails.locationFieldServiceId = alertDetails.location.id;

  const subscriptionDetails = formValues[1].upsertSubscriptionDetails;
  const previousSubscribers = subscriptionDetails.previousSubscribers.map(
    sub => sub.sensightContactId
  );
  const currentSubscribers = subscriptionDetails.subscribers;

  const subscribersToDelete = [];
  const subscribersToCreate = [];

  previousSubscribers.forEach(sub => {
    if (!currentSubscribers.find(currentSub => currentSub === sub)) {
      subscribersToDelete.push(sub);
    }
  });

  yield deleteSubscribers(user, subscribersToDelete, alertDetails.id);

  currentSubscribers.forEach(sub => {
    if (!previousSubscribers.find(prevSub => prevSub === sub)) {
      subscribersToCreate.push(sub);
    }
  });

  yield createSubscribers(user, subscribersToCreate, alertDetails.id);

  yield updateAlert(user, alertDetails);

  try {
    yield put(createSnackbar(t('content.alerts.edit.snackbar.text')));
    yield put(editAlertSucceeded(alertDetails));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* deleteAlertSaga() {
  yield takeEvery(DELETE_ALERT, executeDelete);
}

function* executeDelete(action) {
  const alert = action.alert;
  const user = yield select(currentUserSelector);

  try {
    yield deleteAlert(user, alert.id);
    yield put(createSnackbar(t('content.alerts.delete.snackbar.text')));
    yield put(deleteAlertSucceeded(alert));
  } catch (exception) {
    console.error(exception);
  }
}

export function* fetchSubscribersSaga() {
  yield takeEvery(FETCH_SUBSCRIBERS, executeFetchSubscribers);
}

function* executeFetchSubscribers(action) {
  try {
    const alertId = action.alertId;
    const user = yield select(currentUserSelector);
    const subscriptions = (yield getSubscriptionsByAlertId(user, alertId)).data;
    yield put(setSubscribers(subscriptions));
  } catch (e) {
    yield put(createSnackbar(t('content.alerts.fetchsubscribers'), 'error'));
    console.log(e);
  }
}
