import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { MenuItem, Grid, Select, TextField, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import ImdTimePicker from 'components/imd-components/ImdTimePicker';
import { TextField as formikTextField } from 'formik-mui';
import imdTimeZoneMenuItems from 'components/imd-components/imdTimeZoneMenuItems.jsx';

export const CreateDetailsForm = ({
  contacts,
  locationTypes,
  locationFormRef,
  selectedContactPerson,
  createLocationDetails,
  setAutocompleteContactPerson
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderLocationTypeMenuItems = () => {
    if (locationTypes.length > 0) {
      const menuItems = locationTypes.map(key => {
        return (
          <MenuItem key={key.id} value={key.id}>
            {key.name}
          </MenuItem>
        );
      });
      return menuItems;
    }
    return [];
  };

  const date = new Date();
  date.setHours(0, 0, 0);
  const [switchTime, setSwitchTime] = useState(date);

  return (
    <FormikWithRef
      ref={locationFormRef}
      initialValues={{
        name: '',
        referenceNumber: '',
        projectNumber: '',
        contactPerson: { email: '' },
        city: '',
        address: '',
        latLngLatitude: '',
        latLngLongitude: '',
        locationType: '',
        timeZone: 'Europe/Amsterdam'
      }}
      validate={values => {
        if (selectedContactPerson) {
          if (selectedContactPerson.email !== '') {
            values.contactPerson = selectedContactPerson;
          }
        }
      }}
      enableReinitialize={false}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        if (selectedContactPerson) {
          if (selectedContactPerson.email !== '') {
            values.contactPerson = selectedContactPerson;
          }
        }
        values.switchTime = switchTime.toLocaleTimeString('nl-NL');

        createLocationDetails(values);
      }}
    >
      {props => (
        <Form onSubmit={props.submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className={classes.textField}
                id="name"
                name="name"
                label={t('content.locationdashboard.form.name')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.name}
                required={true}
                variant="standard"
              />
              <TextField
                className={classes.textField}
                id="referenceNumber"
                name="referenceNumber"
                label={t('content.locationdashboard.form.referenceNumber')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.referenceNumber}
                variant="standard"
              />
              <TextField
                className={classes.textField}
                id="projectNumber"
                name="projectNumber"
                label={t('content.locationdashboard.form.projectNumber')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.projectNumber}
                variant="standard"
              />
              <Autocomplete
                options={contacts}
                getOptionLabel={option => option.email}
                defaultValue={selectedContactPerson}
                onChange={(event, value) => {
                  setAutocompleteContactPerson(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="contactPerson"
                    type="text"
                    label={t('content.locationdashboard.form.contactPerson')}
                    fullWidth
                    className={classes.textField}
                    variant="standard"
                  />
                )}
              />
              <TextField
                className={classes.textField}
                id="city"
                name="city"
                label={t('content.locationdashboard.form.city')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.city}
                variant="standard"
              />
              <TextField
                className={classes.textField}
                id="address"
                name="address"
                label={t('content.locationdashboard.form.address')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.address}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textField}
                id="latLngLatitude"
                name="latLngLatitude"
                label={t('content.locationdashboard.form.latLngLatitude')}
                fullWidth
                onChange={props.handleChange}
                value={props.values.latLngLatitude}
                variant="standard"
              />
              <TextField
                id="latLngLongitude"
                name="latLngLongitude"
                label={t('content.locationdashboard.form.latLngLongitude')}
                className={classes.lastInputField}
                fullWidth
                onChange={props.handleChange}
                value={props.values.latLngLongitude}
                variant="standard"
              />
              <InputLabel variant="standard">
                {t('content.locationdashboard.form.locationType')}
              </InputLabel>
              <Select
                id="locationType"
                name="locationType"
                fullWidth
                onChange={props.handleChange}
                value={props.values.locationType}
                variant="standard"
              >
                {renderLocationTypeMenuItems()}
              </Select>
              <ImdTimePicker
                labelText={t('content.locationdashboard.form.switchTime')}
                saveAction={setSwitchTime}
                switchTime={switchTime}
              />
              <Field
                name="timeZone"
                type="select"
                label={t('content.profile.timezone.card.input.text')}
                select
                fullWidth
                className={classes.textField}
                component={formikTextField}
                variant="standard"
              >
                {imdTimeZoneMenuItems}
              </Field>
            </Grid>
          </Grid>
        </Form>
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  }
}));
