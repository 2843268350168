import { connect } from 'react-redux';
import EditContactDisplay from './EditContactDisplay.jsx';
import { editContact, fetchUserDetails } from './redux/editContactActions';
import { t } from 'i18next';
import { organizationsListSelector } from 'components/content/organizations/redux/organizationsListSelectors';
import { fetchOrganizationsList } from 'components/content/organizations/redux/organizationsListActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { userDetailsSelector } from './redux/editContactSelectors';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { contactIsUserSelector } from '../createcontact/redux/createContactSelectors.js';
import {
  setContactIsUser,
  clearContactIsUser
} from '../createcontact/redux/createContactActions.js';
import { createSnackbar } from 'redux/ui/uiActions';

const mapStateToProps = (state, ownProps) => {
  return {
    contactIsUser: contactIsUserSelector(state),
    userDetails: userDetailsSelector(state),
    row: ownProps.row,
    organizations: organizationsListSelector(state),
    currentUser: currentUserSelector(state),
    organization: ownProps.Organization
  };
};

const mapDispatchToProps = dispatch => ({
  editContact(contactInformation) {
    dispatch(editContact(contactInformation));
  },
  createSnackbar(message, variant) {
    dispatch(createSnackbar(message, variant));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  fetchOrganizationsList() {
    dispatch(fetchOrganizationsList());
  },
  fetchUserDetails(contactSensightId) {
    dispatch(fetchUserDetails(contactSensightId));
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  setContactIsUser(value) {
    dispatch(setContactIsUser(value));
  },
  clearContactIsUser() {
    dispatch(clearContactIsUser());
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.email) {
    errors.email = t('general.validation.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = t(
      'content.profile.changeprofiledetails.card.input.email.error'
    );
  }
  if (!values.organizationid) {
    errors.organization = t('general.validation.required');
  }

  if (!values.name) {
    errors.name = t('general.validation.required');
  }
  return errors;
};

export const EditContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(EditContactDisplay);
