import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState
} from 'react';
import { LogbookActionDetailsForm } from './forms/LogbookActionDetailsForm';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

function CreateLogbookActionDisplay(
  { createLogbookAction, setSaveButtonDisabled, upsertLogbookActionDetails },
  ref
) {
  const logbookActionDetailsFormRef = useRef();
  const [logbookActionDetailsFormValid, setLogbookActionDetailsFormValid] =
    useState(false);

  React.useEffect(() => {
    setSaveButtonDisabled(true);
    logbookActionDetailsFormRef.current.validateForm();
  }, []);

  React.useEffect(() => {
    setSaveButtonDisabled(!logbookActionDetailsFormValid);
  }, [logbookActionDetailsFormValid]);

  const logbookActionDetailsContent = (
    <LogbookActionDetailsForm
      logbookActionDetailsFormRef={logbookActionDetailsFormRef}
      upsertLogbookActionDetails={upsertLogbookActionDetails}
      setLogbookActionDetailsFormValid={setLogbookActionDetailsFormValid}
    />
  );
  const formRef = useRef();

  const logbookActionTabContent = (
    <>
      {logbookActionDetailsContent}
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={true}
        onSubmit={() => {
          logbookActionDetailsFormRef.current.handleSubmit();
          createLogbookAction();
        }}
      />
    </>
  );

  useImperativeHandle(ref, () => formRef);
  return <>{logbookActionTabContent}</>;
}

export default forwardRef(CreateLogbookActionDisplay);
