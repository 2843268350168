import React from 'react';
import Content from '../../page/Content';
import { DesktopToolbarContainer } from './toolbar/desktopToolbar/desktopToolbarContainer';
import { DisplayChartComponentContainer } from './displayChartComponent/displayChartComponentContainer';
import { useTranslation } from 'react-i18next';

export function VisualizeDisplay({
  visualizeLink,
  clearSelectChannelDialogStateOnOpen,
  clearSelectChannelDialogStateImmediately
}) {
  const { t } = useTranslation();
  function DisplayToolbar() {
    return (
      <DesktopToolbarContainer
        clearSelectChannelDialogStateImmediately={
          clearSelectChannelDialogStateImmediately
        }
        clearSelectChannelDialogStateOnOpen={
          clearSelectChannelDialogStateOnOpen
        }
      />
    );
  }

  const content = (
    <>
      <DisplayToolbar />
      <DisplayChartComponentContainer visualizeLink={visualizeLink} />
    </>
  );

  return (
    <Content
      title={t('content.visualize.title')}
      content={content}
      isChild={true}
    />
  );
}
