import { connect } from 'react-redux';
import CreateOrganizationDisplay from './CreateOrganizationDisplay.jsx';
import { createOrganization } from './redux/createOrganizationActions';
import { t } from 'i18next';
import { organizationsListSelector } from 'components/content/organizations/redux/organizationsListSelectors';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { dashboardLocationsSelector } from 'components/content/dashboard/locations/redux/dashboardLocationsSelectors.js';

const mapStateToProps = state => {
  return {
    organizations: organizationsListSelector(state),
    currentUser: currentUserSelector(state),
    locations: dashboardLocationsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  createOrganization(organizationInformation) {
    dispatch(createOrganization(organizationInformation));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.name) {
    errors.name = t('general.validation.required');
  }
  return errors;
};

export const CreateOrganizationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateOrganizationDisplay);
