import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { updateProfileDetails } from '../../../../../apis/UserManagementApi';
import { setProfileDetails } from '../../redux/profileDetailsActions';
import { UPDATE_PROFILE_DETAILS } from './updateProfileDetailsActions';
import { profileDetailsSelector } from 'components/content/profile/redux/profileDetailsSelectors';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { namespace } from 'utils/currentUserReader';
import { setCurrentUser } from 'redux/user/currentUserActions';

export function* updateProfileDetailsSaga() {
  yield takeEvery(UPDATE_PROFILE_DETAILS, execute);
}

function* execute(action) {
  try {
    var currentUser = yield select(currentUserSelector);
    var currentProfile = yield select(profileDetailsSelector);
    yield updateProfileDetails(currentUser, action.profileDetails);

    var updatedUser = MapUpdatedProfileDetailFields(
      currentProfile,
      action.profileDetails
    );

    currentUser[namespace + 'name'] = action.profileDetails.fullName;

    yield put(setProfileDetails(updatedUser));
    yield put(setCurrentUser(currentUser));
    yield put(
      createSnackbar(t('content.profile.changeprofiledetails.snackbar.text'))
    );
  } catch (exception) {
    throw exception;
  }
}

const MapUpdatedProfileDetailFields = (
  currentProfile,
  profileDetailsToUpdate
) => {
  return {
    ...currentProfile,
    displayName: profileDetailsToUpdate.fullName,
    email: profileDetailsToUpdate.email,
    phoneNumber: profileDetailsToUpdate.phonenumber
  };
};
