import { takeEvery, select, put } from 'redux-saga/effects';
import { FETCH_LOCATIONS_LIST, setLocationsList } from './locationsListActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { getAllLocations as getAllMeasurementLocations } from 'apis/MeasurementServiceApi';
import { getAllLocations } from 'apis/FieldServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* fetchLocationsListSaga() {
  yield takeEvery(FETCH_LOCATIONS_LIST, execute);
}

function* execute() {
  try {
    const user = yield select(currentUserSelector);
    var fieldServiceLocations = (yield getAllLocations(user)).data;
    var measurementServiceLocations = (yield getAllMeasurementLocations(user))
      .data;
    let locationsList = [];

    if (fieldServiceLocations.length > 0) {
      if (measurementServiceLocations.length > 0) {
        locationsList = fieldServiceLocations.map(fieldLocation => {
          const measurementLocation = measurementServiceLocations.find(
            measureLoc => measureLoc.sensightId === fieldLocation.sensightId
          );
          if (measurementLocation) {
            measurementLocation.measurementId = measurementLocation.id;
            fieldLocation.fieldId = fieldLocation.id;
            return { ...measurementLocation, ...fieldLocation };
          }

          return fieldLocation;
        });
      } else {
        locationsList = fieldServiceLocations;
      }
    }
    yield put(setLocationsList(locationsList));
  } catch (e) {
    yield put(
      createSnackbar(t('content.locations.fetchlocationserror'), 'error')
    );
    console.log(e);
  }
}
