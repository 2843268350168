import { makeActionCreator } from 'utils/makeActionCreator';
export const CREATE_SNACKBAR = 'SNACKBAR_SUCCESS';
export const createSnackbar = makeActionCreator(
  CREATE_SNACKBAR,
  'message',
  'variant',
  'duration'
);

export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const clearSnackbar = makeActionCreator(SNACKBAR_CLEAR);

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const setIsLoading = makeActionCreator(SET_IS_LOADING, 'value');
