import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import ImdExcelTable from 'components/imd-components/exceltable/ImdExcelTable';
import { Column, RequiredRule, RangeRule } from 'devextreme-react/tree-list';
import ImdPopup from 'components/imd-components/ImdPopup';
import { createSnackbar } from 'redux/ui/uiActions';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import ImdCard from 'components/imd-components/ImdCard';
import { format, add, parse } from 'date-fns';

import { enUS, de, nl, pt } from 'date-fns/locale';

export function ManualInsertDisplay({
  selectedLocation,
  fetchManualChannels,
  fetchManualInserts,
  columns,
  manualInsertRows,
  createManualInsertRow,
  editManualInsertRow,
  deleteManualInsertRow,
  syncManualInserts,
  setManualInsertsPeriod,
  manualInsertsPeriod,
  changedManualInsertRows,
  fetchLocation
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = getI18n().language;
  let locale = nl;

  switch (language) {
    case 'de':
      locale = de;
      break;
    case 'en':
      locale = enUS;
      break;
    case 'pt':
      locale = pt;
      break;
    default:
  }

  const autoSaveAndSynchronise = true;

  const dispatch = useDispatch();
  const [manualInsertData, setManualInsertData] = useState([]);
  const excelGrid = useRef();
  const [popup, setPopup] = useState(false);
  const [manualInsertRowToDelete, setManualInsertRowToDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [excelTableIsBusySavingRows, setExcelTableIsBusySavingRows] =
    useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);

  React.useEffect(() => {
    if (selectedLocation) {
      fetchManualChannels();
      fetchLocation(selectedLocation.sensightId);
    }
  }, []);

  React.useEffect(() => {
    if (selectedLocation) {
      fetchManualInserts(selectedChannels);
    }
  }, [selectedChannels]);

  const onFocusedRowChanged = e => {
    setSelectedRow(e.rowIndex);
  };

  const handleAdd = () => {
    const ds = excelGrid.current.instance.getDataSource();

    let date;

    if (manualInsertData.length > 0) {
      date = add(new Date(ds.items()[0].dateTime), { days: 1 });
    } else {
      date = new Date();
    }

    const { switchTime } = selectedLocation.measurementServiceLocation;
    date = parse(switchTime ? switchTime : '00:00:00', 'HH:mm:ss', date);

    ds.store().push([
      {
        type: 'insert',
        data: { id: date.toString(), dateTime: date.toString(), isNew: true },
        index: 0
      }
    ]);
  };

  const handleDelete = e => {
    setManualInsertRowToDelete(e.row.data);
    e.component.option('focusedRowIndex', e.row.rowIndex);
    setPopup(true);
  };

  const handlePeriodChanged = e => {
    setManualInsertsPeriod(e.value);
    fetchManualInserts(selectedChannels);
  };

  const handlePopupOk = () => {
    if (!manualInsertRowToDelete.isNew) {
      excelGrid.current.instance.deleteRow(selectedRow);
      excelGrid.current.instance.saveEditData();
    } else {
      setManualInsertData(
        manualInsertData.filter(d => d.id !== manualInsertRowToDelete.id)
      );
    }

    setPopup(false);
  };

  const itemAdded = row => {
    createManualInsertRow(row);
  };

  const itemUpdated = row => {
    editManualInsertRow(row);
  };

  const itemRemoved = row => {
    deleteManualInsertRow(row);
  };

  useEffect(() => {
    if (autoSaveAndSynchronise && !excelTableIsBusySavingRows) {
      Synchronise(changedManualInsertRows);
    }
  }, [changedManualInsertRows]);

  const Synchronise = changedRows => {
    if (changedRows && changedRows.length > 0) {
      const manualInsertRowsAndColumns = {
        changedRows: changedRows,
        manualChannelsColumns: columns,
        selectedChannels
      };
      console.dev(
        `Start synchronising manual insert rows`,
        manualInsertRowsAndColumns
      );
      syncManualInserts(manualInsertRowsAndColumns);
    }
  };

  const handleSynchronize = () => {
    if (excelGrid.current.instance.hasEditData()) {
      dispatch(createSnackbar(t('content.manualinserts.haseditrows'), 'error'));
    } else {
      Synchronise(changedManualInsertRows);
    }
  };

  const renderColumns = columns => {
    const columnsHtml = columns.map(column => {
      let validationRules = [];
      if (column.required)
        validationRules.push(<RequiredRule key={'required'} />);
      if (column.min && column.max && column.min != column.max) {
        validationRules.push(
          <RangeRule
            key={'range'}
            min={column.min}
            max={column.max}
            message={t('content.manualinserts.rangevalidationerror', {
              column: column.caption,
              min: column.min,
              max: column.max
            })}
          />
        );
      }
      return (
        <Column
          key={column.key}
          dataField={column.dataField}
          caption={column.caption}
          dataType={column.dataType}
          fixed={column.dataField === 'dateTime'}
          width={column.dataField === 'dateTime' ? 150 : null}
          format={
            column.dataField === 'dateTime'
              ? datetime =>
                  // iiiiii = ma, di, wo, etc...
                  format(datetime, 'iiiiii dd/MM/yyyy HH:mm', {
                    locale
                  })
              : null
          }
        >
          {validationRules}
        </Column>
      );
    });

    return (
      <>
        {columnsHtml}
        <Column
          type="buttons"
          caption={t('content.manualinsertstable.actions')}
          width={80}
          buttons={[
            {
              hint: t('general.delete'),
              icon: 'trash',
              onClick: event => {
                handleDelete(event);
              }
            },
            {
              hint: t('general.add'),
              icon: 'plus',
              onClick: () => handleAdd()
            }
          ]}
        />
      </>
    );
  };

  return (
    <Grid container spacing={2} className={classes.tabContent}>
      <Grid item xs={12}>
        <ImdCard
          title={t('content.manualinserts.card.manage')}
          content={
            <>
              <ImdPopup
                open={popup}
                title={t('content.manualinserts.deletepopup.title')}
                content={t('content.manualinserts.deletepopup.content')}
                handleOk={() => handlePopupOk()}
                handleClose={() => setPopup(false)}
              />
              <ImdExcelTable
                data={manualInsertData}
                setData={setManualInsertData}
                intialData={manualInsertRows}
                filterKey={'deviceName'}
                filterValue={'channelId'}
                filterTranslation={'content.channels.form.device'}
                filterTranslationPlural={'content.devices.title'}
                selectFirstTranslation={
                  'content.visualize.dialog.channels.selectdevice'
                }
                columns={columns}
                columnsRenderer={renderColumns}
                onSelectedColumnsChanged={setSelectedChannels}
                autoSaveAndSynchronise={autoSaveAndSynchronise}
                isBusySavingRows={setExcelTableIsBusySavingRows}
                addPermission={'manualinsert:create'}
                handleAddButton={() => handleAdd()}
                columnFixing={true}
                handleSynchronizeButton={() => {
                  handleSynchronize();
                }}
                handleDeleteButton={e => {
                  handleDelete(e);
                }}
                title={t('content.manualinserts.title')}
                selectTextOnEditStart={true}
                startEditAction={'click'}
                identification={excelGrid}
                onRowInserted={event => {
                  itemAdded(event);
                }}
                onRowRemoved={event => {
                  itemRemoved(event);
                }}
                onRowUpdated={event => {
                  itemUpdated(event);
                }}
                onFocusedRowChanged={event => onFocusedRowChanged(event)}
                additionalToolbar={[
                  {
                    location: 'before',
                    widget: 'dxSelectBox',
                    options: {
                      width: 200,
                      items: [
                        {
                          value: 3,
                          text: t(
                            'content.manualinserts.toolbar.time.select.3mo'
                          )
                        },
                        {
                          value: 6,
                          text: t(
                            'content.manualinserts.toolbar.time.select.6mo'
                          )
                        },
                        {
                          value: 12,
                          text: t(
                            'content.manualinserts.toolbar.time.select.1y'
                          )
                        }
                      ],
                      displayExpr: 'text',
                      valueExpr: 'value',
                      value: manualInsertsPeriod,
                      onValueChanged: handlePeriodChanged.bind(this)
                    }
                  }
                ]}
              />
            </>
          }
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  selectBox: {
    minWidth: '75px'
  },
  columnContent: {
    display: 'flex',
    gap: '8px'
  },
  select: {
    marginTop: '18px'
  }
});
