import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import { useDispatch } from 'react-redux';
import { fetchDashboardLocations } from 'components/content/dashboard/locations/redux/dashboardLocationsActions';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { isValidLength } from 'utils/validation';

function CreateOrganizationDisplay(
  { createOrganization, handleValidate, setSaveButtonDisabled, locations },
  ref
) {
  const { t } = useTranslation();
  const classes = useCreateOrganizationDisplayStyles();

  const [selectedLocations, setSelectedLocations] = React.useState([]);

  const [organizationDetails] = React.useState({
    name: '',
    phoneNumber: '',
    address: '',
    postCode: '',
    city: '',
    country: '',
    klantnummer: '',
  });

  const handleSubmit = async values => {
    values.locationIds = selectedLocations;
    createOrganization(values);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (locations.length < 1) {
      dispatch(fetchDashboardLocations());
    }
  }, []);

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (dirty && isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    return (
      <Form id="createOrganizationForm" onSubmit={submitForm}>
        <Field
          name="name"
          type="text"
          label={t('content.editOrganization.input.name.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="phoneNumber"
          type="text"
          label={t('content.editOrganization.input.phoneNumber.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="address"
          type="text"
          label={t('content.editOrganization.input.address.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="postCode"
          type="text"
          label={t('content.editOrganization.input.postCode.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="city"
          type="text"
          label={t('content.editOrganization.input.city.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="country"
          type="text"
          label={t('content.editOrganization.input.country.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="clientnumber"
          type="text"
          label={t('content.editOrganization.input.clientnumber.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
          validate={isValidLength}
        />
        <Autocomplete
          multiple
          filterSelectedOptions
          options={locations}
          getOptionLabel={option => option.name}
          onChange={(event, newValue) => {
            setSelectedLocations(newValue.map(org => org.sensightId));
          }}
          renderInput={params => (
            <MuiTextField
              {...params}
              name="locations"
              type="text"
              label={t('content.locationdashboard.form.locations')}
              fullWidth
              className={classes.textField}
              variant="standard"
            />
          )}
        />
      </Form>
    );
  };

  const formRef = useRef();

  const inputFields = (
    <>
      <FormikWithRef
        ref={formRef}
        validateOnMount={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={organizationDetails}
        validate={values => handleValidate(values)}
        onSubmit={values => handleSubmit(values)}
      >
        {form}
      </FormikWithRef>
    </>
  );

  useImperativeHandle(ref, () => formRef);

  return <>{inputFields}</>;
}

const useCreateOrganizationDisplayStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

export default forwardRef(CreateOrganizationDisplay);
