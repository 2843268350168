import { connect } from 'react-redux';
import { ChannelsDataTableDisplay } from './channelsDataTableDisplay.jsx';
import { channelsListOfLocationSelector } from './redux/channelsListSelectors.js';
import {
  fetchChannelsListOfLocation,
  deleteChannelOfLocation
} from './redux/channelsListActions.js';

const mapStateToProps = (state, ownProps) => {
  return {
    locationSensightId: ownProps.locationSensightId,
    locationId: ownProps.locationId,
    channels: channelsListOfLocationSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchChannelsListOfLocation(locationId, locationSensightId) {
    dispatch(fetchChannelsListOfLocation(locationId, locationSensightId));
  },
  deleteChannelOfLocation(channel) {
    dispatch(deleteChannelOfLocation(channel));
  }
});
export const ChannelsDataTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelsDataTableDisplay);
