import { takeEvery, select, put } from 'redux-saga/effects';
import {
  FETCH_LOCATIONTYPES_LIST,
  setLocationTypesList,
  DELETE_LOCATIONTYPE,
  deleteLocationTypeSucceeded
} from './locationTypesListActions';
import { getLocationTypes, deleteLocationType } from 'apis/FieldServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* fetchLocationTypesListSaga() {
  yield takeEvery(FETCH_LOCATIONTYPES_LIST, execute);
}

export function* deleteLocationTypeSage() {
  yield takeEvery(DELETE_LOCATIONTYPE, executeDelete);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var locationtypesList = (yield getLocationTypes(user, locationtypesList))
    .data;
  yield put(setLocationTypesList(locationtypesList));
}

function* executeDelete(action) {
  const locationType = action.locationtype;
  const user = yield select(currentUserSelector);

  try {
    yield deleteLocationType(user, locationType.id);
    yield put(createSnackbar(t('content.deletelocationtype.snackbar.text')));
    yield put(deleteLocationTypeSucceeded(locationType));
  } catch (exception) {
    console.error(exception);
  }
}
