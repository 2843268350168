import { createReducer } from 'utils/createReducer';
import {
  SET_LOCATIONS_LIST,
  CREATE_LOCATION_SUCCEEDED,
  DELETE_LOCATION_SUCCEEDED,
  EDIT_LOCATION_SUCCEEDED
} from './locationsListActions';
import { fromJS } from 'immutable';

export const locationsList = createReducer(null, {
  [SET_LOCATIONS_LIST](state, { locationsList }) {
    return fromJS(locationsList);
  },
  [CREATE_LOCATION_SUCCEEDED](state, { location }) {
    return state.push(fromJS(location));
  },
  [EDIT_LOCATION_SUCCEEDED](state, { location }) {
    let locationsList = state.toJS();
    var foundLocationIndex = locationsList.findIndex(
      org => location.id === org.id
    );
    locationsList[foundLocationIndex] = location;
    return fromJS(locationsList);
  },
  [DELETE_LOCATION_SUCCEEDED](state, { location }) {
    return state.filter(con => con.get('id') !== location.id);
  }
});
