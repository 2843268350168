import rules from './rules';

//https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

export const check = (role, action) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (
    role === 'PlatformAdministrator' ||
    (staticPermissions && staticPermissions.includes(action))
  ) {
    // static rule not provided for action
    return true;
  }
  return false;
};

export function Can({ perform, yes, no, role, override = false }) {
  return check(role, perform) || override ? yes() : no();
}
