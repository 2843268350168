import { takeEvery, put } from 'redux-saga/effects';

import { FETCH_DASHBOARD_ITEM_DATA } from './dashboardItemActions';

import { fetchDashboardMeasurements } from '../../viewDashboard/redux/dashboardMeasurementActions';

export function* fetchDashboardItemDataSaga() {
  yield takeEvery(FETCH_DASHBOARD_ITEM_DATA, executeFetchDashboardItemData);
}

function* executeFetchDashboardItemData({ item }) {
  if (
    (item.visualizationType !== 1 && item.visualizationType !== 2) ||
    item.visualizationSettings === null
  ) {
    return;
  }

  yield put(fetchDashboardMeasurements(item.id, item.visualizationSettings));
}
