import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import ImdCard from '../../../imd-components/ImdCard';
import ImdButton from '../../../imd-components/ImdButton';
import { TextField } from 'formik-mui';
import makeStyles from '@mui/styles/makeStyles';
import { isEmptyObject } from 'utils/helperMethods';
import { getActiveTenant } from 'utils/tenantManager';
export function ChangeProfileDetailsDisplay({
  profileDetails,
  updateProfileDetails,
  handleValidate
}) {
  const { t } = useTranslation();
  const classes = useChangeProfileDetailsCardStyles();

  const form = ({ submitForm, dirty }) => {
    return (
      <Form>
        <Field
          name="fullName"
          type="text"
          label={t(
            'content.profile.changeprofiledetails.card.input.fullname.text'
          )}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="email"
          type="email"
          label={t(
            'content.profile.changeprofiledetails.card.input.email.text'
          )}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="phonenumber"
          type="text"
          label={t(
            'content.profile.changeprofiledetails.card.input.phonenumber.text'
          )}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="tenant"
          type="text"
          label={t(
            'content.profile.changeprofiledetails.card.input.tenant.text'
          )}
          fullWidth
          disabled
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <ImdButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={submitForm}
          disabled={!dirty}
        >
          {t('content.profile.changeprofiledetails.card.button.text')}
        </ImdButton>
      </Form>
    );
  };

  return (
    <ImdCard
      title={t('content.profile.changeprofiledetails.card.title')}
      content={
        <div>
          {!isEmptyObject(profileDetails) ? (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  fullName: profileDetails.displayName,
                  email: profileDetails.email,
                  phonenumber: profileDetails.phoneNumber,
                  tenant: getActiveTenant()
                }}
                validate={values => handleValidate(values)}
                onSubmit={profileDetails =>
                  updateProfileDetails(profileDetails)
                }
              >
                {form}
              </Formik>
            </>
          ) : (
            <p>Fetching....</p>
          )}
        </div>
      }
    />
  );
}

const useChangeProfileDetailsCardStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));
