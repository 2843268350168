import { createReducer } from 'utils/createReducer';
import {
  SET_THEME,
  SET_THEMES_LIST,
  CREATE_THEME_SUCCEEDED,
  EDIT_THEME_SUCCEEDED,
  DELETE_THEME_SUCCEEDED
} from './themesActions';
import { fromJS } from 'immutable';

export const themesList = createReducer(null, {
  [SET_THEMES_LIST](state, { themesList }) {
    return fromJS(themesList);
  },
  [CREATE_THEME_SUCCEEDED](state, { theme }) {
    return state.push(fromJS(theme));
  },
  [EDIT_THEME_SUCCEEDED](state, { theme }) {
    let themesList = state.toJS();
    var foundThemeIndex = themesList.findIndex(tl => theme.id === tl.id);
    themesList[foundThemeIndex] = theme;
    return fromJS(themesList);
  },
  [DELETE_THEME_SUCCEEDED](state, { theme }) {
    return state.filter(con => con.get('id') !== theme.id);
  }
});

export const theme = createReducer(null, {
  [SET_THEME](state, { theme }) {
    return fromJS(theme);
  }
});
