import {
  CREATE_LOCATION_DETAILS,
  CREATE_PROJECT_DETAILS
} from './createLocationActions';
import { select, put, all, take, actionChannel } from 'redux-saga/effects';
import {
  createLocation,
  saveServiceContacts,
  saveOrganizationsOfLocation
} from 'apis/FieldServiceApi';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import { createLocationSucceeded } from '../../redux/locationsListActions';

export function* createLocationSaga() {
  const locationDetailsChannel = yield actionChannel(CREATE_LOCATION_DETAILS);
  const projectDetailsChannel = yield actionChannel(CREATE_PROJECT_DETAILS);

  while (true) {
    const formValues = yield all([
      take(locationDetailsChannel),
      take(projectDetailsChannel)
    ]);

    const locationDetails = formValues[0].locationDetails;
    const projectDetails = formValues[1].projectDetails;

    const fieldServiceRequest = prepareFieldServiceRequest(
      locationDetails,
      projectDetails
    );

    const user = yield select(currentUserSelector);

    const createdLocation = (yield createLocation(user, fieldServiceRequest))
      .data;
    locationDetails.sensightId = createdLocation.sensightId;

    const organizationIds = projectDetails.organizationsCoupled.map(
      organization => organization.sensightId
    );

    yield saveOrganizationsOfLocation(
      user,
      locationDetails.sensightId,
      organizationIds
    );

    const serviceContactIds = projectDetails.serviceContactPerson.map(
      contact => contact.sensightId
    );

    yield saveServiceContacts(
      user,
      locationDetails.sensightId,
      serviceContactIds
    );

    yield put(createSnackbar(t('content.locationdashboard.form.success')));
    //Do this, to avoid calling measurement for the location properties visible online,
    //which we already have
    var mergedLocation = { ...fieldServiceRequest, ...createdLocation };
    yield put(createLocationSucceeded(mergedLocation));
  }
}

function prepareFieldServiceRequest(locationDetails, projectDetails) {
  return {
    latLngLatitude: locationDetails.latLngLatitude,
    latLngLongitude: locationDetails.latLngLongitude,
    name: locationDetails.name,
    description: projectDetails.description,
    city: locationDetails.city,
    address: locationDetails.address,
    locationTypeId: locationDetails.locationType,
    referenceNumber: locationDetails.referenceNumber,
    projectNumber: locationDetails.projectNumber,
    contact: locationDetails.contactPerson.sensightId,
    switchTime: locationDetails.switchTime,
    timeZone: locationDetails.timeZone,
    visible: projectDetails.visible,
    online: projectDetails.online,
    sensightId: locationDetails.sensightId
  };
}
