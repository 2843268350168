import { createReducer } from 'utils/createReducer';
import {
  SET_DEVICE,
  SET_DEVICES_LIST,
  SET_AVAILABLESIMCARDS_LIST,
  CREATE_DEVICE_SUCCEEDED,
  DELETE_DEVICE_SUCCEEDED,
  EDIT_DEVICE_SUCCEEDED,
  SET_DEVICE_VISIBLE_SWITCH_VALUE,
  SET_DEVICE_ONLINE_SWITCH_VALUE,
  SET_SHOULD_DELETE_FUNCTIONAL_DEVICE
} from './devicesActions';
import { fromJS } from 'immutable';

export const devicesList = createReducer(null, {
  [SET_DEVICES_LIST](state, { devicesList }) {
    return fromJS(devicesList);
  },
  [CREATE_DEVICE_SUCCEEDED](state, { device }) {
    return state.push(fromJS(device));
  },
  [EDIT_DEVICE_SUCCEEDED](state, { device }) {
    let devicesList = state.toJS();
    var foundDeviceIndex = devicesList.findIndex(
      dev => device.sensightId === dev.sensightId
    );
    devicesList[foundDeviceIndex] = device;
    return fromJS(devicesList);
  },
  [DELETE_DEVICE_SUCCEEDED](state, { device }) {
    return state.filter(con => con.get('sensightId') !== device.sensightId);
  }
});

export const device = createReducer(null, {
  [SET_DEVICE](state, { device }) {
    return fromJS(device);
  }
});

export const availableSimCardsList = createReducer(null, {
  [SET_AVAILABLESIMCARDS_LIST](state, { availableSimCardsList }) {
    return fromJS(availableSimCardsList);
  }
});

export const deviceOnlineSwitchValue = createReducer(null, {
  [SET_DEVICE_ONLINE_SWITCH_VALUE](state, { deviceOnlineSwitchValue }) {
    return fromJS(deviceOnlineSwitchValue);
  }
});

export const deviceVisibleSwitchValue = createReducer(null, {
  [SET_DEVICE_VISIBLE_SWITCH_VALUE](state, { deviceVisibleSwitchValue }) {
    return fromJS(deviceVisibleSwitchValue);
  }
});

export const shouldDeleteFunctionalDevice = createReducer(null, {
  [SET_SHOULD_DELETE_FUNCTIONAL_DEVICE](state, { switchValue }) {
    return fromJS(switchValue);
  }
});
