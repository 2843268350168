import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import ImdPopup from 'components/imd-components/ImdPopup';
import { Column } from 'devextreme-react/tree-list';
import { CreateSimCardContainer } from '../managesimcard/CreateSimCardContainer';
import { EditSimCardContainer } from '../managesimcard/EditSimCardContainer';
import { sortIpAddress } from 'utils/simcardSorter';

export default function SimCardsTableDisplay({
  simCards,
  deleteSimCard,
  setSimCard
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleChangeSaveButton = enableSaveButton => {
    setSaveButton(enableSaveButton);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    setSimCard(row);
    openEdit();
  };

  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteSimCard({ selectedRow });
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [simCards]);

  const columns = (
    <>
      <Column
        visible={true}
        dataField={'iccid'}
        caption={t('content.simcards.iccid')}
        SortIndex={1}
        sortOrder={'asc'}
      />
      <Column
        visible={true}
        dataField={'ipAddress'}
        caption={t('content.simcards.ipaddress')}
        sortingMethod={sortIpAddress}
      />
      <Column
        visible={true}
        dataField={'provider'}
        caption={t('content.simcards.provider')}
      />
      <Column
        visible={true}
        dataField={'ratePlan'}
        caption={t('content.simcards.rateplan')}
      />
      <Column
        visible={false}
        dataField={'msisdn'}
        caption={t('content.simcards.msisdn')}
      />
      <Column
        visible={false}
        dataField={'imsi'}
        caption={t('content.simcards.imsi')}
      />
      <Column
        visible={false}
        dataField={'mac'}
        caption={t('content.simcards.mac')}
      />
      <Column
        visible={false}
        dataField={'phoneNumber'}
        caption={t('content.simcards.phonenumber')}
      />
      <Column
        visible={false}
        dataField={'pin'}
        caption={t('content.simcards.pin')}
      />
      <Column
        visible={false}
        dataField={'puk'}
        caption={t('content.simcards.puk')}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.simcardstable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditSimCardContainer
            ref={formRef}
            row={selectedRow}
            setSaveButton={e => handleChangeSaveButton(e)}
          ></EditSimCardContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.simcardstable.editsimcard.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <CreateSimCardContainer ref={formRef}></CreateSimCardContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.simcardstable.createsimcard.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.simcardstable.deletepopup.title')}
        content={t('content.simcardstable.deletepopup.content', {
          simcard: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.simcards.title')}
        data={simCards}
        columns={columns}
        stateStorageKey="sim-cards-table-display"
      />
    </>
  );
}
