import axios from 'axios';
import configFile from '../App_config.json';
import { getCurrentUserLanguage, getCurrentUserSensightId } from 'utils/currentUserReader';
import { getActiveTenant } from 'utils/tenantManager.js';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

const getTenantQueryParameter = () => {
  return `?tenant=${getActiveTenant()}`;
};

const getLanguageQueryParameter = user => {
  const queryParameter = 'language=' + getCurrentUserLanguage(user);
  return queryParameter;
};

export const getDashboardById = async (user, dashboardId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.reportingServiceApiUrl +
      'dashboard/' +
      dashboardId +
      getTenantQueryParameter(),
    config
  );
};

export const getAllDashboards = async user => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.reportingServiceApiUrl + 'dashboard' + getTenantQueryParameter(),
    config
  );
};

export const getAllManagementDashboards = async user => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.reportingServiceApiUrl +
      'dashboard/management' +
      getTenantQueryParameter(),
    config
  );
};

export const createNewDashboard = async (user, dashboard) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.reportingServiceApiUrl + 'dashboard' + getTenantQueryParameter(),
    dashboard,
    config
  );
};

export const updateDashboard = async (user, dashboard) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.reportingServiceApiUrl +
      `dashboard/${dashboard.id}` +
      getTenantQueryParameter(),
    dashboard,
    config
  );
};

export const deleteDashboard = async (user, dashboardId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.reportingServiceApiUrl +
      `dashboard/${dashboardId}` +
      getTenantQueryParameter(),
    config
  );
};

export const saveDashboardSections = async (user, dashboardId, requestBody) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.reportingServiceApiUrl +
      'dashboardSection/' +
      dashboardId +
      getTenantQueryParameter(),
    requestBody,
    config
  );
};

export const GetUsingOData = async (user, query) => {
  const config = createHeaderConfig(user);

  return axios
    .get(configFile.reportingServiceApiUrl + 'odata/' + query, config)
    .then(response => {
      return response.data.value;
    });
};

export const updateDashboardContactAuthorizations = async (
  user,
  dashboardId,
  contactIds
) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.reportingServiceApiUrl +
      'authorization/contact/' +
      dashboardId +
      getTenantQueryParameter(),
    contactIds,
    config
  );
};

export const getAllDataScienceReports = async user => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.reportingServiceApiUrl +
      'report' +
      getTenantQueryParameter() +
      '&' +
      getLanguageQueryParameter(user),
    config
  );
};

export const updateDashboardOrganizationAuthorizations = async (
  user,
  dashboardId,
  organizationIds
) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.reportingServiceApiUrl +
      'authorization/organization/' +
      dashboardId +
      getTenantQueryParameter(),
    organizationIds,
    config
  );
};

export const generateDataScienceReport = async (user, report) => {
  const config = createHeaderConfig(user);
  const language = getCurrentUserLanguage(user);
  const userId = getCurrentUserSensightId(user);

  if (!user || !report || !report.parameters) {
    return null;
  }
  const requestBody = {
    parameters: report.parameters.map(p => ({
      ...p,
      selectionData: report.selectionData
    })),
    language: language,
    user: userId
  };

  const url =
    configFile.reportingServiceApiUrl +
    'report/' +
    report.id +
    getTenantQueryParameter();
  return axios
    .post(url, requestBody, config)
    .then(response => {
      return response.data.report.content;
    })
    .catch(() => 'Failed to retrieve report data');
};

export const createExportChart = async (user, exportChartData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.reportingServiceApiUrl + 'exportChart/',
    exportChartData,
    config
  );
};

export const getExportCharts = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.reportingServiceApiUrl + 'exportChart/', config);
};

export const deleteExportChart = async (user, exportChartId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.reportingServiceApiUrl + 'exportChart/' + exportChartId,
    config
  );
};

export const editExportChart = async (user, exportChartData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.reportingServiceApiUrl + 'exportChart/' + exportChartData.id,
    exportChartData,
    config
  );
};
