import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_DASHBOARD_LOCATIONS = 'FETCH_DASHBOARD_LOCATIONS';
export const fetchDashboardLocations = makeActionCreator(
  FETCH_DASHBOARD_LOCATIONS
);

export const SET_DASHBOARD_LOCATIONS = 'SET_DASHBOARD_LOCATIONS';
export const setDashboardLocations = makeActionCreator(
  SET_DASHBOARD_LOCATIONS,
  'dashboardLocations'
);
