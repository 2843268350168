import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'formik-mui';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';

export const ThemeDetailsForm = ({
  themeDetailsFormRef,
  themeDetailsViewmodel,
  upsertThemeDetails,
  setThemeDetailsFormValid
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormikWithRef
      ref={themeDetailsFormRef}
      initialValues={{
        id: !themeDetailsViewmodel ? '' : themeDetailsViewmodel.id ?? '',
        name: !themeDetailsViewmodel ? '' : themeDetailsViewmodel.name ?? '',
        themeJson: !themeDetailsViewmodel
          ? ''
          : themeDetailsViewmodel.themeJson ?? ''
      }}
      enableReinitialize={true}
      validateOnBlur={true}
      validate={values => {
        let errors = {};
        if (!values.name) {
          errors.name = t('general.validation.required');
        }
        if (!values.themeJson) {
          errors.themeJson = t('general.validation.required');
        }
        return errors;
      }}
      onSubmit={values => {
        upsertThemeDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setThemeDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form>
            <Field
              id="name"
              name="name"
              type="text"
              label={t('content.themes.name')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.name}
              required
              variant="standard"
            />
            <Field
              id="themeJson"
              name="themeJson"
              type="text"
              label={t('content.themes.themejson')}
              fullWidth
              multiline
              rows="8"
              component={TextField}
              onChange={props.handleChange}
              value={props.values.themeJson}
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  }
}));
