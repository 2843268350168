import { connect } from 'react-redux';
import { LogItemsDataTableDisplay } from './LogItemsDataTableDisplay.jsx';
import { logitemsListOfLocationSelector } from './redux/logItemsListSelectors.js';
import {
  fetchLogItemsListOfLocation,
  deleteLogItemOfLocation
} from './redux/logItemsListActions';
import { fetchActionsList } from 'components/content/actions/redux/actionsListActions.js';
import { actionsListSelector } from 'components/content/actions/redux/actionsListSelectors';
import { fetchDevicesOfLocation } from '../manageLocation/devices/redux/devicesListActions.js';
import { fetchDevices } from 'components/content/devices/redux/devicesActions';
import { devicesSelector } from 'components/content/devices/redux/devicesSelectors.js';
import { fetchChannelsListOfLocation } from '../manageLocation/channels/redux/channelsListActions.js';
import { channelsListOfLocationSelector } from '../manageLocation/channels/redux/channelsListSelectors.js';
import { createSnackbar } from 'redux/ui/uiActions';

const mapStateToProps = (state, ownProps) => {
  return {
    logItems: logitemsListOfLocationSelector(state),
    actions: actionsListSelector(state),
    location: ownProps.location,
    locationSensightId: ownProps.locationSensightId,
    measurementLocationId: ownProps.measurementLocationId,
    compactColumns: ownProps.compactColumns ?? false,
    devices: devicesSelector(state),
    channels: channelsListOfLocationSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDevicesOfLocation(locationId) {
    dispatch(fetchDevicesOfLocation(locationId));
  },
  fetchLogItemsListOfLocation(locationSensightId) {
    dispatch(fetchLogItemsListOfLocation(locationSensightId));
  },
  deleteLogItem(logitem) {
    dispatch(deleteLogItemOfLocation(logitem));
  },
  fetchActions() {
    dispatch(fetchActionsList());
  },
  fetchDevices() {
    dispatch(fetchDevices());
  },
  fetchChannelsOfLocation(locationMeasurementId, locationSensightId) {
    dispatch(
      fetchChannelsListOfLocation(locationMeasurementId, locationSensightId)
    );
  },
  createSnackbar(message, variant) {
    dispatch(createSnackbar(message, variant));
  },
});

export const LogItemsDataTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogItemsDataTableDisplay);
