import { takeEvery, select, put } from 'redux-saga/effects';
import {
  FETCH_DATASCIENCE_REPORTS,
  setDataScienceReports
} from './selectDataScienceReportActions';
import { getAllDataScienceReports } from 'apis/ReportingServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchDataScienceReportsSaga() {
  yield takeEvery(FETCH_DATASCIENCE_REPORTS, execute);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var dataScienceReports = (yield getAllDataScienceReports(user)).data;
  yield put(setDataScienceReports(dataScienceReports));
}
