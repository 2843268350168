import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_DEVICES_OF_LOCATION = 'FETCH_DEVICES_OF_LOCATION';
export const fetchDevicesOfLocation = makeActionCreator(
  FETCH_DEVICES_OF_LOCATION,
  'locationId'
);

export const SET_DEVICES_OF_LOCATION = 'SET_DEVICES_OF_LOCATION';
export const setDevicesOfLocation = makeActionCreator(
  SET_DEVICES_OF_LOCATION,
  'devices'
);
