import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../../page/Content';
import { SimCardsTableContainer } from './SimCardsTableContainer';
import { PropTypes } from 'prop-types';

export default function SimCardsDisplay({ simCards, fetchSimCards }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchSimCards();
  }, []);

  const content = <SimCardsTableContainer simCards={simCards} />;
  return (
    <Content
      title={t('content.simcards.title')}
      secondTitle={t('navbar.manager')}
      content={content}
    />
  );
}

SimCardsDisplay.propTypes = {
  simCards: PropTypes.array,
  fetchSimCards: PropTypes.func
};
