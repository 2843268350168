import { createSelector } from 'reselect';

export const alertSelector = createSelector(
  state => state.get('alert'),
  alert => alert.toJS()
);

export const alertsSelector = createSelector(
  state => state.get('alertsList'),
  alertsList => alertsList.toJS()
);

export const alertActiveSwitchValueSelector = createSelector(
  state => state.get('alertActiveSwitchValue'),
  alertActiveSwitchValue => alertActiveSwitchValue
);

export const selectedSubscribersSelector = createSelector(
  state => state.get('selectedSubscribers'),
  selectedSubscribers => selectedSubscribers.toJS()
);

export const subscribersSelector = createSelector(
  state => state.get('subscribers'),
  subscribers => subscribers.toJS()
);
