import { createReducer } from 'utils/createReducer';
import {
  SET_EXPORTCHARTS_LIST,
  CREATE_EXPORTCHART_SUCCEEDED,
  DELETE_EXPORTCHART_SUCCEEDED,
  EDIT_EXPORTCHART_SUCCEEDED
} from './exportChartsListActions';
import { fromJS } from 'immutable';

export const exportChartsList = createReducer(null, {
  [SET_EXPORTCHARTS_LIST](state, { exportChartsList }) {
    return fromJS(exportChartsList);
  },
  [CREATE_EXPORTCHART_SUCCEEDED](state, { exportChart }) {
    return state.push(fromJS(exportChart));
  },
  [EDIT_EXPORTCHART_SUCCEEDED](state, { exportChart }) {
    let exportChartsList = state.toJS();
    var foundExportChartIndex = exportChartsList.findIndex(
      org => exportChart.id === org.id
    );
    exportChartsList[foundExportChartIndex] = exportChart;
    return fromJS(exportChartsList);
  },
  [DELETE_EXPORTCHART_SUCCEEDED](state, { exportChart }) {
    return state.filter(org => org.get('id') !== exportChart.id);
  }
});
