import axios from 'axios';
import configFile from '../App_config.json';
import { getCurrentUserSensightId } from 'utils/currentUserReader';
import { getActiveTenant } from 'utils/tenantManager.js';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

export const getSubscriptionsByAlertId = async (user, alertId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.notificationsServiceApiUrl + 'subscriptions/alertId/' + alertId,
    config
  );
};

export const getSubscriptionsByUserId = async user => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.notificationsServiceApiUrl +
      'subscriptions/userId/' +
      getCurrentUserSensightId(user),
    config
  );
};

export const createSubscription = async (user, subscriptionInformation) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.notificationsServiceApiUrl + 'subscriptions',
    subscriptionInformation,
    config
  );
};

export const deleteSubscription = async (user, alertId, sensightContactId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.notificationsServiceApiUrl +
      'subscriptions/alert/' +
      alertId +
      '/contact/' +
      sensightContactId,
    config
  );
};
