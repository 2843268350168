import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_VALIDATIONPROFILES_LIST = 'FETCH_VALIDATIONPROFILES_LIST';
export const fetchValidationProfilesList = makeActionCreator(
  FETCH_VALIDATIONPROFILES_LIST
);

export const SET_VALIDATIONPROFILES_LIST = 'SET_VALIDATIONPROFILES_LIST';
export const setValidationProfilesList = makeActionCreator(
  SET_VALIDATIONPROFILES_LIST,
  'validationProfilesList'
);
