import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { TextField } from 'formik-mui';
import { isEmptyObject } from 'utils/helperMethods';

export const AlertDetailsForm = ({
  alertDetailsFormRef,
  alertViewmodel,
  setAlertDetailsFormValid,
  upsertAlertDetails,
  selectedChannels,
  selectedDevices,
  selectedLocations
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [checkValueAboveSwitchValue, setCheckValueAboveSwitchValue] =
    React.useState(alertViewmodel ? alertViewmodel.checkValueAbove : true);

  const [alertActiveSwitchValue, setAlertActiveSwitchValue] = React.useState(
    alertViewmodel ? alertViewmodel.active : true
  );

  return (
    <FormikWithRef
      ref={alertDetailsFormRef}
      initialValues={{
        id: !alertViewmodel ? '' : alertViewmodel.id ?? '',
        channelSensightId: !alertViewmodel
          ? ''
          : alertViewmodel.channelSensightId ?? '',
        name: !alertViewmodel ? '' : alertViewmodel.name ?? '',
        valueLimit: !alertViewmodel ? '' : alertViewmodel.valueLimit ?? '',
        hysteresisValue: !alertViewmodel
          ? ''
          : alertViewmodel.hysteresisValue ?? '',
        numberOfViolations: !alertViewmodel
          ? ''
          : alertViewmodel.numberOfViolations ?? '',
        ignoreViolationsForSeconds: !alertViewmodel
          ? ''
          : alertViewmodel.ignoreViolationsForSeconds ?? ''
      }}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      validate={values => {
        let errors = {};
        if (!values.name) {
          errors.name = t('general.validation.required');
        }
        if (values.valueLimit === null || values.valueLimit === undefined) {
          errors.valueLimit = t('general.validation.required');
        }

        if (
          values.hysteresisValue === null ||
          values.hysteresisValue === undefined
        ) {
          errors.hysteresisValue = t('general.validation.required');
        }

        if (
          values.numberOfViolations === null ||
          values.numberOfViolations === undefined
        ) {
          errors.numberOfViolations = t('general.validation.required');
        }

        if (
          values.ignoreViolationsForSeconds === null ||
          values.ignoreViolationsForSeconds === undefined
        ) {
          errors.ignoreViolationsForSeconds = t('general.validation.required');
        }

        return errors;
      }}
      onSubmit={values => {
        values.location = selectedLocations[0];
        values.device = selectedDevices[0];
        values.channel = selectedChannels[0];
        values.active = alertActiveSwitchValue;
        values.checkValueAbove = checkValueAboveSwitchValue;
        upsertAlertDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setAlertDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form onSubmit={props.submitForm}>
            <Field
              id="name"
              name="name"
              type="text"
              label={t('content.alerts.name')}
              fullWidth
              className={classes.textField}
              component={TextField}
              required
              variant="standard"
            />
            <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
              <Typography variant="body1" className={classes.switchLabel}>
                {t('content.alerts.active')}
              </Typography>
              <div className={classes.switchItem}>
                <Switch
                  checked={alertActiveSwitchValue}
                  onChange={() =>
                    setAlertActiveSwitchValue(!alertActiveSwitchValue)
                  }
                  value="hide"
                  color="primary"
                />
              </div>
            </div>
            <Field
              id="valueLimit"
              name="valueLimit"
              type="number"
              label={t('content.alerts.valueLimit')}
              fullWidth
              className={classes.textField}
              component={TextField}
              required
              variant="standard"
            />
            <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
              <Typography variant="body1" className={classes.switchLabel}>
                {t('content.alerts.checkValueAbove')}
              </Typography>
              <div className={classes.switchItem}>
                <Switch
                  checked={checkValueAboveSwitchValue}
                  onChange={() =>
                    setCheckValueAboveSwitchValue(!checkValueAboveSwitchValue)
                  }
                  value="hide"
                  color="primary"
                />
              </div>
            </div>
            <Field
              id="hysteresisValue"
              name="hysteresisValue"
              type="number"
              label={t('content.alerts.hysteresisvalue')}
              fullWidth
              className={classes.textField}
              component={TextField}
              required
              variant="standard"
            />
            <Field
              id="numberOfViolations"
              name="numberOfViolations"
              type="number"
              label={t('content.alerts.numberofviolations')}
              fullWidth
              className={classes.textField}
              component={TextField}
              required
              variant="standard"
            />
            <Field
              id="ignoreViolationsForSeconds"
              name="ignoreViolationsForSeconds"
              type="number"
              label={t('content.alerts.ignoreviolationsforseconds')}
              fullWidth
              className={classes.lastInputField}
              component={TextField}
              required
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  firstWrapper: {
    marginBottom: '8px'
  },
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  textField: {
    marginBottom: '8px'
  },
  lastInputField: {
    marginBottom: '16px'
  }
}));
