import { connect } from 'react-redux';
import { SearchableCardView } from './SearchableCardView.jsx';

const mapStateToProps = (state, ownprops) => {
  return {
    requesting: ownprops.requesting,
    title: ownprops.title,
    content: ownprops.content,
    displayIcon: ownprops.displayIcon,
    selectedContent: ownprops.selectedContent,
    clickAction: ownprops.clickAction,
    deleteAction: ownprops.deleteAction,
    filterContent: ownprops.filterContent,
    locked: ownprops.locked,
    lockedMessage: ownprops.lockedMessage,
    displayKey: ownprops.displayKey
  };
};
export const SearchableCardContainer =
  connect(mapStateToProps)(SearchableCardView);
