import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = {
  palette: {
    primary: { main: '#007962', contrastText: '#ffffff' },
    secondary: { main: '#eeeeee', contrastText: '#000000' }
  },
  logo: 'https://imd-ma.nl/themes/ef2_custom/logo.svg',
  spacing: 4
};

export default createTheme(adaptV4Theme(theme));
