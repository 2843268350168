import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import ImdPopup from 'components/imd-components/ImdPopup';
import { Column } from 'devextreme-react/tree-list';
import makeStyles from '@mui/styles/makeStyles';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { CreateChannelContainer } from './createchannel/CreateChannelContainer';
import { EditChannelContainer } from './editchannel/EditChannelContainer';

export function ChannelsDataTableDisplay({
  locationId,
  locationSensightId,
  channels,
  fetchChannelsListOfLocation,
  deleteChannelOfLocation
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const saveButton = true;
  const [popup, setPopup] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handleEdit = selected => {
    setSelectedRow(selected);
    setEdit(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteChannelOfLocation(selectedRow);
  };

  useEffect(() => {
    if (locationId) {
      fetchChannelsListOfLocation(locationId, locationSensightId);
    }
  }, [locationId]);

  useEffect(() => {
    if (add || edit) {
      setAdd(false);
      setEdit(false);
    }
  }, [channels]);

  const columns = (
    <>
      <Column
        visible={false}
        dataField={'externalChannelId'}
        caption={t('content.channelstable.externalChannelId')}
      />
      <Column
        sortOrder="asc"
        sortIndex={2}
        visible={true}
        dataField={'displayLabel'}
        caption={t('content.channelstable.displayLabel')}
      />
      <Column
        sortOrder="asc"
        visible={true}
        dataField={'device.name'}
        caption={t('content.channelstable.device')}
      />
      <Column
        visible={true}
        dataField={'unit'}
        caption={t('content.channelstable.unit')}
      />
      <Column
        sortOrder="desc"
        sortIndex={0}
        visible={true}
        dataField={'visible'}
        caption={t('content.channelstable.visible')}
      />
      <Column
        visible={!isMobile}
        dataField={'min'}
        caption={t('content.channelstable.min')}
      />
      <Column
        visible={!isMobile}
        dataField={'max'}
        caption={t('content.channelstable.max')}
      />
      <Column
        visible={false}
        dataField={'channelType'}
        caption={t('content.channelstable.channelType')}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        caption={t('content.channelstable.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.channelstable.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        caption={t('content.channelstable.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.channelstable.lastUpdatedBy')}
      />
      <Column
        visible={false}
        dataField={'lowerLimit'}
        caption={t('content.channelstable.lowerLimit')}
      />
      <Column
        visible={!isMobile}
        dataField={'reliabilityPercentage'}
        caption={t('content.channelstable.reliabilityPercentage')}
      />
      <Column
        visible={!isMobile}
        dataField={'validationProfile.name'}
        caption={t('content.channels.form.validationprofile')}
      />
      <Column
        visible={false}
        dataField={'sensorSerialNumber'}
        caption={t('content.channelstable.sensorSerialNumber')}
      />
      <Column
        visible={false}
        dataField={'sensorType'}
        caption={t('content.channelstable.sensorType')}
      />
      <Column
        visible={false}
        dataField={'upperLimit'}
        caption={t('content.channelstable.upperLimit')}
      />
      <Column
        type="buttons"
        caption={t('content.channels.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );
  const formRef = useRef();
  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  return (
    <div className={classes.content}>
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <CreateChannelContainer
            locationId={locationId}
            ref={formRef}
          ></CreateChannelContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.channelstable.createchannel.title')}
      />
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditChannelContainer
            locationId={locationId}
            channel={selectedRow}
            ref={formRef}
          ></EditChannelContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.channelstable.editchannel.title')}
      />

      <ImdPopup
        open={popup}
        title={t('content.channelstable.deletepopup.title')}
        content={t('content.channelstable.deletepopup.content', {
          channel: `'${
            selectedRow.displayLabel ?? selectedRow.externalChannelId$
          }'`
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={() => openAdd()}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.channels.title')}
        data={channels}
        hideActions={isMobile}
        columns={columns}
        stateStorageKey="channels-table-display"
      />
    </div>
  );
}

const useStyles = makeStyles({});
