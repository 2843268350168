import { makeActionCreator } from 'utils/makeActionCreator';

export const CREATE_LOGITEM = 'CREATE_LOGITEM';
export const createLogItem = makeActionCreator(CREATE_LOGITEM, 'logitem');

export const CREATE_LOGITEM_SUCCEEDED = 'CREATE_LOGITEM_SUCCEEDED';
export const createLogItemSucceeded = makeActionCreator(
  CREATE_LOGITEM_SUCCEEDED,
  'logitem'
);
