import { connect } from 'react-redux';
import EditDeviceDisplay from './EditDeviceDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  deviceSelector,
  availableSimCardsSelector,
  onlineSwitchValueSelector,
  visibleSwitchValueSelector
} from '../redux/devicesSelectors.js';
import {
  editDevice,
  upsertTechnicalDeviceDetails,
  upsertTechnicalDeviceDescription,
  upsertFunctionalDeviceDetails,
  fetchAvailableSimCards,
  setDeviceOnlineSwitchValue,
  setDeviceVisibleSwitchValue
} from '../redux/devicesActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = state => {
  var viewModel = deviceSelector(state);
  return {
    viewModel: viewModel,
    currentUser: currentUserSelector(state),
    availableSimCards: availableSimCardsSelector(state),
    onlineSwitchValue: onlineSwitchValueSelector(state),
    visibleSwitchValue: visibleSwitchValueSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  fetchAvailableSimCards() {
    dispatch(fetchAvailableSimCards());
  },
  editDevice(sensightId) {
    dispatch(editDevice(sensightId));
  },
  upsertFunctionalDeviceDetails(functionalDeviceDetails) {
    dispatch(upsertFunctionalDeviceDetails(functionalDeviceDetails));
  },
  upsertTechnicalDeviceDetails(technicalDeviceDetails) {
    dispatch(upsertTechnicalDeviceDetails(technicalDeviceDetails));
  },
  upsertTechnicalDeviceDescription(technicalDeviceDescription) {
    dispatch(upsertTechnicalDeviceDescription(technicalDeviceDescription));
  },
  setDeviceOnlineSwitchValue(switchValue) {
    dispatch(setDeviceOnlineSwitchValue(switchValue));
  },
  setDeviceVisibleSwitchValue(switchValue) {
    dispatch(setDeviceVisibleSwitchValue(switchValue));
  }
});

export const EditDeviceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(EditDeviceDisplay);
