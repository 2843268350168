import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { Get } from 'apis/MeasurementODataApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import {
  setDevicesOfLocation,
  FETCH_DEVICES_OF_LOCATION
} from './devicesListActions';
import { getODataFormattedDateTimeString } from 'utils/helperMethods';
import { devicesSelector } from 'components/content/devices/redux/devicesSelectors';

export function* devicesOfLocationSagas() {
  yield takeEvery(FETCH_DEVICES_OF_LOCATION, fetchDevices);
}

function* fetchDevices(action) {
  try {
    const user = yield select(currentUserSelector);
    let currentDate = getODataFormattedDateTimeString(new Date());

    const query =
      'device?$filter=deviceplacements/any(o: o/locationId eq ' +
      action.locationId +
      ' and (o/startDateTime lt ' +
      currentDate +
      ' or o/startDateTime eq null) and (o/endDateTime gt ' +
      currentDate +
      ' or o/endDateTime eq null))';
    const result = yield Get(user, query);

    const allDevices = yield select(devicesSelector);

    let foundDevices = allDevices.filter(device =>
      result.some(r => r.sensightId === device.sensightId)
    );

    yield put(setDevicesOfLocation(foundDevices));
  } catch (e) {
    yield put(createSnackbar(t('content.devices.fetchdeviceserror'), 'error'));
    console.log(e);
  }
}
