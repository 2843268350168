import { connect } from 'react-redux';
import { DesktopToolbar } from './desktopToolbar.jsx';
import { showDialog } from '../dialogs/selectChannelDialog/redux/selectChannelDialogActions';
import {
  setVisualizeFromTime,
  setVisualizeUntilTime,
  setCurrentVisualizePeriod,
  setPreviousVisualizePeriod,
  setNextVisualizePeriod,
  setTodayVisualizePeriod,
  setChartDisplayOptions
} from '../redux/toolbarActions';
import {
  visualizeFromTimeSelector,
  visualizeUntilTimeSelector,
  visualizePeriodSelector,
  chartDisplayOptionsSelector
} from '../redux/toolbarSelectors';
import { chartRenderedSelector } from '../../displayChartComponent/redux/displayChartSelectors.js';
import { displayChart } from '../../displayChartComponent/redux/displayChartActions.js';
import { selectedChannelsSelector } from '../dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';
import { isLoadingSelector } from 'redux/ui/uiSelectors';

const mapStateToProps = (state, ownProps) => {
  const visualizeFromTime = visualizeFromTimeSelector(state);
  const visualizeUntilTime = visualizeUntilTimeSelector(state);
  const visualizePeriod = visualizePeriodSelector(state);
  const chartRendered = chartRenderedSelector(state);
  const selectedChannels = selectedChannelsSelector(state);
  const chartDisplayOptions = chartDisplayOptionsSelector(state);
  const isLoading = isLoadingSelector(state);

  return {
    clearSelectChannelDialogStateOnOpen: ownProps.clearSelectChannelDialogStateOnOpen ?? false,
    clearSelectChannelDialogStateImmediately: ownProps.clearSelectChannelDialogStateImmediately ?? false,
    visualizeFromTime: visualizeFromTime,
    visualizeUntilTime: visualizeUntilTime,
    visualizePeriod: visualizePeriod,
    chartRendered: chartRendered,
    selectedChannels: selectedChannels,
    chartDisplayOptions: chartDisplayOptions,
    isLoading: isLoading,
    settings: ownProps.settings ?? true,
    chartSettings: ownProps.chartSettings ?? true
  };
};

const mapDispatchToProps = dispatch => ({
  showDialog() {
    dispatch(showDialog());
  },
  setVisualizeFromTime(value) {
    dispatch(setVisualizeFromTime(value));
  },
  setVisualizeUntilTime(value) {
    dispatch(setVisualizeUntilTime(value));
  },
  setCurrentVisualizePeriod(value) {
    dispatch(setCurrentVisualizePeriod(value));
  },
  setPreviousVisualizePeriod() {
    dispatch(setPreviousVisualizePeriod());
  },
  setNextVisualizePeriod() {
    dispatch(setNextVisualizePeriod());
  },
  setTodayVisualizePeriod() {
    dispatch(setTodayVisualizePeriod());
  },
  setChartDisplayOptions(value) {
    dispatch(setChartDisplayOptions(value));
  },
  displayChart() {
    dispatch(displayChart());
  }
});

export const DesktopToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopToolbar);
