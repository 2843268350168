import React from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import ImdPopup from 'components/imd-components/ImdPopup';
import { Column } from 'devextreme-react/tree-list';
import { NewDashboardDialogContainer } from '../newDashboardDialog/NewDashboardDialogContainer';
import { PropTypes } from 'prop-types';

export default function ManageDashboardDisplay({
  dashboards,
  deleteDashboard,
  fetchDashboards,
  setSaveButtonDisabled,
  organizations,
  contacts
}) {
  const { t } = useTranslation();
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [newDashboard, setNewDashboard] = React.useState(false);
  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  React.useEffect(() => {
    fetchDashboards();
  }, []);

  const handleAddButton = () => {
    setSelectedRow({});
    setNewDashboard(true);
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handleSelected = selected => {
    setSelectedRow(selected);
    setNewDashboard(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteDashboard(selectedRow);
  };

  const handleOk = () => {
    setNewDashboard(false);
  };

  const columns = (
    <>
      <Column
        dataField={'title'}
        caption={t('content.dashboardstable.title')}
        sortOrder={'asc'}
      />
      <Column
        dataField={'autoRefreshInMinutes'}
        caption={t('content.dashboardstable.autorefreshinminutes')}
      />
      <Column
        dataField={'onlyAdminsCanEdit'}
        caption={t('content.dashboardstable.onlyadminscanedit')}
      />
      <Column
        visible={!isMobile}
        allowFiltering={false}
        dataField={'organizationIds'}
        caption={t('content.dashboardstable.organisations')}
        calculateDisplayValue={rowData => {
          return getOrganizationNames(rowData, organizations);
        }}
      ></Column>
      <Column
        visible={false}
        dataField={'persons'}
        caption={t('content.dashboardstable.persons')}
        allowFiltering={false}
        calculateDisplayValue={rowData => {
          return getContactNames(rowData, contacts);
        }}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.dashboards.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleSelected(event.row.data)
          },
          {
            hint: t('general.edit'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  return (
    <>
      <ImdPopup
        open={popup}
        title={t('content.dashboardstable.deletepopup.title')}
        content={t('content.dashboardstable.deletepopup.content', {
          dashboard: selectedRow.title
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <NewDashboardDialogContainer
        open={newDashboard}
        setSaveButtonDisabled={value => setSaveButtonDisabled(value)}
        setOpen={() => setNewDashboard(false)}
        dashboardToEdit={selectedRow}
        handleOk={handleOk}
      />
      <ImdDataTable
        handleEditButton={selected => handleSelected(selected)}
        handleAddButton={() => handleAddButton()}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.dashboards.title')}
        data={dashboards}
        columns={columns}
        stateStorageKey="manage-dashboard-display"
      />
    </>
  );
}
function getOrganizationNames(rowData, organizations) {
  const values = rowData.organizationIds;
  if (!values) {
    return '';
  }
  let resultString = '';

  values.forEach(value => {
    if (organizations) {
      let foundOrganization = organizations.find(
        org => org.sensightId === value
      );
      if (foundOrganization) {
        resultString += foundOrganization.name + ', ';
      }
    }
  });
  return resultString.substring(0, resultString.length - 2);
}

function getContactNames(rowData, contacts) {
  const values = rowData.contactIds;
  if (!values) {
    return '';
  }
  let resultString = '';
  values.forEach(value => {
    if (contacts) {
      let foundContact = contacts.find(con => con.sensightId === value);
      if (foundContact) {
        resultString += foundContact.name + ', ';
      }
    }
  });
  return resultString.substring(0, resultString.length - 2);
}

ManageDashboardDisplay.propTypes = {
  dashboards: PropTypes.array,
  deleteDashboard: PropTypes.func,
  fetchDashboards: PropTypes.func,
  setSaveButtonDisabled: PropTypes.func,
  organizations: PropTypes.array,
  contacts: PropTypes.array
};
