import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../../page/Content';
import { DevicesTableContainer } from './DevicesTableContainer';
import { PropTypes } from 'prop-types';

export default function DevicesDisplay({ devices, fetchDevices }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchDevices();
  }, []);

  const content = <DevicesTableContainer devices={devices} />;
  return (
    <Content
      title={t('content.devices.title')}
      secondTitle={t('navbar.manage')}
      content={content}
    />
  );
}

DevicesDisplay.propTypes = {
  devices: PropTypes.array,
  fetchDevices: PropTypes.func
};
