import { makeActionCreator } from 'utils/makeActionCreator';

export const DISPLAY_CHART = 'DISPLAY_CHART';
export const displayChart = makeActionCreator(DISPLAY_CHART, 'chartOptions');

export const SET_CHART_DATA = 'SET_CHART_DATA';
export const setChartdata = makeActionCreator(SET_CHART_DATA, 'data');

export const CLEAR_CHART_DATA = 'CLEAR_CHART_DATA';
export const clearChartData = makeActionCreator(CLEAR_CHART_DATA);

export const SET_CHART_RENDERED = 'SET_CHART_RENDERED';
export const setChartRendered = makeActionCreator(SET_CHART_RENDERED, 'value');

export const SET_CHANNEL_DATA = 'SET_CHANNEL_DATA';
export const setChannelData = makeActionCreator(SET_CHANNEL_DATA, 'data');

export const CLEAR_CHANNEL_DATA = 'CLEAR_CHANNEL_DATA';
export const clearChannelDataa = makeActionCreator(CLEAR_CHANNEL_DATA);

export const IMPORT_VISUALIZE_LINK = 'IMPORT_VISUALIZE_LINK';
export const importVisualizeLink = makeActionCreator(
  IMPORT_VISUALIZE_LINK,
  'importObject'
);

export const SET_LOGITEM_DATA = 'SET_LOGITEM_DATA';
export const setLogItemData = makeActionCreator(SET_LOGITEM_DATA, 'data');
