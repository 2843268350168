import { connect } from 'react-redux';
import { ProfileDisplay } from './ProfileDisplay.jsx';
import { profileDetailsSelector } from './redux/profileDetailsSelectors';
import { fetchProfileDetails } from './redux/profileDetailsActions';

const mapStateToProps = state => {
  const profileDetails = profileDetailsSelector(state);

  return {
    profileDetails: profileDetails
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProfileDetails() {
    dispatch(fetchProfileDetails());
  }
});

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDisplay);
