import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { changeLocaleSettings } from 'apis/UserManagementApi';
import { CHANGE_LOCALE_SETTINGS } from './changeLocaleActions';
import { profileDetailsSelector } from 'components/content/profile/redux/profileDetailsSelectors';
import { setProfileDetails } from 'components/content/profile/redux/profileDetailsActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { changeLanguage, t } from 'i18next';

export function* changeLocaleSettingsSaga() {
  yield takeEvery(CHANGE_LOCALE_SETTINGS, execute);
}

function* execute(action) {
  const localeSettings = action.localeSettings;

  try {
    var currentUser = yield select(currentUserSelector);
    yield changeLocaleSettings(currentUser, localeSettings);
    changeLanguage(localeSettings.selectedLanguage);
    const userProfile = yield select(profileDetailsSelector);

    const language = localeSettings.selectedLanguage;
    const timeZone = localeSettings.selectedTimezone;

    var updatedProfile = { ...userProfile, language, timeZone };
    yield put(setProfileDetails(updatedProfile));
    yield put(
      createSnackbar(t('content.profile.changelanguage.snackbar.text'))
    );
  } catch (exception) {
    console.error(exception);
  }
}
