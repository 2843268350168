import { takeEvery, select, put } from 'redux-saga/effects';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  FETCH_LOGITEMS_LIST_OF_LOCATION,
  setLogItemsListOfLocation,
  DELETE_LOGITEM_OF_LOCATION,
  deleteLogItemOfLocationSucceeded
} from './logItemsListActions';
import {
  getLogItemsByLocationSensightId,
  deleteLogItem
} from 'apis/FieldServiceApi';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* logItemsOfLocationSagas() {
  yield takeEvery(FETCH_LOGITEMS_LIST_OF_LOCATION, fetchLogItems);
  yield takeEvery(DELETE_LOGITEM_OF_LOCATION, deleteLogItemOfLocation);
}

function* fetchLogItems(action) {
  try {
    const user = yield select(currentUserSelector);

    const fieldServiceLogItems = (yield getLogItemsByLocationSensightId(
      user,
      action.locationSensightId
    )).data;

    yield put(setLogItemsListOfLocation(fieldServiceLogItems));
  } catch (e) {
    yield put(createSnackbar(t('content.logitems.fetcherror'), 'error'));
    console.log(e);
  }
}

function* deleteLogItemOfLocation(action) {
  const logitem = action.logitem;
  const user = yield select(currentUserSelector);

  try {
    yield deleteLogItem(user, logitem.id);
    yield put(createSnackbar(t('content.deletelogitem.snackbar.text')));
    yield put(deleteLogItemOfLocationSucceeded(logitem));
  } catch (exception) {
    console.error(exception);
  }
}
