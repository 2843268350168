import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { GetUsingOData, saveDashboardSections } from 'apis/ReportingServiceApi';
import {
  setCurrentDashboard,
  FETCH_DASHBOARD,
  SAVE_DASHBOARD,
  fetchDashboard
} from './dashboardActions';
import { sectionsSelector } from '../../createDashboard/redux/editDashboardSelectors';
import { currentDashboardSelector } from './dashboardSelectors';
import {
  buildChannelODataQuery,
  enrichChannelData
} from 'components/content/visualize/displayChartComponent/redux/displayChartSagas';
import { Get } from 'apis/MeasurementODataApi';
import {
  getFromDate,
  getUntilDate
} from 'components/content/visualize/VisualizePeriods';

export function* fetchDashboardSaga() {
  yield takeEvery(FETCH_DASHBOARD, executeFetchDashboard);
}

export function* saveDashboardSaga() {
  yield takeEvery(SAVE_DASHBOARD, executeSaveDashboard);
}

function* executeFetchDashboard(action) {
  const user = yield select(currentUserSelector);

  const query = buildODataQuery(action.dashboardId);

  const dashboard = (yield GetUsingOData(user, query))[0];
  if (dashboard.dashboardSections) {
    dashboard.dashboardSections.sort((a, b) => a.rowIndex - b.rowIndex);
    for (const section of dashboard.dashboardSections) {
      section.items = JSON.parse(section.items);

      for (const item of section.items) {
        if (
          (item.visualizationType == 1 ||
            item.visualizationType == 2 ||
            item.visualizationType == 5) &&
          item.visualizationSettings != null &&
          item.visualizationSettings.selectionData != null
        ) {
          item.visualizationSettings.selectionData.visualizeFromTime =
            getFromDate(
              item.visualizationSettings.selectionData.visualizePeriod
            );
          item.visualizationSettings.selectionData.visualizeUntilTime =
            getUntilDate(
              item.visualizationSettings.selectionData.visualizePeriod
            );

          if (item.visualizationType == 1 || item.visualizationType == 2) {
            const channelQuery = buildChannelODataQuery(
              item.visualizationSettings.selectedChannels,
              new Date(
                item.visualizationSettings.selectionData.visualizeFromTime
              ),
              new Date(
                item.visualizationSettings.selectionData.visualizeUntilTime
              )
            );
            let channelData = yield Get(user, channelQuery);

            if (channelData.length != 0) {
              item.visualizationSettings.selectedChannels = enrichChannelData(
                item.visualizationSettings.selectedChannels,
                channelData
              );
            }
          }
        }
      }
    }
  }

  yield put(setCurrentDashboard(dashboard));
}

function* executeSaveDashboard() {
  const user = yield select(currentUserSelector);
  const dashboard = yield select(currentDashboardSelector);
  const sections = yield select(sectionsSelector);

  const saveSectionsRequest = [];

  for (let index = 0; index < sections.length; index++) {
    const section = sections[index];

    const requestItem = {
      dashboardId: dashboard.id,
      rowIndex: index,
      dashboardSectionId: section.id,
      numberOfItems: section.numberOfItems,
      items: JSON.stringify(filterEmptyParameters(section.items))
    };

    saveSectionsRequest.push(requestItem);
  }

  yield saveDashboardSections(user, dashboard.id, saveSectionsRequest);
  yield put(fetchDashboard(dashboard.id));
}

function buildODataQuery(dashboardId) {
  return `dashboards(${dashboardId})`;
}

function filterEmptyParameters(items) {
  for (const item of items) {
    if (item?.visualizationSettings?.parameters) {
      item.visualizationSettings.parameters =
        item.visualizationSettings.parameters.filter(
          param => param.value !== null
        );
    }
  }
  return items;
}
