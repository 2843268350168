import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ImdButton from 'components/imd-components/ImdButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SearchableCardContainer } from '../../../searchableCard/SearchableCardContainer';
import { ChannelDataTable } from '../../../datatable/channelDataTable.jsx';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

export function SelectChannelDialogDisplay({
  open,
  hideDialog,
  fetchLocations,
  requestingLocations,
  locations,
  selectedLocations,
  displayedLocations,
  addSelectedLocation,
  deleteSelectedLocation,
  filterDisplayedLocations,
  /* **** */
  fetchDevices,
  requestingDevices,
  devices,
  selectedDevices,
  displayedDevices,
  addSelectedDevice,
  deleteSelectedDevice,
  filterDisplayedDevices,
  /* **** */
  fetchChannels,
  requestingChannels,
  channels,
  selectedChannels,
  displayedChannels,
  addSelectedChannel,
  deleteSelectedChannel,
  filterDisplayedChannels,
  chartRendered,
  clearSelectChannelDialogState,
  displayChart,
  updateSelectedChannel,
  defaultChannelChartType,
  defaultChannelAggregationType,
  clearSelectChannelDialogStateOnOpen,
  clearSelectChannelDialogStateImmediately,
  chartSettings,
  defaultAggregation,
  chartAggregation,
  chartType,
  isFetchingChannelDialogLocations,
  isFetchingChannelDialogDevices,
  hasStartedFetchingLocationsAndDevices,
  hasFinishedFetchingLocationsAndDevices
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasStartedFetchingLocations, setHasStartedFetchingLocations] =
    useState(false);
  const [hasStartedFetchingDevices, setHasStartedFetchingDevices] =
    useState(false);

  useEffectOnce(() => {
    if (
      (clearSelectChannelDialogStateOnOpen && open) ||
      clearSelectChannelDialogStateImmediately
    ) {
      console.dev(
        `Start dispatching action to clear the select channel dialog state ${
          clearSelectChannelDialogStateImmediately
            ? `immediately`
            : `on open dialog`
        }`
      );
      clearSelectChannelDialogState();
      hasStartedFetchingLocationsAndDevices(true);
      hasFinishedFetchingLocationsAndDevices(false);
    }
  }, [
    clearSelectChannelDialogStateOnOpen,
    clearSelectChannelDialogStateImmediately,
    open
  ]);

  useEffect(() => {
    if (
      !isFetchingChannelDialogDevices &&
      hasStartedFetchingDevices &&
      !isFetchingChannelDialogLocations &&
      hasStartedFetchingLocations
    ) {
      console.dev(
        'Finished fetching locations and devices, setting hasFinishedFetchingLocationsAndDevices'
      );
      hasStartedFetchingLocationsAndDevices(false);
      setHasStartedFetchingLocations(false);
      setHasStartedFetchingDevices(false);
      hasFinishedFetchingLocationsAndDevices(true);
    }
    if (isFetchingChannelDialogLocations) {
      setHasStartedFetchingLocations(true);
    }
    if (isFetchingChannelDialogDevices) {
      setHasStartedFetchingDevices(true);
    }
  }, [isFetchingChannelDialogDevices, isFetchingChannelDialogLocations]);

  useEffect(() => {
    fetch();
  }, [open]);

  const fetch = () => {
    if (open == false) {
      return;
    }
    if (
      locations.length + selectedLocations.length == 0 &&
      requestingLocations == false
    ) {
      fetchLocations();
    }
    if (
      devices.length + selectedDevices.length == 0 &&
      requestingDevices == false
    ) {
      fetchDevices();
    }
  };

  const onRowUpdated = updatedRow => {
    updateSelectedChannel(updatedRow.data);
  };

  const checkIfLocked = () => {
    const locked = selectedDevices.length === 0;

    if (!locked) {
      if (
        channels.length + selectedChannels.length == 0 &&
        requestingChannels == false
      ) {
        fetchChannels();
      }
    }

    return locked;
  };

  const executeHideDialog = () => {
    if (chartRendered) {
      displayChart();
    }
    hideDialog();
  };

  return (
    <Dialog
      maxWidth={'xl'}
      open={open}
      onClose={() => hideDialog()}
      scroll={'paper'}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        {t('content.visualize.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={8}>
          <Grid item lg={4} className={classes.card}>
            <SearchableCardContainer
              title={t('content.visualize.dialog.locations.card.title')}
              selectedContent={selectedLocations}
              content={displayedLocations}
              displayKey={'name'}
              displayIcon={true}
              requesting={requestingLocations}
              clickAction={addSelectedLocation}
              deleteAction={deleteSelectedLocation}
              filterContent={filterDisplayedLocations}
            />
          </Grid>
          <Grid item lg={4} className={classes.card}>
            <SearchableCardContainer
              title={t('content.visualize.dialog.devices.card.title')}
              selectedContent={selectedDevices}
              content={displayedDevices}
              displayKey={'name'}
              requesting={requestingDevices}
              clickAction={addSelectedDevice}
              deleteAction={deleteSelectedDevice}
              filterContent={filterDisplayedDevices}
            />
          </Grid>
          <Grid item lg={4} className={classes.lastCard}>
            <SearchableCardContainer
              title={t('content.visualize.dialog.channels.card.title')}
              selectedContent={[]}
              locked={checkIfLocked()}
              lockedMessage={t(
                'content.visualize.dialog.channels.selectdevice'
              )}
              content={displayedChannels}
              displayIcon={true}
              displayKey={'displayLabel'}
              requesting={requestingChannels}
              clickAction={channelName =>
                addSelectedChannel(
                  channelName,
                  defaultChannelChartType,
                  defaultChannelAggregationType
                )
              }
              deleteAction={channelName =>
                deleteSelectedChannel(
                  channelName,
                  defaultChannelChartType,
                  defaultChannelAggregationType
                )
              }
              filterContent={filterDisplayedChannels}
            />
          </Grid>
          <Grid item lg={12} className={classes.dataTable}>
            <ChannelDataTable
              defaultAggregation={defaultAggregation}
              chartAggregation={chartAggregation}
              chartType={chartType}
              selectedChannels={selectedChannels}
              deleteAction={deleteSelectedChannel}
              onRowUpdated={onRowUpdated}
              chartSettings={chartSettings}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ImdButton
          onClick={() => clearSelectChannelDialogState()}
          color="inherit"
        >
          {t('content.visualize.dialog.actions.button.clear')}
        </ImdButton>
        <ImdButton
          onClick={() => executeHideDialog()}
          variant="contained"
          color="primary"
        >
          {t('content.visualize.dialog.actions.button.save')}
        </ImdButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '0px',
    marginLeft: '24px',
    marginBottom: '0px'
  },
  accentBar: {
    backgroundColor: '#007962',
    width: '1%'
  },
  toolbar: {
    display: 'flex',
    marginTop: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#FFFFFF',
    position: 'sticky',
    top: 97,
    zIndex: 3
  },
  toolbarHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '8px'
  },
  toolbarHeadingExtraMargin: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: '8px',
    marginBottom: '16px'
  },
  toolbarHeadingExtraMarginWeergaveEnExporteren: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: '30px',
    marginTop: '8px'
  },
  weergaveEnExporteren: {
    marginLeft: '48px'
  },
  rightUpperCorner: {
    textAlign: 'right'
  },
  secondRow: {
    marginTop: '26px'
  },
  imdButton: {
    display: 'inline'
  },
  imdButtonBlock: {
    width: '73%'
  },
  section: {
    display: 'block',
    paddingTop: '0px',
    marginLeft: '16px',
    marginBottom: '16px'
  },
  tijdsinstellingen: {
    marginLeft: '8px'
  },
  spanText: {
    fontSize: '15px',
    marginBottom: '12px',
    fontStyle: 'italic',
    display: 'block'
  },
  spanTextSecondRow: {
    fontSize: '15px',
    marginBottom: '22px',
    fontStyle: 'italic',
    display: 'block'
  },
  weergaveImdButton: {
    display: 'inline',
    marginRight: '10px'
  },
  nextImdButton: {
    paddingRight: '23px'
  },
  nextIcon: {
    marginRight: '-18px'
  },
  rightDatePicker: {
    display: 'inline',
    marginLeft: '32px'
  },
  iconButton: {
    marginRight: '28px',
    display: 'inline'
  },
  previousIcon: {
    marginRight: '-3px',
    marginBottom: '1px'
  },
  extendedIcon: {
    marginRight: '-3px',
    marginBottom: '-3px'
  },
  timeButtons: {
    marginLeft: '12px',
    display: 'inline'
  },
  timeNavigationButtons: {
    display: 'inline'
  },
  image: {
    marginLeft: '-8px',
    marginTop: '48px'
  },
  previousButton: {
    marginRight: '48px'
  },
  timeselectionButton: {
    width: '50px',
    minWidth: '40px',
    marginLeft: '18px'
  },
  datePickers: {
    marginBottom: '14px'
  },
  expandButton: {
    fontSize: '30px',
    marginRight: '4px',
    marginTop: '8px',
    cursor: 'pointer'
  },
  card: {
    marginTop: '16px',
    marginBottom: '8px',
    maxHeight: '350px'
  },
  lastCard: {
    marginTop: '16px',
    marginRight: '0',
    marginBottom: '32px'
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  dataTable: {
    marginBottom: '8px'
  }
}));
