import { connect } from 'react-redux';
import ContactsTableDisplay from './ContactsTableDisplay.jsx';
import { deleteContact } from './redux/deleteContactActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    contacts: ownProps.contacts,
    organization: ownProps.organization,
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteContact(row) {
    dispatch(deleteContact(row));
  }
});

export const ContactsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsTableDisplay);
