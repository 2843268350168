import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  FETCH_DEVICES_REQUESTED,
  fetchChannelsRequested,
  isFetchingChannelDialogDevices
} from './selectChannelDialogActions';

import {
  ADD_SELECTED_DEVICE,
  DELETE_SELECTED_DEVICE,
  FILTER_DISPLAYED_DEVICES,
  deleteVisualizationDevice,
  deleteDisplayedDevice,
  addVisualizationDevice,
  addDisplayedDevice,
  setVisualizationDevices,
  setDisplayedDevices
} from './deviceActions';

import { Get } from 'apis/MeasurementODataApi';
import {
  devicesSelector,
  selectedLocationsSelector,
  selectedDevicesSelector
} from './selectChannelDialogSelectors';
import { fromJS } from 'immutable';
import { removeSelectedEntriesFromOriginalList } from 'utils/helperMethods';

export function* fetchDevicesSaga() {
  yield takeEvery(FETCH_DEVICES_REQUESTED, fetchDevices);
}

export function* addSelectedDeviceSaga() {
  yield takeEvery(ADD_SELECTED_DEVICE, addSelectedDevice);
}

export function* deleteSelectedDeviceSaga() {
  yield takeEvery(DELETE_SELECTED_DEVICE, addDeletedDeviceBackToList);
}

export function* filterDisplayedDevicesSaga() {
  yield takeEvery(FILTER_DISPLAYED_DEVICES, filterDisplayedDevices);
}

function* filterDisplayedDevices(action) {
  const allDevices = yield select(devicesSelector);
  const result = allDevices.filter(c =>
    c.name.toLowerCase().includes(action.query.toLowerCase())
  );
  yield put(setDisplayedDevices(result));
}

function* addSelectedDevice(action) {
  const device = action.device;
  yield put(deleteVisualizationDevice(device));
  yield put(deleteDisplayedDevice(device));

  yield put(fetchChannelsRequested());
}

function* addDeletedDeviceBackToList(action) {
  const device = fromJS(action.device);
  yield put(addVisualizationDevice(device));
  yield put(addDisplayedDevice(device));

  yield put(fetchChannelsRequested());
}

function* fetchDevices() {
  yield put(isFetchingChannelDialogDevices(true));
  var currentUser = yield select(currentUserSelector);
  const selectedLocations = yield select(selectedLocationsSelector);

  const deviceQuery = buildSelectedDevicesODataQuery(selectedLocations);
  const result = yield Get(currentUser, deviceQuery);
  let devices = result.map(d => d.device);

  yield put(setVisualizationDevices(devices));
  const selectedDevices = yield select(selectedDevicesSelector);
  devices = removeSelectedEntriesFromOriginalList(devices, selectedDevices);

  console.dev(
    `Successfully fetched ${devices.length} devices and selected ${selectedDevices.length}.`,
    devices,
    selectedDevices
  );

  yield put(setDisplayedDevices(devices));
  yield put(isFetchingChannelDialogDevices(false));
}

function buildSelectedDevicesODataQuery(selectedLocations) {
  let baseQuery = `deviceplacement?$expand=location, device `;
  const currentDateTime = new Date().toISOString();

  baseQuery += `& $filter = (startDateTime lt ${currentDateTime} and (endDateTime gt ${currentDateTime} or endDateTime eq null))`;

  for (let index = 0; index < selectedLocations.length; index++) {
    if (index == 0) {
      baseQuery += ` and (location/sensightId eq ${
        selectedLocations[index].sensightId
      }${selectedLocations.length === 1 ? `)` : ``}`;
    } else {
      baseQuery += ` or location/sensightId eq ${
        selectedLocations[index].sensightId
      }${index + 1 === selectedLocations.length ? `)` : ``}`;
    }
  }

  return baseQuery;
}
