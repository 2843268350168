import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_EXPORTCHARTS_LIST = 'FETCH_EXPORTCHARTS_LIST';
export const fetchExportChartsList = makeActionCreator(FETCH_EXPORTCHARTS_LIST);

export const SET_EXPORTCHARTS_LIST = 'SET_EXPORTCHARTS_LIST';
export const setExportChartsList = makeActionCreator(
  SET_EXPORTCHARTS_LIST,
  'exportChartsList'
);

export const CREATE_EXPORTCHART_SUCCEEDED = 'CREATE_EXPORTCHART_SUCCEEDED';
export const createExportChartSucceeded = makeActionCreator(
  CREATE_EXPORTCHART_SUCCEEDED,
  'exportChart'
);

export const EDIT_EXPORTCHART_SUCCEEDED = 'EDIT_EXPORTCHART_SUCCEEDED';
export const editExportChartSucceeded = makeActionCreator(
  EDIT_EXPORTCHART_SUCCEEDED,
  'exportChart'
);

export const DELETE_EXPORTCHART = 'DELETE_EXPORTCHART';
export const deleteExportChart = makeActionCreator(
  DELETE_EXPORTCHART,
  'exportChart'
);

export const DELETE_EXPORTCHART_SUCCEEDED = 'DELETE_EXPORTCHART_SUCCEEDED';
export const deleteExportChartSucceeded = makeActionCreator(
  DELETE_EXPORTCHART_SUCCEEDED,
  'exportChart'
);
