import { connect } from 'react-redux';
import LocationsDisplay from './LocationsDisplay.jsx';
import { fetchLocationsList } from './redux/locationsListActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';

const mapStateToProps = state => {
  return {
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLocationsList() {
    dispatch(fetchLocationsList());
  }
});

export const LocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsDisplay);
