import { connect } from 'react-redux';
import TenantsDisplay from './TenantsDisplay.jsx';
import { fetchTenants } from '../redux/tenantsActions';
import { tenantsSelector } from '../redux/tenantsSelectors';

const mapStateToProps = state => {
  return {
    tenants: tenantsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTenants() {
    dispatch(fetchTenants());
  }
});

export const TenantsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantsDisplay);
