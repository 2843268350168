import { connect } from 'react-redux';
import MyDashboardDisplay from './MyDashboardDisplay.jsx';
import { fetchDashboards } from './redux/myDashboardActions';
import { fetchDashboard } from './dashboard/redux/dashboardActions';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import {
  dashboardsSelector,
  fetchedDashboardsSelector
} from './redux/myDashboardSelectors';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';

const mapStateToProps = (state, ownProps) => {
  return {
    currentDashboardId: ownProps.match.params.id ?? '',
    allDashboards: dashboardsSelector(state),
    editMode: ownProps.editMode,
    currentUser: currentUserSelector(state),
    fetchedDashboards: fetchedDashboardsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  fetchDashboards() {
    dispatch(fetchDashboards());
  },
  fetchDashboard(id) {
    dispatch(fetchDashboard(id));
  }
});

export const MyDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDashboardDisplay);
