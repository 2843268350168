import { createSelector } from 'reselect';

export const dashboardsSelector = createSelector(
  state => state.get('dashboards'),
  dashboards => dashboards.toJS()
);

export const managementDashboardsSelector = createSelector(
  state => state.get('managementDashboards'),
  managementDashboards => managementDashboards.toJS()
);

export const fetchedDashboardsSelector = createSelector(
  state => state.get('fetchedDashboards'),
  fetchedDashboards => fetchedDashboards
);
