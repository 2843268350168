import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { getAllLocations } from 'apis/MeasurementServiceApi';
import {
  FETCH_LOCATIONS_REQUESTED,
  fetchDevicesRequested,
  isFetchingChannelDialogLocations
} from './selectChannelDialogActions';

import {
  ADD_SELECTED_LOCATION,
  DELETE_SELECTED_LOCATION,
  FILTER_DISPLAYED_LOCATIONS,
  deleteVisualizationLocation,
  deleteDisplayedLocation,
  addVisualizationLocation,
  addDisplayedLocation,
  setVisualizationLocations,
  setDisplayedLocations
} from './locationActions';

import {
  locationsSelector,
  selectedLocationsSelector
} from './selectChannelDialogSelectors';
import { fromJS } from 'immutable';
import { removeSelectedEntriesFromOriginalList } from 'utils/helperMethods';

export function* fetchLocationsSaga() {
  yield takeEvery(FETCH_LOCATIONS_REQUESTED, fetchLocations);
}

export function* addSelectedLocationSaga() {
  yield takeEvery(ADD_SELECTED_LOCATION, addSelectedLocation);
}

export function* deleteSelectedLocationSaga() {
  yield takeEvery(DELETE_SELECTED_LOCATION, addDeletedLocationBackToList);
}

export function* filterDisplayedLocationsSaga() {
  yield takeEvery(FILTER_DISPLAYED_LOCATIONS, filterDisplayedLocations);
}

function* filterDisplayedLocations(action) {
  const allLocations = yield select(locationsSelector);
  const result = allLocations.filter(c =>
    c.name.toLowerCase().includes(action.query.toLowerCase())
  );
  yield put(setDisplayedLocations(result));
}

function* addSelectedLocation(action) {
  const location = action.location;
  yield put(deleteVisualizationLocation(location));
  yield put(deleteDisplayedLocation(location));
  console.dev(
    `Added selected location '${location.name}', start fetching devices from selected locations..`
  );

  yield put(fetchDevicesRequested());
}

function* addDeletedLocationBackToList(action) {
  const location = fromJS(action.location);
  yield put(addVisualizationLocation(location));
  yield put(addDisplayedLocation(location));

  yield put(fetchDevicesRequested());
}

function* fetchLocations() {
  yield put(isFetchingChannelDialogLocations(true));
  var currentUser = yield select(currentUserSelector);
  let locations = (yield getAllLocations(currentUser)).data;
  yield put(setVisualizationLocations(locations));
  const selectedLocations = yield select(selectedLocationsSelector);
  locations = removeSelectedEntriesFromOriginalList(
    locations,
    selectedLocations
  );

  console.dev(
    `Successfully fetched ${locations.length} locations and selected ${selectedLocations.length}.`,
    locations,
    selectedLocations
  );

  yield put(setDisplayedLocations(locations));
  yield put(isFetchingChannelDialogLocations(false));
}
