import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  editOrganization,
  getContactsFromOrganization
} from 'apis/CustomerContactApi';
import {
  saveLocationsOfOrganization,
  getLocationOrganizationByOrganizationSensightId
} from 'apis/FieldServiceApi';

import {
  EDIT_ORGANIZATION,
  FETCH_LOCATIONS_FROM_ORGANIZATION,
  FETCH_CONTACTS_FROM_ORGANIZATION_LIST,
  setContactsFromOrganizationList,
  setLocationsFromOrganization
} from './editOrganizationActions';
import { editOrganizationSucceeded } from '../../redux/organizationsListActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* editOrganizationSaga() {
  yield takeEvery(EDIT_ORGANIZATION, execute);
  yield takeEvery(FETCH_CONTACTS_FROM_ORGANIZATION_LIST, fetchContacts);
  yield takeEvery(FETCH_LOCATIONS_FROM_ORGANIZATION, fetchSelectedLocations);
}

function* execute(action) {
  const organization = action.organizationInformation;
  const user = yield select(currentUserSelector);

  try {
    yield editOrganization(user, organization);

    saveLocationsOfOrganization(
      user,
      organization.sensightId,
      organization.locationIds
    );

    yield put(createSnackbar(t('content.editorganization.snackbar.text')));
    yield put(editOrganizationSucceeded(organization));
  } catch (exception) {
    console.error(exception);
  }
}

function* fetchContacts(action) {
  const organizationId = action.organizationId;
  const user = yield select(currentUserSelector);
  var contactsList = yield getContactsFromOrganization(user, organizationId);
  yield put(setContactsFromOrganizationList(contactsList.data));
}

function* fetchSelectedLocations(action) {
  const organizationSensightId = action.organizationSensightId;
  const user = yield select(currentUserSelector);
  var locationIds = yield getLocationOrganizationByOrganizationSensightId(
    user,
    organizationSensightId
  );
  yield put(setLocationsFromOrganization(locationIds.data));
}
