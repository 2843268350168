import { connect } from 'react-redux';
import ViewDashboardDisplay from './ViewDashboardDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import { clearDashboardMeasurements } from './redux/dashboardMeasurementActions.js';

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: currentUserSelector(state),
    dashboard: ownProps.dashboard,
    locations: ownProps.locations,
    setIsEditing: ownProps.setIsEditing
  };
};

const mapDispatchToProps = dispatch => ({
  clearDashboardMeasurements() {
    dispatch(clearDashboardMeasurements());
  }
});

export const ViewDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDashboardDisplay);
