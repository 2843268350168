import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_VISUALIZATION_LOCATIONS = 'SET_VISUALIZATION_LOCATIONS';
export const setVisualizationLocations = makeActionCreator(
  SET_VISUALIZATION_LOCATIONS,
  'locations'
);

export const DELETE_VISUALIZATION_LOCATION = 'DELETE_VISUALIZATION_LOCATION';
export const deleteVisualizationLocation = makeActionCreator(
  DELETE_VISUALIZATION_LOCATION,
  'location'
);

export const ADD_VISUALIZATION_LOCATION = 'ADD_VISUALIZATION_LOCATION';
export const addVisualizationLocation = makeActionCreator(
  ADD_VISUALIZATION_LOCATION,
  'location'
);

export const SET_DISPLAYED_LOCATIONS = 'SET_DISPLAYED_LOCATIONS';
export const setDisplayedLocations = makeActionCreator(
  SET_DISPLAYED_LOCATIONS,
  'locations'
);

export const DELETE_DISPLAYED_LOCATION = 'DELETE_DISPLAYED_LOCATION';
export const deleteDisplayedLocation = makeActionCreator(
  DELETE_DISPLAYED_LOCATION,
  'location'
);

export const ADD_DISPLAYED_LOCATION = 'ADD_DISPLAYED_LOCATION';
export const addDisplayedLocation = makeActionCreator(
  ADD_DISPLAYED_LOCATION,
  'location'
);

export const FILTER_DISPLAYED_LOCATIONS = 'FILTER_DISPLAYED_LOCATIONS';
export const filterDisplayedLocations = makeActionCreator(
  FILTER_DISPLAYED_LOCATIONS,
  'query'
);

export const ADD_SELECTED_LOCATION = 'ADD_SELECTED_LOCATION';
export const addSelectedLocation = makeActionCreator(
  ADD_SELECTED_LOCATION,
  'location'
);

export const DELETE_SELECTED_LOCATION = 'DELETE_SELECTED_LOCATION';
export const deleteSelectedLocation = makeActionCreator(
  DELETE_SELECTED_LOCATION,
  'location'
);

export const CLEAR_SELECTED_LOCATIONS = 'CLEAR_SELECTED_LOCATIONS';
export const clearSelectedLocations = makeActionCreator(
  CLEAR_SELECTED_LOCATIONS
);
