import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { editExportChart } from 'apis/ReportingServiceApi';
import { EDIT_EXPORTCHART } from './editExportChartActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { editExportChartSucceeded } from '../../redux/exportChartsListActions';
import { cloneObject, getEmptyGuid } from 'utils/helperMethods';
import { Get } from 'apis/MeasurementODataApi';
import { getODataQuery } from './exportChartSagaHelpers';

export function* editExportChartSaga() {
  yield takeEvery(EDIT_EXPORTCHART, execute);
}

function* execute(action) {
  const exportChart = action.exportChart;
  const user = yield select(currentUserSelector);

  try {
    exportChart.exportChartContacts = exportChart.exportChartContacts.map(
      contact => {
        return {
          exportChartId: exportChart.id,
          contactSensightId: contact
        };
      }
    );

    let channels = cloneObject(exportChart.exportChartChannels);
    yield addLocationSensightId(user, channels);

    exportChart.exportChartChannels = channels.map((channel, index) => {
      return {
        exportChartId: exportChart.id,
        channelSensightId: channel.sensightId,
        order: index,
        locationSensightId: channel.locationSensightId ?? getEmptyGuid(),
        aggregationType: channel.aggregationType
      };
    });

    const editedExportChart = (yield editExportChart(user, exportChart)).data
      .result;
    yield put(createSnackbar(t('content.editexportchart.snackbar.text')));
    yield put(editExportChartSucceeded(editedExportChart));
  } catch (exception) {
    console.error(exception);
  }
}

const addLocationSensightId = async function (user, channels) {
  for (var channel of channels) {
    const oDataResponse = await Get(user, getODataQuery(channel.sensightId));
    channel.locationSensightId =
      oDataResponse[0].device.devicePlacements[0].location.sensightId;
  }
};
