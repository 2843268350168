import React, { useState, useEffect, useRef } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ImageIcon from '@mui/icons-material/Image';
import CodeIcon from '@mui/icons-material/Code';
import LinkIcon from '@mui/icons-material/Link';
import ReactMarkdown from 'react-markdown';
import {
    Grid,
    InputAdornment,
    List,
    Link,
    MenuItem,
    ListItem,
    ListItemText,
    TextField,
    InputLabel,
    Select
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField as FormikTextField } from 'formik-mui';
import MuiTextField from '@mui/material/TextField';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import ThermometerIcon from '../img/thermometerIcon.svg';
import ScienceIcon from '../img/scienceIcon.svg';
import {
    readVisualisationLink,
    generateVisualisationLink,
    isEmptyObject
} from 'utils/helperMethods';
import Alert from '@mui/material/Alert';
import { useTranslation, Trans } from 'react-i18next';
import {
    showDialog,
    retrieveSelectedChannels
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions';
import { SelectChannelDialogContainer } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/selectChannelDialogContainer';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
    selectedChannelsSelector,
    dialogStateSelector,
    selectedLocationsSelector
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors';
import ImdCard from 'components/imd-components/ImdCard';
import ImdButton from 'components/imd-components/ImdButton';
import ImdTooltip from 'components/imd-components/ImdTooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { locationsListSelector } from 'components/content/locations/redux/locationsListSelectors';
import { Form, Field } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { createSnackbar } from 'redux/ui/uiActions';
import { useUpdateEffect } from 'react-use';

export function VisualizationTypesDialog(props) {
    const classes = useStyles();
    const [title, setTitle] = useState('');
    const [selectedVisualizationType, setSelectedVisualizationType] = useState(0);
    const [parseFieldValue, setParseFieldValue] = useState(null);
    const [parseErrors, setParseErrors] = useState(false);
    const [markdownValue, setMarkdownValue] = useState('');
    const [imageValue, setImageValue] = useState({});
    const [dataScienceValue, setDataScienceValue] = useState(null);
    const [logItemLocation, setLogItemLocation] = useState(null);
    const [gridItemKey, setGridItemKey] = useState(1);
    const [visualisationLink, setVisualisationLink] = useState('');
    const dispatch = useDispatch();
    const user = useSelector(state => currentUserSelector(state));
    const selectedChannels = useSelector(state =>
        selectedChannelsSelector(state)
    );
    const selectedLocations = useSelector(state =>
        selectedLocationsSelector(state)
    );
    const [initialValues, setInitialValues] = useState({});
    const selectedChannelDialogState = useSelector(state =>
        dialogStateSelector(state)
    );
    const locations = useSelector(state => locationsListSelector(state));
    const { t } = useTranslation();

    useUpdateEffect( async () => {
        if (props.open) {
            props.setSaveButtonDisabled(false);
            setTitle(props.currentEditingItem.title);
            setSelectedVisualizationType(props.currentEditingItem.visualizationType);
            const visualizationType = props.currentEditingItem.visualizationType;

            if (visualizationType === 1 || visualizationType === 2) {
                setVisualisationLink(await generateVisualisationLink(
                    user,
                    {
                        selectionDataParameter: props.currentEditingItem.visualizationSettings.selectionData,
                        selectedChannels: props.currentEditingItem.visualizationSettings.selectedChannels,
                        displayOption: props.currentEditingItem.visualizationSettings.displayOptions
                    }
                ));
                setParseFieldValue(props.currentEditingItem.visualizationSettings);
            } else if (visualizationType === 3) {
                setMarkdownValue(props.currentEditingItem.visualizationSettings);
            } else if (visualizationType === 4) {
                initializeImageValues();
            } else if (visualizationType === 5) {
                setDataScienceValue(props.currentEditingItem.visualizationSettings);
            } else if (visualizationType === 6) {
                setLogItemLocation(props.currentEditingItem.visualizationSettings);
            }
        }

        if (!props.open) {
            resetState();
            setDataScienceValue(null);
        }
    }, [props.open]);

    useEffect(() => {
        if (!selectedChannelDialogState.open) {
            if (selectedVisualizationType === 5 && dataScienceValue) {
                var filledparameters = [];
                dataScienceValue.parameters.map((p, index) => {
                    p.value = null;
                    const channels = selectedChannels;
                    if (channels && channels.length > index) {
                        filledparameters.push({
                            key: p.key,
                            displayName: p.displayName,
                            value: channels[index].id,
                            displayValue: channels[index].displayLabel,
                            color: channels[index].color,
                            aggregationType: channels[index].aggregationType
                        });
                    } else {
                        filledparameters.push({
                            key: p.key,
                            displayName: p.displayName,
                            value: null
                        });
                    }
                });
                setDataScienceValue({
                    ...dataScienceValue,
                    parameters: [...filledparameters],
                    locations: [...selectedLocations]
                });
                setGridItemKey(gridItemKey + 1);
            }
        }
    }, [selectedChannelDialogState]);

    useEffect(() => {
        const errors = handleValidate(imageValue);
        props.setSaveButtonDisabled(!isEmptyObject(errors));
    }, [imageValue]);

    const resetState = (
        alsoResetVisualizationType = true,
        alsoResetTitle = true
    ) => {
        setParseFieldValue(null);
        setParseErrors(false);
        if (alsoResetVisualizationType) {
            setSelectedVisualizationType(0);
        }
        if (alsoResetTitle) {
            setTitle('');
        }
        setInitialValues({});
        setImageValue({});
        setVisualisationLink('');
    };

    const parseMarkdown = event => {
        setMarkdownValue(event.target.value);
    };

    const handleSave = () => {
        if (selectedVisualizationType === 4 && formRef.current) {
            formRef.current.handleSubmit();
            return;
        }

        if (selectedVisualizationType === 5) {
            updateReportsettings(props, selectedChannels);
        }

        const returnObject = {
            title: title,
            selectedVisualizationType: selectedVisualizationType,
            selectedVisualizationSettings: getVisualizationSetting()
        };

        if (!returnObject.selectedVisualizationSettings) {
            dispatch(
                createSnackbar(
                    t('content.dashboard.visualizationtypes.error.invalidSettings'),
                    'warning'
                )
            );
            return;
        }

        console.dev(`Calling handlesave with:`, returnObject);
        resetState();
        props.setEditItem(false);
        props.handleSave(returnObject);
    };

    const handleClose = () => {
        resetState();
        props.setEditItem(false);
    };

    const getVisualizationSetting = () => {
        switch (selectedVisualizationType) {
            case 0:
                return {};
            case 1:
                return parseFieldValue;
            case 2:
                return parseFieldValue;
            case 3:
                return markdownValue;
            case 4:
                return imageValue;
            case 5:
                return dataScienceValue;
            case 6:
                return logItemLocation;
            default:
                return {};
        }
    };

    const parseValue = async event => {
        const input = event.target.value;

        if (!input)
        {
            resetState(false, false);
        }
        else
        {
            setVisualisationLink(event.target.value);
            const splittedVisualisationLink = input.split('/visualize/');
            // IndexOf is used to support Internet Explorer
            if (splittedVisualisationLink.length == 2 && splittedVisualisationLink[1].indexOf("?tenant") == -1)
            {
                const importObject = await readVisualisationLink(user, splittedVisualisationLink[1]);

                console.dev(
                    `Splitted parsed values from link:`,
                    splittedVisualisationLink,
                    importObject
                );
                setParseFieldValue(importObject);
                setParseErrors(false);
                props.setSaveButtonDisabled(false);
            }
            else
            {
                props.setSaveButtonDisabled(true);
                setParseErrors(true);
            }
        }
    };

    const renderChannels = channels => {
        return channels.map(channel => {
            return (
                <p key={channel.id}>
                    * {channel.displayLabel} | {channel.color}
                </p>
            );
        });
    };

    const renderChartSettings = () => {
        return (
            <>
                <TextField
                    name="link"
                    type="text"
                    label={t(
                        'content.dashboard.dialogs.visualizationtypes.visualisationlink'
                    )}
                    fullWidth
                    value={visualisationLink}
                    variant="outlined"
                    onChange={value => parseValue(value)}
                    className={classes.parseTextField}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ImdTooltip title={t('general.edit')} placement="top-start">
                                    <div>
                                        <Link target="_blank" href={visualisationLink}>
                                            <LinkIcon />
                                        </Link>
                                    </div>
                                </ImdTooltip>
                            </InputAdornment>
                        )
                    }}
                />
                {parseErrors ? (
                    <Alert className={classes.error} severity="error">
                        {t('content.dashboard.dialogs.visualizationtypes.errorinvalidlink')}
                    </Alert>
                ) : parseFieldValue !== undefined && parseFieldValue !== null ? (
                    <div className={classes.parseResult}>
                        <p>
                            <b>
                                {`${t(
                                    'content.dashboard.dialogs.visualizationtypes.periodfrom'
                                )} `}
                            </b>
                            {`${parseFieldValue.selectionData.visualizeFromTime}`}
                        </p>
                        <p>
                            <b>
                                {`${t(
                                    'content.dashboard.dialogs.visualizationtypes.periodto'
                                )} `}
                            </b>
                            {`${parseFieldValue.selectionData.visualizeUntilTime}`}
                        </p>
                        <p>
                            <b>
                                {`${t('content.dashboard.dialogs.visualizationtypes.period')} `}
                            </b>
                            {parseFieldValue.selectionData.visualizePeriod}
                        </p>
                        <p>
                            <b>
                                {t('content.dashboard.dialogs.visualizationtypes.channels')}
                            </b>
                        </p>
                        {renderChannels(parseFieldValue.selectedChannels)}
                    </div>
                ) : null}
            </>
        );
    };

    const renderMarkdownSettings = () => {
        return (
            <>
                <div className={classes.markDownDescription}>
                    <Trans i18nKey="content.dashboard.visualizationtypes.markdown.description">
                        Markdown is a lightweight markup language....
                        <a
                            href="https://www.markdownguide.org/getting-started"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            this link
                        </a>{' '}
                        For more information see...
                    </Trans>
                </div>
                <TextField
                    name="link"
                    type="text"
                    label={t(
                        'content.dashboard.dialogs.visualizationtypes.markdowninsert'
                    )}
                    fullWidth
                    multiline
                    rows={12}
                    variant="outlined"
                    value={markdownValue}
                    onChange={value => parseMarkdown(value)}
                    className={classes.parseTextField}
                />

                <div className={classes.htmlSpace}>
                    <ReactMarkdown>{markdownValue}</ReactMarkdown>
                </div>
            </>
        );
    };

    const formRef = useRef();
    const submitForm = values => {
        let newSettings = {
            link: values.link,
            externalLink: values.externalLink
        };

        setImageValue(newSettings);
        const returnObject = {
            title: title,
            selectedVisualizationType: selectedVisualizationType,
            selectedVisualizationSettings: newSettings
        };
        resetState();
        props.setEditItem(false);
        props.handleSave(returnObject);
    };
    const renderImageSettings = () => {
        return (
            <>
                <FormikWithRef
                    ref={formRef}
                    initialValues={initialValues}
                    initialValidate={true}
                    onSubmit={submitForm}
                    validateOnChange={false}
                    validate={values => {
                        const errors = handleValidate(values);
                        if (!errors.link && !errors.externalLink) {
                            setImageValue({
                                link: values.link,
                                externalLink: values.externalLink
                            });
                        }

                        return errors;
                    }}
                    validateOnBlur={true}
                >
                    <Form>
                        <Field
                            name="link"
                            type="text"
                            label={t('content.dashboard.visualizationtypes.image.url')}
                            fullWidth
                            className={classes.parseTextField}
                            component={FormikTextField}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkIcon />
                                    </InputAdornment>
                                )
                            }}
                            variant="standard"
                        />
                        <Field
                            name="externalLink"
                            type="text"
                            label={t(
                                'content.dashboard.visualizationtypes.image.externalLink'
                            )}
                            fullWidth
                            className={classes.parseTextField}
                            component={FormikTextField}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkIcon />
                                    </InputAdornment>
                                )
                            }}
                            variant="standard"
                        />
                    </Form>
                </FormikWithRef>
                <div className={classes.htmlSpace}>
                    <a href={imageValue.externalLink} target="_blank noopener noreferrer">
                        <img className={classes.imgContainer} src={imageValue.link} />
                    </a>
                </div>
            </>
        );
    };
    const handleValidate = values => {
        const errors = {};
        if (!values.link) {
            errors.link = t('general.validation.required');
        } else if (!isValidImageUrl(values.link)) {
            errors.link = t('content.dashboard.visualizationtypes.image.noValidUrl');
        }

        if (values.externalLink && !isValidExternalUrl(values.externalLink)) {
            errors.externalLink = t(
                'content.dashboard.visualizationtypes.image.noValidExternalLink'
            );
        }
        return errors;
    };
    const dataScienceMenuItems = () => {
        if (props.dataScienceOptions.reports === undefined) {
            return (
                <MenuItem key="0" value="0">
                    {t(
                        'content.dashboard.visualizationtypes.datascience.noReportsAvailable'
                    )}
                </MenuItem>
            );
        }
        return props.dataScienceOptions.reports.map((t, index) => {
            var displayValue = !t.displayName ? t.id : t.displayName;
            return (
                <MenuItem key={index} value={t.id}>
                    {displayValue}
                </MenuItem>
            );
        });
    };

    const renderDataScienceSettings = () => {
        let report = {};
        if (props.dataScienceOptions.reports) {
            report =
                props.dataScienceOptions.reports.find(
                    r => dataScienceValue && r.id == dataScienceValue.id
                ) || {};
        }
        return (
            <div>
                <TextField
                    name="selectedDataScience"
                    type="select"
                    label={'Soort'}
                    select
                    fullWidth
                    className={classes.textField}
                    value={report.id}
                    onChange={event => {
                        setDataScienceValue(
                            props.dataScienceOptions.reports.find(
                                r => r.id == event.target.value
                            )
                        );
                    }}
                    variant="standard"
                >
                    {dataScienceMenuItems()}
                </TextField>
                <InputLabel shrink>
                    {t('content.visualize.toolbar.time.select.text')}
                </InputLabel>
                <InputLabel>{report.description}</InputLabel>
                {renderReportParameters(report)}
                <InputLabel>
                    {t('content.visualize.toolbar.time.select.text')}
                </InputLabel>
                <Select
                    name="selectedVisualizePeriod"
                    fullWidth
                    value={dataScienceValue?.selectionData?.visualizePeriod}
                    onChange={event => {
                        setDataScienceValue({
                            ...dataScienceValue,
                            selectionData: { visualizePeriod: event.target.value }
                        });
                    }}
                    classes={{ root: classes.selectBox }}
                >
                    <MenuItem value={'fourHours'}>
                        {t('content.visualize.toolbar.time.select.4h')}
                    </MenuItem>
                    <MenuItem value={'eightHours'}>
                        {t('content.visualize.toolbar.time.select.8h')}
                    </MenuItem>
                    <MenuItem value={'twelveHours'}>
                        {t('content.visualize.toolbar.time.select.12h')}
                    </MenuItem>
                    <MenuItem value={'twentyFourHours'}>
                        {t('content.visualize.toolbar.time.select.24h')}
                    </MenuItem>
                    <MenuItem value={'fourtyEightHours'}>
                        {t('content.visualize.toolbar.time.select.48h')}
                    </MenuItem>
                    <MenuItem value={'oneWeek'}>
                        {t('content.visualize.toolbar.time.select.1wk')}
                    </MenuItem>
                    <MenuItem value={'oneMonth'}>
                        {t('content.visualize.toolbar.time.select.1mo')}
                    </MenuItem>
                    <MenuItem value={'threeMonths'}>
                        {t('content.visualize.toolbar.time.select.3mo')}
                    </MenuItem>
                    <MenuItem value={'oneYear'}>
                        {t('content.visualize.toolbar.time.select.1yr')}
                    </MenuItem>
                    <MenuItem value={'currentYear'}>
                        {t('content.visualize.toolbar.time.select.currentYear')}
                    </MenuItem>
                </Select>
            </div>
        );
    };

    const renderLogBookItemSettings = () => {
        let location;
        if (logItemLocation) {
            location = locations.find(location => location.id == logItemLocation.id);
        }

        if (!location) {
            <Autocomplete
                filterSelectedOptions
                options={locations}
                getOptionLabel={option => option.name}
                value={location}
                onChange={(event, newValue) => {
                    setLogItemLocation(newValue);
                }}
                renderInput={params => (
                    <MuiTextField
                        {...params}
                        name="locations"
                        type="text"
                        label={t('content.locationdashboard.form.locations')}
                        fullWidth
                        className={classes.textField}
                        variant="standard"
                    />
                )}
            />
        }
    };

    const renderReportParameters = report => {
        if (!(report && report.parameters && report.parameters.length > 0)) {
            return (
                <p>
                    <div>
                        {t(
                            'content.dashboard.visualizationtypes.datascience.noInputParameters'
                        )}
                    </div>
                </p>
            );
        }
        if (!dataScienceValue.parameters) {
            dataScienceValue.parameters = report.parameters;
        }

        let params = [];

        report.parameters.forEach(p => {
            const param = dataScienceValue.parameters.find(
                param => param.key === p.key
            );

            if (param) {
                params.push(param);
            } else {
                params.push(p);
            }
        });
        dataScienceValue.parameters = params;

        return (
            <div className={classes.channels}>
                <ImdButton
                    variant="contained"
                    color="primary"
                    onClick={showChannelSelection}
                >
                    {t(
                        'content.dashboard.visualizationtypes.datascience.selectchannelsforparams'
                    )}
                </ImdButton>
                {dataScienceValue.parameters.map(p => {
                    return (
                        <>
                            <div>
                                <strong>{p.displayName + ': '}</strong>
                                {p.displayValue || ''}
                            </div>
                        </>
                    );
                })}
            </div>
        );
    };

    const showChannelSelection = () => {
        dispatch(showDialog());
        if (
            props.currentEditingItem.visualizationSettings &&
            props.currentEditingItem.visualizationSettings.parameters
        ) {
            const parameters =
                props.currentEditingItem.visualizationSettings.parameters;
            dispatch(retrieveSelectedChannels(parameters));
        }
    };

    const renderVisualizationInputFields = () => {
        switch (selectedVisualizationType) {
            case 0:
                return null;
            case 1:
                return renderChartSettings();
            case 2:
                return renderChartSettings();
            case 3:
                return renderMarkdownSettings();
            case 4:
                return renderImageSettings();
            case 5:
                return renderDataScienceSettings();
            case 6:
                return renderLogBookItemSettings();
            default:
                return null;
        }
    };

    const updateReportsettings = (props, selectedChannels) => {
        var filledparameters = [];

        if (!props.currentEditingItem.visualizationSettings) {
            props.currentEditingItem.visualizationSettings =
                getVisualizationSetting();
            props.currentEditingItem.visualizationType = selectedVisualizationType;
        }
        if (!props.currentEditingItem.visualizationSettings.parameters) {
            props.currentEditingItem.visualizationSettings.parameters =
                filledparameters;
        }

        if (selectedChannels && selectedChannels.length > 0) {
            props.currentEditingItem.visualizationSettings.parameters.map(
                (p, index) => {
                    const channels = selectedChannels;
                    if (channels && channels.length > index) {
                        filledparameters.push({
                            key: p.key,
                            displayName: p.displayName,
                            value: channels[index].id,
                            displayValue: channels[index].displayLabel,
                            color: channels[index].color,
                            aggregationType: channels[index].aggregationType
                        });
                    }
                }
            );

            props.currentEditingItem.visualizationSettings.parameters =
                filledparameters;
        }

        if (selectedLocations && selectedLocations.length > 0) {
            props.currentEditingItem.visualizationSettings.locations = [
                ...selectedLocations
            ];
        }

        setDataScienceValue(props.currentEditingItem.visualizationSettings);
    };

    return (
        <>
            {props.open && (
                <ImdDialogContainer
                    open={props.open}
                    maxWidth="md"
                    resizeToContent={false}
                    closeEdit={() => props.setEditItem(false)}
                    handleSaveAction={handleSave}
                    handleCloseAction={handleClose}
                    dialogTitle={t('content.dashboard.visualizationtypes.selectitem')}
                    content={
                        <>
                            <SelectChannelDialogContainer
                                clearSelectChannelDialogStateImmediately
                                chartType={false}
                            />
                            <Grid container spacing={3} className={classes.container}>
                                <Grid item lg={5} className={classes.visualizationTypesList}>
                                    <ImdCard
                                        title={t(
                                            'content.dashboard.visualizationtypes.visualisationtypestitle'
                                        )}
                                        content={
                                            <div>
                                                <List className={classes.scrollableContent}>
                                                    <ListItem
                                                        className={classes.button}
                                                        selected={selectedVisualizationType === 1}
                                                        onClick={() => setSelectedVisualizationType(1)}
                                                    >
                                                        <ListItemText
                                                            primary={t(
                                                                'content.dashboard.visualizationtypes.charttitle'
                                                            )}
                                                        />
                                                        <BarChartIcon
                                                            className={classes.visualizationIcon}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={classes.button}
                                                        selected={selectedVisualizationType === 2}
                                                        onClick={() => setSelectedVisualizationType(2)}
                                                    >
                                                        <ListItemText
                                                            primary={t(
                                                                'content.dashboard.visualizationtypes.kpititle'
                                                            )}
                                                        />
                                                        <ThermometerIcon
                                                            width={40}
                                                            height={40}
                                                            viewBox="0 0 24 24"
                                                            className={classes.customIcon}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={classes.button}
                                                        selected={selectedVisualizationType === 3}
                                                        onClick={() => setSelectedVisualizationType(3)}
                                                    >
                                                        <ListItemText
                                                            primary={t(
                                                                'content.dashboard.visualizationtypes.markdowntitle'
                                                            )}
                                                        />
                                                        <CodeIcon className={classes.visualizationIcon} />
                                                    </ListItem>
                                                    <ListItem
                                                        className={classes.button}
                                                        selected={selectedVisualizationType === 4}
                                                        onClick={() => setSelectedVisualizationType(4)}
                                                    >
                                                        <ListItemText
                                                            primary={t(
                                                                'content.dashboard.visualizationtypes.imagetitle'
                                                            )}
                                                        />
                                                        <ImageIcon className={classes.visualizationIcon} />
                                                    </ListItem>
                                                    <ListItem
                                                        className={classes.button}
                                                        selected={selectedVisualizationType === 5}
                                                        onClick={() => setSelectedVisualizationType(5)}
                                                    >
                                                        <ListItemText
                                                            primary={t(
                                                                'content.dashboard.visualizationtypes.datasciencetitle'
                                                            )}
                                                        />
                                                        <ScienceIcon
                                                            width={40}
                                                            height={40}
                                                            viewBox="0 0 24 24"
                                                            className={classes.customIcon}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={classes.button}
                                                        selected={selectedVisualizationType === 6}
                                                        onClick={() => setSelectedVisualizationType(6)}
                                                    >
                                                        <ListItemText
                                                            primary={t(
                                                                'content.dashboard.visualizationtypes.logbooktitle'
                                                            )}
                                                        />
                                                        <ListAltIcon
                                                            className={classes.visualizationIcon}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </div>
                                        }
                                        variant="secondary"
                                        cardHeight="100%"
                                    />
                                </Grid>
                                <Grid key={gridItemKey} item lg={7}>
                                    <ImdCard
                                        title={t(
                                            'content.dashboard.visualizationtypes.visualisationinformationtitle'
                                        )}
                                        content={
                                            <div>
                                                <TextField
                                                    name="title"
                                                    type="text"
                                                    label={t(
                                                        'content.dashboard.visualizationtypes.title'
                                                    )}
                                                    fullWidth
                                                    value={title}
                                                    onChange={event => setTitle(event.target.value)}
                                                    className={classes.textField}
                                                    variant="standard"
                                                />
                                                {renderVisualizationInputFields()}
                                            </div>
                                        }
                                        variant="secondary"
                                        cardHeight="100%"
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            )}
        </>
    );

    function initializeImageValues() {
        //for backwards compatibility with current dashboard images
        if (typeof props.currentEditingItem.visualizationSettings === 'string') {
            setInitialValues({
                link: props.currentEditingItem.visualizationSettings
            });
            setImageValue({
                link: props.currentEditingItem.visualizationSettings
            });
        } else {
            setInitialValues({
                link: props.currentEditingItem.visualizationSettings.link,
                externalLink:
                    props.currentEditingItem.visualizationSettings.externalLink
            });
            setImageValue({
                link: props.currentEditingItem.visualizationSettings.link,
                externalLink:
                    props.currentEditingItem.visualizationSettings.externalLink
            });
        }
    }
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%'
    },
    visualizationIcon: {
        height: '40px',
        width: '40px',
        color: '#aaa'
    },
    customIcon: {
        fill: '#aaa'
    },
    scrollableContent: {
        overflow: 'auto',
        maxHeight: '90%'
    },
    visualizationTypesList: {
        height: '100%'
    },
    textField: {
        marginBottom: '20px'
    },
    markDownDescription: {
        marginBottom: '20px'
    },
    parseResult: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        padding: '8px',
        paddingLeft: '24px',
        marginTop: '16px',
        borderRadius: '8px',
        boxShadow: 'inset 0 0 4px #8A8D8D'
    },
    parseTextField: {
        color: 'green',
        borderColor: 'green',
        marginBottom: '12px'
    },
    error: {
        marginTop: '8px'
    },
    markdownSpace: {
        height: '100%',
        width: '100%',
        overflow: 'hidden'
    },
    htmlSpace: {
        height: '100%',
        width: '100%',
        overflow: 'hidden'
    },
    imgContainer: {
        maxWidth: '100%',
        maxHeight: 'auto'
    },
    button: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        cursor: 'pointer',
        color: theme.palette.secondary.contrastText
    },
    channels: {
        marginTop: '1rem',
        marginBottom: '1rem'
    }
}));

function isValidImageUrl(url) {
    return /^http[s]?:\/\/.*(\.png|\.gif|\.jpg|\.jpeg|\.bmp)$/i.test(url);
}

function isValidExternalUrl(url) {
    return /^http[s]?:\/\/.*$/i.test(url);
}
