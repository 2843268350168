import { connect } from 'react-redux';
import { DataScienceDisplay } from './DataScienceDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector';

const mapStateToProps = (state, { report, item, locations }) => {
  return {
    item,
    locations,
    report,
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = () => ({});

export const DataScienceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(DataScienceDisplay);
