import React from 'react';
import 'date-fns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';

export default function ImdTimePicker(props) {
  const handleOnClose = () => {
    props.saveAction(props.switchTime);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        ampm={false}
        views={['hours', 'minutes', 'seconds']}
        format="HH:mm:ss"
        id="time-picker-inline"
        value={props.switchTime}
        onClose={handleOnClose}
        onChange={value => props.saveAction(value)}
        renderInput={params => {
          return (
            <TextField
              {...params}
              name="devices"
              type="text"
              label={props.labelText}
              className={props.className}
              fullWidth
              variant="standard"
            />
          );
        }}
        slotProps={{
          inputAdornment: {
            'aria-label': 'change date'
          }
        }}
      />
    </LocalizationProvider>
  );
}

ImdTimePicker.propTypes = {
  saveAction: PropTypes.func.isRequired,
  switchTime: PropTypes.object.isRequired,
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};
