import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import ImdButton from '../ImdButton';
import ImdTooltip from '../ImdTooltip';

export default function ImdDialog({
  resizeToContent,
  open,
  handleCloseAction,
  content,
  extraAction,
  formRef,
  dialogTitle,
  saveButtonDisabled,
  saveButtonTooltip,
  maxWidth,
  handleSaveAction,
  saveButtonVisible,
  saveButtonTranslation,
  cancelButtonTranslation
}) {
  const { t } = useTranslation();
  const classes = useImdDialogStyles();

  const handleSave = () => {
    if (handleSaveAction) {
      handleSaveAction();
    }

    if (formRef === undefined) {
      return;
    } else if (formRef.current.current === undefined) {
      formRef.current.handleSubmit();
    } else {
      formRef.current.current.handleSubmit();
    }
  };

  return (
    <div>
      <Dialog
        classes={resizeToContent ? {} : { paper: classes.paper }}
        scroll={'paper'}
        maxWidth={maxWidth ? maxWidth : 'lg'}
        fullWidth={true}
        open={open}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.title} id="form-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent className={classes.content} id="form-dialog-content">
          {content}
        </DialogContent>
        <DialogActions>
          <ImdButton
            onClick={handleCloseAction}
            variant="contained"
            color="inherit"
          >
            {cancelButtonTranslation || t('general.cancel')}
          </ImdButton>
          {saveButtonVisible && (
            <ImdTooltip title={saveButtonTooltip} placement="top-start">
              <span className={classes.saveButton}>
                <ImdButton
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={saveButtonDisabled}
                >
                  {saveButtonTranslation || t('general.save')}
                </ImdButton>
              </span>
            </ImdTooltip>
          )}
          {extraAction && (
            <ImdButton
              onClick={extraAction.handle}
              variant="contained"
              color="primary"
            >
              {extraAction.translation || 'Missing translation'}
            </ImdButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useImdDialogStyles = makeStyles(theme => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    padding: '8px 12px !important'
  },
  saveButton: {
    marginLeft: '1rem'
  }
}));
