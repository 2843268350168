import { connect } from 'react-redux';
import DevicesTableDisplay from './DevicesTableDisplay.jsx';
import {
  setDevice,
  deleteDevice,
  setShouldDeleteFunctionalDevice,
  setDeviceOnlineSwitchValue,
  setDeviceVisibleSwitchValue
} from '../redux/devicesActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { shouldDeleteFunctionalDeviceValueSelector } from '../redux/devicesSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    devices: ownProps.devices,
    currentUser: currentUserSelector(state),
    shouldDeleteFunctionalDeviceValue:
      shouldDeleteFunctionalDeviceValueSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteDevice(row) {
    dispatch(deleteDevice(row));
  },
  setDevice(row) {
    dispatch(setDevice(row));
  },
  setShouldDeleteFunctionalDevice(value) {
    dispatch(setShouldDeleteFunctionalDevice(value));
  },
  setDeviceOnlineSwitchValue(switchValue) {
    dispatch(setDeviceOnlineSwitchValue(switchValue));
  },
  setDeviceVisibleSwitchValue(switchValue) {
    dispatch(setDeviceVisibleSwitchValue(switchValue));
  }
});

export const DevicesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesTableDisplay);
