import axios from 'axios';
import configFile from '../App_config.json';
import fileDownload from 'js-file-download';
import { getActiveTenant } from 'utils/tenantManager.js';

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

function reviver(key, value) {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    },
    transformResponse: [
      function (data) {
        return JSON.parse(data, reviver);
      }
    ]
  };
};

export const Get = async (user, query) => {
  const config = createHeaderConfig(user);

  return axios
    .get(configFile.measurementServiceODataUrl + 'odata/' + query, config)
    .then(response => {
      return response.data.value;
    });
};

export const downloadOData = async (user, query) => {
  const config = {
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };

  const response = await axios.post(
    configFile.measurementServiceODataUrl + 'odata/' + query,
    {},
    config
  );

  const contentDispositionHeader = response.headers['content-disposition'];
  const contentTypeHeader = response.headers['content-type'];
  const getMetadata = (header, metadata) => {
    var splitString = header.split(metadata + '=');
    return splitString[1].split(';')[0];
  };
  const fileName = getMetadata(contentDispositionHeader, 'filename').replace(
    /"/g,
    ''
  );
  fileDownload(response.data, fileName, contentTypeHeader);
};
