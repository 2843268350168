import axios from 'axios';
import configFile from '../App_config.json';
import { getActiveTenant } from 'utils/tenantManager.js';
import { getCurrentUserRole } from 'utils/currentUserReader';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

const getUserRole = user => {
  return getCurrentUserRole(user);
};

const getUserTenants = user => {
  return user[user.namespace + 'tenants'];
};

export const getUserProfile = async user => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.userManagementApiUrl + user[user.namespace + 'id'],
    config
  );
};

export const getUserDetails = async (user, contactSensightId) => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.userManagementApiUrl + contactSensightId, config);
};

export const changeLocaleSettings = async (user, localeSettings) => {
  const config = createHeaderConfig(user);
  const tenants = getUserTenants(user);
  const data = {
    email: user.email,
    timezone: localeSettings.selectedTimezone,
    language: localeSettings.selectedLanguage,
    role: getUserRole(user),
    id: user[user.namespace + 'id'],
    tenant: tenants.find(t => t.FriendlyId === getActiveTenant()),
    tenants
  };

  return axios.patch(
    configFile.userManagementApiUrl + user[user.namespace + 'id'],
    data,
    config
  );
};

export const updateProfileDetails = async (user, updateProfileDetails) => {
  const config = createHeaderConfig(user);
  const tenants = getUserTenants(user);
  const data = {
    email: updateProfileDetails.email,
    phoneNumber: updateProfileDetails.phonenumber,
    displayName: updateProfileDetails.fullName,
    role: getUserRole(user),
    id: user[user.namespace + 'id'],
    tenant: tenants.find(t => t.FriendlyId === getActiveTenant()),
    tenants
  };

  if (updateProfileDetails.email !== user.email) {
    data['email'] = updateProfileDetails.email;
  }

  return axios.patch(
    configFile.userManagementApiUrl + user[user.namespace + 'id'],
    data,
    config
  );
};

export const createUser = async (user, userInformation) => {
  const config = createHeaderConfig(user);
  const data = {
    id: userInformation.id,
    status: userInformation.status,
    username: userInformation.username,
    email: userInformation.email,
    language: userInformation.language,
    timeZone: userInformation.timezone,
    displayName: userInformation.name,
    password: userInformation.password,
    tenants: [
      {
        friendlyId: userInformation.tenant,
        organization: userInformation.organization.sensightId,
        userRole: userInformation.role
      }
    ]
  };
  return axios.post(configFile.userManagementApiUrl, data, config);
};

export const editUser = async (user, userInformation) => {
  const config = createHeaderConfig(user);
  const data = {
    id: userInformation.id,
    status: userInformation.status,
    email: userInformation.email,
    language: userInformation.language,
    timeZone: userInformation.timezone,
    displayName: userInformation.name,
    tenant: {
      friendlyId: userInformation.tenant,
      organization: userInformation.organization.sensightId,
      userRole: userInformation.role
    }
  };
  return axios.put(
    configFile.userManagementApiUrl + userInformation.id,
    data,
    config
  );
};

export const deleteUser = async (user, sensightId) => {
  const config = createHeaderConfig(user);
  config.data = {
    tenantFriendlyId: getActiveTenant()
  };
  return axios.delete(configFile.userManagementApiUrl + sensightId, config);
};
