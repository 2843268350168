import { connect } from 'react-redux';
import { VisualizeDisplay } from './VisualizeDisplay.jsx';

const mapStateToProps = (state, ownProps) => {
  let visualizeLink = null;

  if (ownProps.match !== undefined) {
    visualizeLink = ownProps.match.params.link;
  } else if (ownProps.visualizeLink) {
    visualizeLink = ownProps.visualizeLink;
  }

  return {
    clearSelectChannelDialogStateOnOpen:
      ownProps.clearSelectChannelDialogStateOnOpen ?? false,
    clearSelectChannelDialogStateImmediately:
      ownProps.clearSelectChannelDialogStateImmediately ?? false,
    visualizeLink: visualizeLink
  };
};

export const VisualizeContainer = connect(mapStateToProps)(VisualizeDisplay);
