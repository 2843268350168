export const TENANT_ID = 'tenant';
const INITIAL_PAGELOAD_TENANT = 'initial_pageload_tenant';
const FRIENDLY_ID = 'FriendlyId';

export const setInitialTenantFromPageload = locationSearch => {
  const tenantParam = getTenantFromSearchParams(locationSearch);
  const intitialPageLoadTenant = getInitialPageloadTenant();
  if (tenantParam != null && tenantParam !== intitialPageLoadTenant) {
    console.dev(
      `Initial pageload tenant from the url is different from the last one, setting it to ${tenantParam}`
    );
    sessionStorage.setItem(INITIAL_PAGELOAD_TENANT, tenantParam);
  }
};

export const switchToTenant = (tenant, openInNewTab) => {
  const url = new URL(window.location.origin);
  url.searchParams.set(TENANT_ID, tenant);

  if (!openInNewTab) {
    window.location.href = url.href;
  } else {
    window.open(url.href, '_blank'); // Open window with url of current page
  }
};

export const getTenantFromSearchParams = locationSearch => {
  let params = new URLSearchParams(locationSearch);
  return params.get(TENANT_ID);
};

export const getInitialPageloadTenant = () => {
  const initialPageloadTenant = sessionStorage.getItem(INITIAL_PAGELOAD_TENANT);
  console.dev(`Getting initial pageload tenant: ${initialPageloadTenant}`);
  return initialPageloadTenant;
};

export const setActiveTenant = tenant => {
  console.dev(`Setting active tenant: ${tenant}`);
  sessionStorage.setItem(TENANT_ID, tenant);
  setTenantInSearchParams(tenant);
};

export const setTenantInSearchParamsIfNotAlready = () => {
  const tenantParam = getTenantFromSearchParams(window.location.search);
  const tenant = getActiveTenant();

  if (tenantParam !== tenant) {
    setTenantInSearchParams(tenant);
  }
};

export const setTenantInSearchParams = tenant => {
  const url = new URL(window.location.href);
  url.searchParams.set(TENANT_ID, tenant);
  window.history.replaceState(null, null, url);
};

export const getActiveTenant = () => {
  return sessionStorage.getItem(TENANT_ID);
};

export const determineTenant = tenantsNamespaceData => {
  if (tenantsNamespaceData) {
    if (
      tenantsNamespaceData.length == 1 &&
      tenantsNamespaceData[0][FRIENDLY_ID]
    ) {
      return tenantsNamespaceData[0][FRIENDLY_ID];
    }

    if (tenantsNamespaceData.length > 1) {
      var initialPageloadTenant = getInitialPageloadTenant();
      if (initialPageloadTenant) {
        for (const tenantNamespace of tenantsNamespaceData) {
          let authorizedTenants = tenantNamespace[FRIENDLY_ID];
          if (
            authorizedTenants &&
            authorizedTenants.toUpperCase() ==
              initialPageloadTenant.toUpperCase()
          ) {
            return initialPageloadTenant;
          }
        }
      }
    }
  }
  console.dev(
    `Account is authenticated to ${tenantsNamespaceData.length} tenants and no (matching) tenant was found in the url. Choosing for the default: IMD`
  );
  return 'IMD';
};
