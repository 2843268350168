import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import ImdButton from './ImdButton';

export default function ImdPopup({
  handleClose,
  handleOk,
  open,
  title,
  content,
  displayCancel = true
}) {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {displayCancel ? (
          <ImdButton
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="inherit"
          >
            {t('general.cancel')}
          </ImdButton>
        ) : null}
        <ImdButton onClick={handleOk} variant="contained" color="primary">
          {t('general.ok')}
        </ImdButton>
      </DialogActions>
    </Dialog>
  );
}
