import React from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ImdTooltip from 'components/imd-components/ImdTooltip';
import ImdButton from 'components/imd-components/ImdButton';
import { Link, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

const ManualEntryLink = ({ item, locations }) => {
  const { t } = useTranslation();
  const classes = useImdCardStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (
    !locations ||
    !(
      item.visualizationType === 1 ||
      item.visualizationType === 2 ||
      item.visualizationType === 5
    )
  ) {
    // 1 = chart & 2 = kpi & 5 = datascience
    return null;
  }

  let manualEntryLocations;

  if (item.visualizationType === 1 || item.visualizationType === 2) {
    let locationIds = [];
    item?.visualizationSettings?.selectedChannels.forEach(sc => {
      sc?.device?.devicePlacements.forEach(dp => {
        locationIds.push(dp?.location.id);
      });
    });

    manualEntryLocations = locations.filter(l =>
      locationIds.includes(l.measurementId)
    );
  } else {
    if (!item?.visualizationSettings?.locations) {
      return null;
    }
    manualEntryLocations = item?.visualizationSettings?.locations;
  }

  if (manualEntryLocations.length === 1) {
    return (
      <ImdTooltip
        title={t('content.managelocation.manualinsert')}
        placement="top-start"
      >
        <Link
          target="_blank"
          href={`/management/locations/${manualEntryLocations[0]?.sensightId}/manualinsert`}
        >
          <ImdButton
            className={classes.smallButton}
            variant="contained"
            size="small"
            color="inherit"
            onClick={() => {}}
          >
            <PostAddIcon />
          </ImdButton>
        </Link>
      </ImdTooltip>
    );
  } else if (manualEntryLocations.length > 1) {
    return (
      <>
        <ImdTooltip
          title={t('content.managelocation.manualinsert')}
          placement="top-start"
        >
          <ImdButton
            className={classes.smallButton}
            variant="contained"
            size="small"
            color="inherit"
            onClick={handleOpen}
          >
            <PostAddIcon />
          </ImdButton>
        </ImdTooltip>
        <Menu
          className={classes.menu}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {manualEntryLocations.map(location => (
            <MenuItem key={location.sensightId} onClick={handleClose}>
              <Link
                target="_blank"
                href={`/management/locations/${location.sensightId}/manualinsert`}
              >
                {location.name}
              </Link>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return null;
};

const useImdCardStyles = makeStyles(() => ({
  smallButton: {
    minWidth: '32px',
    maxHeight: '30px'
  },
  menu: {
    marginTop: '44px'
  }
}));

export default ManualEntryLink;
