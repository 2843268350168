import { connect } from 'react-redux';
import ManageAlertDisplay from './ManageAlertDisplay.jsx';
import {
  alertSelector,
  alertActiveSwitchValueSelector,
  selectedSubscribersSelector,
  subscribersSelector
} from '../redux/alertsSelectors.js';
import {
  editAlert,
  upsertAlertDetails,
  upsertSubscriptionDetails,
  setAlertActiveSwitchValue,
  setSelectedSubscribers,
  fetchSubscribers,
  setSubscribers
} from '../redux/alertsActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import {
  showDialog,
  addSelectedChannel,
  clearSelectChannelDialogState
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions.js';
import { contactsListSelector } from 'components/content/contacts/redux/contactsListSelectors.js';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import {
  selectedChannelsSelector,
  selectedDevicesSelector,
  selectedLocationsSelector
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';
import { addSelectedDevice } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/deviceActions.js';
import { addSelectedLocation } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/locationActions.js';

const mapStateToProps = state => {
  return {
    alertActiveSwitchValue: alertActiveSwitchValueSelector(state),
    availableSubscribers: contactsListSelector(state),
    selectedSubscribers: selectedSubscribersSelector(state),
    alertViewmodel: alertSelector(state),
    currentUser: currentUserSelector(state),
    selectedChannels: selectedChannelsSelector(state),
    selectedDevices: selectedDevicesSelector(state),
    selectedLocations: selectedLocationsSelector(state),
    subscribers: subscribersSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  upsertAlert() {
    dispatch(editAlert());
  },
  clearDialog() {
    dispatch(clearSelectChannelDialogState());
  },
  openSelectChannelsDialog() {
    dispatch(showDialog());
  },
  upsertAlertDetails(alertDetails) {
    dispatch(upsertAlertDetails(alertDetails));
  },
  upsertSubscriptionDetails(subscriptionDetails) {
    dispatch(upsertSubscriptionDetails(subscriptionDetails));
  },
  setSelectedSubscribers(autocompleteSubscribers) {
    dispatch(setSelectedSubscribers(autocompleteSubscribers));
  },
  setAlertActiveSwitchValue(switchValue) {
    dispatch(setAlertActiveSwitchValue(switchValue));
  },
  addSelectedChannel(channel) {
    if (!channel.displayLabel) {
      channel.displayLabel = channel.externalChannelId;
    }
    dispatch(addSelectedChannel(channel));
  },
  addSelectedDevice(device) {
    dispatch(addSelectedDevice(device));
  },
  addSelectedLocation(location) {
    dispatch(addSelectedLocation(location));
  },
  fetchSubscribers(alertId) {
    dispatch(fetchSubscribers(alertId));
  },
  setSubscribers(subscribers) {
    dispatch(setSubscribers(subscribers));
  }
});

export const EditAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageAlertDisplay);
