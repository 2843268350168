import { createReducer } from 'utils/createReducer';
import {
  SET_ALERT,
  SET_ALERTS_LIST,
  CREATE_ALERT_SUCCEEDED,
  EDIT_ALERT_SUCCEEDED,
  DELETE_ALERT_SUCCEEDED,
  SET_ALERT_ACTIVE_SWITCH_VALUE,
  SET_SELECTED_SUBSCRIBERS,
  SET_SUBSCRIBERS
} from './alertsActions';
import { fromJS } from 'immutable';

export const alertsList = createReducer(null, {
  [SET_ALERTS_LIST](state, { alertsList }) {
    return fromJS(alertsList);
  },
  [CREATE_ALERT_SUCCEEDED](state, { alert }) {
    return state.push(fromJS(alert));
  },
  [EDIT_ALERT_SUCCEEDED](state, { alert }) {
    let alertsList = state.toJS();
    var foundAlertIndex = alertsList.findIndex(dev => alert.id === dev.id);
    alertsList[foundAlertIndex] = alert;
    return fromJS(alertsList);
  },
  [DELETE_ALERT_SUCCEEDED](state, { alert }) {
    return state.filter(con => con.get('id') !== alert.id);
  }
});

export const alert = createReducer(null, {
  [SET_ALERT](state, { alert }) {
    return fromJS(alert);
  }
});

export const selectedSubscribers = createReducer(null, {
  [SET_SELECTED_SUBSCRIBERS](state, { selectedSubscribers }) {
    return fromJS(selectedSubscribers);
  }
});

export const subscribers = createReducer(null, {
  [SET_SUBSCRIBERS](state, { subscribers }) {
    return fromJS(subscribers);
  }
});

export const alertActiveSwitchValue = createReducer(null, {
  [SET_ALERT_ACTIVE_SWITCH_VALUE](state, { alertActiveSwitchValue }) {
    return fromJS(alertActiveSwitchValue);
  }
});
