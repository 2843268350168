import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import ImdPopup from 'components/imd-components/ImdPopup';
import { Column, Lookup } from 'devextreme-react/tree-list';
import makeStyles from '@mui/styles/makeStyles';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { LogItemModalContainer } from './logbookmodal/LogItemModalContainer';
import { getLogItemStates } from './LogItemsHelpers';
import {
  currentUserIsReader,
  getCurrentUserSensightId
} from 'utils/currentUserReader';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function LogItemsDataTableDisplay({
  logItems,
  fetchLogItemsListOfLocation,
  fetchActions,
  actions,
  locationSensightId,
  deleteLogItem,
  measurementLocationId,
  fetchDevicesOfLocation,
  compactColumns,
  devices,
  fetchDevices,
  fetchChannelsOfLocation,
  channels,
  createSnackbar
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [userAllowedToEdit, setUserAllowedToEdit] = React.useState(false);
  const [key, setKey] = React.useState('');
  const [editLogbookTitle, setEditLogbookTitle] = React.useState(
    t('content.logitems.editlogitem.title')
  );
  const isMobile = window.matchMedia('(max-width: 450px)').matches;
  const isTablet = window.matchMedia('(max-width: 900px)').matches;
  const currentUser = useSelector(state => currentUserSelector(state));
  const isReader = currentUserIsReader(currentUser);
  const currentUserSensightId = getCurrentUserSensightId(currentUser);

  const userHasEditRights = selected => {
    return !isReader || selected?.createdBySensightId === currentUserSensightId;
  };

  const onSelectionChanged = selected => {
    setSelectedRow(selected);
    setUserAllowedToEdit(userHasEditRights(selected));
  };

  const handleDelete = selected => {
    setSelectedRow(selected);

    if (userHasEditRights(selected)) {
      // Readers are allowed to delete their own logitems.
      setPopup(true);
    } else {
      createSnackbar(
        t('content.deletelogitem.forbidden.snackbar.text'),
        'error'
      );
    }
  };

  const handlePopupOk = () => {
    deleteLogItem(selectedRow);
    setPopup(false);
  };

  React.useEffect(() => {
    if (actions.length > 0 && channels.length > 0) {
      // Refresh datatable for lookup columns.
      setKey('1');
    }
  }, [actions, channels]);

  React.useEffect(() => {
    if (measurementLocationId) {
      fetchChannelsOfLocation(measurementLocationId, locationSensightId);
      if (devices.length === 0) {
        fetchDevices();
      } else {
        if (devices.length !== 0) {
          fetchDevicesOfLocation(measurementLocationId);
        }
      }
    }
  }, [measurementLocationId, devices]);

  React.useEffect(() => {
    if (locationSensightId) {
      fetchActions();
      fetchLogItemsListOfLocation(locationSensightId);
    }
  }, [locationSensightId]);

  React.useEffect(() => {
    if (add || edit) {
      setAdd(false);
      setEdit(false);
    }
  }, [logItems]);

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const formRef = useRef();
  const closeAdd = () => {
    setAdd(false);
  };
  const closePopup = () => {
    setEdit(false);
    setView(false);
  };

  const handleView = selected => {
    setSelectedRow(selected);
    setView(true);
    setEditLogbookTitle(t('content.logitems.viewlogitem.title'));
  };

  const handleEdit = selected => {
    setSelectedRow(selected);

    if (userHasEditRights(selected)) {
      // Readers are allowed to edit their own logitems.
      setEdit(true);
      setEditLogbookTitle(t('content.logitems.editlogitem.title'));
    } else {
      createSnackbar(t('content.editlogitem.forbidden.snackbar.text'), 'error');
    }
  };

  return (
    <div className={classes.content}>
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <LogItemModalContainer
            ref={formRef}
            isAllowedToEdit={true}
          ></LogItemModalContainer>
        }
        formRef={formRef}
        dialogTitle={t('content.logitems.createlogitem.title')}
        perform={'logitems:create'}
      />
      <ImdDialogContainer
        open={edit || view}
        handleCloseAction={closePopup}
        saveButtonTooltip={
          edit ? '' : t('content.logitems.viewlogitem.savewarning')
        }
        content={
          <LogItemModalContainer
            logItem={selectedRow}
            ref={formRef}
            isAllowedToEdit={edit}
          ></LogItemModalContainer>
        }
        formRef={formRef}
        dialogTitle={editLogbookTitle}
        perform={'logitems:edit'}
        saveButtonDisabled={!edit}
      />
      <ImdPopup
        open={popup}
        title={t('content.logitems.deletepopup.title')}
        content={t('content.logitems.deletepopup.content')}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        key={key}
        addPermission={'logitems:create'}
        editPermission={'logitems:edit'}
        deletePermission={'logitems:delete'}
        handleEditButton={selected => handleEdit(selected)}
        handleViewButton={selected => handleView(selected)}
        handleAddButton={() => setAdd(true)}
        handleDeleteButton={selected => handleDelete(selected)}
        onSelectionChangedEvent={selected => onSelectionChanged(selected)}
        userAllowedToEdit={userAllowedToEdit}
        title={t('content.logitems.title')}
        data={logItems}
        hideActions={isMobile}
        displayViewButton={isReader}
        displayDeleteButton={!isReader}
        columns={
          <>
            <Column
              sortOrder="desc"
              dataField={'startDateTime'}
              caption={t('content.logitems.startDateTime')}
              dataType={'datetime'}
              visible={true}
            />
            <Column
              dataField={'actionId'}
              caption={t('content.logitems.action')}
              visible={!compactColumns}
            >
              <Lookup dataSource={actions} valueExpr="id" displayExpr="name" />
            </Column>
            <Column
              dataField={'logItemStateKey'}
              caption={t('content.logitems.states')}
              visible={!isMobile && !compactColumns}
            >
              <Lookup
                dataSource={getLogItemStates()}
                valueExpr="key"
                displayExpr="label"
              />
            </Column>
            <Column
              dataField={'createdBy'}
              caption={t('content.logitems.createdBy')}
              visible={!isMobile && !compactColumns}
            />
            <Column
              dataField={'deadline'}
              caption={t('content.logitems.deadline')}
              visible={!isMobile && !compactColumns && !isTablet}
              dataType={'datetime'}
            />
            <Column
              dataField={'files'}
              caption={t('content.logitems.fileupload')}
              visible={false}
            />
            <Column
              dataField={'channelId'}
              caption={t('content.logitems.channel')}
              visible={false}
            >
              <Lookup
                dataSource={channels}
                valueExpr="id"
                displayExpr="displayLabel"
              />
            </Column>
            <Column
              dataField={'timeSpent'}
              caption={t('content.logitems.timeSpent')}
              visible={false}
            />
            <Column
              dataField={'hideForCustomer'}
              caption={t('content.logitems.hideForCustomer')}
              visible={false}
            />
            <Column
              dataField={'description'}
              caption={t('content.logitems.description')}
              visible={true}
            />
            <Column
              visible={false}
              dataField={'createdAt'}
              dataType={'datetime'}
              caption={t('content.general.createdAt')}
            />
            <Column
              visible={false}
              dataField={'lastUpdatedAt'}
              caption={t('content.general.lastUpdatedAt')}
            />
            <Column
              visible={false}
              dataField={'lastUpdatedBy'}
              caption={t('content.general.lastUpdatedBy')}
            />
            <Column
              type="buttons"
              caption={t('content.logitems.actions')}
              width={100}
              buttons={[
                {
                  hint: t('general.edit'),
                  icon: 'edit',
                  onClick: event => handleEdit(event.row.data)
                },
                ...(isReader
                  ? [
                      {
                        hint: t('general.view'),
                        icon: 'textdocument',
                        onClick: event => handleView(event.row.data)
                      }
                    ]
                  : [
                      {
                        hint: t('general.delete'),
                        icon: 'trash',
                        onClick: event => handleDelete(event.row.data)
                      }
                    ])
              ]}
              stateStorageKey="log-items-table-display"
            />
          </>
        }
      />
    </div>
  );
}

const useStyles = makeStyles({});
