import { takeEvery, select, put, call } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { generateRandomHexCode } from 'utils/helperMethods';
import {
  FETCH_CHANNELS_REQUESTED,
  setVisualizationChannels,
  setDisplayedChannels,
  deleteDisplayedChannel,
  ADD_SELECTED_CHANNEL,
  FILTER_DISPLAYED_CHANNELS,
  DELETE_SELECTED_CHANNEL,
  deleteVisualizationChannel,
  addChannel,
  addDisplayedChannel,
  CLEAR_SELECT_CHANNEL_DIALOG_STATE,
  RETRIEVE_SELECTED_CHANNELS,
  clearSelectedChannels,
  fetchDevicesRequested,
  fetchLocationsRequested,
  setSelectedChannels,
  isClearingChannelDialog,
  CLEAR_SELECT_CHANNEL_DIALOG_STATE_WITHOUT_FETCH,
  CLEAR_SELECT_CHANNEL_DIALOG_CHANNELS_STATE
} from './selectChannelDialogActions';

import {
  channelsSelector,
  selectedDevicesSelector,
  selectedChannelsSelector,
  displayedDevicesSelector
} from './selectChannelDialogSelectors';
import { Get } from 'apis/MeasurementODataApi';
import { fromJS } from 'immutable';
import {
  setVisualizationDevices,
  setDisplayedDevices,
  clearSelectedDevices,
  addSelectedDevice
} from './deviceActions';
import {
  setVisualizationLocations,
  setDisplayedLocations,
  clearSelectedLocations
} from './locationActions';
import { getSelectorColors } from 'utils/themeManager';

const colors = getSelectorColors();
let colorCounter = 0;

export function* retrieveSelectedChannelsSaga() {
  yield takeEvery(RETRIEVE_SELECTED_CHANNELS, retrieveSelectedChannels);
}
export function* fetchChannelsSaga() {
  yield takeEvery(FETCH_CHANNELS_REQUESTED, fetchChannels);
}

export function* addSelectedChannelSaga() {
  yield takeEvery(ADD_SELECTED_CHANNEL, addSelectedChannel);
}

export function* deleteSelectedChannelSaga() {
  yield takeEvery(DELETE_SELECTED_CHANNEL, addDeletedChannelBackToList);
}

export function* filterDisplayedChannelsSaga() {
  yield takeEvery(FILTER_DISPLAYED_CHANNELS, filterDisplayedChannels);
}

export function* clearSelectedChannelsStateSaga() {
  yield takeEvery(
    CLEAR_SELECT_CHANNEL_DIALOG_CHANNELS_STATE,
    executeClearSelectedChannelsState
  );
}

export function* clearStateWithoutFetchSaga() {
  yield takeEvery(
    CLEAR_SELECT_CHANNEL_DIALOG_STATE_WITHOUT_FETCH,
    executeClearStateWithoutFetch
  );
}

export function* clearSelectChannelDialogStateSaga() {
  yield takeEvery(CLEAR_SELECT_CHANNEL_DIALOG_STATE, executeClearState);
}

function* addSelectedChannel(action) {
  const channel = action.channel;
  yield put(deleteVisualizationChannel(channel));
  yield put(deleteDisplayedChannel(channel));
}

function* addDeletedChannelBackToList(action) {
  const channel = fromJS(action.channel);
  yield put(addChannel(channel));
  yield put(addDisplayedChannel(channel));
}

function* filterDisplayedChannels(action) {
  const allChannels = yield select(channelsSelector);
  const result = allChannels.filter(c =>
    c.displayLabel.toLowerCase().includes(action.query.toLowerCase())
  );
  yield put(setDisplayedChannels(result));
}

function* fetchChannels() {
  const currentUser = yield select(currentUserSelector);
  const selectedDevices = yield select(selectedDevicesSelector);
  const locked = selectedDevices.length === 0;

  if (!locked) {
    const deviceQuery = buildSelectedDevicesODataQuery(selectedDevices);
    const devices = yield Get(currentUser, deviceQuery);

    const selectedChannels = yield select(selectedChannelsSelector);

    const channelQuery = buildChannelODataQuery(devices, selectedChannels);
    let channels = yield Get(currentUser, channelQuery);

    channels = enrichChannels(channels, devices);

    yield put(setVisualizationChannels(channels));
    yield put(setDisplayedChannels(channels));
  }
}

function* retrieveSelectedChannels({ channels }) {
  const currentUser = yield select(currentUserSelector);
  const displayedDevices = yield select(displayedDevicesSelector);
  const locked = channels.length === 0 || displayedDevices.length === 0;

  const channelIds = channels.map(c => c.value);

  if (!locked) {
    const channelQuery = buildChannelODataQueryFromIds(channelIds);
    let foundChannels = yield Get(currentUser, channelQuery);
    const deviceIds = foundChannels.map(c => {
      return c.deviceId;
    });
    const devices = [];
    displayedDevices.forEach(dev => {
      let foundDevice = deviceIds.find(d => d === dev.id);
      if (foundDevice) {
        devices.push(dev);
      }
    });

    for (var device of devices) {
      if (device) yield put(addSelectedDevice(device));
    }

    foundChannels = enrichChannels(foundChannels, devices);

    // Add channel colors for datascience
    for (let i = 0; i < foundChannels.length; i++) {
      const channel = channels.find(c => c.value === foundChannels[i].id);

      if (channel) {
        foundChannels[i] = {
          ...foundChannels[i],
          color: channel.color || foundChannels[i].color,
          aggregationType: channel.aggregationType || 'source'
        };
      }
    }

    for (var c of foundChannels) {
      yield put(deleteVisualizationChannel(c));
      yield put(deleteDisplayedChannel(c));
    }
    yield put(setSelectedChannels(foundChannels));
  }
}

function* executeClearState() {
  console.dev(
    'Start clearing selected channels, devices, locations and fetching new channels and devices'
  );

  yield call(executeClearStateWithoutFetch);
  yield put(fetchLocationsRequested());
  yield put(fetchDevicesRequested());
}

function* executeClearStateWithoutFetch() {
  yield call(executeClearSelectedChannelsState);
  yield call(executeClearSelectedDevicesState);
  yield call(executeClearSelectedLocationsState);
  yield put(isClearingChannelDialog(false));
}

function* executeClearSelectedLocationsState() {
  yield put(setVisualizationLocations([]));
  yield put(setDisplayedLocations([]));
  yield put(clearSelectedLocations());

  console.dev('Succesfully cleared all locations.');
}

function* executeClearSelectedDevicesState() {
  yield put(setVisualizationDevices([]));
  yield put(setDisplayedDevices([]));
  yield put(clearSelectedDevices());

  console.dev('Succesfully cleared all devices.');
}

function* executeClearSelectedChannelsState() {
  yield put(setVisualizationChannels([]));
  yield put(setDisplayedChannels([]));
  yield put(clearSelectedChannels());

  console.dev('Succesfully cleared all channels.');
}

function enrichChannels(channels, devices) {
  for (let index = 0; index < channels.length; index++) {
    const channel = channels[index];

    if (colorCounter >= colors.length) {
      channel.color = generateRandomHexCode();
    } else {
      channel.color = colors[colorCounter];
    }

    colorCounter += 1;

    let deviceName = '';
    if (devices.length > 0) {
      deviceName = devices.find(d => d.id === channel.deviceId).name;
    }

    channel.unit = channel.unit !== null ? channel.unit : '-';

    channel.displayLabel = !channel.displayLabel
      ? `${deviceName} - ${channel.externalChannelId} [${channel.unit}]`
      : `${deviceName} - ${channel.displayLabel} - [${channel.unit}]`;

    channels = channels.map(c =>
      c.sensightId === channel.sensightId ? { ...channel } : c
    );
  }

  return channels;
}

function buildSelectedDevicesODataQuery(selectedDevices) {
  let baseQuery = `device?$filter=`;

  for (let index = 0; index < selectedDevices.length; index++) {
    const device = selectedDevices[index];
    if (index == 0) {
      baseQuery += ` (sensightId eq ${device.sensightId}`;
      continue;
    }
    baseQuery += ` or sensightId eq ${device.sensightId}`;
  }

  const endQuery = `)`;

  baseQuery += endQuery;

  return baseQuery;
}

export function buildChannelODataQueryFromIds(channelsId) {
  let baseQuery = `channel?$filter=`;

  for (let index = 0; index < channelsId.length; index++) {
    let id = channelsId[index];
    if (!id) {
      continue;
    }

    if (index == 0) {
      baseQuery += ` (id eq ${channelsId[index]}`;
    } else {
      baseQuery += ` or id eq ${channelsId[index]}`;
    }
  }
  baseQuery += ')';

  return baseQuery;
}

export function buildChannelODataQuery(selectedDevices, selectedChannels) {
  let baseQuery = `channel?$filter=`;

  for (let index = 0; index < selectedDevices.length; index++) {
    const device = selectedDevices[index];
    if (index == 0) {
      baseQuery += ` (deviceId eq ${device.id}`;
      continue;
    }
    baseQuery += ` or deviceId eq ${device.id}`;
  }

  baseQuery += `)`;

  if (selectedChannels.length > 0) {
    for (let index = 0; index < selectedChannels.length; index++) {
      const selectedChannel = selectedChannels[index];
      if (index == 0) {
        baseQuery += ` and (id ne ${selectedChannel.id}`;
        continue;
      }
      baseQuery += ` and id ne ${selectedChannel.id}`;
    }

    baseQuery += `)`;
  }

  return baseQuery;
}
