import { connect } from 'react-redux';
import SimCardsDisplay from './SimCardsDisplay.jsx';
import { fetchSimCards } from '../redux/simCardsActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { simCardsSelector } from '../redux/simCardsSelectors';

const mapStateToProps = state => {
  return {
    simCards: simCardsSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSimCards() {
    dispatch(fetchSimCards());
  }
});

export const SimCardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimCardsDisplay);
