import { createSelector } from 'reselect';

export const visualizeFromTimeSelector = createSelector(
  state => state.get('visualizeFromTime'),
  visualizeFromTime => visualizeFromTime
);

export const visualizeUntilTimeSelector = createSelector(
  state => state.get('visualizeUntilTime'),
  visualizeUntilTime => visualizeUntilTime
);

export const visualizePeriodSelector = createSelector(
  state => state.get('visualizePeriod'),
  visualizePeriod => visualizePeriod
);

export const chartDisplayOptionsSelector = createSelector(
  state => state.get('chartDisplayOptions'),
  chartDisplayOptions => chartDisplayOptions.toJS()
);
