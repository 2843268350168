import { connect } from 'react-redux';
import EditSimCardDisplay from './EditSimCardDisplay.jsx';
import { simCardSelector } from '../redux/simCardsSelectors.js';
import { editSimCard, upsertSimCardDetails } from '../redux/simCardsActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = state => {
  var viewModel = simCardSelector(state);
  return {
    viewModel: viewModel
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  editSimCard(sensightId) {
    dispatch(editSimCard(sensightId));
  },
  upsertSimCardDetails(simCardDetails) {
    dispatch(upsertSimCardDetails(simCardDetails));
  }
});

export const EditSimCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(EditSimCardDisplay);
