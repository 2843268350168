import { connect } from 'react-redux';
import ExportChartTableDisplay from './ExportChartTableDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { exportChartsListSelector } from './redux/exportChartsListSelectors.js';
import {
  deleteExportChart,
  fetchExportChartsList
} from './redux/exportChartsListActions.js';

const mapStateToProps = state => {
  return {
    exportCharts: exportChartsListSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchExportChartsList() {
    dispatch(fetchExportChartsList());
  },
  deleteExportChart(exportchart) {
    dispatch(deleteExportChart(exportchart));
  }
});

export const ExportChartTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportChartTableDisplay);
