import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

export default function ImdLoader({ height = '75vh' }) {
  const classes = useStyles({ height });
  return (
    <div className={classes.spinnerWrapper}>
      <CircularProgress className={classes.spinner} />
    </div>
  );
}

const useStyles = makeStyles({
  spinnerWrapper: {
    height: props => props.height,
    display: 'flex',
    justifyContent: 'center'
  },
  spinner: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
});
