import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'formik-mui';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';

export const SimCardDetailsForm = ({
  simCardDetailsFormRef,
  simCardDetailsViewmodel,
  upsertSimCardDetails,
  setSimCardDetailsFormValid
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormikWithRef
      ref={simCardDetailsFormRef}
      initialValues={{
        id: !simCardDetailsViewmodel ? '' : simCardDetailsViewmodel.id ?? '',
        iccid: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.iccid ?? '',
        msisdn: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.msisdn ?? '',
        imsi: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.imsi ?? '',
        mac: !simCardDetailsViewmodel ? '' : simCardDetailsViewmodel.mac ?? '',
        phoneNumber: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.phoneNumber ?? '',
        ipAddress: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.ipAddress ?? '',
        provider: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.provider ?? '',
        ratePlan: !simCardDetailsViewmodel
          ? ''
          : simCardDetailsViewmodel.ratePlan ?? '',
        pin: !simCardDetailsViewmodel ? '' : simCardDetailsViewmodel.pin ?? '',
        puk: !simCardDetailsViewmodel ? '' : simCardDetailsViewmodel.puk ?? ''
      }}
      enableReinitialize={true}
      validateOnBlur={true}
      validate={values => {
        let errors = {};
        if (!values.iccid) {
          errors.iccid = t('general.validation.required');
        }
        if (values.phoneNumber && !/^\d{12}$/i.test(values.phoneNumber)) {
          errors.phoneNumber = t('content.simcards.invalidphonenumber');
        }
        if (
          values.ipAddress &&
          !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            values.ipAddress
          )
        ) {
          errors.ipAddress = t('content.simcards.invalidipaddress');
        }

        return errors;
      }}
      onSubmit={values => {
        upsertSimCardDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setSimCardDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form>
            <Field
              id="iccid"
              name="iccid"
              type="text"
              label={t('content.simcards.iccid')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.iccid}
              required
              variant="standard"
            />
            <Field
              id="msisdn"
              name="msisdn"
              type="text"
              label={t('content.simcards.msisdn')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.msisdn}
              variant="standard"
            />
            <Field
              id="imsi"
              name="imsi"
              type="text"
              label={t('content.simcards.imsi')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.imsi}
              variant="standard"
            />
            <Field
              id="mac"
              name="mac"
              type="text"
              label={t('content.simcards.mac')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.mac}
              variant="standard"
            />
            <Field
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              label={t('content.simcards.phonenumber')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.phoneNumber}
              variant="standard"
            />
            <Field
              id="ipAddress"
              name="ipAddress"
              type="text"
              label={t('content.simcards.ipaddress')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.ipAddress}
              variant="standard"
            />
            <Field
              id="provider"
              name="provider"
              type="text"
              label={t('content.simcards.provider')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.provider}
              variant="standard"
            />
            <Field
              id="ratePlan"
              name="ratePlan"
              type="text"
              label={t('content.simcards.rateplan')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.ratePlan}
              variant="standard"
            />
            <Field
              id="pin"
              name="pin"
              type="text"
              label={t('content.simcards.pin')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.pin}
              variant="standard"
            />
            <Field
              id="puk"
              name="puk"
              type="text"
              label={t('content.simcards.puk')}
              fullWidth
              className={classes.lastInputField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.puk}
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  }
}));
