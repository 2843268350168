import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'formik-mui';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';

export const LogbookActionDetailsForm = ({
  logbookActionDetailsFormRef,
  logbookActionDetailsViewmodel,
  upsertLogbookActionDetails,
  setLogbookActionDetailsFormValid
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormikWithRef
      ref={logbookActionDetailsFormRef}
      initialValues={{
        id: !logbookActionDetailsViewmodel
          ? ''
          : logbookActionDetailsViewmodel.id ?? '',
        name: !logbookActionDetailsViewmodel
          ? ''
          : logbookActionDetailsViewmodel.name ?? ''
      }}
      enableReinitialize={true}
      validateOnBlur={true}
      validate={values => {
        let errors = {};
        if (!values.name) {
          errors.name = t('general.validation.required');
        }

        return errors;
      }}
      onSubmit={values => {
        upsertLogbookActionDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setLogbookActionDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form>
            <Field
              id="name"
              name="name"
              type="text"
              label={t('content.logbookactions.name')}
              fullWidth
              className={classes.lastInputField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.name}
              required
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  }
}));
