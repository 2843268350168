import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { editContact } from 'apis/CustomerContactApi';
import {
  EDIT_CONTACT,
  FETCH_USER_DETAILS,
  setUserDetails,
  clearUserDetails
} from './editContactActions';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  createUser,
  editUser,
  getUserDetails,
  deleteUser
} from 'apis/UserManagementApi';
import { t } from 'i18next';
import { editContactSucceeded } from '../../redux/contactsListActions';
import { cloneObject } from 'utils/helperMethods';
import { getError } from '../../ContactHelpers';

export function* editContactSaga() {
  yield takeEvery(EDIT_CONTACT, execute);
  yield takeEvery(FETCH_USER_DETAILS, fetchUserDetails);
}

function* execute(action) {
  const contact = action.contactInformation;
  const user = yield select(currentUserSelector);

  const userdata = cloneObject(contact);
  userdata.id = contact.sensightId;
  if (contact.contactIsUser === true) {
    try {
      if (contact.userid) {
        // contact already has user, edit the user
        contact.sensightId = (yield editUser(user, userdata)).data.id;
        yield put(createSnackbar(t('content.edituser.snackbar.text')));
      } else {
        // contact doesn't have user, create an user
        contact.sensightId = (yield createUser(user, userdata)).data.id;
        yield put(createSnackbar(t('content.adduser.snackbar.text')));
      }
    } catch (exception) {
      const error = getError(exception);
      yield put(createSnackbar(error, 'error'));
      return;
    }
  } else {
    try {
      if (contact.userid) {
        // contactIsUser is false, but contact is an user, so delete the user
        yield deleteUser(user, contact.sensightId);
        yield put(clearUserDetails());
        yield put(createSnackbar(t('content.deleteuser.snackbar.text')));
      }
    } catch (exception) {
      console.error(exception);
    }
  }

  try {
    yield editContact(user, contact);
    yield put(createSnackbar(t('content.editcontact.snackbar.text')));
    yield put(editContactSucceeded(contact));
  } catch (exception) {
    console.error(exception);
  }
}

function* fetchUserDetails(action) {
  const { contactSensightId } = action;
  const user = yield select(currentUserSelector);

  try {
    const userDetails = yield getUserDetails(user, contactSensightId);

    // Show snackbar when fetching userDetails failed
    if (userDetails.data === '') {
      yield put(setUserDetails({ hasNoAccessToSensightLogin: true }));
      yield put(clearUserDetails());
      yield put(
        createSnackbar(
          t('content.getcontact.failed.snackbar.text'),
          'error',
          20000
        )
      );
      return;
    } else if (userDetails.status !== 200) {
      yield put(createSnackbar(t('snackbar.generic.error'), 'error'));
      return;
    }

    yield put(setUserDetails(userDetails.data));
    yield put(clearUserDetails());
  } catch (exception) {
    console.error(exception);
  }
}
