import { connect } from 'react-redux';
import CreateDashboardDisplay from './CreateDashboardDisplay.jsx';
import { fetchDataScienceReports } from 'components/content/dashboard/createDashboard/redux/selectDataScienceReportActions';
import { dataScienceReportsSelector } from 'components/content/dashboard/createDashboard/redux/selectDataScienceReportSelectors';
import { sectionsSelector } from './redux/editDashboardSelectors';
import {
  addSection,
  removeSection,
  editSection,
  setSections
} from './redux/editDashboardActions';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions.js';
import { saveButtonDisabledSelector } from 'components/imd-components/imddialog/redux/imdDialogSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    dashboard: ownProps.dashboard,
    onSave: ownProps.onSave,
    sections: sectionsSelector(state),
    saveButtonDisabled: saveButtonDisabledSelector(state),
    dataScienceReports: dataScienceReportsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  addSection(section) {
    dispatch(addSection(section));
  },
  removeSection(section) {
    dispatch(removeSection(section));
  },
  editSection(section) {
    dispatch(editSection(section));
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  setSections(value) {
    dispatch(setSections(value));
  },
  fetchDataScienceReports() {
    dispatch(fetchDataScienceReports());
  }
});

export const CreateDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDashboardDisplay);
