import { t } from "i18next";

/**
 * @param {string} value 
 * @param {number} length 
 * @returns {string|undefined}
 */
export function isValidLength(value, length = 32) {
  if (value === null || value === undefined) {
    return;
  }

  if (value.length <= length) {
    return;
  }

  return t('content.general.input.validation.invalid.length', { amount: length });
}
