import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { deleteContact } from 'apis/CustomerContactApi';
import { DELETE_CONTACT } from './deleteContactActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { deleteContactSucceeded } from '../../redux/contactsListActions';
import { deleteUser } from 'apis/UserManagementApi';

export function* deleteContactSaga() {
  yield takeEvery(DELETE_CONTACT, execute);
}

function* execute(action) {
  const contact = action.contactInformation;
  const user = yield select(currentUserSelector);

  try {
    yield deleteUser(user, contact.sensightId);
    yield put(createSnackbar(t('content.deleteuser.snackbar.text')));
  } catch (exception) {
    // Doesn't have an auth0 account containing this tenant, continue.
  }
  try {
    yield deleteContact(user, contact.id);
    yield put(createSnackbar(t('content.deletecontact.snackbar.text')));
    yield put(deleteContactSucceeded(contact));
  } catch (exception) {
    console.error(exception);
  }
}
