import { connect } from 'react-redux';
import CreateLogItemModalDisplay from './CreateLogItemModalDisplay.jsx';
import { renderChartCreateLogItem } from './redux/renderChartCreateLogItemActions';
import { t } from 'i18next';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { actionsListSelector } from 'components/content/actions/redux/actionsListSelectors';
import { fetchActionsList } from 'components/content/actions/redux/actionsListActions.js';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import { logItemViewModelSelector } from './redux/renderChartCreateLogItemSelectors';
import {
  selectedChannelsSelector,
  selectedDevicesSelector
} from '../toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';

const mapStateToProps = state => {
  const logItem = logItemViewModelSelector(state);
  return {
    currentUser: currentUserSelector(state),
    actions: actionsListSelector(state).sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    ),
    device: selectedDevicesSelector(state).find(
      device => device.id === logItem.deviceId
    ),
    channel: selectedChannelsSelector(state).find(
      channel => channel.id === logItem.channelId
    ),
    logItem: logItem
  };
};

const mapDispatchToProps = dispatch => ({
  renderChartCreateLogItem(logitem) {
    dispatch(renderChartCreateLogItem(logitem));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  fetchActions() {
    dispatch(fetchActionsList());
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.actionId) {
    errors.logitemType = t('general.validation.required');
  }
  if (values.logItemStateKey === undefined || values.logItemStateKey === null) {
    errors.logitemType = t('general.validation.required');
  }
  return errors;
};

export const CreateLogItemModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateLogItemModalDisplay);
