import axios from 'axios';
import config from '../auth_config.json';

export const SendChangePasswordMailApi = async user => {
  return axios.post(config.apiUrl + 'dbconnections/change_password', {
    client_id: config.client_id,
    email: user.email,
    connection: 'Username-Password-Authentication'
  });
};
