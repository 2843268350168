import React, { Component } from 'react';
import { Auth0Context } from '../react-auth0-spa';
import Loading from './Loading';
export class Logout extends Component {
  static contextType = Auth0Context;

  render() {
    const { logout } = this.context;
    logout();
    return <Loading />;
  }
}
