import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { MenuItem, Typography, Switch, Tabs, Tab, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import { getLogItemStates } from '../LogItemsHelpers';
import ImdDatePicker from 'components/imd-components/ImdDatePicker';
import { Can } from 'components/imd-components/authorization/Can';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { FileUploadContainer } from '../../manageLocation/fileupload/FileUploadContainer';
import { PropTypes } from 'prop-types';

function LogItemModalDisplay(
  {
    currentUser,
    handleValidate,
    setSaveButtonDisabled,
    channels,
    createLogItem,
    updateLogItem,
    logItem,
    devices,
    actions,
    location,
    fetchDevicesOfLocation,
    isAllowedToEdit
  },
  ref
) {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setSaveButtonDisabled(false);
  }, []);

  const handleSubmit = async values => {
    values.hideForCustomer = hideSwitchValue;
    values.startDateTime = startDateTime;
    values.deadline = deadline;
    if (!logItem) {
      createLogItem(values);
    } else {
      if (isAllowedToEdit) {
        updateLogItem(values);
      }
    }
  };

  useEffect(() => {
    if (location.measurementServiceLocation) {
      fetchDevicesOfLocation(location.measurementServiceLocation.id);
    }
  }, [location.measurementServiceLocation]);

  const [hideSwitchValue, setHideSwitchValue] = useState(
    !logItem ? false : logItem.hideForCustomer
  );
  const [startDateTime, setStartDateTime] = useState(
    !logItem ? new Date() : new Date(logItem.startDateTime)
  );
  const [deadline, setDeadline] = useState(
    !logItem ? null : new Date(logItem.deadline)
  );
  const form = ({ submitForm, dirty, errors, validateForm, values }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (dirty && isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    const logItemStateMenuItems = getLogItemStates().map(logItemState => {
      return (
        <MenuItem key={logItemState.key} value={logItemState.key}>
          {logItemState.label}
        </MenuItem>
      );
    });

    const actionMenuItems = actions.map(action => {
      return (
        <MenuItem key={action.id} value={action.id}>
          {action.name}
        </MenuItem>
      );
    });

    const getChannelsMenuItems = () => {
      let channelMenuItems = [];
      channels.forEach(channel => {
        if (values.deviceId !== null && channel.deviceId === values.deviceId)
          channelMenuItems.push(
            <MenuItem key={channel.id} value={channel.id}>
              {channel.displayLabel}
            </MenuItem>
          );
      });
      return channelMenuItems;
    };

    const devicesMenuItems = devices.map(device => {
      return (
        <MenuItem key={device.id} value={device.id}>
          {device.name}
        </MenuItem>
      );
    });

    const textFieldStyling = {
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: '#333333',
        color: '#333333'
      }
    };

    return (
      <Form onSubmit={submitForm}>
        <Grid container>
          <Grid item md={12}>
            <Field
              id="actionId"
              type="select"
              name="actionId"
              label={t('content.logitems.modal.action')}
              fullWidth
              component={TextField}
              select
              className={classes.textField}
              required
              variant="standard"
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            >
              {actionMenuItems}
            </Field>
            <Field
              name="logItemStateKey"
              label={t('content.logitems.modal.logItemState')}
              fullWidth
              component={TextField}
              type="select"
              className={classes.textField}
              select
              required
              variant="standard"
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            >
              {logItemStateMenuItems}
            </Field>
            <ImdDatePicker
              id="startDateTime"
              labelText={t('content.logitems.startDateTime')}
              date={startDateTime}
              saveAction={setStartDateTime}
              className={classes.textField}
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            />
            <Field
              id="deviceId"
              type="select"
              name="deviceId"
              label={t('content.logitems.modal.device')}
              fullWidth
              component={TextField}
              className={classes.textField}
              select
              required
              variant="standard"
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            >
              {devicesMenuItems}
            </Field>
            <Field
              id="channelId"
              type="select"
              name="channelId"
              label={t('content.logitems.channel')}
              fullWidth
              component={TextField}
              className={classes.textField}
              select
              disabled={!isAllowedToEdit || !values.deviceId}
              variant="standard"
              sx={textFieldStyling}
            >
              {getChannelsMenuItems()}
            </Field>
            <Field
              id="description"
              type="text"
              name="description"
              label={t('content.logitems.description')}
              multiline
              rows="7"
              fullWidth
              className={classes.textField}
              component={TextField}
              variant="standard"
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            />
            <Field
              id="timeSpent"
              type="number"
              name="timeSpent"
              label={t('content.logitems.timeSpent')}
              className={classes.textField}
              fullWidth
              component={TextField}
              variant="standard"
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            />
            <ImdDatePicker
              id="deadline"
              labelText={t('content.logitems.deadline')}
              date={deadline}
              saveAction={setDeadline}
              className={classes.lastInputField}
              disabled={!isAllowedToEdit}
              sx={textFieldStyling}
            />
            <Can
              role={getCurrentUserRole(currentUser)}
              perform={'logitems:hideforcustomer'}
              yes={() => (
                <div
                  className={clsx(classes.switchWrapper, classes.firstWrapper)}
                >
                  <Typography variant="body1" className={classes.switchLabel}>
                    {t('content.logitems.hideForCustomer')}
                  </Typography>
                  <div className={classes.switchItem}>
                    <Switch
                      checked={hideSwitchValue}
                      onChange={() => setHideSwitchValue(!hideSwitchValue)}
                      value="hide"
                      color="primary"
                      disabled={!isAllowedToEdit}
                    />
                  </div>
                </div>
              )}
              no={() => null}
            />
          </Grid>
        </Grid>
      </Form>
    );
  };

  const formRef = useRef();

  const inputFields = (
    <FormikWithRef
      ref={formRef}
      validateOnMount={true}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        actionId: !logItem ? '' : logItem.actionId,
        description: !logItem ? '' : logItem.description,
        id: !logItem ? '' : logItem.id,
        logItemStateKey: !logItem ? 0 : logItem.logItemStateKey,
        timeSpent: !logItem ? 0 : logItem.timeSpent,
        channelId: !logItem ? '' : logItem.channelId,
        deviceId: !logItem ? '' : logItem.deviceId
      }}
      validate={values => handleValidate(values)}
      onSubmit={values => handleSubmit(values)}
      enableReinitalize={true}
    >
      {form}
    </FormikWithRef>
  );
  useImperativeHandle(ref, () => formRef);

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      {logItem ? (
        <>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, tab) => setCurrentTab(tab)}
            aria-label="disabled tabs example"
          >
            <Tab label={t('content.logitems.title')} />
            <Tab label={t('content.logitems.fileupload')} />
          </Tabs>
          <Typography
            component="div"
            role="tabpanel"
            hidden={currentTab !== 0}
            id={`simple-tabpanel-${0}`}
            aria-labelledby={`simple-tab-${0}`}
          >
            {inputFields}
          </Typography>
          <Typography
            component="div"
            role="tabpanel"
            hidden={currentTab !== 1}
            id={`simple-tabpanel-${1}`}
            aria-labelledby={`simple-tab-${1}`}
          >
            <FileUploadContainer
              locationId={logItem ? logItem.id : null}
              isAllowedToEdit={isAllowedToEdit}
            />
          </Typography>
        </>
      ) : (
        inputFields
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  textField: {
    marginBottom: '8px'
  },
  lastInputField: {
    marginBottom: '16px'
  }
}));

LogItemModalDisplay.propTypes = {
  currentUser: PropTypes.object,
  handleValidate: PropTypes.func,
  setSaveButtonDisabled: PropTypes.func,
  channels: PropTypes.array,
  createLogItem: PropTypes.func,
  updateLogItem: PropTypes.func,
  logItem: PropTypes.object,
  devices: PropTypes.array,
  actions: PropTypes.array,
  location: PropTypes.object,
  fetchDevicesOfLocation: PropTypes.func,
  isAllowedToEdit: PropTypes.bool
};

export default forwardRef(LogItemModalDisplay);
