import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_LOGBOOKACTION = 'SET_LOGBOOKACTION';
export const setLogbookAction = makeActionCreator(
  SET_LOGBOOKACTION,
  'logbookAction'
);

export const FETCH_LOGBOOKACTIONS = 'FETCH_LOGBOOKACTIONS';
export const fetchLogbookActions = makeActionCreator(FETCH_LOGBOOKACTIONS);

export const UPSERT_LOGBOOKACTION_DETAILS = 'UPSERT_LOGBOOKACTION_DETAILS';
export const upsertLogbookActionDetails = makeActionCreator(
  UPSERT_LOGBOOKACTION_DETAILS,
  'logbookActionDetails'
);

export const SET_LOGBOOKACTIONS_LIST = 'SET_LOGBOOKACTIONS_LIST ';
export const setLogbookActionsList = makeActionCreator(
  SET_LOGBOOKACTIONS_LIST,
  'logbookActionsList'
);

export const CREATE_LOGBOOKACTION = 'CREATE_LOGBOOKACTION';
export const createLogbookAction = makeActionCreator(CREATE_LOGBOOKACTION);

export const CREATE_LOGBOOKACTION_SUCCEEDED = 'CREATE_LOGBOOKACTION_SUCCEEDED';
export const createLogbookActionSucceeded = makeActionCreator(
  CREATE_LOGBOOKACTION_SUCCEEDED,
  'logbookAction'
);

export const EDIT_LOGBOOKACTION = 'EDIT_LOGBOOKACTION';
export const editLogbookAction = makeActionCreator(EDIT_LOGBOOKACTION);

export const EDIT_LOGBOOKACTION_SUCCEEDED = 'EDIT_LOGBOOKACTION_SUCCEEDED';
export const editLogbookActionSucceeded = makeActionCreator(
  EDIT_LOGBOOKACTION_SUCCEEDED,
  'logbookAction'
);

export const DELETE_LOGBOOKACTION = 'DELETE_LOGBOOKACTION';
export const deleteLogbookAction = makeActionCreator(
  DELETE_LOGBOOKACTION,
  'deleteObject'
);

export const DELETE_LOGBOOKACTION_SUCCEEDED = 'DELETE_LOGBOOKACTION_SUCCEEDED ';
export const deleteLogbookActionSucceeded = makeActionCreator(
  DELETE_LOGBOOKACTION_SUCCEEDED,
  'logbookAction'
);
