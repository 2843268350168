import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../page/Content';
import { LocationsTableContainer } from './locationstable/LocationsTableContainer';

export default function LocationsDisplay({ fetchLocationsList }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchLocationsList();
  }, []);

  const content = (
    <>
      <LocationsTableContainer></LocationsTableContainer>
    </>
  );
  return (
    <Content
      title={t('content.locations.title')}
      secondTitle={t('navbar.manage')}
      content={content}
    ></Content>
  );
}
