import { takeEvery, select, put } from 'redux-saga/effects';
import { FETCH_LOCATION, setLocation } from './locationActions';
import { getFsLocationBySensightId } from 'apis/FieldServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { getLocationBySensightId } from 'apis/MeasurementServiceApi';
export function* fetchLocationSaga() {
  yield takeEvery(FETCH_LOCATION, execute);
}

function* execute(action) {
  try {
    const user = yield select(currentUserSelector);
    if (action && action.locationId) {
      let locations = (yield getFsLocationBySensightId(user, action.locationId))
        .data;
      if (!locations || locations.length == 0) {
        throw 'No locations found for sensightId ' + action.locationId;
      }
      let location = locations[0];
      location.sensightId = action.locationId;
      let measurementServiceLocation = (yield getLocationBySensightId(
        user,
        location.sensightId
      )).data[0];

      location.measurementServiceLocation = measurementServiceLocation;
      yield put(setLocation(location));
    }
  } catch (e) {
    yield put(
      createSnackbar(t('content.locations.fetchlocationerror'), 'error')
    );
    console.log(e);
  }
}
