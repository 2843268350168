import { connect } from 'react-redux';
import { DevicePlacementsTableDisplay } from './DevicePlacementsTableDisplay.jsx';
import {
  setDevicePlacement,
  setDevicePlacementStartDateTime,
  setDevicePlacementEndDateTime,
  deleteDevicePlacement,
  fetchDevicePlacementsOfLocation,
  setAvailableDevicesList
} from './redux/devicePlacementsActions';
import { devicePlacementsSelector } from './redux/devicePlacementsSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    locationId: ownProps.locationId,
    devicePlacements: devicePlacementsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteDevicePlacement(row) {
    dispatch(deleteDevicePlacement(row));
  },
  setDevicePlacement(row) {
    dispatch(setDevicePlacement(row));
  },
  fetchDevicePlacementsOfLocation(locationId) {
    dispatch(fetchDevicePlacementsOfLocation(locationId));
  },
  setDevicePlacementStartDateTime(devicePlacementStartDateTime) {
    dispatch(setDevicePlacementStartDateTime(devicePlacementStartDateTime));
  },
  setDevicePlacementEndDateTime(devicePlacementEndDateTime) {
    dispatch(setDevicePlacementEndDateTime(devicePlacementEndDateTime));
  },
  setAvailableDevicesList(availableDevices) {
    dispatch(setAvailableDevicesList(availableDevices));
  }
});

export const DevicePlacementsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicePlacementsTableDisplay);
