import { connect } from 'react-redux';
import TenantsTableDisplay from './TenantsTableDisplay.jsx';
import { setTenant, deleteTenant } from '../redux/tenantsActions';

const mapStateToProps = (state, ownProps) => {
  return {
    tenants: ownProps.tenants
  };
};

const mapDispatchToProps = dispatch => ({
  deleteTenant(row) {
    dispatch(deleteTenant(row));
  },
  setTenant(row) {
    dispatch(setTenant(row));
  }
});

export const TenantsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantsTableDisplay);
