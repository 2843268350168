import { connect } from 'react-redux';
import CreateLocationDisplay from './CreateLocationDisplay.jsx';
import { fetchOrganizationsList } from 'components/content/organizations/redux/organizationsListActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import {
  selectedOrganizationSelector,
  autocompleteSelectedContactPersonSelector,
  onlineSwitchValueSelector,
  hideSwitchValueSelector,
  autocompleteSelectedServiceContactPersonsSelector,
  locationDetailsSelector
} from 'components/content/location/manageLocation/redux/manageLocationSelectors.js';
import {
  setAutocompleteSelectedOrganizations,
  setAutocompleteSelectedServiceContactPersons,
  setAutocompleteSelectedContactPersons,
  setOnlineSwitchValue,
  setHideSwitchValue,
  fetchLocationDetails
} from 'components/content/location/manageLocation/redux/manageLocationActions.js';
import {
  createProjectDetails,
  createLocationDetails
} from './redux/createLocationActions.js';

const mapStateToProps = state => {
  const viewModel = locationDetailsSelector(state);

  return {
    viewModel: viewModel,
    currentUser: currentUserSelector(state),
    selectedOrganizations: findOrganizationsBySensightId(
      viewModel.organizations,
      selectedOrganizationSelector(state)
    ),
    selectedServiceContactPersons: findLocationServiceContacts(
      viewModel.contacts,
      autocompleteSelectedServiceContactPersonsSelector(state)
    ),
    selectedContactPerson: autocompleteSelectedContactPersonSelector(state),
    onlineSwitchValue: onlineSwitchValueSelector(state),
    hideSwitchValue: hideSwitchValueSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLocationDetails() {
    dispatch(fetchLocationDetails(null));
  },
  fetchOrganizationsList() {
    dispatch(fetchOrganizationsList());
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  createLocationDetails(locationDetails) {
    dispatch(createLocationDetails(locationDetails));
  },
  createProjectDetails(projectDetails) {
    dispatch(createProjectDetails(projectDetails));
  },
  setAutocompleteSelectedOrganizations(selectedOrganizations) {
    dispatch(setAutocompleteSelectedOrganizations(selectedOrganizations));
  },
  setAutocompleteServiceContactPersons(selectedServiceContactPersons) {
    dispatch(
      setAutocompleteSelectedServiceContactPersons(
        selectedServiceContactPersons
      )
    );
  },
  setAutocompleteContactPerson(selectedContactPerson) {
    dispatch(setAutocompleteSelectedContactPersons(selectedContactPerson));
  },
  setOnlineSwitchValue(switchValue) {
    dispatch(setOnlineSwitchValue(switchValue));
  },
  setHideSwitchValue(switchValue) {
    dispatch(setHideSwitchValue(switchValue));
  }
});

function findOrganizationsBySensightId(organizations, currentOrganizations) {
  const result = currentOrganizations.map(currentOrganizationId =>
    organizations.find(org => org.sensightId === currentOrganizationId)
  );
  return result;
}

function findLocationServiceContacts(contacts, serviceContactIds) {
  if (!serviceContactIds) {
    return [];
  }

  const result = serviceContactIds.map(contactId =>
    contacts.find(con => con.sensightId === contactId)
  );

  return result;
}

export const CreateLocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateLocationDisplay);
