import { connect } from 'react-redux';
import LogbookActionsTableDisplay from './LogbookActionsTableDisplay.jsx';
import {
  setLogbookAction,
  deleteLogbookAction
} from '../redux/logbookActionsActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    logbookActions: ownProps.logbookActions,
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteLogbookAction(row) {
    dispatch(deleteLogbookAction(row));
  },
  setLogbookAction(row) {
    dispatch(setLogbookAction(row));
  }
});

export const LogbookActionsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogbookActionsTableDisplay);
