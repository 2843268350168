import { connect } from 'react-redux';
import ManageTenantDisplay from './ManageTenantDisplay.jsx';
import { tenantSelector } from '../redux/tenantsSelectors.js';
import { themesSelector } from '../redux/themesSelectors.js';
import { editTenant } from '../redux/tenantsActions.js';
import { fetchThemes } from '../redux/themesActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = state => {
  return {
    viewModel: tenantSelector(state),
    themes: themesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  upsertTenantDetails(tenantDetails) {
    dispatch(editTenant(tenantDetails));
  },
  fetchThemes() {
    dispatch(fetchThemes());
  }
});

export const EditTenantContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageTenantDisplay);
