import { connect } from 'react-redux';
import { DisplayChannelOptionsDialogDisplay } from './DisplayChannelOptionsDialogDisplay.jsx';
import { setChartDisplayOptions } from '../../redux/toolbarActions';
import { chartRenderedSelector } from '../../../displayChartComponent/redux/displayChartSelectors.js';
import { displayChart } from '../../../displayChartComponent/redux/displayChartActions.js';
import { chartDisplayOptionsSelector } from '../../redux/toolbarSelectors.js';

const mapStateToProps = (state, ownProps) => {
  const chartRendered = chartRenderedSelector(state);
  const chartDisplayOptions = chartDisplayOptionsSelector(state);
  return {
    dialogOpen: ownProps.open,
    setDialogOpen: ownProps.setDialogOpen,
    chartRendered: chartRendered,
    chartDisplayOptions: chartDisplayOptions
  };
};

const mapDispatchToProps = dispatch => ({
  setChartDisplayOptions(value) {
    dispatch(setChartDisplayOptions(value));
  },
  displayChart() {
    dispatch(displayChart());
  }
});

export const DisplayChannelOptionsDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayChannelOptionsDialogDisplay);
