import { connect } from 'react-redux';
import LogItemModalDisplay from './LogItemModalDisplay.jsx';
import { createLogItem } from './redux/createLogItemActions';
import { editLogItemOfLocation } from './redux/editLogItemActions';
import { t } from 'i18next';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { channelsListOfLocationSelector } from '../../manageLocation/channels/redux/channelsListSelectors';
import { devicesListOfLocationSelector } from '../../manageLocation/devices/redux/devicesListSelectors';
import { actionsListSelector } from 'components/content/actions/redux/actionsListSelectors';
import { locationSelector } from '../../redux/locationSelectors';
import { fetchDevicesOfLocation } from '../../manageLocation/devices/redux/devicesListActions.js';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: currentUserSelector(state),
    channels: channelsListOfLocationSelector(state),
    logItem: ownProps.logItem,
    actions: actionsListSelector(state).sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    ),
    devices: devicesListOfLocationSelector(state),
    location: locationSelector(state),
    isAllowedToEdit: ownProps.isAllowedToEdit
  };
};

const mapDispatchToProps = dispatch => ({
  createLogItem(logitem) {
    dispatch(createLogItem(logitem));
  },
  updateLogItem(logitem) {
    dispatch(editLogItemOfLocation(logitem));
  },
  fetchDevicesOfLocation(locationId) {
    dispatch(fetchDevicesOfLocation(locationId));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.actionId) {
    errors.logitemType = t('general.validation.required');
  }
  if (!values.deviceId) {
    errors.logitemType = t('general.validation.required');
  }
  if (values.logItemStateKey === undefined || values.logItemStateKey === null) {
    errors.logitemType = t('general.validation.required');
  }
  return errors;
};

export const LogItemModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(LogItemModalDisplay);
