import { connect } from 'react-redux';
import LogbookActionsDisplay from './LogbookActionsDisplay.jsx';
import { fetchLogbookActions } from '../redux/logbookActionsActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { logbookActionsSelector } from '../redux/logbookActionsSelectors';

const mapStateToProps = state => {
  return {
    logbookActions: logbookActionsSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLogbookActions() {
    dispatch(fetchLogbookActions());
  }
});

export const LogbookActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogbookActionsDisplay);
