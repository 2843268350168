import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from '../../imd-components/datatable/ImdDataTable';

import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { Column } from 'devextreme-react/tree-list';
import ImdPopup from 'components/imd-components/ImdPopup';
import { LocationTypeModalContainer } from './locationtypesmodal/LocationTypeModalContainer';

export default function OrganizationsTable({
  locationTypes,
  deleteLocationType,
  fetchLocationTypesList
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  React.useEffect(() => {
    fetchLocationTypesList();
  }, []);

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    openEdit();
  };

  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteLocationType(selectedRow);
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [locationTypes]);

  const columns = (
    <>
      <Column
        dataField={'name'}
        caption={t('content.locationstypetable.name')}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.locationstypetable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <LocationTypeModalContainer
            ref={formRef}
            locationType={selectedRow}
          ></LocationTypeModalContainer>
        }
        formRef={formRef}
        saveButton={[]}
        dialogTitle={t('content.locationtypemodal.editlocationtype.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <LocationTypeModalContainer
            ref={formRef}
          ></LocationTypeModalContainer>
        }
        formRef={formRef}
        saveButton={[]}
        dialogTitle={t('content.locationtypemodal.createlocationtype.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.organizationstable.deletepopup.title')}
        content={t('content.organizationstable.deletepopup.content', {
          organization: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        addPermission="organizations:add"
        deletePermission="organizations:delete"
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.locationtypes.title')}
        data={locationTypes}
        columns={columns}
        stateStorageKey="location-type-table-display"
      />
    </>
  );
}
