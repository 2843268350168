import { connect } from 'react-redux';
import AlertsDisplay from './AlertsDisplay.jsx';
import { fetchMyAlerts } from '../redux/alertsActions';
import { alertsSelector } from '../redux/alertsSelectors';
import { fetchContactsList } from 'components/content/contacts/redux/contactsListActions';

const mapStateToProps = state => {
  return {
    alerts: alertsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAlerts() {
    dispatch(fetchMyAlerts());
  },
  fetchContactsList() {
    dispatch(fetchContactsList());
  }
});

export const MyAlertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsDisplay);
