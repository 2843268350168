import {
  UPSERT_LOGBOOKACTION_DETAILS,
  FETCH_LOGBOOKACTIONS,
  CREATE_LOGBOOKACTION,
  EDIT_LOGBOOKACTION,
  DELETE_LOGBOOKACTION,
  createLogbookActionSucceeded,
  editLogbookActionSucceeded,
  deleteLogbookActionSucceeded,
  setLogbookActionsList
} from './logbookActionsActions';
import {
  takeEvery,
  select,
  put,
  take,
  actionChannel
} from 'redux-saga/effects';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  getActions,
  createAction,
  editAction,
  deleteAction
} from 'apis/FieldServiceApi';

export function* fetchLogbookActionsListSaga() {
  yield takeEvery(FETCH_LOGBOOKACTIONS, executeList);
}

function* executeList() {
  try {
    const user = yield select(currentUserSelector);
    var fieldServiceLogbookActions = (yield getActions(user)).data;
    yield put(setLogbookActionsList(fieldServiceLogbookActions));
  } catch (e) {
    yield put(
      createSnackbar(
        t('content.logbookactions.fetchlogbookactionserror'),
        'error'
      )
    );
    console.log(e);
  }
}

export function* createActionSaga() {
  yield takeEvery(CREATE_LOGBOOKACTION, executeCreate);
}

export function* executeCreate() {
  try {
    const logbookActionDetailsChannel = yield actionChannel(
      UPSERT_LOGBOOKACTION_DETAILS
    );
    const formValues = yield take(logbookActionDetailsChannel);
    const user = yield select(currentUserSelector);
    const createdLogbookAction = (yield createAction(user, {
      name: formValues.logbookActionDetails.name
    })).data;
    yield put(createSnackbar(t('content.createlogbookaction.snackbar.text')));
    yield put(createLogbookActionSucceeded(createdLogbookAction));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* editActionSaga() {
  yield takeEvery(EDIT_LOGBOOKACTION, executeEdit);
}

function* executeEdit() {
  try {
    const logbookActionDetailsChannel = yield actionChannel(
      UPSERT_LOGBOOKACTION_DETAILS
    );
    const formValues = yield take(logbookActionDetailsChannel);
    const user = yield select(currentUserSelector);
    const actionModel = {
      id: formValues.logbookActionDetails.id,
      name: formValues.logbookActionDetails.name
    };
    yield editAction(user, actionModel);
    yield put(createSnackbar(t('content.editlogbookaction.snackbar.text')));
    yield put(editLogbookActionSucceeded(actionModel));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* deleteActionSaga() {
  yield takeEvery(DELETE_LOGBOOKACTION, executeDelete);
}

function* executeDelete(action) {
  const logbookAction = action.deleteObject.selectedRow;
  const user = yield select(currentUserSelector);

  try {
    yield deleteAction(user, logbookAction.id);
    yield put(createSnackbar(t('content.deletelogbookaction.snackbar.text')));
    yield put(deleteLogbookActionSucceeded(logbookAction));
  } catch (exception) {
    console.error(exception);
  }
}
