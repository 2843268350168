import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export function SearchableCardView({
  title,
  selectedContent,
  content,
  requesting,
  clickAction,
  deleteAction,
  filterContent,
  locked,
  lockedMessage,
  displayKey,
  displayIcon
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const createUnselectedItems = items => {
    const unselectedItems = [];
    const unselectedKeys = [];
    for (let index in items) {
      const key = items[index];
      const uniqueKey = `unselected${key.id}`;
      const content = (
        <div key={uniqueKey}>
          <ListItem
            className={classes.listItem}
            key={uniqueKey}
            onClick={() => clickAction(key)}
          >
            {displayIcon ? (
              key['visible'] === true ? (
                <VisibilityIcon className={classes.icon} />
              ) : (
                <VisibilityOffIcon className={classes.icon} />
              )
            ) : null}
            <ListItemText
              classes={{
                primary: classes.listItemText
              }}
              primary={key[displayKey]}
            />
          </ListItem>
          <Divider />
        </div>
      );
      if (!unselectedKeys.some(us => us == uniqueKey)) {
        unselectedItems.push(content);
        unselectedKeys.push(uniqueKey);
      }
    }
    return unselectedItems;
  };

  const renderList = () => {
    if (locked) {
      return <p>{lockedMessage}</p>;
    }
    if (requesting) {
      return <p>{t('content.visualize.dialog.searchcard.fetching.text')}</p>;
    }
    if (content.length + selectedContent.length == 0) {
      return <p>{t('content.visualize.dialog.searchcard.noresults.text')}</p>;
    }

    const selectedItems = selectedContent.map(key => {
      const uniqueKey = 'selected' + key.id;
      return (
        <div key={uniqueKey}>
          <ListItem
            className={classes.listItemSelected}
            key={uniqueKey}
            onClick={() => deleteAction(key)}
          >
            <ListItemText
              classes={{
                primary: classes.selectedListItemText
              }}
              primary={key[displayKey]}
            />
          </ListItem>
          <Divider />
        </div>
      );
    });
    const unselectedItems =
      content?.length > 0 ? createUnselectedItems(content) : [];
    return (
      <>
        {selectedItems}
        {unselectedItems}
      </>
    );
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={title}
        classes={{ root: classes.root }}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <>
          <TextField
            classes={{ root: classes.search }}
            className="input-with-icon-textfield"
            placeholder={t(
              'content.visualize.dialog.searchcard.searchbar.text'
            )}
            fullWidth
            onChange={event => filterContent(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            variant="standard"
          />
          <div className={classes.scrollableContent}>{renderList()}</div>
        </>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    paddingRight: '4px'
  },
  root: {
    backgroundColor: '#f0f0f0',
    color: '#000000',
    fontSize: '1rem !important',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  search: {
    fontSize: '0.9rem'
  },
  card: {
    minWidth: 375,
    marginBottom: '16px',
    maxHeight: '350px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  },
  scrollableContent: {
    marginTop: '8px',
    height: '300px',
    maxHeight: '224px',
    overflow: 'auto'
  },
  listItem: {
    paddingTop: '4px',
    paddingBottom: '4px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText
  },
  listItemSelected: {
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: '#F4F4F4',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText
  },
  listItemText: {
    fontSize: '0.9rem '
  },
  selectedListItemText: {
    fontSize: '0.9rem ',
    fontWeight: 'bold'
  }
}));
