import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';

function LogItemModalDisplay(
  {
    handleValidate,
    setSaveButtonDisabled,
    createLocationType,
    editLocationType,
    locationType
  },
  ref
) {
  const { t } = useTranslation();

  useEffect(() => {
    setSaveButtonDisabled(false);
  }, []);

  const handleSubmit = async values => {
    if (!locationType) {
      createLocationType(values);
    } else {
      editLocationType(values);
    }
  };

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (dirty && isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    return (
      <Form onSubmit={submitForm}>
        <Field
          name="name"
          type="text"
          label={t('content.locationtypemodal.input.name.text')}
          fullWidth
          component={TextField}
          required
          variant="standard"
        />
      </Form>
    );
  };

  const formRef = useRef();

  const inputFields = (
    <>
      <FormikWithRef
        ref={formRef}
        validateOnMount={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          id: !locationType ? '' : locationType.id,
          name: !locationType ? '' : locationType.name
        }}
        validate={values => handleValidate(values)}
        onSubmit={values => handleSubmit(values)}
        enableReinitalize={true}
      >
        {form}
      </FormikWithRef>
    </>
  );
  useImperativeHandle(ref, () => formRef);
  return inputFields;
}

export default forwardRef(LogItemModalDisplay);
