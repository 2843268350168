import { createSelector } from 'reselect';

export const displayChartSelector = createSelector(
  state => state.get('chartData'),
  chartData => chartData.toJS()
);

export const chartRenderedSelector = createSelector(
  state => state.get('chartRendered'),
  chartRendered => chartRendered
);

export const channelDataSelector = createSelector(
  state => state.get('channelData'),
  channelData => channelData.toJS()
);

export const logItemDataSelector = createSelector(
  state => state.get('logItemData'),
  logItemData => logItemData.toJS()
);
