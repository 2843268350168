import { createReducer } from 'utils/createReducer';
import {
  SET_LOGBOOKACTION,
  SET_LOGBOOKACTIONS_LIST,
  CREATE_LOGBOOKACTION_SUCCEEDED,
  EDIT_LOGBOOKACTION_SUCCEEDED,
  DELETE_LOGBOOKACTION_SUCCEEDED
} from './logbookActionsActions';
import { fromJS } from 'immutable';

export const logbookActionsList = createReducer(null, {
  [SET_LOGBOOKACTIONS_LIST](state, { logbookActionsList }) {
    return fromJS(logbookActionsList);
  },
  [CREATE_LOGBOOKACTION_SUCCEEDED](state, { logbookAction }) {
    return state.push(fromJS(logbookAction));
  },
  [EDIT_LOGBOOKACTION_SUCCEEDED](state, { logbookAction }) {
    let logbookActionsList = state.toJS();
    var foundLogbookActionIndex = logbookActionsList.findIndex(
      logbookA => logbookAction.id === logbookA.id
    );
    logbookActionsList[foundLogbookActionIndex] = logbookAction;
    return fromJS(logbookActionsList);
  },
  [DELETE_LOGBOOKACTION_SUCCEEDED](state, { logbookAction }) {
    return state.filter(con => con.get('id') !== logbookAction.id);
  }
});

export const logbookAction = createReducer(null, {
  [SET_LOGBOOKACTION](state, { logbookAction }) {
    return fromJS(logbookAction);
  }
});
