import { connect } from 'react-redux';
import { adaptV4Theme } from '@mui/material/styles';
import ManageThemeDisplay from './ManageThemeDisplay.jsx';
import { createTheme } from '../redux/themesActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  upsertThemeDetails(themeDetails) {
    dispatch(createTheme(adaptV4Theme(themeDetails)));
  }
});

export const CreateThemeContainer = connect(null, mapDispatchToProps, null, {
  forwardRef: true
})(ManageThemeDisplay);
