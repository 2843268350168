import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_DATASCIENCE_REPORTS = 'FETCH_DATASCIENCE_REPORTS';
export const fetchDataScienceReports = makeActionCreator(
  FETCH_DATASCIENCE_REPORTS
);

export const SET_DATASCIENCE_REPORTS = 'SET_DATASCIENCE_REPORTS';
export const setDataScienceReports = makeActionCreator(
  SET_DATASCIENCE_REPORTS,
  'dataScienceReports'
);
