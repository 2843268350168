import { createReducer } from 'utils/createReducer';
import { fromJS } from 'immutable';
import {
  SET_VISUALIZE_FROM_TIME,
  SET_VISUALIZE_UNTIL_TIME,
  SET_CURRENT_VISUALIZE_PERIOD,
  SET_CHART_DISPLAY_OPTIONS
} from './toolbarActions';

export const visualizeFromTime = createReducer(null, {
  [SET_VISUALIZE_FROM_TIME](state, { time }) {
    return fromJS(time);
  }
});

export const visualizeUntilTime = createReducer(null, {
  [SET_VISUALIZE_UNTIL_TIME](state, { time }) {
    return fromJS(time);
  }
});

export const visualizePeriod = createReducer(null, {
  [SET_CURRENT_VISUALIZE_PERIOD](state, { period }) {
    return fromJS(period);
  }
});

export const chartDisplayOptions = createReducer(null, {
  [SET_CHART_DISPLAY_OPTIONS](state, { displayOptions }) {
    return fromJS(displayOptions);
  }
});
