import {
  FETCH_DEVICES,
  FETCH_AVAILABLESIMCARDS,
  UPSERT_TECHNICAL_DEVICE_DETAILS,
  UPSERT_TECHNICAL_DEVICE_DESCRIPTION,
  UPSERT_FUNCTIONAL_DEVICE_DETAILS,
  CREATE_DEVICE,
  EDIT_DEVICE,
  DELETE_DEVICE,
  createDeviceSucceeded,
  editDeviceSucceeded,
  deleteDeviceSucceeded,
  setDevicesList,
  setAvailableSimCardsList
} from './devicesActions';
import {
  takeEvery,
  select,
  put,
  all,
  take,
  actionChannel
} from 'redux-saga/effects';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { deviceSelector } from './devicesSelectors';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  getAllDevices as getAllMeasurementDevices,
  editDevice as editFunctionalDevice,
  deleteDevice as deleteFunctionalDevice
} from 'apis/MeasurementServiceApi';
import {
  getAllDevices,
  getAvailableSimCards,
  createDevice,
  editDevice as editTechnicalDevice,
  deleteDevice as deleteTechnicalDevice
} from 'apis/FieldServiceApi';
import { sortByIpAddressThenICCID } from 'utils/simcardSorter';

export function* fetchDevicesListSaga() {
  yield takeEvery(FETCH_DEVICES, executeList);
}

function* executeList() {
  try {
    const user = yield select(currentUserSelector);
    var fieldServiceDevices = (yield getAllDevices(user)).data;
    var measurementServiceDevices = (yield getAllMeasurementDevices(user)).data;
    let devicesList = [];

    const now = new Date();

    if (fieldServiceDevices.length > 0) {
      if (measurementServiceDevices.length > 0) {
        devicesList = fieldServiceDevices.map(fieldDevice => {
          // Check if there is a current devicePlacement
          fieldDevice.latestDevicePlacement =
            fieldDevice?.devicePlacements?.find(
              devicePlacement =>
                (now >= new Date(devicePlacement.startDateTime) ||
                  !devicePlacement.startDateTime) &&
                (now <= new Date(devicePlacement.endDateTime) ||
                  !devicePlacement.endDateTime)
            );

          if (!fieldDevice.latestDevicePlacement) {
            // Find last active devicePlacement
            fieldDevice.latestDevicePlacement = fieldDevice?.devicePlacements
              ?.sort((a, b) => {
                if (!a.endDateTime) {
                  return -1;
                }
                if (!b.endDateTime) {
                  return 1;
                }
                if (new Date(a.endDateTime) > new Date(b.endDateTime)) {
                  return -1;
                }
                if (new Date(a.endDateTime) < new Date(b.endDateTime)) {
                  return 1;
                }
                return 0;
              })
              .find(devicePlacement => devicePlacement);
          }

          const measurementDevice = measurementServiceDevices.find(
            measureDev => measureDev.sensightId === fieldDevice.sensightId
          );
          if (measurementDevice) {
            measurementDevice.measurementId = measurementDevice.id;
            var device = { ...measurementDevice, ...fieldDevice };
            device.sensightId = fieldDevice.sensightId;
            device.fieldId = fieldDevice.id;
            return device;
          }
          fieldDevice.fieldId = fieldDevice.id;

          return fieldDevice;
        });
      } else {
        devicesList = fieldServiceDevices.map(fieldDevice => {
          fieldDevice.fieldId = fieldDevice.id;
          return fieldDevice;
        });
      }
    }
    yield put(setDevicesList(devicesList));
  } catch (e) {
    yield put(createSnackbar(t('content.devices.fetchdeviceserror'), 'error'));
    console.log(e);
  }
}

export function* fetchAvailableSimCardsListSaga() {
  yield takeEvery(FETCH_AVAILABLESIMCARDS, executeAvailableSimCardsList);
}

function* executeAvailableSimCardsList() {
  try {
    const user = yield select(currentUserSelector);
    const device = yield select(deviceSelector);
    var availableSimCards = !device
      ? (yield getAvailableSimCards(user, null)).data
      : (yield getAvailableSimCards(user, device.id)).data;
    availableSimCards.sort((a, b) => sortByIpAddressThenICCID(a, b));
    yield put(setAvailableSimCardsList(availableSimCards));
  } catch (e) {
    yield put(
      createSnackbar(t('content.devices.fetchavailablesimcardserror'), 'error')
    );
    console.log(e);
  }
}

export function* createDeviceSaga() {
  yield takeEvery(CREATE_DEVICE, executeCreate);
}

export function* executeCreate() {
  const technicalDeviceDetailsChannel = yield actionChannel(
    UPSERT_TECHNICAL_DEVICE_DETAILS
  );
  const technicalDeviceDescriptionChannel = yield actionChannel(
    UPSERT_TECHNICAL_DEVICE_DESCRIPTION
  );
  const functionalDeviceDetailsChannel = yield actionChannel(
    UPSERT_FUNCTIONAL_DEVICE_DETAILS
  );

  const formValues = yield all([
    take(technicalDeviceDetailsChannel),
    take(technicalDeviceDescriptionChannel),
    take(functionalDeviceDetailsChannel)
  ]);

  const technicalDeviceDetails = formValues[0].upsertTechnicalDeviceDetails;
  const technicalDeviceDescription =
    formValues[1].upsertTechnicalDeviceDescription;
  const functionalDeviceDetails = formValues[2].upsertFunctionalDeviceDetails;

  const fieldServiceRequest = prepareFieldServiceCreateRequest(
    technicalDeviceDetails,
    technicalDeviceDescription,
    functionalDeviceDetails
  );
  const user = yield select(currentUserSelector);
  const createdDevice = (yield createDevice(user, fieldServiceRequest)).data;
  technicalDeviceDetails.sensightId = createdDevice.sensightId;
  createdDevice.fieldId = createdDevice.id;
  createdDevice.online = functionalDeviceDetails.online;
  createdDevice.visible = functionalDeviceDetails.visible;

  yield put(createSnackbar(t('content.devicedashboard.form.success')));
  yield put(createDeviceSucceeded(createdDevice));
}

function prepareFieldServiceCreateRequest(
  technicalDeviceDetails,
  technicalDeviceDescription,
  functionalDeviceDetails
) {
  return {
    name: technicalDeviceDetails.name,
    description: technicalDeviceDescription.description,
    phoneNumber: technicalDeviceDetails.phoneNumber,
    manufacturer: technicalDeviceDetails.manufacturer,
    serialNumber: technicalDeviceDetails.serialNumber,
    deviceType: technicalDeviceDetails.deviceType,
    simCardId: technicalDeviceDetails.simCardId,
    ipAddress: technicalDeviceDetails.ipAddress,
    portNr: technicalDeviceDetails.portNr,
    externalLink: technicalDeviceDetails.externalLink,
    visible: functionalDeviceDetails.visible,
    online: functionalDeviceDetails.online
  };
}

export function* editDeviceSaga() {
  yield takeEvery(EDIT_DEVICE, executeEdit);
}

function* executeEdit() {
  const technicalDeviceDetailsChannel = yield actionChannel(
    UPSERT_TECHNICAL_DEVICE_DETAILS
  );
  const technicalDeviceDescriptionChannel = yield actionChannel(
    UPSERT_TECHNICAL_DEVICE_DESCRIPTION
  );
  const functionalDeviceDetailsChannel = yield actionChannel(
    UPSERT_FUNCTIONAL_DEVICE_DETAILS
  );

  const formValues = yield all([
    take(technicalDeviceDetailsChannel),
    take(technicalDeviceDescriptionChannel),
    take(functionalDeviceDetailsChannel)
  ]);

  const technicalDeviceDetails = formValues[0].upsertTechnicalDeviceDetails;
  const technicalDeviceDescription =
    formValues[1].upsertTechnicalDeviceDescription;
  const functionalDeviceDetails = formValues[2].upsertFunctionalDeviceDetails;

  try {
    const user = yield select(currentUserSelector);

    if (technicalDeviceDetails.fieldId) {
      const fieldServiceRequest = prepareFieldServiceUpdateRequest(
        technicalDeviceDetails,
        technicalDeviceDescription
      );
      yield editTechnicalDevice(user, fieldServiceRequest);
    }

    const measurementServiceRequest = prepareMeasurementServiceUpdateRequest(
      functionalDeviceDetails
    );
    yield editFunctionalDevice(user, measurementServiceRequest);

    const device = {
      ...technicalDeviceDetails,
      ...technicalDeviceDescription,
      ...functionalDeviceDetails
    };
    device.sensightId =
      functionalDeviceDetails.sensightId ?? technicalDeviceDetails.sensightId;
    // Without this, after editing the device, the simcard would always be empty since the
    // API call would be with the wrong ID.
    device.id = device.fieldId;
    device.online = functionalDeviceDetails.online;
    device.visible = functionalDeviceDetails.visible;

    yield put(createSnackbar(t('content.editchannel.snackbar.text')));
    yield put(editDeviceSucceeded(device));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

function prepareFieldServiceUpdateRequest(
  technicalDeviceDetails,
  technicalDeviceDescription
) {
  return {
    id: technicalDeviceDetails.fieldId,
    name: technicalDeviceDetails.name,
    description: technicalDeviceDescription.description,
    phoneNumber: technicalDeviceDetails.phoneNumber,
    manufacturer: technicalDeviceDetails.manufacturer,
    serialNumber: technicalDeviceDetails.serialNumber,
    deviceType: technicalDeviceDetails.deviceType,
    simCardId: technicalDeviceDetails.simCardId,
    ipAddress: technicalDeviceDetails.ipAddress,
    portNr: technicalDeviceDetails.portNr,
    externalLink: technicalDeviceDetails.externalLink,
    sensightId: technicalDeviceDetails.sensightId
  };
}

function prepareMeasurementServiceUpdateRequest(functionalDeviceDetails) {
  return {
    sensightId: functionalDeviceDetails.sensightId,
    visible: functionalDeviceDetails.visible,
    online: functionalDeviceDetails.online
  };
}

export function* deleteDeviceSaga() {
  yield takeEvery(DELETE_DEVICE, executeDelete);
}

function* executeDelete(action) {
  const device = action.deleteObject.selectedRow;
  const shouldDeleteFunctionalDevice =
    action.deleteObject.shouldDeleteFunctionalDevice;
  const user = yield select(currentUserSelector);

  try {
    yield deleteTechnicalDevice(user, device.fieldId);
    if (shouldDeleteFunctionalDevice && device.externalDeviceId) {
      yield deleteFunctionalDevice(user, device.sensightId);
    }

    yield put(createSnackbar(t('content.deletedevice.snackbar.text')));

    yield put(deleteDeviceSucceeded(device));
  } catch (exception) {
    if (
      exception?.response?.status == 409 &&
      exception.response.data == 'This device is still linked to a channel.'
    ) {
      yield put(
        createSnackbar(t('content.devices.has.device.placement'), 'error')
      );
    } else {
      console.error(exception);
    }
  }
}
