import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import MuiTextField from '@mui/material/TextField';

export const SubscriptionDetailsForm = ({
  subscriptionDetailsFormRef,
  upsertSubscriptionDetails,
  setSubscriptionDetailsFormValid,
  availableSubscribers,
  id,
  subscribers
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [localSelectedSubscribers, setLocalSelectedSubscribers] =
    React.useState([]);

  React.useEffect(() => {
    if (subscribers.length > 0 && subscribers[0].alertId === id) {
      let tempSubscribersList = [];
      subscribers.forEach(selectedSub => {
        const foundSub = availableSubscribers.find(
          availableSub =>
            availableSub.sensightId === selectedSub.sensightContactId
        );
        if (foundSub) {
          tempSubscribersList.push(foundSub);
        }
        setLocalSelectedSubscribers(tempSubscribersList);
      });
    }
  }, [subscribers]);

  return (
    <FormikWithRef
      ref={subscriptionDetailsFormRef}
      initialValues={{
        id: id ?? null,
        subscribers: []
      }}
      enableReinitialize={true}
      validateOnBlur={true}
      onSubmit={values => {
        values.previousSubscribers = subscribers;
        values.subscribers = localSelectedSubscribers.map(
          selectedSub => selectedSub.sensightId
        );
        upsertSubscriptionDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setSubscriptionDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form onSubmit={props.submitForm}>
            <Autocomplete
              multiple
              filterSelectedOptions
              options={availableSubscribers}
              getOptionLabel={option => option.email}
              value={localSelectedSubscribers}
              onChange={(event, newValue) => {
                setLocalSelectedSubscribers(newValue);
              }}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  name="subscribers"
                  type="text"
                  label={t('content.alerts.subscribers')}
                  fullWidth
                  className={classes.lastInputField}
                  variant="standard"
                />
              )}
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  }
}));
