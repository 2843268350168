import { createReducer } from 'utils/createReducer';
import {
  SET_MANUALCHANNELS_LIST,
  SET_MANUALINSERTS_LIST,
  SET_MANUALINSERTROWS_LIST,
  CREATE_ROW_SUCCEEDED,
  EDIT_ROW_SUCCEEDED,
  DELETE_ROW_SUCCEEDED,
  SET_CURRENT_MANUALINSERTS_PERIOD
} from './manualInsertActions';
import { fromJS } from 'immutable';

export const manualChannelsList = createReducer(null, {
  [SET_MANUALCHANNELS_LIST](_, { manualChannelsList }) {
    return fromJS(manualChannelsList);
  }
});

export const manualInsertsList = createReducer(null, {
  [SET_MANUALINSERTS_LIST](_, { manualInsertsList }) {
    return fromJS(manualInsertsList);
  }
});

const updateRowInList = (state, row) => {
  let manualInsertRowsList = state.toJS();
  manualInsertRowsList = manualInsertRowsList.filter(r => r.id !== row.id);
  manualInsertRowsList.push(row);
  return manualInsertRowsList;
};

export const manualInsertRowsList = createReducer(null, {
  [SET_MANUALINSERTROWS_LIST](state, { manualInsertRowsList }) {
    return fromJS(manualInsertRowsList);
  },
  [CREATE_ROW_SUCCEEDED](state, { row }) {
    return fromJS(updateRowInList(state, row));
  },
  [EDIT_ROW_SUCCEEDED](state, { row }) {
    return fromJS(updateRowInList(state, row));
  },
  [DELETE_ROW_SUCCEEDED](state, { row }) {
    return fromJS(updateRowInList(state, row));
  }
});

export const manualInsertsPeriod = createReducer(null, {
  [SET_CURRENT_MANUALINSERTS_PERIOD](_, { period }) {
    return fromJS(period);
  }
});
