import { createReducer } from 'utils/createReducer';
import {
  SET_DISPLAYED_DEVICES,
  ADD_DISPLAYED_DEVICE,
  ADD_SELECTED_DEVICE,
  DELETE_SELECTED_DEVICE,
  DELETE_DISPLAYED_DEVICE,
  DELETE_VISUALIZATION_DEVICE,
  SET_VISUALIZATION_DEVICES,
  ADD_VISUALIZATION_DEVICE,
  CLEAR_SELECTED_DEVICES
} from './deviceActions';
import { fromJS } from 'immutable';

export const visualizationDevices = createReducer(null, {
  [SET_VISUALIZATION_DEVICES](state, { devices }) {
    return fromJS(devices);
  },
  [ADD_VISUALIZATION_DEVICE](state, { device }) {
    return state.push(device);
  },
  [DELETE_VISUALIZATION_DEVICE](state, { device }) {
    return state.filter(c => c.get('id') !== device.id);
  }
});

export const selectedDevices = createReducer(null, {
  [ADD_SELECTED_DEVICE](state, { device }) {
    return state.push(device);
  },
  [DELETE_SELECTED_DEVICE](state, { device }) {
    return state.filter(c => c.id !== device.id);
  },
  [CLEAR_SELECTED_DEVICES]() {
    return fromJS([]);
  }
});

export const displayedDevices = createReducer(null, {
  [SET_DISPLAYED_DEVICES](state, { devices }) {
    return fromJS(devices);
  },
  [ADD_DISPLAYED_DEVICE](state, { device }) {
    return state.push(device);
  },
  [DELETE_DISPLAYED_DEVICE](state, { device }) {
    return state.filter(c => c.get('id') !== device.id);
  }
});
