import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from '../../../imd-components/datatable/ImdDataTable';
import { EditContactContainer } from '../editcontact/EditContactContainer';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import ImdPopup from 'components/imd-components/ImdPopup';

import { Column } from 'devextreme-react/tree-list';
import { CreateContactContainer } from '../createcontact/CreateContactContainer';

export default function ContactsTable({
  contacts,
  deleteContact,
  organization
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  const handleChangeSaveButton = enableSaveButton => {
    setSaveButton(enableSaveButton);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    openEdit();
  };

  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteContact(selectedRow);
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [contacts]);

  const columns = (
    <>
      <Column 
        visible={true}
        dataField={'name'} 
        caption={t('content.contactstable.name')} 
      />
      <Column
        visible={!isMobile}
        dataField={'function'}
        caption={t('content.contactstable.function')}
      />
      <Column 
        visible={true}
        dataField={'email'} 
        caption={t('content.contactstable.email')} 
      />
      <Column
        visible={true}
        dataField={'organization.name'}
        caption={t('content.contactstable.organizationname')}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.contactstable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditContactContainer
            ref={formRef}
            organization={organization}
            row={selectedRow}
            setSaveButton={e => handleChangeSaveButton(e)}
          ></EditContactContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.contactstable.editcontact.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <CreateContactContainer
            ref={formRef}
            organization={organization}
          ></CreateContactContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.contactstable.createcontact.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.contactstable.deletepopup.title')}
        content={t('content.contactstable.deletepopup.content', {
          contact: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.users.title')}
        data={contacts}
        columns={columns}
        stateStorageKey="contacts-table-display"
      />
    </>
  );
}
