import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { Get } from 'apis/MeasurementODataApi';
import {
  DISPLAY_CHART,
  IMPORT_VISUALIZE_LINK,
  setChartdata,
  setChartRendered,
  setChannelData,
  displayChart,
  setLogItemData
} from './displayChartActions';

import { setSelectedChannels } from '../../toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions';
import {
  selectedChannelsSelector,
  selectedDevicesSelector
} from '../../toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors';
import {
  visualizeFromTimeSelector,
  visualizeUntilTimeSelector
} from '../../toolbar/redux/toolbarSelectors';
import {
  setVisualizeFromTime,
  setVisualizeUntilTime,
  setCurrentVisualizePeriod,
  setChartDisplayOptions
} from '../../toolbar/redux/toolbarActions';
import { createSnackbar, setIsLoading } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { getAggregationValue } from 'utils/helperMethods';
import { getLogItemsByDeviceSensightId } from 'apis/FieldServiceApi';

export function* displayChartSaga() {
  yield takeEvery(DISPLAY_CHART, execute);
}

export function* importVisualizeLinkSaga() {
  yield takeEvery(IMPORT_VISUALIZE_LINK, executeImportVisualizeLink);
}

function* executeImportVisualizeLink(action) {
  const importObject = action.importObject;
  yield put(setSelectedChannels(importObject.selectedChannels));
  yield put(
    setVisualizeFromTime(new Date(importObject.selectionData.visualizeFromTime))
  );
  yield put(
    setVisualizeUntilTime(
      new Date(importObject.selectionData.visualizeUntilTime)
    )
  );
  yield put(
    setCurrentVisualizePeriod(importObject.selectionData.visualizePeriod)
  );
  yield put(setChartDisplayOptions(importObject.displayOptions));
  yield put(displayChart());
}

export const getLogItems = async function (user, devices, startDate, endDate) {
  let logItems = [];
  for (var device of devices) {
    const logItemsResult = (
      await getLogItemsByDeviceSensightId(
        user,
        device.sensightId,
        startDate,
        endDate
      )
    ).data;
    for (var logItem of logItemsResult) {
      logItems.push(logItem);
    }
  }
  return logItems;
};

function* execute() {
  yield put(setIsLoading(true));
  const currentUser = yield select(currentUserSelector);
  const selectedChannels = yield select(selectedChannelsSelector);
  const fromPeriod = yield select(visualizeFromTimeSelector);
  const toPeriod = yield select(visualizeUntilTimeSelector);
  const selectedDevices = yield select(selectedDevicesSelector);

  if (selectedDevices.length !== 0) {
    const logItems = yield getLogItems(
      currentUser,
      selectedDevices,
      fromPeriod,
      toPeriod
    );
    yield put(setLogItemData(logItems));
  }

  if (selectedChannels.length !== 0) {
    const channelQuery = buildChannelODataQuery(
      selectedChannels,
      fromPeriod,
      toPeriod
    );
    let channelData = yield Get(currentUser, channelQuery);

    if (channelData.length != 0) {
      channelData = enrichChannelData(selectedChannels, channelData);

      yield put(setChannelData(channelData));

      yield* getMeasurements(channelData, fromPeriod, toPeriod, currentUser);
      yield put(setIsLoading(false));
    } else {
      yield put(
        createSnackbar(t('content.visulize.snackbar.no_data_found'), 'info')
      );
      yield put(setIsLoading(false));
    }
  } else {
    yield put(
      createSnackbar(
        t('content.visulize.snackbar.no_channels_selected'),
        'warning'
      )
    );
    yield put(setIsLoading(false));
  }
}

export function enrichChannelData(channels, channelData) {
  for (const channelDataItem of channelData) {
    const matchingChannel = channels.find(
      c => c.sensightId === channelDataItem.sensightId
    );

    const split = matchingChannel.aggregationType.split('-');
    const aggregationValue = split[1] !== undefined ? split[1] : '';
    const valueLabel = getAggregationValue(aggregationValue);

    channelDataItem.chartTypeName = matchingChannel.chartTypeName;
    channelDataItem.aggregationType = matchingChannel.aggregationType;
    channelDataItem.color = matchingChannel.color;

    if (!matchingChannel.displayLabel) {
      matchingChannel.displayLabel = `${
        matchingChannel.externalChannelId ?? ''
      } [${matchingChannel.unit ?? ''}]`;
    }

    channelDataItem.name =
      matchingChannel.displayLabel + ' ' + aggregationValue;

    channelDataItem.value = valueLabel;
    channelDataItem.channelId = channelDataItem.id;
  }

  return channelData;
}

// eslint-disable-next-line no-unused-vars
const hasSourceAsAggregationType = channel =>
  channel.aggregationType === 'source';

function* getMeasurements(channelData, fromPeriod, toPeriod, currentUser) {
  const result = yield fetchMeasurements(
    channelData,
    fromPeriod,
    toPeriod,
    currentUser
  );
  yield put(setChartdata(result));
  yield put(setChartRendered(true));
}

export function* fetchMeasurements(
  channelData,
  fromPeriod,
  toPeriod,
  currentUser
) {
  return yield getMeasurementsUsingMultipleRequests(
    channelData,
    fromPeriod,
    toPeriod,
    currentUser
  );
}

/*
  Due to the complex nature of expanding and executing conditional logic in an ODATA query, 
  this function will execute mulitiple ODATA requests for every selected channel and concat the result into one array.
*/
function* getMeasurementsUsingMultipleRequests(
  channels,
  fromPeriod,
  toPeriod,
  currentUser
) {
  let measurements = new Object();
  for (let index = 0; index < channels.length; index++) {
    const channel = channels[index];
    const split = channel.aggregationType.split('-');
    const aggregationPeriod = split[0];
    const aggregationValue = getAggregationValue(split[1]);
    const aggregationProperty = getAggregationProperty(aggregationPeriod);

    let baseQuery = buildBaseQuery(
      aggregationValue,
      aggregationProperty,
      channel.chartTypeName
    );
    baseQuery += `&$filter=dateTime gt ${fromPeriod.toISOString()} and dateTime lt ${toPeriod.toISOString()} and channelId eq ${
      channel.id
    }&$orderby=dateTime`;

    const result = yield Get(currentUser, baseQuery);
    measurements[channel.id] = result;
  }

  return measurements;
}

/*
  When all channels have an aggregationtype of "source", fetching underlying measurements is easier and more performant in one request.
*/
// eslint-disable-next-line no-unused-vars
function* getMeasurementsInSingleRequest(
  channelData,
  fromPeriod,
  toPeriod,
  currentUser
) {
  return yield Get(
    currentUser,
    measurementsODataQuery(channelData, fromPeriod, toPeriod)
  );
}

export function buildChannelODataQuery(channels, fromPeriod, toPeriod) {
  let baseQuery = `channel?$filter=`;

  for (let index = 0; index < channels.length; index++) {
    const channel = channels[index];
    if (index == 0) {
      baseQuery += ` (SensightId eq ${channel.sensightId}`;
      continue;
    }
    baseQuery += ` or SensightId eq ${channel.sensightId}`;
  }

  const endQuery = `)`;

  baseQuery += endQuery;
  // Voeg device placements toe aan channel
  baseQuery += `&$expand=device($select=devicePlacements;$expand=devicePlacements($select=location,startDateTime,endDateTime;$filter=startDateTime lt ${toPeriod.toISOString()} and endDateTime gt ${fromPeriod.toISOString()};$orderby=startDateTime desc;$expand=location($select=id,name)))`;

  return baseQuery;
}

function measurementsODataQuery(channels, fromPeriod, toPeriod) {
  let baseQuery = `measurements?$select=dateTime, value, channelId & $filter=`;

  for (let index = 0; index < channels.length; index++) {
    const channel = channels[index];
    if (index == 0) {
      baseQuery += ` (ChannelId eq ${channel.id}`;
      continue;
    }
    baseQuery += ` or ChannelId eq ${channel.id}`;
  }

  const endQuery = `) and dateTime gt ${fromPeriod.toISOString()} and dateTime lt ${toPeriod.toISOString()}`;

  baseQuery += endQuery;

  return baseQuery;
}

function getAggregationProperty(aggregationType) {
  switch (aggregationType) {
    case 'source':
      return 'measurements';
    case 'hour':
      return 'hourMeasurements';
    case 'day':
      return 'dayMeasurements';
    case 'week':
      return 'weekMeasurements';
    case 'month':
      return 'monthMeasurements';
    default:
      return 'hourMeasurements';
  }
}

function buildBaseQuery(aggregationValue, aggregationProperty, chartTypeName) {
  if (chartTypeName === 'kpi') {
    return `${aggregationProperty}?$select=dateTime, ${aggregationValue}, channelId, reliabilityPercentage, reliabilityReasonKey&$expand=reliabilityReason($select=name)`;
  }

  return `${aggregationProperty}?$select=dateTime, ${aggregationValue}, channelId`;
}
