import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import { DropzoneArea } from 'mui-file-dropzone';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { useSelector, useDispatch } from 'react-redux';
import { downloadFile, createFile } from 'apis/FieldServiceApi';
import ImdButton from 'components/imd-components/ImdButton';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import ImdPopup from 'components/imd-components/ImdPopup';
import { createSnackbar } from 'redux/ui/uiActions';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { Can } from 'components/imd-components/authorization/Can';

export function FileUploadDisplay({
  blobCreated,
  blobs,
  getBlobs,
  locationId,
  deleteBlob,
  isAllowedToEdit = false
}) {
  const classes = useStyles();
  const currentUser = useSelector(state => currentUserSelector(state));
  const { t } = useTranslation();

  useEffect(() => {
    getBlobs(locationId);
  }, []);

  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [dropzoneKey, setdropzoneKey] = useState(0);
  const [popup, setPopup] = React.useState({ open: false, blob: { name: '' } });
  const [fileMetaData, setFileMetaData] = useState({
    name: '',
    tags: ''
  });
  const maxFileSize = 20000000;

  const incrementDropzoneKey = () => {
    setdropzoneKey(dropzoneKey + 1);
  };

  useEffect(() => {
    if (file) {
      setFileMetaData({
        name: file.name,
        tags: ''
      });
      var reader = new FileReader();
      reader.onload = function (e) {
        if (e && e.target && e.target.result) {
          const byteArray = new Uint8Array(e.target.result);
          const base64EncodedFile = new Buffer(byteArray).toString('base64');
          setFileContent(base64EncodedFile);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  const submitFile = values => {
    let blob = {
      contentType: file.type,
      entityId: locationId,
      name: file.name,
      description: values.tags,
      base64EncodedContent: fileContent
    };
    createFile(currentUser, blob).then(createdBlob => {
      blobCreated(createdBlob.data);
      incrementDropzoneKey();
      setFile(null);
      setFileMetaData({
        name: '',
        tags: ''
      });
      dispatch(createSnackbar(t('content.files.blobcreated.title')));
    });
  };

  const dispatch = useDispatch();
  const fileRejected = file => {
    if (file === null) {
      dispatch(
        createSnackbar(
          t('content.files.uploaderror.file_limit_exceeded'),
          'error'
        )
      );
      return;
    }
    if (file.length > 0) {
      if (file[0].size > maxFileSize) {
        dispatch(
          createSnackbar(
            t('content.files.uploaderror.file_size_exceeded'),
            'error'
          )
        );
        return;
      }
    }
    dispatch(createSnackbar(t('content.files.uploaderror.unknown'), 'error'));
  };

  const uploadContent = (
    <Formik
      enableReinitialize={true}
      initialValues={fileMetaData}
      onSubmit={values => submitFile(values)}
    >
      <Form>
        <Field
          disabled={file === null}
          name="name"
          type="text"
          label={'Naam'}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          disabled={file === null}
          name="tags"
          type="text"
          label={'Tags'}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <DropzoneArea
          acceptedFiles={[
            'application/*',
            'audio/*',
            'image/*',
            'model/*',
            'text/*',
            'video/*'
          ]}
          dropzoneText="Bestand uploaden"
          disabled={true}
          dropzoneClass={classes.dropZone}
          filesLimit={1}
          maxFileSize={maxFileSize}
          key={dropzoneKey}
          onChange={file => file && setFile(file[0])}
          showAlerts={false}
          onDropRejected={file => {
            fileRejected(file);
          }}
          getFileLimitExceedMessage={() => {
            fileRejected(null);
          }}
          showFileNames={true}
          showPreviewsInDropzone={file !== null}
        />
        <ImdButton color="primary" type="submit" variant="contained">
          {t('general.save')}
        </ImdButton>
      </Form>
    </Formik>
  );

  const deleteFile = blob => {
    setPopup({ open: true, blob: blob });
  };

  const handlePopupOk = () => {
    const blob = popup.blob;
    setPopup({ open: false, blob: { name: popup.blob.name } });
    deleteBlob(blob.id);
  };

  const blobContent = blobs.map(blob => {
    return (
      <ListItem
        component={Link}
        className={classes.button}
        divider
        key={blob.id}
        onClick={() => {
          downloadFile(currentUser, blob.id);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <InsertDriveFileIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={blob.name}
          secondary={blob.description}
          className={classes.listItemText}
        />
        <Can
          role={getCurrentUserRole(currentUser)}
          perform={'location:fileDelete'}
          yes={() => (
            <ListItemSecondaryAction>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  deleteFile(blob);
                }}
                edge="end"
                aria-label="delete"
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          no={() => null}
        />
      </ListItem>
    );
  });

  const content = (
    <>
      <ImdPopup
        open={popup.open}
        title={t('content.files.deletepopup.title')}
        content={t('content.files.deletepopup.content', {
          filename: popup.blob.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() =>
          setPopup({ open: false, blob: { name: popup.blob.name } })
        }
      ></ImdPopup>
      <Grid container spacing={2} className={classes.tabContent}>
        <Can
          role={getCurrentUserRole(currentUser)}
          perform={'location:fileCreate'}
          override={isAllowedToEdit}
          yes={() => (
            <>
              <Grid item lg={5} md={12} sm={12}>
                <h4 className={classes.header}>Uploaden</h4>
                {uploadContent}
              </Grid>
              <Grid item lg={1}>
                <Divider orientation="vertical" className={classes.divider} />
              </Grid>
            </>
          )}
          no={() => null}
        />
        <Grid item lg={5} md={12} sm={12}>
          <h4 className={classes.header}>{t('content.logitems.fileupload')}</h4>
          <List dense={false}>{blobContent}</List>
        </Grid>
      </Grid>
    </>
  );

  return <>{content}</>;
}

const useStyles = makeStyles(theme => ({
  tabs: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  tabsRoot: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px 5px 0 0'
  },
  tab: {
    color: '#FFFFFF'
  },
  indicator: {
    backgroundColor: '#FFFFFF'
  },
  tabContent: {
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
    marginTop: '16px',
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  },
  dropZone: {
    backgroundColor: '#FFFFFF',
    marginTop: '16px',
    marginBottom: '32px'
  },
  divider: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  header: {
    marginBottom: '8px'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText
  }
}));
