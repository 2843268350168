import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createContact } from 'apis/CustomerContactApi';
import { CREATE_CONTACT } from './createContactActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { createUser } from 'apis/UserManagementApi';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { createContactSucceeded } from '../../redux/contactsListActions';
import { getError } from '../../ContactHelpers';

export function* createContactSaga() {
  yield takeEvery(CREATE_CONTACT, execute);
}

function* execute(action) {
  let contact = action.contactInformation;
  const user = yield select(currentUserSelector);
  try {
    contact.sensightId = yield executeCreateUser(contact, user);
    yield executeCreateContact(contact, user);
  } catch (exception) {
    console.error(exception);
  }
}

function* executeCreateUser(contact, user) {
  try {
    if (contact.contactIsUser) {
      let userdata = contact;
      userdata.id = uuidv4();
      userdata.tenant = contact.tenant;
      userdata.organization = contact.organization;

      var response = (yield createUser(user, userdata)).data;
      yield put(createSnackbar(t('content.adduser.snackbar.text')));
      return response.id;
    }
  } catch (exception) {
    const error = getError(exception);
    yield put(createSnackbar(error, 'error'));
    throw exception;
  }
}

function* executeCreateContact(contact, user) {
  try {
    const createdContactResult = yield createContact(user, contact);
    yield put(createSnackbar(t('content.createcontact.snackbar.text')));
    const createdContact = createdContactResult.data.result;
    yield put(createContactSucceeded(createdContact));
  } catch (exception) {
    throw exception;
  }
}
