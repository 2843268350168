import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createOrganization } from 'apis/CustomerContactApi';
import { CREATE_ORGANIZATION } from './createOrganizationActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { createOrganizationSucceeded } from '../../redux/organizationsListActions';
import { saveLocationsOfOrganization } from 'apis/FieldServiceApi';

export function* createOrganizationSaga() {
  yield takeEvery(CREATE_ORGANIZATION, execute);
}

function* execute(action) {
  let organization = action.organizationInformation;
  const user = yield select(currentUserSelector);

  try {
    const createdOrganizationResult = yield createOrganization(
      user,
      organization
    );
    const createdOrganization = createdOrganizationResult.data.result;
    saveLocationsOfOrganization(
      user,
      createdOrganization.sensightId,
      organization.locationIds
    );

    yield put(createSnackbar(t('content.createorganization.snackbar.text')));

    yield put(createOrganizationSucceeded(createdOrganization));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}
