import { createSelector } from 'reselect';

export const manualInsertsSelector = createSelector(
  state => state.get('manualInsertsList'),
  manualInsertsList => manualInsertsList.toJS()
);

export const manualChannelsSelector = createSelector(
  state => state.get('manualChannelsList'),
  manualChannelsList => manualChannelsList.toJS()
);

export const manualInsertRowsListSelector = createSelector(
  state => state.get('manualInsertRowsList'),
  manualInsertRowsList => manualInsertRowsList.toJS()
);

export const manualInsertsPeriodSelector = createSelector(
  state => state.get('manualInsertsPeriod'),
  manualInsertsPeriod => manualInsertsPeriod
);
