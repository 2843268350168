import { connect } from 'react-redux';
import CreateSimCardDisplay from './CreateSimCardDisplay.jsx';
import {
  createSimCard,
  upsertSimCardDetails
} from '../redux/simCardsActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapDispatchToProps = dispatch => ({
  createSimCard() {
    dispatch(createSimCard());
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  upsertSimCardDetails(simCardDetails) {
    dispatch(upsertSimCardDetails(simCardDetails));
  }
});

export const CreateSimCardContainer = connect(null, mapDispatchToProps, null, {
  forwardRef: true
})(CreateSimCardDisplay);
