import { connect } from 'react-redux';
import { FileUploadDisplay } from './FileUploadDisplay.jsx';
import {
  fetchBlobs,
  deleteBlobRequest,
  createBlobSucceeded
} from './redux/fileUploadActions.js';
import { blobsSelector } from './redux/fileUploadSelectors.js';

const mapStateToProps = (state, ownProps) => {
  return {
    locationId: ownProps.locationId,
    isAllowedToEdit: ownProps.isAllowedToEdit,
    blobs: blobsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  getBlobs(locationId) {
    dispatch(fetchBlobs(locationId));
  },
  deleteBlob(blobId) {
    dispatch(deleteBlobRequest(blobId));
  },
  blobCreated(blob) {
    dispatch(createBlobSucceeded(blob));
  }
});

export const FileUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadDisplay);
