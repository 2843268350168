import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  FETCH_BLOBS,
  setBlobs,
  DELETE_BLOB_REQUEST,
  deleteBlob as deleteBlobAction
} from './fileUploadActions';
import { deleteBlob as deleteBlobApi, getBlobs } from 'apis/FieldServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* fileUploadSaga() {
  yield takeEvery(FETCH_BLOBS, fetchBlobs);
  yield takeEvery(DELETE_BLOB_REQUEST, deleteBlob);
}

function* fetchBlobs(action) {
  const locationId = action.locationId;
  const user = yield select(currentUserSelector);

  try {
    const blobs = yield getBlobs(user, locationId);
    yield put(setBlobs(blobs.data));
  } catch (exception) {
    console.error(exception);
  }
}

function* deleteBlob(action) {
  const user = yield select(currentUserSelector);

  try {
    yield deleteBlobApi(user, action.blobId);
    yield put(createSnackbar(t('content.files.blobdeleted.title')));
    yield put(deleteBlobAction(action.blobId));
  } catch (exception) {
    console.error(exception);
  }
}
