import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_DEVICE = 'SET_DEVICE';
export const setDevice = makeActionCreator(SET_DEVICE, 'device');

export const FETCH_DEVICES = 'FETCH_DEVICES';
export const fetchDevices = makeActionCreator(FETCH_DEVICES);

export const SET_DEVICES_LIST = 'SET_DEVICES_LIST ';
export const setDevicesList = makeActionCreator(
  SET_DEVICES_LIST,
  'devicesList'
);

export const FETCH_AVAILABLESIMCARDS = 'FETCH_AVAILABLESIMCARDS';
export const fetchAvailableSimCards = makeActionCreator(
  FETCH_AVAILABLESIMCARDS
);

export const SET_AVAILABLESIMCARDS_LIST = 'SET_AVAILABLESIMCARDS_LIST ';
export const setAvailableSimCardsList = makeActionCreator(
  SET_AVAILABLESIMCARDS_LIST,
  'availableSimCardsList'
);

export const CREATE_DEVICE = 'CREATE_DEVICE';
export const createDevice = makeActionCreator(CREATE_DEVICE);

export const EDIT_DEVICE = 'EDIT_DEVICE';
export const editDevice = makeActionCreator(EDIT_DEVICE, 'sensightId');

export const UPSERT_TECHNICAL_DEVICE_DETAILS =
  'UPSERT_TECHNICAL_DEVICE_DETAILS';
export const upsertTechnicalDeviceDetails = makeActionCreator(
  UPSERT_TECHNICAL_DEVICE_DETAILS,
  'upsertTechnicalDeviceDetails'
);

export const UPSERT_TECHNICAL_DEVICE_DESCRIPTION =
  'UPSERT_TECHNICAL_DEVICE_DESCRIPTION';
export const upsertTechnicalDeviceDescription = makeActionCreator(
  UPSERT_TECHNICAL_DEVICE_DESCRIPTION,
  'upsertTechnicalDeviceDescription'
);

export const UPSERT_FUNCTIONAL_DEVICE_DETAILS =
  'UPSERT_FUNCTIONAL_DEVICE_DETAILS';
export const upsertFunctionalDeviceDetails = makeActionCreator(
  UPSERT_FUNCTIONAL_DEVICE_DETAILS,
  'upsertFunctionalDeviceDetails'
);

export const CREATE_DEVICE_SUCCEEDED = 'CREATE_DEVICE_SUCCEEDED';
export const createDeviceSucceeded = makeActionCreator(
  CREATE_DEVICE_SUCCEEDED,
  'device'
);

export const EDIT_DEVICE_SUCCEEDED = 'EDIT_DEVICE_SUCCEEDED';
export const editDeviceSucceeded = makeActionCreator(
  EDIT_DEVICE_SUCCEEDED,
  'device'
);

export const DELETE_DEVICE = 'DELETE_DEVICE';
export const deleteDevice = makeActionCreator(DELETE_DEVICE, 'deleteObject');

export const DELETE_DEVICE_SUCCEEDED = 'DELETE_DEVICE_SUCCEEDED ';
export const deleteDeviceSucceeded = makeActionCreator(
  DELETE_DEVICE_SUCCEEDED,
  'device'
);

export const SET_DEVICE_VISIBLE_SWITCH_VALUE =
  'SET_DEVICE_VISIBLE_SWITCH_VALUE';
export const setDeviceVisibleSwitchValue = makeActionCreator(
  SET_DEVICE_VISIBLE_SWITCH_VALUE,
  'deviceVisibleSwitchValue'
);

export const SET_DEVICE_ONLINE_SWITCH_VALUE = 'SET_DEVICE_ONLINE_SWITCH_VALUE';
export const setDeviceOnlineSwitchValue = makeActionCreator(
  SET_DEVICE_ONLINE_SWITCH_VALUE,
  'deviceOnlineSwitchValue'
);

export const SET_SHOULD_DELETE_FUNCTIONAL_DEVICE =
  'SET_SHOULD_DELETE_FUNCTIONAL_DEVICE';
export const setShouldDeleteFunctionalDevice = makeActionCreator(
  SET_SHOULD_DELETE_FUNCTIONAL_DEVICE,
  'switchValue'
);
