import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_VISUALIZE_FROM_TIME = 'SET_VISUALIZE_FROM_TIME';
export const setVisualizeFromTime = makeActionCreator(
  SET_VISUALIZE_FROM_TIME,
  'time'
);

export const SET_VISUALIZE_UNTIL_TIME = 'SET_VISUALIZE_UNTIL_TIME';
export const setVisualizeUntilTime = makeActionCreator(
  SET_VISUALIZE_UNTIL_TIME,
  'time'
);

export const SET_CURRENT_VISUALIZE_PERIOD = 'SET_CURRENT_VISUALIZE_PERIOD';
export const setCurrentVisualizePeriod = makeActionCreator(
  SET_CURRENT_VISUALIZE_PERIOD,
  'period'
);

export const SET_PREVIOUS_VISUALIZE_PERIOD = 'SET_PREVIOUS_VISUALIZE_PERIOD';
export const setPreviousVisualizePeriod = makeActionCreator(
  SET_PREVIOUS_VISUALIZE_PERIOD
);

export const SET_NEXT_VISUALIZE_PERIOD = 'SET_NEXT_VISUALIZE_PERIOD';
export const setNextVisualizePeriod = makeActionCreator(
  SET_NEXT_VISUALIZE_PERIOD
);

export const SET_TODAY_VISUALIZE_PERIOD = 'SET_TODAY_VISUALIZE_PERIOD';
export const setTodayVisualizePeriod = makeActionCreator(
  SET_TODAY_VISUALIZE_PERIOD
);

export const SET_CHART_DISPLAY_OPTIONS = 'SET_CHART_DISPLAY_OPTIONS';
export const setChartDisplayOptions = makeActionCreator(
  SET_CHART_DISPLAY_OPTIONS,
  'displayOptions'
);
