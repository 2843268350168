import axios from 'axios';
import configFile from '../App_config.json';
import { getActiveTenant } from 'utils/tenantManager.js';

const apiPrefix = 'visualisation/';

const createHeaderConfig = user => {
    return {
        headers: {
            Authorization: 'Bearer ' + user.token,
            'X-IMD-Tenant': getActiveTenant()
        }
    };
};

export const createVisualisationAsync = async (user, visualisationData) => {
    const config = createHeaderConfig(user);
    const url = configFile.reportingServiceApiUrl + apiPrefix;
    // Conform to api required data format
    const exportObject = {
        visualisationProperties: visualisationData
    }

    return axios.
        post(url, exportObject, config)
        .then(response => {
            return `${window.location.origin}/visualize/${response.data}`;
        })
        .catch(() => 'Unable to save visualisation properties.');
};

export const getVisualisationByGuidAsync = async (user, visualisationId) => {
    const config = createHeaderConfig(user);
    const url = configFile.reportingServiceApiUrl + apiPrefix + visualisationId;

    return axios
        .get(url, config)
        .then(response => {
            return response;
        })
        .catch(() => 'Something went wrong retreiving the visualisation');
};