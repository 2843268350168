import { createSelector } from 'reselect';

export const isClearingChannelDialogSelector = createSelector(
  state => state.get('isClearingChannelDialog'),
  isClearingChannelDialog => isClearingChannelDialog
);

export const isFetchingChannelDialogLocationsSelector = createSelector(
  state => state.get('isFetchingChannelDialogLocations'),
  isFetchingChannelDialogLocations => isFetchingChannelDialogLocations
);

export const isFetchingChannelDialogDevicesSelector = createSelector(
  state => state.get('isFetchingChannelDialogDevices'),
  isFetchingChannelDialogDevices => isFetchingChannelDialogDevices
);

export const hasStartedFetchingLocationsAndDevicesSelector = createSelector(
  state => state.get('hasStartedFetchingLocationsAndDevices'),
  hasStartedFetchingLocationsAndDevicesSelector =>
    hasStartedFetchingLocationsAndDevicesSelector
);

export const hasFinishedFetchingLocationsAndDevicesSelector = createSelector(
  state => state.get('hasFinishedFetchingLocationsAndDevices'),
  hasFinishedFetchingLocationsAndDevices =>
    hasFinishedFetchingLocationsAndDevices
);

export const locationsSelector = createSelector(
  state => state.get('visualizationLocations'),
  locations => locations.toJS()
);

export const selectedLocationsSelector = createSelector(
  state => state.get('selectedLocations'),
  selectedLocations => selectedLocations.toJS()
);

export const displayedLocationsSelector = createSelector(
  state => state.get('displayedLocations'),
  displayedLocations => displayedLocations.toJS()
);

export const devicesSelector = createSelector(
  state => state.get('visualizationDevices'),
  devices => devices.toJS()
);

export const selectedDevicesSelector = createSelector(
  state => state.get('selectedDevices'),
  selectedDevices => selectedDevices.toJS()
);

export const displayedDevicesSelector = createSelector(
  state => state.get('displayedDevices'),
  displayedDevices => displayedDevices.toJS()
);

export const dialogStateSelector = createSelector(
  state => state.get('dialog'),
  dialog => dialog
);

export const channelsSelector = createSelector(
  state => state.get('visualizationChannels'),
  channels => channels.toJS()
);

export const selectedChannelsSelector = createSelector(
  state => state.get('selectedChannels'),
  selectedChannels => selectedChannels.toJS()
);

export const displayedChannelsSelector = createSelector(
  state => state.get('displayedChannels'),
  displayedChannels => displayedChannels.toJS()
);

export const defaultChannelChartTypeSelector = createSelector(
  state => state.get('defaultChannelChartType'),
  defaultChannelChartType => defaultChannelChartType
);

export const defaultChannelAggregationTimeTypeSelector = createSelector(
  state => state.get('defaultChannelAggregationType'),
  defaultChannelAggregationType => defaultChannelAggregationType
);

export const defaultChannelAggregationTimeSelector = createSelector(
  state => state.get('defaultChannelAggregationType'),
  defaultChannelAggregationType => {
    const stringArray = defaultChannelAggregationType.split('-');
    if (stringArray.length === 2) {
      return stringArray[0];
    }
    return '';
  }
);

export const defaultChannelAggregationTypeSelector = createSelector(
  state => state.get('defaultChannelAggregationType'),
  defaultChannelAggregationType => {
    const stringArray = defaultChannelAggregationType.split('-');
    if (stringArray.length === 2) {
      return stringArray[1];
    }
    return stringArray[0];
  }
);
