import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import ImdCard from 'components/imd-components/ImdCard';
import { FunctionalDetailsForm } from './forms/FunctionalDetailsForm';
import { TechnicalDetailsForm } from './forms/TechnicalDetailsForm';
import { DescriptionForm } from './forms/DescriptionForm';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';

function EditDeviceDisplay(
  {
    viewModel,
    upsertTechnicalDeviceDetails,
    upsertTechnicalDeviceDescription,
    upsertFunctionalDeviceDetails,
    editDevice,
    availableSimCards,
    fetchAvailableSimCards,
    onlineSwitchValue,
    setDeviceOnlineSwitchValue,
    visibleSwitchValue,
    setDeviceVisibleSwitchValue,
    setSaveButtonDisabled
  },
  ref
) {
  const { t } = useTranslation();

  const classes = useSpecificCardStyles();
  const deviceTechnicalDescriptionFormRef = useRef();
  const deviceTechnicalDetailsFormRef = useRef();
  const deviceFunctionalDetailsFormRef = useRef();
  const [techDescrFormValid, setTechDescrFormValid] = useState(false);
  const [techDetailsFormValid, setTechDetailsFormValid] = useState(false);
  const [funcDetailsFormValid, setFuncDetailsFormValid] = useState(false);

  React.useEffect(() => {
    fetchAvailableSimCards();
    setSaveButtonDisabled(true);
    deviceTechnicalDescriptionFormRef.current.validateForm();
    deviceTechnicalDetailsFormRef.current.validateForm();
    deviceFunctionalDetailsFormRef.current.validateForm();
  }, []);

  React.useEffect(() => {
    setSaveButtonDisabled(
      !(techDescrFormValid && techDetailsFormValid && funcDetailsFormValid)
    );
  }, [techDescrFormValid, techDetailsFormValid, funcDetailsFormValid]);

  const deviceTechnicalContent = (
    <TechnicalDetailsForm
      deviceTechnicalDetailsFormRef={deviceTechnicalDetailsFormRef}
      deviceTechnicalViewmodel={viewModel}
      upsertTechnicalDeviceDetails={upsertTechnicalDeviceDetails}
      simCards={availableSimCards}
      setTechDetailsFormValid={setTechDetailsFormValid}
    />
  );

  const descriptionContent = (
    <DescriptionForm
      deviceTechnicalDescriptionFormRef={deviceTechnicalDescriptionFormRef}
      upsertTechnicalDeviceDescription={upsertTechnicalDeviceDescription}
      viewModel={viewModel}
      setTechDescrFormValid={setTechDescrFormValid}
    />
  );

  const deviceFunctionalContent = (
    <FunctionalDetailsForm
      deviceFunctionalDetailsFormRef={deviceFunctionalDetailsFormRef}
      deviceFunctionalViewmodel={viewModel}
      upsertFunctionalDeviceDetails={upsertFunctionalDeviceDetails}
      onlineSwitchValue={onlineSwitchValue}
      visibleSwitchValue={visibleSwitchValue}
      setDeviceVisibleSwitchValue={setDeviceVisibleSwitchValue}
      setDeviceOnlineSwitchValue={setDeviceOnlineSwitchValue}
      setFuncDetailsFormValid={setFuncDetailsFormValid}
    />
  );
  const formRef = useRef();

  const deviceTabContent = (
    <Grid container spacing={2} className={classes.tabContent}>
      <Grid item md={4} xs={10}>
        <ImdCard
          title={t('content.devicedashboard.card.devicetechnicaldetails')}
          content={deviceTechnicalContent}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <Grid item md={4} xs={10}>
        <ImdCard
          title={t('content.locationdashboard.card.description')}
          content={descriptionContent}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <Grid item md={4} xs={10}>
        <ImdCard
          title={t('content.devicedashboard.card.devicefunctionaldetails')}
          content={deviceFunctionalContent}
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
      <FormikWithRef
        ref={formRef}
        initialValues={{ ref: 'ref' }}
        enableReinitialize={true}
        onSubmit={() => {
          deviceTechnicalDetailsFormRef.current.handleSubmit();
          deviceTechnicalDescriptionFormRef.current.handleSubmit();
          deviceFunctionalDetailsFormRef.current.handleSubmit();
          editDevice(viewModel.sensightId);
        }}
      />
    </Grid>
  );

  useImperativeHandle(ref, () => formRef);
  return <>{deviceTabContent}</>;
}

const useSpecificCardStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

export default forwardRef(EditDeviceDisplay);
