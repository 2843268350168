import { createSelector } from 'reselect';

export const logbookActionsSelector = createSelector(
  state => state.get('logbookActionsList'),
  logbookActionsList => logbookActionsList.toJS()
);

export const logbookActionSelector = createSelector(
  state => state.get('logbookAction'),
  logbookAction => logbookAction.toJS()
);
