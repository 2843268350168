import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import MuiTextField from '@mui/material/TextField';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject, getDateFromTimeString } from 'utils/helperMethods';
import Autocomplete from '@mui/material/Autocomplete';
import ImdTimePicker from 'components/imd-components/ImdTimePicker';
import { MenuItem, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { periods } from '../ExportChartHelpers';
import { SelectChannelDialogContainer } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/selectChannelDialogContainer';
import ImdCard from 'components/imd-components/ImdCard';
import ImdButton from 'components/imd-components/ImdButton';

function ExportChartModalDisplay(
  {
    handleValidate,
    setSaveButtonDisabled,
    createExportChart,
    editExportChart,
    exportChart,
    contacts,
    openSelectChannelsDialog,
    selectedChannels,
    currentUser,
    enrichExportChartChannel,
    clearDialog,
    fetchContactsList
  },
  ref
) {
  const classes = useExportChartModalStyles();
  const { t } = useTranslation();

  const [autoCompleteContacts, setAutoCompleteContacts] = useState(
    exportChart
      ? exportChart.exportChartContacts.map(
          contact => contact.contactSensightId
        )
      : []
  );

  const date = new Date();
  date.setHours(0, 0, 0);
  const [switchTime, setSwitchTime] = useState(
    exportChart ? getDateFromTimeString(exportChart.switchTime) : date
  );

  const periodMenuItems = periods.map(period => {
    return (
      <MenuItem key={period.value} value={period.value}>
        {t(period.name)}
      </MenuItem>
    );
  });

  useEffect(() => {
    clearDialog();
    setSaveButtonDisabled(false);
    fetchContactsList();
    if (exportChart) {
      var enrichedExportChannels = [];
      exportChart.exportChartChannels.forEach(exportChannel => {
        enrichedExportChannels.push(
          enrichExportChartChannel(currentUser, exportChannel)
        );
      });
    }
  }, []);

  const handleSubmit = async values => {
    values.exportChartContacts = autoCompleteContacts.filter(
      newArrayValue => newArrayValue
    ); // Filteren zodat lege contacten automatisch worden verwijderd
    values.exportChartChannels = selectedChannels;
    values.switchTime = switchTime.toLocaleTimeString('nl-NL');

    if (!exportChart) {
      createExportChart(values);
    } else {
      editExportChart(values);
    }
  };

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (dirty && isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    return (
      <Form onSubmit={submitForm}>
        <Field
          name="name"
          type="text"
          label={t('content.exportchart.modal.name')}
          fullWidth
          component={TextField}
          required
          variant="standard"
        />
        <Autocomplete
          multiple
          filterSelectedOptions
          options={contacts}
          getOptionLabel={option => option?.email}
          value={autoCompleteContacts.map(contactSensightId =>
            contacts.find(contact => contact.sensightId === contactSensightId)
          )}
          onChange={(event, newValue) => {
            // Voor contacten die zijn verwijderd
            newValue = newValue.filter(newArrayValue => newArrayValue);
            setAutoCompleteContacts(newValue.map(con => con.sensightId));
          }}
          renderInput={params => (
            <MuiTextField
              {...params}
              name="exportChartContacts"
              type="text"
              label={t('content.exportchart.modal.contacts')}
              fullWidth
              variant="standard"
            />
          )}
        />
        <ImdTimePicker
                labelText={t('content.exportchart.modal.switchTime')}
                saveAction={setSwitchTime}
                switchTime={switchTime}
                className={'exportChartModalDisplay'}
        />
        <Field
          name="exportPeriod"
          label={t('content.exportchart.modal.exportPeriod')}
          fullWidth
          className={classes.textField}
          component={TextField}
          type="select"
          select
          variant="standard"
        >
          {periodMenuItems}
        </Field>
      </Form>
    );
  };

  const formRef = useRef();

  const inputFields = (
    <FormikWithRef
      ref={formRef}
      enableReinitialize={false}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        id: !exportChart ? '' : exportChart.id,
        name: !exportChart ? '' : exportChart.name,
        exportPeriod: !exportChart ? 0 : exportChart.exportPeriod
      }}
      validate={values => handleValidate(values)}
      onSubmit={values => handleSubmit(values)}
    >
      {form}
    </FormikWithRef>
  );
  useImperativeHandle(ref, () => formRef);

  return (
    <>
      <Grid container spacing={2} className={classes.tabContent}>
        <Grid item xs={12} md={8}>
          <ImdCard
            title={t('content.exportchart.card.exportchartdetails')}
            content={inputFields}
            variant="secondary"
            cardHeight="100%"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ImdCard
            title={t('content.exportchart.card.selectchannels')}
            content={
              <>
                <SelectChannelDialogContainer
                        defaultAggregation={false}
                        chartSettings={false}
                        chartAggregation={true}
                />
                <ImdButton
                        color={'primary'}
                        variant="contained"
                        onClick={() => openSelectChannelsDialog()}
                >
                  {t('content.exportchart.card.selectchannels')}
                </ImdButton>
                <p>{t('content.exportchart.card.selectedchannels')}</p>
                <ul>
                  {selectedChannels.map(selectedChannel => (
                    <li key={selectedChannel.sensightId}>
                      {selectedChannel.displayLabel}
                    </li>
                  ))}
                </ul>
              </>
            }
            variant="secondary"
            cardHeight="100%"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default forwardRef(ExportChartModalDisplay);

const useExportChartModalStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {},
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));
