import { connect } from 'react-redux';
import ManageAlertDisplay from './ManageAlertDisplay.jsx';
import {
  alertActiveSwitchValueSelector,
  selectedSubscribersSelector
} from '../redux/alertsSelectors.js';
import {
  createAlert,
  upsertAlertDetails,
  upsertSubscriptionDetails,
  setAlertActiveSwitchValue,
  setSelectedSubscribers
} from '../redux/alertsActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { contactsListSelector } from 'components/content/contacts/redux/contactsListSelectors';
import { showDialog } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions.js';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import {
  selectedChannelsSelector,
  selectedDevicesSelector,
  selectedLocationsSelector
} from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';

const mapStateToProps = state => {
  return {
    alertActiveSwitchValue: alertActiveSwitchValueSelector(state),
    availableSubscribers: contactsListSelector(state),
    currentUser: currentUserSelector(state),
    selectedChannels: selectedChannelsSelector(state),
    selectedDevices: selectedDevicesSelector(state),
    selectedLocations: selectedLocationsSelector(state),
    selectedSubscribers: selectedSubscribersSelector(state),
    subscribers: []
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  openSelectChannelsDialog() {
    dispatch(showDialog());
  },
  upsertAlert() {
    dispatch(createAlert());
  },
  upsertAlertDetails(alertDetails) {
    dispatch(upsertAlertDetails(alertDetails));
  },
  upsertSubscriptionDetails(subscriptionDetails) {
    dispatch(upsertSubscriptionDetails(subscriptionDetails));
  },
  setSelectedSubscribers(autocompleteSubscribers) {
    dispatch(setSelectedSubscribers(autocompleteSubscribers));
  },
  setAlertActiveSwitchValue(switchValue) {
    dispatch(setAlertActiveSwitchValue(switchValue));
  }
});

export const CreateAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageAlertDisplay);
