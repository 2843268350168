import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_ALERT = 'SET_ALERT';
export const setAlert = makeActionCreator(SET_ALERT, 'alert');

export const FETCH_ALERTS = 'FETCH_ALERTS';
export const fetchAlerts = makeActionCreator(FETCH_ALERTS);

export const FETCH_MY_ALERTS = 'FETCH_MY_ALERTS';
export const fetchMyAlerts = makeActionCreator(FETCH_MY_ALERTS);

export const SET_ALERTS_LIST = 'SET_ALERTS_LIST ';
export const setAlertsList = makeActionCreator(SET_ALERTS_LIST, 'alertsList');

export const CREATE_ALERT = 'CREATE_ALERT';
export const createAlert = makeActionCreator(CREATE_ALERT);

export const EDIT_ALERT = 'EDIT_ALERT';
export const editAlert = makeActionCreator(EDIT_ALERT, 'alert');

export const DELETE_ALERT = 'DELETE_ALERT';
export const deleteAlert = makeActionCreator(DELETE_ALERT, 'alert');

export const UPSERT_ALERT_DETAILS = 'UPSERT_ALERT_DETAILS';
export const upsertAlertDetails = makeActionCreator(
  UPSERT_ALERT_DETAILS,
  'upsertAlertDetails'
);

export const UPSERT_SUBSCRIPTION_DETAILS = 'UPSERT_SUBSCRIPTION_DETAILS';
export const upsertSubscriptionDetails = makeActionCreator(
  UPSERT_SUBSCRIPTION_DETAILS,
  'upsertSubscriptionDetails'
);

export const CREATE_ALERT_SUCCEEDED = 'CREATE_ALERT_SUCCEEDED';
export const createAlertSucceeded = makeActionCreator(
  CREATE_ALERT_SUCCEEDED,
  'alert'
);

export const EDIT_ALERT_SUCCEEDED = 'EDIT_ALERT_SUCCEEDED';
export const editAlertSucceeded = makeActionCreator(
  EDIT_ALERT_SUCCEEDED,
  'alert'
);

export const DELETE_ALERT_SUCCEEDED = 'DELETE_ALERT_SUCCEEDED ';
export const deleteAlertSucceeded = makeActionCreator(
  DELETE_ALERT_SUCCEEDED,
  'alert'
);

export const SET_ALERT_ACTIVE_SWITCH_VALUE = 'SET_ALERT_ACTIVE_SWITCH_VALUE';
export const setAlertActiveSwitchValue = makeActionCreator(
  SET_ALERT_ACTIVE_SWITCH_VALUE,
  'alertActiveSwitchValue'
);

export const SET_SELECTED_SUBSCRIBERS = 'SET_SELECTED_SUBSCRIBERS';
export const setSelectedSubscribers = makeActionCreator(
  SET_SELECTED_SUBSCRIBERS,
  'selectedSubscribers'
);

export const FETCH_SUBSCRIBERS = 'FETCH_SUBSCRIBERS';
export const fetchSubscribers = makeActionCreator(FETCH_SUBSCRIBERS, 'alertId');

export const SET_SUBSCRIBERS = 'SET_SUBSCRIBERS';
export const setSubscribers = makeActionCreator(SET_SUBSCRIBERS, 'subscribers');
