import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_ORGANIZATIONS_LIST = 'FETCH_ORGANIZATIONS_LIST';
export const fetchOrganizationsList = makeActionCreator(
  FETCH_ORGANIZATIONS_LIST,
  'organizationsList'
);

export const SET_ORGANIZATIONS_LIST = 'SET_ORGANIZATIONS_LIST';
export const setOrganizationsList = makeActionCreator(
  SET_ORGANIZATIONS_LIST,
  'organizationsList'
);

export const CREATE_ORGANIZATION_SUCCEEDED = 'CREATE_ORGANIZATION_SUCCEEDED';
export const createOrganizationSucceeded = makeActionCreator(
  CREATE_ORGANIZATION_SUCCEEDED,
  'organization'
);

export const EDIT_ORGANIZATION_SUCCEEDED = 'EDIT_ORGANIZATION_SUCCEEDED';
export const editOrganizationSucceeded = makeActionCreator(
  EDIT_ORGANIZATION_SUCCEEDED,
  'organization'
);

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const deleteOrganization = makeActionCreator(
  DELETE_ORGANIZATION,
  'organization'
);
