import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  getAllDashboards,
  getAllManagementDashboards,
  deleteDashboard,
  updateDashboard
} from 'apis/ReportingServiceApi';
import {
  setDashboards,
  setManagementDashboards,
  fetchedDashboards,
  FETCH_DASHBOARDS,
  FETCH_MANAGEMENT_DASHBOARDS,
  DELETE_DASHBOARD,
  UPDATE_DASHBOARD
} from './myDashboardActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* fetchDashboardsSaga() {
  yield takeEvery(FETCH_DASHBOARDS, executeFetchDashboards);
}

function* executeFetchDashboards() {
  const user = yield select(currentUserSelector);

  const dashboards = (yield getAllDashboards(user)).data;
  dashboards.forEach(dashboard => {
    dashboard.contactIds = [];
    dashboard.organizationIds = [];
    if (dashboard.authorizations) {
      dashboard.authorizations.forEach(a => {
        if (a.relationType === 1) {
          dashboard.contactIds.push(a.relationId);
        } else if (a.relationType === 0) {
          dashboard.organizationIds.push(a.relationId);
        }
      });
    }
  });

  yield put(setDashboards(dashboards));
  yield put(fetchedDashboards());
}

export function* fetchManagementDashboardsSaga() {
  yield takeEvery(FETCH_MANAGEMENT_DASHBOARDS, executeFetchManagmentDashboards);
}

function* executeFetchManagmentDashboards() {
  const user = yield select(currentUserSelector);

  const dashboards = (yield getAllManagementDashboards(user)).data;
  for (let index in dashboards) {
    let dashboard = dashboards[index];
    dashboard.contactIds = [];
    dashboard.organizationIds = [];
    if (dashboard.authorizations) {
      dashboard.authorizations.forEach(a => {
        if (a.relationType === 1) {
          dashboard.contactIds.push(a.relationId);
        } else if (a.relationType === 0) {
          dashboard.organizationIds.push(a.relationId);
        }
      });
    }
  }

  yield put(setManagementDashboards(dashboards));
}

export function* deleteDashboardSaga() {
  yield takeEvery(DELETE_DASHBOARD, executeDeleteDashboard);
}

export function* updateDashboardSaga() {
  yield takeEvery(UPDATE_DASHBOARD, executeUpdateDashboard);
}

export function* executeDeleteDashboard(action) {
  const user = yield select(currentUserSelector);
  const dashboardId = action.dashboard.id;

  yield deleteDashboard(user, dashboardId);
  put(createSnackbar(t('Dashboard verwijderd.')));
}

export function* executeUpdateDashboard(action) {
  const user = yield select(currentUserSelector);
  const dashboard = action.dashboard;

  dashboard.authorizedOrganizations = dashboard.organizationIds;
  dashboard.authorizedContacts = dashboard.contactIds;

  yield updateDashboard(user, dashboard);
  put(createSnackbar(t('Dashboard aangepast.')));
}
