import { takeEvery, select, put } from 'redux-saga/effects';
import {
  FETCH_VALIDATIONPROFILES_LIST,
  setValidationProfilesList
} from './validationProfilesListActions';
import { getAllValidationProfiles } from 'apis/MeasurementServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchValidationsListSagas() {
  yield takeEvery(FETCH_VALIDATIONPROFILES_LIST, execute);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var validationProfilesList = (yield getAllValidationProfiles(user)).data;
  validationProfilesList.sort((a, b) => (a.name > b.name ? 1 : -1));
  yield put(setValidationProfilesList(validationProfilesList));
}
