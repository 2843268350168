import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { DELETE_LOCATION } from './deleteLocationActions';
import { t } from 'i18next';
import { deleteLocation } from 'apis/FieldServiceApi';
import { deleteLocationSucceeded } from '../../redux/locationsListActions';
import { createSnackbar } from 'redux/ui/uiActions';

export function* deleteLocationSaga() {
  yield takeEvery(DELETE_LOCATION, execute);
}

function* execute(action) {
  const contact = action.location;
  const user = yield select(currentUserSelector);

  try {
    yield deleteLocation(user, contact.id);
    yield put(createSnackbar(t('content.deletelocation.snackbar.text')));
    yield put(deleteLocationSucceeded(contact));
  } catch (exception) {
    console.error(exception);
  }
}
