import { connect } from 'react-redux';
import ManageDashboardDisplay from './ManageDashboardDisplay.jsx';
import { createNewDashboard } from '../newDashboardDialog/redux/newDashboardDialogActions.js';
import { managementDashboardsSelector } from '../redux/myDashboardSelectors.js';
import {
  deleteDashboard,
  fetchManagementDashboards
} from '../redux/myDashboardActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions.js';
import { organizationsListSelector } from 'components/content/organizations/redux/organizationsListSelectors.js';
import { contactsListSelector } from 'components/content/contacts/redux/contactsListSelectors.js';

const mapStateToProps = state => {
  return {
    dashboards: managementDashboardsSelector(state),
    organizations: organizationsListSelector(state),
    contacts: contactsListSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDashboards() {
    dispatch(fetchManagementDashboards());
  },
  addDashboard(dashboard) {
    dispatch(createNewDashboard(dashboard));
  },
  deleteDashboard(dashboard) {
    dispatch(deleteDashboard(dashboard));
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  }
});

export const ManageAllDashboardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDashboardDisplay);
