import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from 'react-i18next';

import { getCurrentUserRole } from 'utils/currentUserReader';
import ImdButton from 'components/imd-components/ImdButton';
import { Can } from 'components/imd-components/authorization/Can';
import { DashboardItemContainer } from '../dashboardItem/DashboardItemContainer.js';

export default function DashboardDisplay({
  dashboard,
  setIsEditing,
  currentUser,
  clearDashboardMeasurements,
  locations
}) {
  React.useEffect(() => {
    return () => {
      clearDashboardMeasurements();
    };
  }, []);

  const { t } = useTranslation();
  const classes = useImdCardStyles();

  const EmptyDashboard = () => {
    return (
      <div className={classes.emptyMessage}>
        <DashboardIcon className={classes.emptyIcon} />
        <p>
          {t('content.dashboard.emptydashboard')}
          <Can
            role={getCurrentUserRole(currentUser)}
            perform="dashboard:manage"
            yes={() => t('content.dashboard.edittext')}
            no={() => null}
          />
        </p>
        <Can
          perform="dashboard:manage"
          yes={() => (
            <ImdButton
              role={getCurrentUserRole(currentUser)}
              color={'primary'}
              variant="contained"
              size={'large'}
              className={classes.routeButton}
              startIcon={<EditIcon />}
              onClick={() => setIsEditing(true)}
            >
              {t('content.dashboard.edit')}
            </ImdButton>
          )}
          no={() => null}
        />
      </div>
    );
  };

  const RenderedDashboard = () => {
    return renderDashboard();
  };

  const renderDashboard = () => {
    if (dashboard.dashboardSections) {
      return dashboard.dashboardSections.map(section => {
        return (
          <Grid container spacing={1} key={'section' + section.rowIndex}>
            {renderDashboardItems(dashboard, section.items, section.rowIndex)}
          </Grid>
        );
      });
    }
  };

  const GridItem = ({ item, dashboard, rowIndex, index, itemCount }) => {
    const gridItemClasses = useGridItemStyles({ itemCount });

    return (
      <Grid item xs={12} lg className={gridItemClasses.gridItem}>
        <div className={classes.itemContent}>
          <div className={classes.titleBar}>
            <p className={classes.sectionTitle}>{item.title}</p>
          </div>
          <DashboardItemContainer
            dashboard={dashboard}
            item={item}
            renderKey={`dashboardItem_row${rowIndex}_column${index}`}
            locations={locations}
          />
        </div>
      </Grid>
    );
  };

  const renderDashboardItems = (dashboard, items, rowIndex) => {
    let gridItems = [];
    for (let index = 0; index < items.length; index++) {
      if (!items[index].visualizationSettings) {
        gridItems.push(
          <Grid
            key={'renderdi' + index}
            item
            xs={12}
            lg
            className={classes.sectionItem}
          />
        );
        continue;
      }

      gridItems.push(
        <GridItem
          key={'renderdi' + index}
          item={items[index]}
          dashboard={dashboard}
          rowIndex={rowIndex}
          index={index}
          itemCount={items.length}
        />
      );
    }
    return gridItems;
  };

  return (
    <Grid container>
      <div className={classes.header}>
        <div className={classes.headerDiv}>
          <h3 className={classes.headerText}>{dashboard.title}</h3>
          <Can
            role={getCurrentUserRole(currentUser)}
            perform={
              dashboard.onlyAdminsCanEdit
                ? 'dashboard:manageAdminOnly'
                : 'dashboard:manage'
            }
            yes={() => (
              <ImdButton
                color={'primary'}
                variant="contained"
                size={'large'}
                className={classes.routeButton}
                startIcon={<EditIcon />}
                onClick={() => setIsEditing(true)}
              >
                {t('content.dashboard.edit')}
              </ImdButton>
            )}
            no={() => null}
          />
        </div>

        {dashboard.dashboardSections.length === 0 ? (
          <EmptyDashboard />
        ) : (
          <RenderedDashboard />
        )}
      </div>
    </Grid>
  );
}

const useGridItemStyles = makeStyles(theme => ({
  gridItem: {
    marginBottom: '2px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: props => `${100 / props.itemCount}%`
    }
  }
}));

const useImdCardStyles = makeStyles(theme => ({
  header: {
    marginBottom: '16px',
    width: '100%'
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '24px',
    paddingRight: '12px'
  },
  headerDiv: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '8px'
  },
  headerText: {
    marginTop: '0px',
    marginBottom: '0px',
    width: '100%',
    lineHeight: '32px',
    display: 'none'
  },
  sectionHeader: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row-reverse',
    borderRadius: '5px 5px 0px 0px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)'
  },
  largeIconButton: {
    width: '3em',
    height: '3em'
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
    flexGrow: 1
  },
  itemContent: {
    height: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px 5px 5px 5px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    paddingLeft: '12px',
    paddingRight: '12px',
    marginBottom: '12px'
  },
  emptyMessage: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  emptyIcon: {
    width: '6em',
    height: '6em',
    color: '#e0e0e0'
  },
  linkItem: {
    cursor: 'pointer',
    marginLeft: '0.5rem',
    color: '#007962'
  },
  linkItemDisabled: {
    color: '#000',
    cursor: 'default'
  }
}));
