import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import locales from './locales/locales.json';

const resources = locales;

use(initReactI18next).init({
  resources,
  lng: ['nl'],
  fallbackLng: 'nl',

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: true,

  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },

  react: {
    wait: true
  }
});

export default i18n;
