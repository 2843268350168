import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../page/Content';
import { LocationTypeTableContainer } from './LocationTypeTableContainer';

export default function LocationTypes() {
  const { t } = useTranslation();

  const content = <LocationTypeTableContainer />;
  return (
    <Content
      title={t('content.locationtypes.title')}
      secondTitle={t('navbar.manager')}
      content={content}
    />
  );
}
