import { createReducer } from 'utils/createReducer';
import {
  SET_ORGANIZATIONS_LIST,
  CREATE_ORGANIZATION_SUCCEEDED,
  DELETE_ORGANIZATION,
  EDIT_ORGANIZATION_SUCCEEDED
} from './organizationsListActions';
import { fromJS } from 'immutable';

export const organizationsList = createReducer(null, {
  [SET_ORGANIZATIONS_LIST](state, { organizationsList }) {
    return fromJS(organizationsList);
  },
  [CREATE_ORGANIZATION_SUCCEEDED](state, { organization }) {
    return state.push(fromJS(organization));
  },
  [EDIT_ORGANIZATION_SUCCEEDED](state, { organization }) {
    let organizationsList = state.toJS();
    var foundOrganizationIndex = organizationsList.findIndex(
      org => organization.id === org.id
    );
    organizationsList[foundOrganizationIndex] = organization;
    return fromJS(organizationsList);
  },
  [DELETE_ORGANIZATION](state, { organization }) {
    return state.filter(org => org.get('id') !== organization.id);
  }
});
