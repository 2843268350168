import { connect } from 'react-redux';
import { sendChangePasswordMail } from './redux/changePasswordActions';
import { ChangePasswordDisplay } from './ChangePasswordDisplay.jsx';
import { profileDetailsSelector } from '../redux/profileDetailsSelectors';

const mapStateToProps = state => {
  const profileDetails = profileDetailsSelector(state);

  return {
    profileDetails: profileDetails
  };
};

const mapDispatchToProps = dispatch => ({
  handleSendPasswordEmail() {
    dispatch(sendChangePasswordMail());
  }
});

export const ChangePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordDisplay);
