import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_THEME = 'SET_THEME';
export const setTheme = makeActionCreator(SET_THEME, 'theme');

export const FETCH_THEMES = 'FETCH_THEMES';
export const fetchThemes = makeActionCreator(FETCH_THEMES);

export const SET_THEMES_LIST = 'SET_THEMES_LIST ';
export const setThemesList = makeActionCreator(SET_THEMES_LIST, 'themesList');

export const CREATE_THEME = 'CREATE_THEME';
export const createTheme = makeActionCreator(CREATE_THEME, 'themeDetails');

export const CREATE_THEME_SUCCEEDED = 'CREATE_THEME_SUCCEEDED';
export const createThemeSucceeded = makeActionCreator(
  CREATE_THEME_SUCCEEDED,
  'theme'
);

export const EDIT_THEME = 'EDIT_THEME';
export const editTheme = makeActionCreator(EDIT_THEME, 'themeDetails');

export const EDIT_THEME_SUCCEEDED = 'EDIT_THEME_SUCCEEDED';
export const editThemeSucceeded = makeActionCreator(
  EDIT_THEME_SUCCEEDED,
  'theme'
);

export const DELETE_THEME = 'DELETE_THEME';
export const deleteTheme = makeActionCreator(DELETE_THEME, 'deleteObject');

export const DELETE_THEME_SUCCEEDED = 'DELETE_THEME_SUCCEEDED ';
export const deleteThemeSucceeded = makeActionCreator(
  DELETE_THEME_SUCCEEDED,
  'theme'
);
