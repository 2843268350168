import { connect } from 'react-redux';
import { VisualizeDashboardDisplay } from './VisualizeDashboardDisplay.jsx';
import {
  setDefaultChannelChartType,
  setDefaultChannelAggregationType
} from '../visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions.js';
import {
  setVisualizeFromTime,
  setCurrentVisualizePeriod
} from '../visualize/toolbar/redux/toolbarActions';

const mapStateToProps = (state, ownProps) => {
  let visualizeLink = null;
  if (ownProps.match !== undefined) {
    visualizeLink = ownProps.match.params.link;
  }

  return {
    visualizeLink: visualizeLink
  };
};

const mapDispatchToProps = dispatch => ({
  setVisualizeFromTime(value) {
    dispatch(setVisualizeFromTime(value));
  },
  setCurrentVisualizePeriod(value) {
    dispatch(setCurrentVisualizePeriod(value));
  },
  setDefaultChannelChartType(value) {
    dispatch(setDefaultChannelChartType(value));
  },
  setDefaultChannelAggregationType(value) {
    dispatch(setDefaultChannelAggregationType(value));
  }
});

export const VisualizeDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisualizeDashboardDisplay);
