import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  Editing,
  Export,
  Paging,
  SearchPanel,
  ColumnFixing,
  Pager,
  Scrolling
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { HeaderFilter } from 'devextreme-react/pivot-grid-field-chooser';
import { Workbook } from 'exceljs';
import { check } from '../authorization/Can';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { moveItemPositionInArray } from 'utils/helperMethods';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: 'auto',
    minWidth: 120,
    maxWidth: '50%',
    'padding-left': '10px'
  },
  loadBar: {
    backgroundColor: theme.palette.primary.main
  }
}));

export default function ImdExcelTable(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [updateCount, setNewUpdateCount] = useState(0);
  const [prevUpdateCount, setNewPrevUpdateCount] = useState(0);
  const [isBusySavingRows, setIsBusySavingRows] = useState(false);
  const [renderedColumns, setRenderedColumns] = useState({});
  const [selectedFilterOptions, setSelectedFilterColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const filterMode = typeof props.filterKey === 'string';
  const [filterOptions, setFilterOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data, setData } = props;

  useEffect(() => {
    if (selectedFilterOptions.length > 0) {
      setData(props.intialData);
    } else {
      setData([]);
    }
    setRenderedColumns(props.columnsRenderer(selectedColumns));
  }, [props.intialData, selectedColumns]);

  if (filterMode) {
    useEffect(() => {
      props.onSelectedColumnsChanged(selectedFilterOptions);
      setSelectedColumns(
        props.columns.filter(
          c =>
            selectedFilterOptions.includes(c[props.filterValue]) || c.key === 0
        )
      );
    }, [selectedFilterOptions]);
  } else {
    // Wanneer er niet gefilterd wordt mogen alle kolommen en alle data worden weergegeven.
    useEffect(() => {
      setRenderedColumns(props.columnsRenderer(props.columns));
    }, [props.columns]);
  }

  useEffect(() => {
    // Pak de distinct opties uit de meegegeven kolommen lijst op basis van de filterkey en values
    setFilterOptions(
      Array.from(
        new Set(
          props.columns.filter(c => c.key !== 0).map(s => s[props.filterKey])
        )
      ).map(filterKey => {
        return {
          filterKey: filterKey,
          values: props.columns
            .filter(c => c[props.filterKey] === filterKey)
            .map(column => column[props.filterValue])
        };
      })
    );
  }, [props.columns]);

  useEffect(() => {
    if (prevUpdateCount === updateCount) {
      return;
    }
    setNewPrevUpdateCount(updateCount);

    if (prevUpdateCount === 0 && updateCount === 1) {
      setIsBusySavingRows(true);
      props.isBusySavingRows(true);
    }

    if (isBusySavingRows && updateCount === 0) {
      setIsBusySavingRows(false);
      props.isBusySavingRows(false);
    }
  }, [updateCount]);

  const incrementUpdateCount = () => {
    setNewUpdateCount(updateCount + 1);
  };
  const decrementUpdateCount = () => {
    setNewUpdateCount(updateCount - 1);
  };

  const exportFilename = props.title || 'Data';
  const getMuiTheme = createTheme();
  const userRole = getCurrentUserRole(
    useSelector(state => currentUserSelector(state))
  );

  const grid = props.identification;
  const onToolbarPreparing = (toolbar, props) => {
    toolbar.toolbarOptions.items.unshift(
      ...props.additionalToolbar,
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'add',
          width: 136,
          text: t('general.add'),
          onClick: () => props.handleAddButton()
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'xlsfile',
          text: t('general.exportexcel'),
          onClick: () => onExporting(grid.current.instance)
        }
      }
    );

    if (!props.autoSaveAndSynchronise) {
      toolbar.toolbarOptions.items.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          icon: 'save',
          text: t('general.synchronizeall'),
          onClick: () => props.handleSynchronizeButton()
        }
      });
    } else {
      const saveButton = toolbar.toolbarOptions.items.find(
        item => item.name === 'saveButton'
      );
      toolbar.toolbarOptions.items = moveItemPositionInArray(
        toolbar.toolbarOptions.items,
        3,
        2
      );
      saveButton.showText = 'always';
      saveButton.options = {
        ...saveButton.options,
        icon: 'save',
        text: t('general.save')
      };
    }

    let addPermission = props.addPermission;
    if (addPermission === undefined) {
      addPermission = 'datatable:add';
    }

    if (check(userRole, addPermission)) {
      toolbar.toolbarOptions.items.unshift({});
    }
  };

  const onExporting = dataGrid => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(exportFilename);

    exportDataGrid({
      component: dataGrid,
      worksheet: worksheet
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(
          new Blob([buffer], { type: 'application/octet-stream' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${exportFilename}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
    dataGrid.cancel = true;
  };

  const devextremeTable = (
    <div id={'imd-data-grid'}>
      {isLoading && <LinearProgress />}
      <Autocomplete
        className={classes.formControl}
        multiple
        filterSelectedOptions
        options={filterOptions}
        getOptionLabel={option => option.filterKey}
        isOptionEqualToValue={(option, value) =>
          option.filterKey === value.filterKey
        }
        onChange={(_, newValue) => {
          setIsLoading(true);
          setSelectedFilterColumns(
            newValue
              .map(value => value.values)
              .reduce((a, b) => [...a, ...b], [])
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            name="devices"
            type="text"
            label={`${
              selectedFilterOptions.length === 0
                ? t(props.selectFirstTranslation)
                : t(props.filterTranslationPlural)
            }`}
            fullWidth
            variant="standard"
          />
        )}
      />
      <DataGrid
        ref={grid}
        dataSource={data}
        keyExpr={'id'}
        noDataText={`${t('content.visulize.snackbar.no_data_found')}${
          selectedFilterOptions.length === 0
            ? `. ${t(props.selectFirstTranslation)}`
            : ``
        }`}
        showBorders={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        autoNavigateToFocusedRow={true}
        onContentReady={() => {
          setTimeout(() => setIsLoading(false), 1500);
        }} // Timeout omdat de content ready te vroeg wordt aangeroepen door de datagrid
        focusedRowEnabled={true}
        onRowInserting={() => {
          incrementUpdateCount();
        }}
        onRowRemoving={() => {
          incrementUpdateCount();
        }}
        onRowUpdating={() => {
          incrementUpdateCount();
        }}
        onRowInserted={event => {
          decrementUpdateCount();
          props.onRowInserted(event);
        }}
        onRowRemoved={event => {
          decrementUpdateCount();
          props.onRowRemoved(event);
        }}
        onRowUpdated={event => {
          decrementUpdateCount();
          props.onRowUpdated(event);
        }}
        onFocusedRowChanged={event => props.onFocusedRowChanged(event)}
        onToolbarPreparing={toolbar => onToolbarPreparing(toolbar, props)}
        columnAutoWidth={true}
      >
        <Paging enabled={true} defaultPageSize={15} />
        <Editing
          mode="batch"
          allowUpdating={true}
          selectTextOnEditStart={props.selectTextOnEditStart}
          startEditAction={props.startEditAction}
        />
        <ColumnFixing enabled={true} />
        <Scrolling columnRenderingMode="virtual" />
        <Export enabled={false} />
        <HeaderFilter visible={false} />
        <SearchPanel
          visible={true}
          width={240}
          placeholder={t('general.search')}
        />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 15, 20, 50]}
          showInfo={true}
        />
        {renderedColumns?.props?.children}
      </DataGrid>
    </div>
  );

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getMuiTheme}>{devextremeTable}</ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
