import React, { useState, useEffect, useContext } from 'react';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { useTranslation } from 'react-i18next';
import { setCurrentUser } from 'redux/user/currentUserActions';
import { determineTenant, setActiveTenant } from 'utils/tenantManager.js';
import { setActiveThemeByTenant } from 'utils/themeManager.js';
import { PropTypes } from 'prop-types';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  store,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const namespace = 'https://api.sensight.nl/';
  const [auth0Client, setAuth0Client] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const initAuth0 = async () => {
      const { domain, clientId, audience, redirect_uri } = initOptions;
      const auth0FromHook = await createAuth0Client({
        domain,
        clientId,
        authorizationParams: {
          audience,
          redirect_uri
        }
      });
      setAuth0Client(auth0FromHook);
      if (window.location.search.includes('code=')) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (e) {
          onRedirectCallback({});
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        const token = await auth0FromHook.getTokenSilently();
        const currentUser = { ...user, token, namespace };
        store.dispatch(setCurrentUser(currentUser));
        if (user[namespace + 'language']) {
          i18n.changeLanguage(user[namespace + 'language'].toLowerCase());
        } else {
          i18n.changeLanguage('nl');
        }

        var tenantsNamespaceData = user[namespace + 'tenants'];
        console.dev(`User logged in for tenants:`, tenantsNamespaceData);
        let tenant = determineTenant(tenantsNamespaceData);
        setActiveTenant(tenant);
        await setActiveThemeByTenant(currentUser, tenant);
      }
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    if (auth0Client != null) {
      setPopupOpen(true);
      try {
        await auth0Client.loginWithPopup(params);
      } catch (error) {
        console.error(error);
      } finally {
        setPopupOpen(false);
      }
      const user = await auth0Client.getUser();
      setUser(user);
      setIsAuthenticated(true);
    }
  };

  const handleRedirectCallback = async () => {
    if (auth0Client != null) {
      setLoading(true);
      await auth0Client.handleRedirectCallback();
      const user = await auth0Client.getUser();
      setLoading(false);
      setIsAuthenticated(true);
      setUser(user);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        namespace,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.any.isRequired,
  store: PropTypes.any.isRequired,
  onRedirectCallback: PropTypes.any.isRequired
};
