import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#007962'
  },
  tooltip: {
    fontSize: '1rem',
    backgroundColor: '#007962'
  }
}));

const ImdTooltip = props => {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
};

export default ImdTooltip;
