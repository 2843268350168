import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_LOCATION_DETAILS = 'FETCH_LOCATION_DETAILS';
export const fetchLocationDetails = makeActionCreator(
  FETCH_LOCATION_DETAILS,
  'locationId',
  'organizationId'
);

export const SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS';
export const setLocationDetails = makeActionCreator(
  SET_LOCATION_DETAILS,
  'locationDetails'
);

export const UPDATE_LOCATION_DETAILS = 'UPDATE_LOCATION_DETAILS';
export const updateLocationDetails = makeActionCreator(
  UPDATE_LOCATION_DETAILS,
  'locationDetails'
);

export const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS';
export const updateProjectDetails = makeActionCreator(
  UPDATE_PROJECT_DETAILS,
  'projectDetails'
);

export const SET_AUTOCOMPLETE_SELECTED_ORGANIZATIONS =
  'SET_AUTOCOMPLETE_SELECTED_ORGANIZATIONS';
export const setAutocompleteSelectedOrganizations = makeActionCreator(
  SET_AUTOCOMPLETE_SELECTED_ORGANIZATIONS,
  'selectedOrganizations'
);

export const SET_AUTOCOMPLETE_SELECTED_SERVICE_CONTACT_PERSONS =
  'SET_AUTOCOMPLETE_SELECTED_SERVICE_CONTACT_PERSONS';
export const setAutocompleteSelectedServiceContactPersons = makeActionCreator(
  SET_AUTOCOMPLETE_SELECTED_SERVICE_CONTACT_PERSONS,
  'selectedServiceContactPersons'
);

export const SET_AUTOCOMPLETE_SELECTED_CONTACT_PERSONS =
  'SET_AUTOCOMPLETE_SELECTED_CONTACT_PERSONS';
export const setAutocompleteSelectedContactPersons = makeActionCreator(
  SET_AUTOCOMPLETE_SELECTED_CONTACT_PERSONS,
  'selectedContactPersons'
);

export const SET_HIDE_SWITCH_VALUE = 'SET_HIDE_SWITCH_VALUE';
export const setHideSwitchValue = makeActionCreator(
  SET_HIDE_SWITCH_VALUE,
  'switchValue'
);

export const SET_ONLINE_SWITCH_VALUE = 'SET_ONLINE_SWITCH_VALUE';
export const setOnlineSwitchValue = makeActionCreator(
  SET_ONLINE_SWITCH_VALUE,
  'switchValue'
);

export const RESET_LOCATION_DASHBOARD_STATE = 'RESET_LOCATION_DASHBOARD_STATE';
export const resetLocationDashboardState = makeActionCreator(
  RESET_LOCATION_DASHBOARD_STATE
);
