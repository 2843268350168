import {
  FETCH_MANUALINSERTS,
  FETCH_MANUALCHANNELS,
  CREATE_MANUALINSERTROW,
  DELETE_MANUALINSERTROW,
  EDIT_MANUALINSERTROW,
  createRowSucceeded,
  deleteRowSucceeded,
  editRowSucceeded,
  SYNC_MANUALINSERTS,
  setManualChannelsList,
  setManualInsertsList,
  setManualInsertRowsList
} from './manualInsertActions';
import {
  manualInsertsPeriodSelector,
  manualChannelsSelector
} from './manualInsertSelectors';
import { takeEvery, select, put } from 'redux-saga/effects';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  syncManualMeasurements,
  getLocationBySensightId
} from 'apis/MeasurementServiceApi';
import { Get } from 'apis/MeasurementODataApi';
import { getODataFormattedDateTimeString } from 'utils/helperMethods';
import { getAllChannelsByLocationSensightId } from 'apis/FieldServiceApi';
import { locationSelector } from '../../redux/locationSelectors';

export function* fetchManualInsertsSaga() {
  yield takeEvery(FETCH_MANUALINSERTS, fetchManualInserts);
}

export function* fetchManualChannelsSaga() {
  yield takeEvery(FETCH_MANUALCHANNELS, fetchManualChannelsOfLocation);
}

function* fetchManualInserts(payload) {
  try {
    const user = yield select(currentUserSelector);
    let manualChannelsList = yield select(manualChannelsSelector);

    if (
      typeof payload.selectedChannels === 'object' &&
      payload.selectedChannels?.length > 0
    ) {
      manualChannelsList = manualChannelsList.filter(manualChannel =>
        payload.selectedChannels.includes(manualChannel.measurementServiceId)
      );

      if (manualChannelsList.length > 0) {
        const periodInMonths = yield select(manualInsertsPeriodSelector);
        let monthNumber = new Date().getMonth();
        let fromDateTime = new Date();
        fromDateTime.setMonth(monthNumber - periodInMonths);
        let toDateTime = new Date();
        toDateTime.setMonth(monthNumber + 1);
        yield* getMeasurementsUsingMultipleRequests(
          manualChannelsList,
          fromDateTime,
          toDateTime,
          user
        );
      }
    }
  } catch (e) {
    yield put(
      createSnackbar(
        t('content.manualinserts.fetchmanualinsertserror'),
        'error'
      )
    );
    console.log(e);
  }
}

function* fetchManualChannelsOfLocation() {
  try {
    const user = yield select(currentUserSelector);
    let location = yield select(locationSelector);
    let measurementServiceLocation = (yield getLocationBySensightId(
      user,
      location.sensightId
    )).data[0];
    var locationId = measurementServiceLocation.id;
    let currentDate = getODataFormattedDateTimeString(new Date());
    const query =
      'channel?$filter=device/deviceplacements/any(o: o/locationId eq ' +
      locationId +
      ' and o/startDateTime lt ' +
      currentDate +
      ' and (o/endDateTime gt ' +
      currentDate +
      ' or o/endDateTime eq null))&$expand=validationprofile';

    const measurementServiceChannels = yield Get(user, query);
    const fieldServiceChannels = (yield getAllChannelsByLocationSensightId(
      user,
      location.sensightId
    )).data;
    let channelsList = [];

    if (fieldServiceChannels.length > 0) {
      if (measurementServiceChannels.length > 0) {
        fieldServiceChannels.forEach(fieldServiceChannel => {
          const measureChannel = measurementServiceChannels.find(
            measureChannel =>
              fieldServiceChannel.sensightId === measureChannel.sensightId
          );
          if (measureChannel) {
            measureChannel.measurementServiceId = measureChannel.id;
            fieldServiceChannel.fieldServiceId = fieldServiceChannel.id;
            channelsList.push({ ...measureChannel, ...fieldServiceChannel });
          }
        });
      } else {
        channelsList = fieldServiceChannels;
      }
    }
    const manualChannelsList = channelsList.filter(ch => ch.channelType === 1);
    yield put(setManualChannelsList(manualChannelsList));
  } catch (e) {
    yield put(
      createSnackbar(t('content.channels.fetchchannelserror'), 'error')
    );
    console.log(e);
  }
}

export function buildChannelODataQuery(channels) {
  let baseQuery = `channel?$filter=`;
  for (let index = 0; index < channels.length; index++) {
    const channel = channels[index];
    if (index == 0) {
      baseQuery += ` (SensightId eq ${channel.sensightId}`;
      continue;
    }
    baseQuery += ` or SensightId eq ${channel.sensightId}`;
  }
  const endQuery = `)`;
  baseQuery += endQuery;
  return baseQuery;
}

/*
  Due to the complex nature of expanding and executing conditional logic in an ODATA query, 
  this function will execute mulitiple ODATA requests for every selected channel and concat the result into one array.
*/
function* getMeasurementsUsingMultipleRequests(
  channels,
  fromDateTime,
  toDateTime,
  currentUser
) {
  let baseQuery = `channel?$expand=measurements($select=dateTime, value; $filter=`;
  baseQuery += `dateTime gt ${fromDateTime.toISOString()} and dateTime lt ${toDateTime.toISOString()})`;
  let initialFilterAdded = false;
  for (let index = 0; index < channels.length; index++) {
    const channel = channels[index];

    if (channel.measurementServiceId) {
      if (!initialFilterAdded) {
        baseQuery += `& $filter=`;
        initialFilterAdded = true;
      } else {
        baseQuery += ` or`;
      }

      baseQuery += ` (id eq ${channel.measurementServiceId})`;
    }
  }

  let measures = {};
  let result = yield Get(currentUser, baseQuery);
  for (let index = 0; index < result.length; index++) {
    let channelMeasurements = result[index];
    for (let key = 0; key < channelMeasurements.measurements.length; key++) {
      let currentMeasure = channelMeasurements.measurements[key];
      if (!measures[currentMeasure.dateTime]) {
        measures[currentMeasure.dateTime] = {};
      }
      measures[currentMeasure.dateTime][channelMeasurements.sensightId] =
        currentMeasure.value;
    }
  }
  yield put(setManualInsertsList(measures));
}

export function* createManualInsertRowSaga() {
  yield takeEvery(CREATE_MANUALINSERTROW, executeCreateRow);
}

function* executeCreateRow(row) {
  yield put(
    createRowSucceeded({
      ...row.row.data,
      isDeleted: false
    })
  );
}

export function* deleteManualInsertRowSaga() {
  yield takeEvery(DELETE_MANUALINSERTROW, executeDeleteRow);
}

function* executeDeleteRow(row) {
  yield put(
    deleteRowSucceeded({
      ...row.row.data,
      isDeleted: true
    })
  );
}

export function* editManualInsertRowSaga() {
  yield takeEvery(EDIT_MANUALINSERTROW, executeEditRow);
}

function* executeEditRow(row) {
  yield put(
    editRowSucceeded({
      ...row.row.data,
      isDeleted: false
    })
  );
}

export function* syncManualInsertsSaga() {
  yield takeEvery(SYNC_MANUALINSERTS, executeSync);
}

function* executeSync(event) {
  try {
    if (
      event.manualInserts.changedRows &&
      event.manualInserts.changedRows.length > 0
    ) {
      const user = yield select(currentUserSelector);
      const measurementServiceRequest = prepareSyncManualMeasurementsRequest(
        event.manualInserts.changedRows,
        event.manualInserts.manualChannelsColumns
      );
      yield syncManualMeasurements(user, measurementServiceRequest);
    }
    yield fetchManualInserts(event.manualInserts);
    yield put(setManualInsertRowsList([]));

    yield put(
      createSnackbar(t('content.manualinserts.syncmanualinsertssuccess'))
    );
  } catch (e) {
    yield put(
      createSnackbar(t('content.manualinserts.syncmanualinsertserror'), 'error')
    );
    console.log(e);
  }
}

function prepareSyncManualMeasurementsRequest(
  manualInserts,
  manualChannelsColumns
) {
  var manualMeasurements = [];

  manualInserts.forEach(manualInsert => {
    for (var propertyName in manualInsert) {
      if (
        propertyName !== 'id' &&
        propertyName !== 'dateTime' &&
        propertyName !== 'isDeleted' &&
        propertyName !== 'isNew' &&
        propertyName &&
        manualInsert[propertyName] !== undefined
      ) {
        // Lege cellen slaan we over, hier is geen data voor in de database
        if (manualInsert[propertyName] !== '') {
          const date = new Date(manualInsert.dateTime);
          const keyDate = new Date(manualInsert.id);
          const manualMeasurement = {
            value: manualInsert[propertyName]
              ? parseFloat(`${manualInsert[propertyName]}`.replace(',', '.'))
              : 0, // vul 0 als placeholder in
            dateTime: date,
            channelId: manualChannelsColumns.find(
              c => c.dataField === propertyName
            ).channelId, // Zoek de juiste bijbehorende kolom op basis van het dataField (sensightId) zodat we daar het channelID van kunnen pakken.
            isDeleted:
              typeof manualInsert[propertyName] === 'number' ||
              typeof manualInsert[propertyName] === 'string'
                ? manualInsert.isDeleted
                : true
          };

          manualMeasurements.push(manualMeasurement);

          // Wanneer de datum is gewijzigd (de unique key aan de rij) moet de oude rij worden verwijderd, behalve als het om een nieuw toegevoegde rij gaat.
          if (!isNaN(keyDate) && +date !== +keyDate) {
            manualMeasurements.push({
              ...manualMeasurement,
              dateTime: keyDate,
              isDeleted: true
            });
          }
        }
      }
    }
  });
  console.dev(
    `Generated added/deleted measurements to send to the server.`,
    manualMeasurements
  );
  return manualMeasurements;
}
