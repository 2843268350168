import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect
} from 'react';
import { Tabs, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import MuiTextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { ContactsTableContainer } from '../../contacts/contactstable/ContactsTableContainer';
import { isEmptyObject } from 'utils/helperMethods';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { fetchDashboardLocations } from 'components/content/dashboard/locations/redux/dashboardLocationsActions';
import { useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { isValidLength } from 'utils/validation';

function EditOrganizationDisplay(
  {
    row,
    fetchContactsFromOrganizationList,
    fetchLocationsFromOrganization,
    contacts,
    editOrganization,
    setSaveButtonDisabled,
    locations,
    locationsFromOrganization
  },
  ref
) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const classes = useChangeProfileDetailsCardStyles();
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (locations.length < 1) {
      dispatch(fetchDashboardLocations());
    }
    fetchContactsFromOrganizationList(row.id);
    fetchLocationsFromOrganization(row.sensightId);
  }, []);

  React.useEffect(() => {
    setOrganizationDetails({
      name: row.name,
      city: row.city,
      country: row.country,
      phoneNumber: row.phoneNumber,
      address: row.address,
      postCode: row.postCode,
      clientnumber: row.clientnumber
    });
  }, [row]);

  React.useEffect(() => {
    if (locations.length > 1) {
      const result = locationsFromOrganization.map(locationId =>
        locations.find(loc => loc.sensightId === locationId)
      );

      setSelectedLocations(result);
    }
  }, [locationsFromOrganization]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [organizationDetails, setOrganizationDetails] = React.useState({
    name: row.name === null ? row.name : '',
    city: row.city === null ? row.city : '',
    country: row.country === null ? row.country : '',
    phoneNumber: row.phoneNumber === null ? row.phoneNumber : '',
    address: row.address === null ? row.address : '',
    postCode: row.postCode === null ? row.postCode : ''
  });

  const handleSubmit = async values => {
    values.id = row.id;
    values.sensightId = row.sensightId;
    values.locationIds = selectedLocations.map(loc => loc.sensightId);
    editOrganization(values);
  };

  const handleValidate = () => {
    let errors = {};
    return errors;
  };

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (
        (dirty ||
          JSON.stringify(locationsFromOrganization) !==
            JSON.stringify(selectedLocations.map(loc => loc.sensightId))) &&
        isEmptyObject(errors)
      ) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors, selectedLocations]);
    return (
      <Form id="EditOrganizationForm" onSubmit={submitForm}>
        <Field
          name="name"
          type="text"
          label={t('content.editOrganization.input.name.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          required
          variant="standard"
        />
        <Field
          name="phoneNumber"
          type="text"
          label={t('content.editOrganization.input.phoneNumber.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="address"
          type="text"
          label={t('content.editOrganization.input.address.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="postCode"
          type="text"
          label={t('content.editOrganization.input.postCode.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="city"
          type="text"
          label={t('content.editOrganization.input.city.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="country"
          type="text"
          label={t('content.editOrganization.input.country.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
        />
        <Field
          name="clientnumber"
          type="text"
          label={t('content.editOrganization.input.clientnumber.text')}
          fullWidth
          className={classes.textField}
          component={TextField}
          variant="standard"
          validate={isValidLength}
        />
        <Autocomplete
          multiple
          filterSelectedOptions
          options={locations}
          getOptionLabel={option => option.name}
          value={selectedLocations}
          onChange={(event, newValue) => {
            setSelectedLocations(newValue);
          }}
          renderInput={params => (
            <MuiTextField
              {...params}
              name="locations"
              type="text"
              label={t('content.locationdashboard.form.locations')}
              fullWidth
              className={classes.textField}
              variant="standard"
            />
          )}
        />
      </Form>
    );
  };

  const formRef = useRef();

  const inputFields = (
    <>
      <FormikWithRef
        ref={formRef}
        enableReinitialize={true}
        validateOnMount={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={organizationDetails}
        validate={handleValidate}
        onSubmit={values => handleSubmit(values)}
      >
        {form}
      </FormikWithRef>
    </>
  );

  useImperativeHandle(ref, () => formRef);

  return (
    <>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label={t('content.editOrganization.tab.organization')} />
        <Tab label={t('content.editOrganization.tab.contacts')} />
      </Tabs>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 0}
        id={`simple-tabpanel-${0}`}
        aria-labelledby={`simple-tab-${0}`}
      >
        {inputFields}
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 1}
        id={`simple-tabpanel-${1}`}
        aria-labelledby={`simple-tab-${1}`}
      >
        <ContactsTableContainer
          organization={row.id}
          contacts={contacts}
        ></ContactsTableContainer>
      </Typography>
    </>
  );
}

const useChangeProfileDetailsCardStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

export default forwardRef(EditOrganizationDisplay);
