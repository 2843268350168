import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from '../../../imd-components/datatable/ImdDataTable';
import { EditOrganizationContainer } from '../editorganization/EditOrganizationContainer';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { Column } from 'devextreme-react/tree-list';
import { CreateOrganizationContainer } from '../createorganization/CreateOrganizationContainer';
import ImdPopup from 'components/imd-components/ImdPopup';

export default function OrganizationsTable({
  organizations,
  deleteOrganization,
  fetchOrganizationsList
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  React.useEffect(() => {
    fetchOrganizationsList();
  }, []);

  const handleChangeSaveButton = enableSaveButton => {
    setSaveButton(enableSaveButton);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    openEdit();
  };

  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteOrganization(selectedRow);
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [organizations]);

  const columns = (
    <>
      <Column
        dataField={'name'}
        caption={t('content.organizationstable.name')}
      />
      <Column
        dataField={'city'}
        caption={t('content.organizationstable.city')}
      />
      <Column
        dataField={'address'}
        caption={t('content.organizationstable.address')}
      />
      <Column
        visible={!isMobile}
        dataField={'postCode'}
        caption={t('content.organizationstable.postcode')}
      />
      <Column
        visible={!isMobile}
        dataField={'phoneNumber'}
        caption={t('content.organizationstable.phonenumber')}
      />
      <Column
        visible={!isMobile}
        dataField={'country'}
        caption={t('content.organizationstable.country')}
      />
      <Column
        visible={!isMobile}
        dataField={'clientnumber'}
        caption={t('content.organizationstable.clientnumber')}
        />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.organizationstable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditOrganizationContainer
            ref={formRef}
            row={selectedRow}
            setSaveButton={e => handleChangeSaveButton(e)}
          ></EditOrganizationContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.organizationstable.editorganization.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <CreateOrganizationContainer
            ref={formRef}
          ></CreateOrganizationContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.organizationstable.createorganization.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.organizationstable.deletepopup.title')}
        content={t('content.organizationstable.deletepopup.content', {
          organization: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        addPermission="organizations:add"
        deletePermission="organizations:delete"
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.organizations.title')}
        data={organizations}
        columns={columns}
        stateStorageKey="organizations-table-display"
      />
    </>
  );
}
