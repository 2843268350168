import React from 'react';
import { SketchPicker } from 'react-color';
import { getSelectorColors } from 'utils/themeManager';
import makeStyles from '@mui/styles/makeStyles';
import ImdButton from 'components/imd-components/ImdButton';
import { useTranslation } from 'react-i18next';
import { TinyColor } from '@ctrl/tinycolor';
import DialogActions from '@mui/material/DialogActions';

export default function ImdColorPicker(props) {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const [color, setColor] = React.useState(new TinyColor(props.color).toRgb());
  const [oldColor, setOldColor] = React.useState(
    new TinyColor(props.color).toRgb()
  );
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const onCancel = () => {
    setColor(oldColor);
    handleClose();
  };

  const handleChange = color => {
    setColor(color.rgb);
  };

  const onSave = () => {
    setOldColor(color);
    props.handleChangeComplete(
      props.rowIndex,
      new TinyColor(color).toHexString()
    );
    handleClose();
  };

  return (
    <div>
      <div className={classes.swatch} onClick={handleClick}>
        <div
          className={classes.color}
          style={{
            backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
          }}
        />
      </div>
      {displayColorPicker ? (
        <>
          <div className={classes.cover} onClick={onCancel}>
            {' '}
          </div>
          <div className={classes.popover}>
            <SketchPicker
              className={classes.sketchPicker}
              width={350}
              color={color}
              disableAlpha={true}
              presetColors={getSelectorColors()}
              onChange={handleChange}
            />
            <DialogActions>
              <ImdButton
                className={classes.buttons}
                variant="contained"
                size="small"
                onClick={onCancel}
                color="inherit"
              >
                {t('general.cancel')}
              </ImdButton>
              <ImdButton
                className={classes.buttons}
                variant="contained"
                color="primary"
                size="small"
                onClick={onSave}
              >
                {t('general.save')}
              </ImdButton>
            </DialogActions>
          </div>
        </>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles({
  sketchPicker: {
    height: '430px'
  },
  buttons: {
    top: '-42px'
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  popover: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2'
  },
  cover: {
    height: '100%',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '1'
  }
});
