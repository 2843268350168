import { createReducer } from 'utils/createReducer';
import {
  SET_SECTIONS,
  CLEAR_SECTIONS,
  ADD_SECTION,
  EDIT_SECTION,
  REMOVE_SECTION
} from './editDashboardActions';
import { fromJS } from 'immutable';

export const sections = createReducer(null, {
  [SET_SECTIONS](state, { sections }) {
    return fromJS(sections);
  },
  [CLEAR_SECTIONS]() {
    return fromJS([]);
  },
  [ADD_SECTION](state, { section }) {
    return state.push(fromJS(section));
  },
  [REMOVE_SECTION](state, { section }) {
    const sections = state
      .toJS()
      .filter(s => s.id !== section.id)
      .map((s, rowIndex) => ({
        ...s,
        rowIndex,
        items: s.items.map(i => ({ ...i, rowIndex }))
      }));
    return fromJS(sections);
  },
  [EDIT_SECTION](state, { section }) {
    let sectionList = state.toJS();
    var foundIndex = sectionList.findIndex(s => section.id === s.id);
    sectionList[foundIndex] = section;
    return fromJS(sectionList);
  }
});
