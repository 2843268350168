import greenMarker from 'components/assets/map-marker-green.png';
import orangeMarker from 'components/assets/map-marker-orange.png';
import greyMarker from 'components/assets/map-marker-grey.png';
import redMarker from 'components/assets/map-marker-red.png';
import { Marker, Tooltip } from 'devextreme-react/map';
import React from 'react';

export const getMarkerIconSrc = location => {
  if (!location) {
    return;
  }

  if (location.online === false) {
    return greyMarker;
  }
  if (location.reliabilityPercentage <= 10) {
    return redMarker;
  }
  if (location.reliabilityPercentage <= 39) {
    return orangeMarker;
  }
  return greenMarker;
};

export const getMarker = loc => {
  if (loc.latLngLatitude && loc.latLngLongitude) {
    const position = { lat: loc.latLngLatitude, lng: loc.latLngLongitude };
    return (
      <Marker iconSrc={getMarkerIconSrc(loc)} key={loc.id} location={position}>
        <Tooltip
          text={`<a href=${getTooltipContent(loc)} target="_blank">${
            loc.name
          }</a>`}
        />
      </Marker>
    );
  }
  return null;
};

const getTooltipContent = location => {
  const link = `/management/locations/${location.sensightId}`;
  return link;
};
