/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { isEmptyObject, deepEqual } from 'utils/helperMethods';
import { Typography, Switch } from '@mui/material';
import clsx from 'clsx';
import {
  getCurrentUserRole,
  currentUserIsAdmin
} from 'utils/currentUserReader';
import { Can } from 'components/imd-components/authorization/Can';

export default function NewDashboardDialogDisplay({
  allPersons,
  allOrganizations,
  handleOk,
  setSaveButtonDisabled,
  open,
  setOpen,
  createNewDashboard,
  dashboardToEdit,
  updateDashboard,
  fetchOrganizationsList,
  fetchContactsList,
  findByContactSensightId,
  findByOrganizationSensightId,
  currentUser
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const formRef = useRef();

  const [onlyAdminsCanEdit, setOnlyAdminsCanEdit] = React.useState(false);

  const [userMayEdit, setUserMayEdit] = React.useState(false);

  const [autocompleteOrganizations, setAutoCompleteOrganizations] =
    React.useState([]);

  const [
    initialAutocompleteOrganizations,
    setInitialAutoCompleteOrganizations
  ] = React.useState([]);

  const [autocompleteContacts, setAutoCompleteContacts] = React.useState([]);

  const [initialAutocompleteContacts, setInitialAutoCompleteContacts] =
    React.useState([]);

  React.useEffect(() => {
    if (open) {
      fetchOrganizationsList();
      fetchContactsList();
    }
  }, [open]);

  React.useEffect(() => {
    if (dashboardToEdit.id) {
      setInitialAutoCompleteContacts(
        findByContactSensightId(allPersons, dashboardToEdit.contactIds)
      );
      setAutoCompleteContacts(
        findByContactSensightId(allPersons, dashboardToEdit.contactIds)
      );
    }
  }, [allPersons]);

  React.useEffect(() => {
    if (dashboardToEdit.id) {
      setInitialAutoCompleteOrganizations(
        findByOrganizationSensightId(
          allOrganizations,
          dashboardToEdit.organizationIds
        )
      );

      setAutoCompleteOrganizations(
        findByOrganizationSensightId(
          allOrganizations,
          dashboardToEdit.organizationIds
        )
      );
    }
  }, [allOrganizations]);

  React.useEffect(() => {
    if (dashboardToEdit.id) {
      setUserMayEdit(true);
      if (
        dashboardToEdit.onlyAdminsCanEdit &&
        !currentUserIsAdmin(currentUser)
      ) {
        setUserMayEdit(false);
      }
      setOnlyAdminsCanEdit(dashboardToEdit.onlyAdminsCanEdit);
      setInitialAutoCompleteContacts(
        findByContactSensightId(allPersons, dashboardToEdit.contactIds)
      );
      setInitialAutoCompleteOrganizations(
        findByOrganizationSensightId(
          allOrganizations,
          dashboardToEdit.organizationIds
        )
      );
      setAutoCompleteContacts(
        findByContactSensightId(allPersons, dashboardToEdit.contactIds)
      );
      setAutoCompleteOrganizations(
        findByOrganizationSensightId(
          allOrganizations,
          dashboardToEdit.organizationIds
        )
      );
    } else {
      setUserMayEdit(true);
      setInitialAutoCompleteContacts([]);
      setInitialAutoCompleteOrganizations([]);
      setAutoCompleteContacts([]);
      setAutoCompleteOrganizations([]);
    }
  }, [dashboardToEdit.id]);

  const handleValidate = values => {
    let errors = {};

    if (
      values.title === '' ||
      values.title === null ||
      values.title === undefined
    ) {
      errors.title = t('general.validation.required');
    }

    if (
      autocompleteContacts.length === 0 &&
      autocompleteOrganizations.length === 0
    ) {
      errors.organizations = t('general.validation.required');
      errors.persons = t('general.validation.required');
    }
    return errors;
  };

  const handleSwitchChange = () => {
    setOnlyAdminsCanEdit(!onlyAdminsCanEdit);
  };

  const submitForm = values => {
    const result = {
      title: values.title,
      autoRefreshInMinutes: values.autorefresh,
      organizationIds: autocompleteOrganizations.map(org => org.sensightId),
      contactIds: autocompleteContacts.map(con => con.sensightId),
      onlyAdminsCanEdit: onlyAdminsCanEdit
    };

    if (Object.entries(dashboardToEdit).length !== 0) {
      result.id = dashboardToEdit.id;
      updateDashboard(result);
    } else {
      createNewDashboard(result);
    }

    handleOk();
  };

  const checkIfUndefined = (property, defaultValue = '') => {
    return property !== undefined ? property : defaultValue;
  };

  const form = ({ dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (
        (dirty ||
          !deepEqual(initialAutocompleteContacts, autocompleteContacts) ||
          !deepEqual(
            initialAutocompleteOrganizations,
            autocompleteOrganizations
          ) ||
          dashboardToEdit.onlyAdminsCanEdit !== onlyAdminsCanEdit) &&
        isEmptyObject(errors)
      ) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [
      dirty,
      errors,
      autocompleteOrganizations,
      autocompleteContacts,
      onlyAdminsCanEdit
    ]);

    const SwitchItem = props => {
      return (
        <Can
          role={getCurrentUserRole(currentUser)}
          perform="dashboard:manageAdminOnly"
          yes={() => (
            <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
              <Typography variant="body1" className={classes.switchLabel}>
                {props.name}
              </Typography>
              <div className={classes.switchItem}>
                <Switch
                  checked={onlyAdminsCanEdit}
                  onChange={() => handleSwitchChange()}
                  value="hide"
                  color="primary"
                  disabled={!userMayEdit}
                />
              </div>
            </div>
          )}
          no={() => null}
        />
      );
    };

    return (
      <Form>
        <Field
          name="title"
          type="text"
          label={'Titel'}
          fullWidth
          className={classes.inputField}
          component={TextField}
          disabled={!userMayEdit}
          variant="standard"
        ></Field>
        <Autocomplete
          multiple
          filterSelectedOptions
          options={allOrganizations}
          getOptionLabel={option => option.name}
          value={autocompleteOrganizations}
          onChange={(event, newValue) => {
            setAutoCompleteOrganizations(newValue);
          }}
          renderInput={params => (
            <Field
              {...params}
              name="organizations"
              type="text"
              label={t('content.locationdashboard.form.organizations')}
              fullWidth
              className={classes.inputField}
              component={TextField}
              variant="standard"
            />
          )}
          disabled={!userMayEdit}
        />
        <Autocomplete
          multiple
          filterSelectedOptions
          options={allPersons}
          getOptionLabel={option => option.name}
          value={autocompleteContacts}
          onChange={(event, newValue) => {
            setAutoCompleteContacts(newValue);
          }}
          renderInput={params => (
            <Field
              {...params}
              name="persons"
              type="text"
              label={t('content.dashboard.new.form.persons')}
              fullWidth
              className={classes.inputField}
              component={TextField}
              variant="standard"
            />
          )}
          disabled={!userMayEdit}
        />
        <Field
          name="autorefresh"
          type="select"
          label={t('content.dashboard.new.form.refresh')}
          select
          fullWidth
          className={classes.inputField}
          component={TextField}
          disabled={!userMayEdit}
          variant="standard"
        >
          <MenuItem value={0}>
            {t('content.dashboard.new.form.refresh.never')}
          </MenuItem>
          <MenuItem value={1}>
            {t('content.dashboard.new.form.refresh.oneminute')}
          </MenuItem>
          <MenuItem value={5}>
            {t('content.dashboard.new.form.refresh.fiveminutes')}
          </MenuItem>
          <MenuItem value={15}>
            {t('content.dashboard.new.form.refresh.fifteenminutes')}
          </MenuItem>
          <MenuItem value={60}>
            {t('content.dashboard.new.form.refresh.hour')}
          </MenuItem>
        </Field>
        <SwitchItem
          name={t('content.dashboard.new.form.adminOnly')}
          disabled={!userMayEdit}
        />
      </Form>
    );
  };

  return (
    <ImdDialogContainer
      resizeToContent={true}
      open={open}
      handleCloseAction={setOpen}
      formRef={formRef}
      content={
        <>
          <FormikWithRef
            ref={formRef}
            enableReinitialize={true}
            validate={values => handleValidate(values)}
            onSubmit={values => submitForm(values)}
            initialValues={{
              title: checkIfUndefined(dashboardToEdit.title),
              organizations: initialAutocompleteOrganizations,
              persons: initialAutocompleteContacts,
              autorefresh: checkIfUndefined(
                dashboardToEdit.autoRefreshInMinutes,
                0
              ),
              onlyAdminsCanEdit: checkIfUndefined(
                dashboardToEdit.onlyAdminsCanEdit,
                false
              )
            }}
          >
            {form}
          </FormikWithRef>
        </>
      }
      dialogTitle={t('content.dashboard.new.form.title')}
    />
  );
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: '16px'
  },
  switchWrapper: {
    display: 'flex',
    marginBottom: '16px'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  }
});
