import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_MANUALCHANNELS = 'FETCH_MANUALCHANNELS';
export const fetchManualChannels = makeActionCreator(FETCH_MANUALCHANNELS);

export const FETCH_MANUALINSERTS = 'FETCH_MANUALINSERTS';
export const fetchManualInserts = makeActionCreator(
  FETCH_MANUALINSERTS,
  'selectedChannels'
);

export const SET_MANUALCHANNELS_LIST = 'SET_MANUALCHANNELS_LIST ';
export const setManualChannelsList = makeActionCreator(
  SET_MANUALCHANNELS_LIST,
  'manualChannelsList'
);

export const SET_MANUALINSERTS_LIST = 'SET_MANUALINSERTS_LIST ';
export const setManualInsertsList = makeActionCreator(
  SET_MANUALINSERTS_LIST,
  'manualInsertsList'
);

export const SET_MANUALINSERTROWS_LIST = 'SET_MANUALINSERTROWS_LIST ';
export const setManualInsertRowsList = makeActionCreator(
  SET_MANUALINSERTROWS_LIST,
  'manualInsertRowsList'
);

export const CREATE_MANUALINSERTROW = 'CREATE_MANUALINSERTROW';
export const createManualInsertRow = makeActionCreator(
  CREATE_MANUALINSERTROW,
  'row'
);

export const EDIT_MANUALINSERTROW = 'EDIT_MANUALINSERTROW';
export const editManualInsertRow = makeActionCreator(
  EDIT_MANUALINSERTROW,
  'row'
);

export const DELETE_MANUALINSERTROW = 'DELETE_MANUALINSERTROW';
export const deleteManualInsertRow = makeActionCreator(
  DELETE_MANUALINSERTROW,
  'row'
);

export const EDIT_ROW_SUCCEEDED = 'EDIT_ROW_SUCCEEDED';
export const editRowSucceeded = makeActionCreator(EDIT_ROW_SUCCEEDED, 'row');

export const CREATE_ROW_SUCCEEDED = 'CREATE_ROW_SUCCEEDED';
export const createRowSucceeded = makeActionCreator(
  CREATE_ROW_SUCCEEDED,
  'row'
);

export const DELETE_ROW_SUCCEEDED = 'DELETE_ROW_SUCCEEDED';
export const deleteRowSucceeded = makeActionCreator(
  DELETE_ROW_SUCCEEDED,
  'row'
);

export const SYNC_MANUALINSERTS = 'SYNC_MANUALINSERTS';
export const syncManualInserts = makeActionCreator(
  SYNC_MANUALINSERTS,
  'manualInserts'
);

export const SET_CURRENT_MANUALINSERTS_PERIOD =
  'SET_CURRENT_MANUALINSERTS_PERIOD';
export const setManualInsertsPeriod = makeActionCreator(
  SET_CURRENT_MANUALINSERTS_PERIOD,
  'period'
);
