import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_CHANNELS_LIST_OF_LOCATION =
  'FETCH_CHANNELS_LIST_OF_LOCATION';
export const fetchChannelsListOfLocation = makeActionCreator(
  FETCH_CHANNELS_LIST_OF_LOCATION,
  'location',
  'locationSensightId'
);

export const SET_CHANNELS_LIST_OF_LOCATION = 'SET_CHANNELS_LIST_OF_LOCATION';
export const setChannelsListOfLocation = makeActionCreator(
  SET_CHANNELS_LIST_OF_LOCATION,
  'channelsList'
);

export const EDIT_CHANNEL_OF_LOCATION = 'EDIT_CHANNEL_OF_LOCATION';
export const editChannelOfLocation = makeActionCreator(
  EDIT_CHANNEL_OF_LOCATION,
  'channel'
);

export const EDIT_CHANNEL_OF_LOCATION_SUCCEEDED =
  'EDIT_CHANNEL_OF_LOCATION_SUCCEEDED';
export const editChannelOfLocationSucceeded = makeActionCreator(
  EDIT_CHANNEL_OF_LOCATION_SUCCEEDED,
  'channel'
);

export const DELETE_CHANNEL_OF_LOCATION = 'DELETE_CHANNEL_OF_LOCATION';
export const deleteChannelOfLocation = makeActionCreator(
  DELETE_CHANNEL_OF_LOCATION,
  'channel'
);

export const DELETE_CHANNEL_OF_LOCATION_SUCCEEDED =
  'DELETE_CHANNEL_OF_LOCATION_SUCCEEDED ';
export const deleteChannelOfLocationSucceeded = makeActionCreator(
  DELETE_CHANNEL_OF_LOCATION_SUCCEEDED,
  'channel'
);
