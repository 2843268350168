import { connect } from 'react-redux';
import SimCardsTableDisplay from './SimCardsTableDisplay.jsx';
import { setSimCard, deleteSimCard } from '../redux/simCardsActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    simCards: ownProps.simCards,
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteSimCard(row) {
    dispatch(deleteSimCard(row));
  },
  setSimCard(row) {
    dispatch(setSimCard(row));
  }
});

export const SimCardsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimCardsTableDisplay);
