import { takeEvery, select, put } from 'redux-saga/effects';
import {
  FETCH_DASHBOARD_LOCATIONS,
  setDashboardLocations
} from './dashboardLocationsActions';
import { getAllLocations } from 'apis/FieldServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchDashboardLocationsSaga() {
  yield takeEvery(FETCH_DASHBOARD_LOCATIONS, execute);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var locations = (yield getAllLocations(user)).data;
  yield put(setDashboardLocations(locations));
}
