import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_LOCATIONS_LIST = 'FETCH_LOCATIONS_LIST';
export const fetchLocationsList = makeActionCreator(FETCH_LOCATIONS_LIST);

export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST ';
export const setLocationsList = makeActionCreator(
  SET_LOCATIONS_LIST,
  'locationsList'
);

export const CREATE_LOCATION_SUCCEEDED = 'CREATE_LOCATION_SUCCEEDEDs';
export const createLocationSucceeded = makeActionCreator(
  CREATE_LOCATION_SUCCEEDED,
  'location'
);

export const EDIT_LOCATION_SUCCEEDED = 'EDIT_LOCATION_SUCCEEDED';
export const editLocationSucceeded = makeActionCreator(
  EDIT_LOCATION_SUCCEEDED,
  'location'
);

export const DELETE_LOCATION_SUCCEEDED = 'DELETE_LOCATION_SUCCEEDED ';
export const deleteLocationSucceeded = makeActionCreator(
  DELETE_LOCATION_SUCCEEDED,
  'location'
);
