import { createReducer } from 'utils/createReducer';
import {
  SET_LOCATIONTYPES_LIST,
  CREATE_LOCATIONTYPE_SUCCEEDED,
  DELETE_LOCATIONTYPE_SUCCEEDED,
  EDIT_LOCATIONTYPE_SUCCEEDED
} from './locationTypesListActions';
import { fromJS } from 'immutable';

export const locationtypesList = createReducer(null, {
  [SET_LOCATIONTYPES_LIST](state, { locationtypesList }) {
    return fromJS(locationtypesList);
  },
  [CREATE_LOCATIONTYPE_SUCCEEDED](state, { locationtype }) {
    return state.push(fromJS(locationtype));
  },
  [EDIT_LOCATIONTYPE_SUCCEEDED](state, { locationtype }) {
    let locationtypesList = state.toJS();
    var foundLocationTypeIndex = locationtypesList.findIndex(
      org => locationtype.id === org.id
    );
    locationtypesList[foundLocationTypeIndex] = locationtype;
    return fromJS(locationtypesList);
  },
  [DELETE_LOCATIONTYPE_SUCCEEDED](state, { locationtype }) {
    return state.filter(org => org.get('id') !== locationtype.id);
  }
});
