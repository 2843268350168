import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import { Map } from 'devextreme-react/map';
import Paper from '@mui/material/Paper';
import LocationDataTable from 'components/imd-components/datatable/LocationDataTable';
import { useTranslation } from 'react-i18next';
import { getMarker } from 'utils/mapHelpers';
import configFile from '../../../../App_config.json';

export function DashboardLocationsDisplay({
  fetchLocationsList,
  allLocations,
  render
}) {
  const classes = useStyles();
  const [redLocations, setRedLocations] = useState([]);
  const [greenLocations, setGreenLocations] = useState([]);
  const [yellowLocations, setYellowLocations] = useState([]);
  const [greyLocations, setGreyLocations] = useState([]);
  const [currentLocations, setCurrentLocations] = useState([]);
  const [allVisibleLocations, setAllVisibleLocations] = useState([]);
  const { t } = useTranslation();
  const isMobile = window.matchMedia('(max-width: 450px)').matches;

  React.useEffect(() => {
    if (allLocations && !allLocations.length) {
      fetchLocationsList();
    }
  }, []);

  useEffect(() => {
    let redLocationsArray = [];
    let yellowLocationsArray = [];
    let greenLocationsArray = [];
    let greyLocationsArray = [];
    let allVisibleLocationsArray = [];

    if (allLocations.length > 0) {
      allLocations.forEach(location => {
        if (!location.visible) return;

        allVisibleLocationsArray.push(location);

        if (location.online === false) {
          greyLocationsArray.push(location);
          return;
        }
        if (location.reliabilityPercentage <= 10) {
          redLocationsArray.push(location);
          return;
        }
        if (location.reliabilityPercentage <= 39) {
          yellowLocationsArray.push(location);
          return;
        }
        greenLocationsArray.push(location);
      });
    }

    setAllVisibleLocations(allVisibleLocationsArray);
    setRedLocations(redLocationsArray);
    setYellowLocations(yellowLocationsArray);
    setGreenLocations(greenLocationsArray);
    setGreyLocations(greyLocationsArray);
    setCurrentLocations(allVisibleLocationsArray);
  }, [allLocations]);

  if (!render) {
    return null;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={5} md={7} sm={12} xs={12}>
          <div className={classes.contentWrapper}>
            <div className={classes.buttonWrapper}>
              <div
                className={clsx(classes['totaal'], classes.button)}
                onClick={() => setCurrentLocations(allVisibleLocations)}
              >
                <span className={classes.identifier}>
                  {allVisibleLocations.length}
                </span>
                <span className={classes.identifierType}>
                  {t('content.dashboard.locations.total')}
                </span>
              </div>
              <div
                className={clsx(classes['afgekeurd'], classes.button)}
                onClick={() => setCurrentLocations(redLocations)}
              >
                <span className={classes.identifier}>
                  {redLocations.length}
                </span>
                <span className={classes.identifierType}>
                  {t('content.dashboard.locations.rejected')}
                </span>
              </div>
              <div
                className={clsx(classes['twijfel'], classes.button)}
                onClick={() => setCurrentLocations(yellowLocations)}
              >
                <span className={classes.identifier}>
                  {yellowLocations.length}
                </span>
                <span className={classes.identifierType}>
                  {t('content.dashboard.locations.doubt')}
                </span>
              </div>
              <div
                className={clsx(classes['goed'], classes.button)}
                onClick={() => setCurrentLocations(greenLocations)}
              >
                <span className={classes.identifier}>
                  {greenLocations.length}
                </span>
                <span className={classes.identifierType}>
                  {t('content.dashboard.locations.correct')}
                </span>
              </div>
              <div
                className={clsx(classes['offline'], classes.button)}
                onClick={() => setCurrentLocations(greyLocations)}
              >
                <span className={classes.identifier}>
                  {greyLocations.length}
                </span>
                <span className={classes.identifierType}>
                  {t('content.dashboard.locations.offline')}
                </span>
              </div>
            </div>

            <div className={classes.searchLocationsBox}>
              <Paper className={classes.searchPaper}>
                <div className={classes.tableWrapper}>
                  <LocationDataTable
                    data={currentLocations}
                  ></LocationDataTable>
                </div>
              </Paper>
            </div>
          </div>
        </Grid>
        {!isMobile ? (
          <Grid item lg={7} md={5} sm={12} xs={12}>
            <Map
              id="bingMap"
              defaultZoom={18}
              width="100%"
              height="80vh"
              controls={true}
              provider="bing"
              autoAdjust={true}
              apiKey={configFile.bingMapsAPIKey}
            >
              {currentLocations ? (
                currentLocations.map(loc => getMarker(loc))
              ) : (
                <> </>
              )}
            </Map>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  card: {
    minWidth: 275
  },
  contentWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    maxWidth: '93vw'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  container: {
    gap: '22px'
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  },
  searchLocationsBox: {
    height: '100%',
    display: 'flex',
    flexGrow: '1'
  },
  searchPaper: {
    marginTop: '8px',
    paddingTop: '16px',
    paddingRight: '12px',
    paddingLeft: '12px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonWrapper: {
    display: 'flex',
    gap: '8px'
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    width: '114px',
    color: '#FFFFFF',
    paddingLeft: '8px',
    paddingBottom: '8px',
    paddingTop: '8px',
    borderRadius: '8px',
    flexGrow: '1'
  },
  totaal: {
    backgroundColor: '#0060A7',
    '&:hover': {
      backgroundColor: '#004a81',
      cursor: 'pointer'
    }
  },
  afgekeurd: {
    backgroundColor: '#c4161d',
    '&:hover': {
      backgroundColor: '#B9151B',
      cursor: 'pointer'
    }
  },
  twijfel: {
    backgroundColor: '#ba6f00',
    '&:hover': {
      backgroundColor: '#AD6700',
      cursor: 'pointer'
    }
  },
  goed: {
    backgroundColor: '#377f3a',
    '&:hover': {
      backgroundColor: '#347636',
      cursor: 'pointer'
    }
  },
  offline: {
    backgroundColor: '#8F8F8F',
    '&:hover': {
      backgroundColor: '#818181',
      cursor: 'pointer'
    }
  },
  identifier: {
    fontWeight: 'bold',
    fontSize: '1.4rem'
  },
  identifierType: {
    fontSize: '0.85rem'
  },
  search: {
    marginBottom: '8px'
  },
  pagination: {
    marginTop: 'auto'
  },
  percentageButton: {
    color: '#FFFFFF',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '4px',
    paddingTop: '4px',
    borderRadius: '8px',
    textAlign: 'center'
  },
  mapIcon: {
    position: 'relative',
    top: '5px',
    marginRight: '4px'
  },
  tableCell: {
    color: theme.palette.primary.main
  }
}));
