import { createReducer } from 'utils/createReducer';
import { fromJS } from 'immutable';
import {
  SET_CONTACT_IS_USER,
  CLEAR_CONTACT_IS_USER
} from './createContactActions';

export const contactIsUser = createReducer(null, {
  [SET_CONTACT_IS_USER](_state, { contactIsUser }) {
    return fromJS(contactIsUser);
  },
  [CLEAR_CONTACT_IS_USER]() {
    return fromJS(false);
  }
});
