import { connect } from 'react-redux';
import { ChangeLocaleDisplay } from './ChangeLocaleDisplay.jsx';
import { profileDetailsSelector } from '../redux/profileDetailsSelectors';
import { changeLocaleSettings } from './redux/changeLocaleActions';

const mapStateToProps = state => {
  let profileDetails = profileDetailsSelector(state);

  return {
    profileDetails: profileDetails
  };
};

const mapDispatchToProps = dispatch => ({
  changeLocaleSettings(localeSettings) {
    dispatch(changeLocaleSettings(localeSettings));
  }
});

export const ChangeLocaleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeLocaleDisplay);
