export function sortByIpAddressThenICCID(a, b) {
  if (!a || !b) return 0;

  //if no ipAddresses available
  if (!a.ipAddress && !b.ipAddress) {
    if (!a.iccid && !b.iccid) return 0;
    if (a.iccid && b.iccid) return Number(a.iccid - b.iccid);
    if (!a.iccid && b.iccid) return -1;
    if (a.iccid && !b.iccid) return 1;
  } else {
    return sortIpAddress(a.ipAddress, b.ipAddress);
  }
}

export function sortIpAddress(a, b) {
  if (!a || !b) return 0;

  if (!a && b) return -1;

  if (a && !b) return 1;

  const num1 = Number(
    a
      .split('.')
      .map(num => `000${num}`.slice(-3))
      .join('')
  );
  const num2 = Number(
    b
      .split('.')
      .map(num => `000${num}`.slice(-3))
      .join('')
  );
  return num1 - num2;
}
