import { connect } from 'react-redux';
import { ExportDisplay } from './ExportDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import {
  selectedChannelsSelector,
  defaultChannelAggregationTimeSelector,
  defaultChannelAggregationTypeSelector
} from '../visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors.js';
import {
  visualizeFromTimeSelector,
  visualizeUntilTimeSelector
} from '../visualize/toolbar/redux/toolbarSelectors.js';
import { createSnackbar } from 'redux/ui/uiActions';

const mapStateToProps = state => {
  return {
    currentUser: currentUserSelector(state),
    selectedChannels: selectedChannelsSelector(state),
    fromPeriod: visualizeFromTimeSelector(state),
    toPeriod: visualizeUntilTimeSelector(state),
    defaultAggregationType: defaultChannelAggregationTypeSelector(state),
    defaultAggregationTime: defaultChannelAggregationTimeSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  createSnackbar(message, variant, duration) {
    dispatch(createSnackbar(message, variant, duration));
  }
});

export const ExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportDisplay);
