import {
  FETCH_TENANTS,
  CREATE_TENANT,
  EDIT_TENANT,
  DELETE_TENANT,
  createTenantSucceeded,
  editTenantSucceeded,
  deleteTenantSucceeded,
  setTenantsList
} from './tenantsActions';
import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  getAllTenants,
  createTenant,
  editTenant,
  deleteTenant
} from 'apis/PlatformServiceApi';
import { t } from 'i18next';

export function* fetchTenantsListSaga() {
  yield takeEvery(FETCH_TENANTS, executeList);
}

function* executeList() {
  try {
    const user = yield select(currentUserSelector);
    var platformServiceTenantsResponse = yield getAllTenants(user);
    var platformServiceTenants = platformServiceTenantsResponse.data;
    yield put(setTenantsList(platformServiceTenants));
  } catch (e) {
    yield put(createSnackbar(t('content.tenant.fetchtenantserror'), 'error'));
    console.log(e);
  }
}

export function* createTenantSaga() {
  yield takeEvery(CREATE_TENANT, executeCreate);
}

export function* executeCreate(object) {
  try {
    const user = yield select(currentUserSelector);
    const tenantDetails = object.tenantDetails;
    const createdTenantResponse = (yield createTenant(user, tenantDetails))
      .data;
    yield put(createSnackbar(t('content.createtenant.snackbar.text')));
    yield put(createTenantSucceeded(createdTenantResponse.result));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* editTenantSaga() {
  yield takeEvery(EDIT_TENANT, executeEdit);
}

function* executeEdit(object) {
  try {
    const user = yield select(currentUserSelector);
    const tenantDetails = object.tenantDetails;
    yield editTenant(user, tenantDetails);
    yield put(createSnackbar(t('content.edittenant.snackbar.text')));
    yield put(editTenantSucceeded(tenantDetails));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* deleteTenantSaga() {
  yield takeEvery(DELETE_TENANT, executeDelete);
}

function* executeDelete(action) {
  const tenant = action.deleteObject.selectedRow;
  const user = yield select(currentUserSelector);

  try {
    yield deleteTenant(user, tenant.id);
    yield put(createSnackbar(t('content.deletetenant.snackbar.text')));
    yield put(deleteTenantSucceeded(tenant));
  } catch (exception) {
    console.error(exception);
  }
}
