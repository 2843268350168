import { useSelector } from 'react-redux';
import React from 'react';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getCurrentUserTenant,
  getCurrentUserRole
} from 'utils/currentUserReader';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { t } from 'i18next';

export function getRoleMenuItems() {
  const currentUser = useSelector(state => currentUserSelector(state));
  const role = getCurrentUserRole(currentUser);
  const { t } = useTranslation();
  let roles = [
    'Reader',
    'Contributor',
    'Administrator',
    'PlatformAdministrator'
  ];

  let filteredRoles = [];
  switch (role) {
    case 'Contributor':
      roles.pop();
      roles.pop();
      filteredRoles = roles;
      break;
    case 'Administrator':
      roles.pop();
      filteredRoles = roles;
      break;
    case 'PlatformAdministrator':
      filteredRoles = roles;
      break;
  }

  const menuItems = filteredRoles.map((role, index) => {
    return (
      <MenuItem key={index} value={role}>
        {t('content.contactform.role.' + role)}
      </MenuItem>
    );
  });

  return { menuItems };
}

export const getTenantMenuItems = () => {
  const currentUser = useSelector(state => currentUserSelector(state));
  let tenants = ['IMD', 'Nijhuis'];
  let filteredTenants = [];

  const role =
    currentUser[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ][0];

  switch (role) {
    case 'Administrator':
      filteredTenants = [getCurrentUserTenant()];
      break;
    case 'PlatformAdministrator':
      filteredTenants = tenants;
      break;
  }

  const menuItems = filteredTenants.map((tenant, index) => {
    return (
      <MenuItem key={index} value={tenant}>
        {tenant}
      </MenuItem>
    );
  });

  return { menuItems };
};

export const getError = exception => {
  switch (exception.response.status) {
    case 400:
      switch (exception.response.data) {
        case 52:
          return t('content.user.error.duplicateusername');
        default:
          return t('content.user.error.generic');
      }
    case 401:
      return t('content.user.error.authorization');
    case 403:
      return t('content.user.error.authorization');
    case 409:
      return t('content.user.error.duplicateemail');
    default:
      return t('content.user.error.generic');
  }
};

export const getRoleFromValue = value => {
  switch (value) {
    case 0:
      return 'Reader';
    case 1:
      return 'Contributor';
    case 2:
      return 'Administrator';
    case 3:
      return 'PlatformAdministrator';
    default:
      break;
  }
};

export const getValueFromRole = role => {
  switch (role) {
    case 'Reader':
      return 0;
    case 'Contributor':
      return 1;
    case 'Administrator':
      return 2;
    case 'PlatformAdministrator':
      return 3;
    default:
      break;
  }
};
