import { connect } from 'react-redux';
import CreateContactDisplay from './CreateContactDisplay.jsx';
import {
  createContact,
  setContactIsUser,
  clearContactIsUser
} from './redux/createContactActions';
import { t } from 'i18next';
import { organizationsListSelector } from 'components/content/organizations/redux/organizationsListSelectors';
import { fetchOrganizationsList } from 'components/content/organizations/redux/organizationsListActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { contactIsUserSelector } from './redux/createContactSelectors.js';

const mapStateToProps = (state, ownProps) => {
  return {
    organization: ownProps.organization,
    organizations: organizationsListSelector(state),
    currentUser: currentUserSelector(state),
    contactIsUser: contactIsUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  createContact(contactInformation) {
    dispatch(createContact(contactInformation));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  fetchOrganizationsList() {
    dispatch(fetchOrganizationsList());
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  setContactIsUser(value) {
    dispatch(setContactIsUser(value));
  },
  clearContactIsUser() {
    dispatch(clearContactIsUser());
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.email) {
    errors.email = t('general.validation.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = t(
      'content.profile.changeprofiledetails.card.input.email.error'
    );
  }

  if (!values.name) {
    errors.name = t('general.validation.required');
  }

  if (!values.organizationid) {
    errors.organizationid = t('general.validation.required');
  }

  return errors;
};

export const CreateContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateContactDisplay);
