import { connect } from 'react-redux';
import EditLogbookActionDisplay from './EditLogbookActionDisplay.jsx';
import { logbookActionSelector } from '../redux/logbookActionsSelectors.js';
import {
  editLogbookAction,
  upsertLogbookActionDetails
} from '../redux/logbookActionsActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = state => {
  var viewModel = logbookActionSelector(state);
  return {
    viewModel: viewModel
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  editLogbookAction(sensightId) {
    dispatch(editLogbookAction(sensightId));
  },
  upsertLogbookActionDetails(logbookActionDetails) {
    dispatch(upsertLogbookActionDetails(logbookActionDetails));
  }
});

export const EditLogbookActionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(EditLogbookActionDisplay);
