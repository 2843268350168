import { createReducer } from 'utils/createReducer';
import {
  SET_SIMCARD,
  SET_SIMCARDS_LIST,
  CREATE_SIMCARD_SUCCEEDED,
  EDIT_SIMCARD_SUCCEEDED,
  DELETE_SIMCARD_SUCCEEDED
} from './simCardsActions';
import { fromJS } from 'immutable';

export const simCardsList = createReducer(null, {
  [SET_SIMCARDS_LIST](state, { simCardsList }) {
    return fromJS(simCardsList);
  },
  [CREATE_SIMCARD_SUCCEEDED](state, { simCard }) {
    return state.push(fromJS(simCard));
  },
  [EDIT_SIMCARD_SUCCEEDED](state, { simCard }) {
    let simCardsList = state.toJS();
    var foundSimCardIndex = simCardsList.findIndex(
      sim => simCard.id === sim.id
    );
    simCardsList[foundSimCardIndex] = simCard;
    return fromJS(simCardsList);
  },
  [DELETE_SIMCARD_SUCCEEDED](state, { simCard }) {
    return state.filter(con => con.get('id') !== simCard.id);
  }
});

export const simCard = createReducer(null, {
  [SET_SIMCARD](state, { simCard }) {
    return fromJS(simCard);
  }
});
