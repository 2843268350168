import { takeEvery, select, put } from 'redux-saga/effects';
import { CREATE_NEW_DASHBOARD } from './newDashboardDialogActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createNewDashboard } from 'apis/ReportingServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { push } from 'connected-react-router';
import { addDashboard } from '../../redux/myDashboardActions';

export function* createNewDashboardSaga() {
  yield takeEvery(CREATE_NEW_DASHBOARD, execute);
}

function* execute(action) {
  try {
    const user = yield select(currentUserSelector);
    const dashboardToCreate = action.dashboard;
    dashboardToCreate.authorizedOrganizations =
      dashboardToCreate.organizationIds;
    dashboardToCreate.authorizedContacts = dashboardToCreate.contactIds;

    const result = yield createNewDashboard(user, dashboardToCreate);

    if (result.status === 200) {
      const dashboard = result.data.result;
      dashboard.contactIds = [];
      dashboard.authorizations.forEach(a => {
        if (a.relationType === 1) {
          dashboard.contactIds.push(a.relationId);
        }
      });

      dashboard.organizationIds = [];
      dashboard.authorizations.forEach(a => {
        if (a.relationType === 0) {
          dashboard.organizationIds.push(a.relationId);
        }
      });
      yield put(addDashboard(dashboard));

      yield put(push(`/dashboard/${dashboard.id}/edit`));
    }
    if (result.status !== 200 && result.status !== 204) {
      put(createSnackbar(t('content.dashboard.new.error'), 'error'));
    }
  } catch (exception) {
    console.error(exception);
  }
}
