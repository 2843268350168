import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import TuneIcon from '@mui/icons-material/Tune';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { generateVisualisationLink } from 'utils/helperMethods';
import ImdButton from 'components/imd-components/ImdButton';
import ImdDatePicker from 'components/imd-components/ImdDatePicker';
import ImdTooltip from 'components/imd-components/ImdTooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { DisplayChannelOptionsDialogContainer } from '../dialogs/channelOptionsDialog/DisplayChannelOptionsDialogContainer';
import { SelectChannelDialogContainer } from '../dialogs/selectChannelDialog/selectChannelDialogContainer';

export function DesktopToolbar({
  clearSelectChannelDialogStateOnOpen,
  clearSelectChannelDialogStateImmediately,
  showDialog,
  visualizeFromTime,
  visualizeUntilTime,
  visualizePeriod,
  setVisualizeFromTime,
  setVisualizeUntilTime,
  setCurrentVisualizePeriod,
  setPreviousVisualizePeriod,
  setNextVisualizePeriod,
  setTodayVisualizePeriod,
  selectedChannels,
  chartDisplayOptions,
  chartRendered,
  displayChart,
  isLoading,
  settings,
  chartSettings
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [
    clearSelectChannelDialogStateOnToolbarVisit,
    setClearSelectChannelDialogStateOnToolbarVisit
  ] = useState(false);

  const user = useSelector(state => currentUserSelector(state));
  const autoRefreshPeriod = 60000;

  const handleChange = event => {
    setCurrentVisualizePeriod(event.target.value);
  };

  const executeAutoRefresh = (autoRefresh, chartRendered) => {
    if (autoRefresh && chartRendered) {
      displayChart();
    }
  };

  useEffectOnce(() => {
    if (!chartRendered && clearSelectChannelDialogStateOnOpen) {
      console.dev(
        `Chart not rendered, initial render of toolbar and clear state is enabled, setting select channel dialog reset on visit on true..`
      );
      setClearSelectChannelDialogStateOnToolbarVisit(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      executeAutoRefresh(chartDisplayOptions.autoRefresh, chartRendered);
    }, autoRefreshPeriod);
    return () => clearInterval(interval);
  }, [chartDisplayOptions.autoRefresh, chartRendered]);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

    const handleExportToLink = async event => {
        let visualisationData =
        {
            selectionDataParameter: {
                visualizeFromTime: new Date(visualizeFromTime),
                visualizeUntilTime: new Date(visualizeUntilTime),
                visualizePeriod
            },
            displayOption: chartDisplayOptions,
            selectedChannels
        };
    var copyInputField = document.getElementById('copyInputField');

    console.dev(
      `Generating export object from settings:`,
        visualisationData
      );

    // Aanroep voor het genereren van de link (nieuw)
    copyInputField.value = await generateVisualisationLink(user, visualisationData);

    copyInputField.style.display = 'block';
    copyInputField.select();
    document.execCommand('copy');
    copyInputField.style.display = 'none';

    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <>
      <input id="copyInputField" type="text" hidden></input>
      <DisplayChannelOptionsDialogContainer
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
      <SelectChannelDialogContainer
        clearSelectChannelDialogStateOnOpen={
          clearSelectChannelDialogStateOnToolbarVisit
        }
        clearSelectChannelDialogStateImmediately={
          clearSelectChannelDialogStateImmediately
        }
        chartSettings={chartSettings}
      />
      <div className={classes.toolbar}>
        <div className={classes.accentBar}></div>
        <Grid container spacing={2} className={classes.container}>
          <Grid item lg={2}>
            <div className={classes.buttonsContent}>
              <ImdButton
                variant="contained"
                color="inherit"
                startIcon={<TuneIcon />}
                onClick={() => showDialog()}
              >
                {t('content.visualize.toolbar.channels.button.text')}
              </ImdButton>
            </div>
          </Grid>
          <Grid item lg={7} className={classes.tijdsinstellingen}>
            <div className={classes.columnContent}>
              <ImdDatePicker
                labelText={t('content.visualize.toolbar.time.from')}
                date={visualizeFromTime}
                saveAction={setVisualizeFromTime}
                maxDate={visualizeUntilTime}
              />
              <ImdDatePicker
                labelText={t('content.visualize.toolbar.time.until')}
                date={visualizeUntilTime}
                saveAction={setVisualizeUntilTime}
                minDate={visualizeFromTime}
              />
              <div className={classes.select}>
                <InputLabel variant="standard" shrink>
                  {t('content.visualize.toolbar.time.select.text')}
                </InputLabel>
                <Select
                  value={visualizePeriod}
                  onChange={handleChange}
                  classes={{ root: classes.selectBox }}
                  variant="standard"
                >
                  <MenuItem value={'fourHours'}>
                    {t('content.visualize.toolbar.time.select.4h')}
                  </MenuItem>
                  <MenuItem value={'eightHours'}>
                    {t('content.visualize.toolbar.time.select.8h')}
                  </MenuItem>
                  <MenuItem value={'twelveHours'}>
                    {t('content.visualize.toolbar.time.select.12h')}
                  </MenuItem>
                  <MenuItem value={'twentyFourHours'}>
                    {t('content.visualize.toolbar.time.select.24h')}
                  </MenuItem>
                  <MenuItem value={'fourtyEightHours'}>
                    {t('content.visualize.toolbar.time.select.48h')}
                  </MenuItem>
                  <MenuItem value={'oneWeek'}>
                    {t('content.visualize.toolbar.time.select.1wk')}
                  </MenuItem>
                  <MenuItem value={'oneMonth'}>
                    {t('content.visualize.toolbar.time.select.1mo')}
                  </MenuItem>
                  <MenuItem value={'threeMonths'}>
                    {t('content.visualize.toolbar.time.select.3mo')}
                  </MenuItem>
                  <MenuItem value={'oneYear'}>
                    {t('content.visualize.toolbar.time.select.1yr')}
                  </MenuItem>
                  <MenuItem value={'currentYear'}>
                    {t('content.visualize.toolbar.time.select.currentYear')}
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.smallButtons}>
                <ImdButton
                  className={classes.smallButton}
                  variant="contained"
                  size="small"
                  color="inherit"
                  onClick={() => setPreviousVisualizePeriod()}
                >
                  <ArrowLeftIcon />
                </ImdButton>
                <ImdButton
                  className={classes.smallButton}
                  variant="contained"
                  size="small"
                  color="inherit"
                  onClick={() => setNextVisualizePeriod()}
                >
                  <ArrowRightIcon />
                </ImdButton>
                <ImdTooltip
                   title={'Actuele periode'}
                   placement="top-start"
                   className={classes.linkItem}
                >
                <ImdButton
                  className={classes.smallButton}
                  variant="contained"
                  size="small"
                  color="inherit"
                  onClick={() => setTodayVisualizePeriod()}
                >
                  <HistoryIcon/>
                </ImdButton>
               </ImdTooltip>
              </div>
            </div>
          </Grid>
          {settings ? (
            <Grid item lg={3}>
              <div className={classes.buttonsContent}>
                <ImdButton
                  variant="contained"
                  color="inherit"
                  startIcon={<SettingsIcon />}
                  onClick={() => setDialogOpen(true)}
                >
                  {t('content.visualize.toolbar.settings.button.text')}
                </ImdButton>
                <ImdButton
                  variant="contained"
                  color="inherit"
                  startIcon={<SyncIcon />}
                  onClick={() => executeAutoRefresh(true, chartRendered)}
                >
                  {t('content.visualize.toolbar.sync.button.text')}
                </ImdButton>
                <ImdButton
                  variant="contained"
                  color="inherit"
                  startIcon={<LinkIcon className={classes.extendedIcon} />}
                  onClick={handleExportToLink}
                >
                  {t('content.visualize.toolbar.link.button.text')}
                </ImdButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Typography className={classes.popover}>
                    {t('content.visualize.toolbar.link.exported')}
                  </Typography>
                </Popover>
              </div>
            </Grid>
          ) : null}
          {isLoading ? (
            <Grid item lg={12}>
              <div className={classes.loadBar}>
                <LinearProgress />
              </div>
            </Grid>
          ) : null}
        </Grid>
        <div className={classes.rightUpperCorner}>
          <ExpandLessIcon className={classes.expandButton} />
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  buttonsContent: {
    display: 'flex',
    marginTop: '12px',
    marginRight: '4px',
    gap: '8px'
  },
  selectBox: {
    minWidth: '75px'
  },
  columnContent: {
    display: 'flex',
    gap: '8px'
  },
  smallButtons: {
    marginTop: '4px',
    gap: '4px',
    display: 'flex'
  },
  smallButton: {
    minWidth: '32px',
    maxHeight: '30px'
  },
  container: {
    marginTop: '0px',
    marginLeft: '0px',
    marginBottom: '0px',
    paddingBottom: '8px'
  },
  accentBar: {
    backgroundColor: theme.palette.primary.main,
    width: '1%',
    marginRight: '4px'
  },
  toolbar: {
    display: 'flex',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#FFFFFF',
    position: 'sticky',
    zIndex: 3,
    overflow: 'hidden'
  },
  rightUpperCorner: {
    textAlign: 'right'
  },
  imdButton: {
    display: 'inline',
    marginTop: '12px'
  },
  image: {
    marginLeft: '-8px',
    marginTop: '48px'
  },
  expandButton: {
    fontSize: '30px',
    marginRight: '4px',
    marginTop: '8px',
    cursor: 'pointer',
    display: 'none'
  },
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  popover: {
    padding: '8px'
  },
  loadBar: {
    width: '120%',
    marginLeft: '-41px'
  },
  tijdsinstellingen: {
    marginTop: '8px'
  }
}));
