import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import ImdPopup from 'components/imd-components/ImdPopup';
import { Column } from 'devextreme-react/tree-list';
import { CreateThemeContainer } from '../managetheme/CreateThemeContainer';
import { EditThemeContainer } from '../managetheme/EditThemeContainer';

export default function ThemesTableDisplay({ themes, fetchThemes, deleteTheme, setTheme }) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [themes]);

  useEffect(() => {
    fetchThemes();
  }, []);

  const handleChangeSaveButton = enableSaveButton => {
    setSaveButton(enableSaveButton);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    setTheme(row);
    openEdit();
  };

  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteTheme({ selectedRow });
  };

  const columns = (
    <>
      <Column
        visible={true}
        dataField={'name'}
        caption={t('content.themes.name')}
        SortIndex={1}
        sortOrder={'asc'}
      />
      <Column
        visible={true}
        dataField={'themeJson'}
        caption={t('content.themes.themejson')}
      />
      <Column
        type="buttons"
        caption={t('content.themestable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditThemeContainer
            ref={formRef}
            row={selectedRow}
            setSaveButton={e => handleChangeSaveButton(e)}
          ></EditThemeContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.themestable.edittheme.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={<CreateThemeContainer ref={formRef}></CreateThemeContainer>}
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.themestable.createtheme.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.themestable.deletepopup.title')}
        content={t('content.themestable.deletepopup.content', {
          Theme: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.themes.title')}
        data={themes}
        columns={columns}
        stateStorageKey="themes-table-display"
      />
    </>
  );
}
