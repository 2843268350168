import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import Collapse from '@mui/material/Collapse';
import { RenderChartContainer } from 'components/content/visualize/renderChartComponent/renderChartContainer';
import { formatDate } from 'devextreme/localization';
import { getAggregationValue } from 'utils/helperMethods';
import PropTypes from 'prop-types';

export function KpiDisplay({
  item,
  locations,
  visualize,
  dataSources,
  measurements,
  visualizeFromTime,
  visualizeUntilTime,
  renderKey,
  defaultOpen,
  logItems,
  chartDisplayOptions
}) {
  const classes = useStyles();
  measurements = measurements === undefined ? [] : measurements;
  const lastMeasurement =
    measurements.length === 0 ? null : measurements[measurements.length - 1];
  const date =
    lastMeasurement !== null
      ? formatDate(lastMeasurement.dateTime, 'shortDateShortTime')
      : '';
  const [expanded, setExpanded] = useState(defaultOpen);
  const dataSource = dataSources[0];

  const determineVariant = () => {
    if (lastMeasurement === null) {
      return 'offline';
    }

    const { min, max, upperLimit, lowerLimit } = dataSource;
    const value = lastMeasurement?.value;

    switch (true) {
      case min === 0 && max === 0 && lowerLimit === 0 && upperLimit === 0:
        return 'success';
      case min === 0 && max === 0:
        if (value >= lowerLimit && value <= upperLimit) {
          return 'success';
        } else {
          return 'warning';
        }
      case lowerLimit === 0 && upperLimit === 0:
        if (value >= min && value <= max) {
          return 'success';
        } else {
          return 'error';
        }
      case value >= min &&
        value <= max &&
        value >= lowerLimit &&
        value <= upperLimit:
        return 'success';
      case value >= min && value <= max:
        return 'warning';
      default:
        return 'error';
    }
  };

  const variant = determineVariant();

  const renderIdentifier = () => {
    if (!dataSource.displayLabel) {
      dataSource.displayLabel = `${dataSource.externalChannelId}`;
    }

    return `${dataSource.displayLabel} ${formatAggregationValue()}`;
  };

  const renderValue = () => {
    const unit = dataSource.unit ?? '';

    if (lastMeasurement !== null) {
      return `${roundValue()} ${unit}`;
    }
    return `--`;
  };

  const determineValue = () => {
    const split = dataSource.aggregationType.split('-');
    const aggregationValue = split[1] !== undefined ? split[1] : '';
    const valueLabel = getAggregationValue(aggregationValue);
    return lastMeasurement[valueLabel];
  };

  const roundValue = () => {
    return Math.round((determineValue() + Number.EPSILON) * 100) / 100;
  };

  const formatAggregationValue = () => {
    const split = dataSource.aggregationType.split('-');
    const aggregationValue = split[1] !== undefined ? split[1] : '';

    switch (aggregationValue) {
      case 'avg':
        return '(Avg)';
      case 'sum':
        return '(Sum)';
      case 'min':
        return '(Min)';
      case 'max':
        return '(Max)';
      default:
        return '';
    }
  };

  const renderCircleIcon = () => {
    switch (variant) {
      case 'success':
        return <CheckCircleIcon className={classes.expandButton} />;
      case 'warning':
        return <HelpIcon className={classes.expandButton} />;
      case 'error':
        return <CancelIcon className={classes.expandButton} />;
      default:
        return <CancelIcon className={classes.expandButton} />;
    }
  };

  return (
    <>
      <div className={classes.kpiWrapper} key={renderKey}>
        <div
          className={clsx(classes[variant], classes.button)}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.expandButtonWrapper}>
            <ExpandMoreIcon className={classes.expandButton} />
          </div>
          <div className={classes.buttonText}>
            <span className={classes.identifier}>{renderValue()}</span>
            <div className={classes.innerButtonText}>
              <span className={classes.identifierType}>
                {renderIdentifier()}
              </span>
              <span className={classes.identifierType}>{date}</span>
            </div>
          </div>
          <div className={classes.circleIconWrapper}>{renderCircleIcon()}</div>
        </div>
        <Collapse in={expanded}>
          <RenderChartContainer
            item={item}
            locations={locations}
            visualize={visualize}
            dataSources={dataSources}
            measurements={measurements}
            visualizeFromTime={visualizeFromTime}
            visualizeUntilTime={visualizeUntilTime}
            renderKey={renderKey}
            logItems={logItems}
            chartDisplayOptions={chartDisplayOptions}
          />
        </Collapse>
      </div>
    </>
  );
}

KpiDisplay.propTypes = {
  dataSources: PropTypes.arrayOf(
    PropTypes.shape({
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      upperLimit: PropTypes.number.isRequired,
      lowerLimit: PropTypes.number.isRequired
    })
  )
};

const useStyles = makeStyles({
  button: {
    display: 'flex',
    flexDirection: 'row',
    color: '#FFFFFF',
    paddingLeft: '8px',
    paddingTop: '2px',
    borderRadius: '4px',
    marginBottom: '2px',
    flexGrow: '1'
  },
  buttonText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0px 8px 0px 8px'
  },
  innerButtonText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  error: {
    backgroundColor: '#c4161d',
    '&:hover': {
      backgroundColor: '#B9151B',
      cursor: 'pointer'
    }
  },
  warning: {
    backgroundColor: '#ba6f00',
    '&:hover': {
      backgroundColor: '#AD6700',
      cursor: 'pointer'
    }
  },
  success: {
    backgroundColor: '#377f3a',
    '&:hover': {
      backgroundColor: '#347636',
      cursor: 'pointer'
    }
  },
  offline: {
    backgroundColor: '#8F8F8F',
    '&:hover': {
      backgroundColor: '#818181',
      cursor: 'pointer'
    }
  },
  identifier: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    padding: '2px 0px'
  },
  identifierType: {
    fontSize: '0.9rem',
    paddingBottom: '4px',
    paddingRight: '8px'
  },
  expandButtonWrapper: {
    display: 'flex'
  },
  circleIconWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '24px'
  },
  expandButton: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '28px'
  },
  kpiWrapper: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)'
  }
});
