import { makeActionCreator } from 'utils/makeActionCreator';

export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const editOrganization = makeActionCreator(
  EDIT_ORGANIZATION,
  'organizationInformation'
);

export const FETCH_CONTACTS_FROM_ORGANIZATION_LIST =
  'FETCH_CONTACTS_FROM_ORGANIZATION_LIST';
export const fetchContactsFromOrganizationList = makeActionCreator(
  FETCH_CONTACTS_FROM_ORGANIZATION_LIST,
  'organizationId'
);

export const SET_CONTACTS_FROM_ORGANIZATION = 'SET_CONTACTS_FROM_ORGANIZATION';
export const setContactsFromOrganizationList = makeActionCreator(
  SET_CONTACTS_FROM_ORGANIZATION,
  'contactsFromOrganizationList'
);

export const FETCH_LOCATIONS_FROM_ORGANIZATION =
  'FETCH_LOCATIONS_FROM_ORGANIZATION';
export const fetchLocationsFromOrganization = makeActionCreator(
  FETCH_LOCATIONS_FROM_ORGANIZATION,
  'organizationSensightId'
);

export const SET_LOCATIONS_FROM_ORGANIZATION =
  'SET_LOCATIONS_FROM_ORGANIZATION';
export const setLocationsFromOrganization = makeActionCreator(
  SET_LOCATIONS_FROM_ORGANIZATION,
  'locationsFromOrganization'
);

export const CREATE_CONTACT_FROM_ORGANIZATION =
  'CREATE_CONTACT_FROM_ORGANIZATION';
export const createContactFromOrganization = makeActionCreator(
  CREATE_CONTACT_FROM_ORGANIZATION,
  'contact'
);

export const EDIT_CONTACT_FROM_ORGANIZATION = 'EDIT_CONTACT_FROM_ORGANIZATION';
export const editContactFromOrganizationList = makeActionCreator(
  EDIT_CONTACT_FROM_ORGANIZATION,
  'contact'
);

export const DELETE_CONTACT_FROM_ORGANIZATION =
  'DELETE_CONTACT_FROM_ORGANIZATION';
export const setContactFromOrganizationList = makeActionCreator(
  DELETE_CONTACT_FROM_ORGANIZATION,
  'contact'
);
