import { createReducer } from 'utils/createReducer';
import {
  SET_CONTACTS_FROM_ORGANIZATION,
  SET_LOCATIONS_FROM_ORGANIZATION
} from './editOrganizationActions';
import { fromJS } from 'immutable';
import {
  CREATE_CONTACT_SUCCEEDED,
  EDIT_CONTACT_SUCCEEDED,
  DELETE_CONTACT_SUCCEEDED
} from 'components/content/contacts/redux/contactsListActions';

export const contactsFromOrganizationList = createReducer([], {
  [SET_CONTACTS_FROM_ORGANIZATION](state, { contactsFromOrganizationList }) {
    return fromJS(contactsFromOrganizationList);
  },
  [CREATE_CONTACT_SUCCEEDED](state, { contact }) {
    return state.push(fromJS(contact));
  },
  [EDIT_CONTACT_SUCCEEDED](state, { contact }) {
    let contactsFromOrganizationList = state.toJS();
    var foundContactIndex = contactsFromOrganizationList.findIndex(
      con => con.id === contact.id
    );
    contactsFromOrganizationList[foundContactIndex] = contact;
    return fromJS(contactsFromOrganizationList);
  },
  [DELETE_CONTACT_SUCCEEDED](state, { contact }) {
    return state.filter(con => con.get('id') !== contact.id);
  }
});

export const locationsFromOrganization = createReducer([], {
  [SET_LOCATIONS_FROM_ORGANIZATION](state, { locationsFromOrganization }) {
    return fromJS(locationsFromOrganization);
  }
});
