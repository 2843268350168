import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_DASHBOARD_MEASUREMENTS = 'FETCH_DASHBOARD_MEASUREMENTS';
export const fetchDashboardMeasurements = makeActionCreator(
  FETCH_DASHBOARD_MEASUREMENTS,
  'dashboardSectionItemId',
  'fetchParameters'
);

export const SET_DASHBOARD_MEASUREMENTS = 'SET_DASHBOARD_MEASUREMENTS';
export const setDashboardMeasurements = makeActionCreator(
  SET_DASHBOARD_MEASUREMENTS,
  'dashboardSectionItemId',
  'measurements'
);

export const CLEAR_DASHBOARD_MEASUREMENTS = 'CLEAR_DASHBOARD_MEASUREMENTS';
export const clearDashboardMeasurements = makeActionCreator(
  CLEAR_DASHBOARD_MEASUREMENTS
);
