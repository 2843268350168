import React, { useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Content from '../../page/Content';
import ImdButton from 'components/imd-components/ImdButton';
import { useTranslation } from 'react-i18next';
import { DesktopToolbarContainer } from '../visualize/toolbar/desktopToolbar/desktopToolbarContainer';
import { downloadOData } from 'apis/MeasurementODataApi';
import { aggregationTypes } from './ExportHelpers';

export function ExportDisplay({
  currentUser,
  fromPeriod,
  toPeriod,
  selectedChannels,
  defaultAggregationType,
  defaultAggregationTime,
  createSnackbar
}) {
  const classes = useImdStyles();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const content = (
    <>
      <DesktopToolbarContainer settings={false} chartSettings={false} />
      <ImdButton
        className={classes.exportbutton}
        color={'primary'}
        variant="contained"
        onClick={() => getExport()}
      >
        {t('content.export.export')}
      </ImdButton>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item className={classes.gridItem}>
          {isLoading ? <CircularProgress className={classes.spinner} /> : null}
        </Grid>
      </Grid>
    </>
  );

  const getExport = async () => {
    setIsLoading(true);
    createSnackbar(t('content.export.started'), 'success');
    const odataString = generateOData();
    try {
      await downloadOData(currentUser, odataString);
      createSnackbar(t('content.export.finished'), 'success');
    } catch (e) {
      if (e.response?.status === 504) {
        // Timeout response code
        createSnackbar(t('content.export.failed'), 'error', 12000);
      } else {
        createSnackbar(t('snackbar.generic.error'), 'error', 12000);
      }
    }
    setIsLoading(false);
  };

  const generateOData = () => {
    let aggregationTime = defaultAggregationTime;
    let aggregationType = '';

    if (aggregationType !== aggregationTypes.source) {
      aggregationType = defaultAggregationType;
    }
    return (
      'channel?$expand=' +
      aggregationTime +
      aggregationTypes.measurements +
      '($filter=dateTime%20gt%20' +
      fromPeriod.toISOString() +
      '%20and%20dateTime%20lt%20' +
      toPeriod.toISOString() +
      ';$expand=reliabilityReason),device($expand=deviceplacements($expand=location)),reliabilityreason&%20$filter=%20(id%20in%20(' +
      selectedChannels.map(channel => channel.id).join(',') +
      '))' +
      '&aggregationtype=value' +
      aggregationType
    );
  };

  return <Content title={t('content.export.title')} content={content} />;
}

const useImdStyles = makeStyles(() => ({
  exportbutton: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  gridItem: {
    minHeight: '43px'
  },
  spinner: {
    marginBottom: 'auto'
  }
}));
