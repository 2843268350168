import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_PROFILE_DETAILS = 'FETCH_PROFILE_DETAILS';
export const fetchProfileDetails = makeActionCreator(
  FETCH_PROFILE_DETAILS,
  'profileDetails'
);

export const SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS';
export const setProfileDetails = makeActionCreator(
  SET_PROFILE_DETAILS,
  'profileDetails'
);
