import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { MenuItem, Typography, Switch, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import { getLogItemStates } from 'components/content/location/logbook/LogItemsHelpers';
import ImdDatePicker from 'components/imd-components/ImdDatePicker';
import { Can } from 'components/imd-components/authorization/Can';
import { getCurrentUserRole } from 'utils/currentUserReader';
import {
  getDeviceById,
  getMeasurementChannelBySensightId
} from 'apis/MeasurementServiceApi';
import { useEffectOnce } from 'react-use';

function CreateLogItemModalDisplay(
  {
    currentUser,
    handleValidate,
    setSaveButtonDisabled,
    renderChartCreateLogItem,
    logItem,
    actions,
    fetchActions,
    device,
    channel
  },
  ref
) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deadline, setDeadline] = useState(null);
  const [hideSwitchValue, setHideSwitchValue] = useState(false);
  const [startDateTime, setStartDateTime] = useState(
    !logItem ? new Date() : logItem.startDateTime
  );
  const [channelName, setChannelName] = useState(channel?.displayLabel);
  const [deviceName, setDeviceName] = useState(device?.name);

  useEffectOnce(() => {
    setSaveButtonDisabled(true);
    fetchActions();

    if (!deviceName) {
      void (async function fetchDevice() {
        const deviceResponse = await getDeviceById(
          currentUser,
          logItem.deviceId
        );
        setDeviceName(deviceResponse.data.name);
      })();
    }

    if (!channelName) {
      void (async function fetchChannel() {
        const channelResponse = await getMeasurementChannelBySensightId(
          currentUser,
          logItem.channelSensightId
        );
        setChannelName(channelResponse.data[0].displayLabel);
      })();
    }
  }, []);

  const formRef = useRef();
  useImperativeHandle(ref, () => formRef);

  if (!deviceName || !channelName) {
    return null;
  }

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    useEffect(() => {
      (() => validateForm())();
    }, []);

    useEffect(() => {
      if (dirty && isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    const logItemStateMenuItems = getLogItemStates().map(logItemState => {
      return (
        <MenuItem key={logItemState.key} value={logItemState.key}>
          {logItemState.label}
        </MenuItem>
      );
    });

    const actionMenuItems = actions.map(action => {
      return (
        <MenuItem key={action.id} value={action.id}>
          {action.name}
        </MenuItem>
      );
    });
    return (
      <Form onSubmit={submitForm}>
        <Grid container>
          <Grid item md={12}>
            <Field
              id="actionId"
              type="select"
              name="actionId"
              label={t('content.logitems.modal.action')}
              fullWidth
              component={TextField}
              select
              className={classes.textField}
              required
              variant="standard"
            >
              {actionMenuItems}
            </Field>
            <Field
              name="logItemStateKey"
              label={t('content.logitems.modal.logItemState')}
              fullWidth
              component={TextField}
              type="select"
              className={classes.textField}
              select
              required
              variant="standard"
            >
              {logItemStateMenuItems}
            </Field>
            <ImdDatePicker
              id="startDateTime"
              labelText={t('content.logitems.startDateTime')}
              date={startDateTime}
              saveAction={setStartDateTime}
              className={classes.textField}
            />
            <Field
              name="deviceName"
              type="text"
              label={t('content.logitems.modal.device')}
              fullWidth
              component={TextField}
              className={classes.textField}
              disabled
              variant="standard"
            />
            <Field
              name="channelName"
              type="text"
              label={t('content.logitems.channel')}
              fullWidth
              component={TextField}
              className={classes.textField}
              disabled
              variant="standard"
            />
            <Field
              name="description"
              type="text"
              label={t('content.logitems.description')}
              fullWidth
              className={classes.textField}
              component={TextField}
              variant="standard"
            />
            <Field
              type="number"
              name="timeSpent"
              label={t('content.logitems.timeSpent')}
              className={classes.textField}
              fullWidth
              component={TextField}
              variant="standard"
            />
            <ImdDatePicker
              id="deadline"
              labelText={t('content.logitems.deadline')}
              date={deadline}
              saveAction={setDeadline}
              className={classes.lastInputField}
            />
            <Can
              role={getCurrentUserRole(currentUser)}
              perform={'logitems:hideforcustomer'}
              yes={() => (
                <div
                  className={clsx(classes.switchWrapper, classes.firstWrapper)}
                >
                  <Typography variant="body1" className={classes.switchLabel}>
                    {t('content.logitems.hideForCustomer')}
                  </Typography>
                  <div className={classes.switchItem}>
                    <Switch
                      checked={hideSwitchValue}
                      onChange={() => setHideSwitchValue(!hideSwitchValue)}
                      value="hide"
                      color="primary"
                    />
                  </div>
                </div>
              )}
              no={() => null}
            />
          </Grid>
        </Grid>
      </Form>
    );
  };

  const inputFields = (
    <>
      <FormikWithRef
        ref={formRef}
        validateOnMount={true}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          actionId: '',
          description: '',
          logItemStateKey: 0,
          timeSpent: 0,
          channelId: logItem ? logItem.channelId : '',
          channelSensightId: logItem ? logItem.channelSensightId : '',
          channelName,
          deviceId: logItem ? logItem.deviceId : '',
          deviceName
        }}
        validate={values => handleValidate(values)}
        onSubmit={values => {
          values.startDateTime = startDateTime ?? new Date();
          values.deadline = deadline;
          values.hideForCustomer = hideSwitchValue;
          values.logItemStateKey = parseInt(values.logItemStateKey);
          renderChartCreateLogItem(values);
        }}
        enableReinitalize={true}
      >
        {form}
      </FormikWithRef>
    </>
  );

  return inputFields;
}

const useStyles = makeStyles(() => ({
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  textField: {
    marginBottom: '8px'
  },
  lastInputField: {
    marginBottom: '16px'
  }
}));

export default forwardRef(CreateLogItemModalDisplay);
