import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_LOCATIONTYPES_LIST = 'FETCH_LOCATIONTYPES_LIST';
export const fetchLocationTypesList = makeActionCreator(
  FETCH_LOCATIONTYPES_LIST
);

export const SET_LOCATIONTYPES_LIST = 'SET_LOCATIONTYPES_LIST';
export const setLocationTypesList = makeActionCreator(
  SET_LOCATIONTYPES_LIST,
  'locationtypesList'
);

export const CREATE_LOCATIONTYPE_SUCCEEDED = 'CREATE_LOCATIONTYPE_SUCCEEDED';
export const createLocationTypeSucceeded = makeActionCreator(
  CREATE_LOCATIONTYPE_SUCCEEDED,
  'locationtype'
);

export const EDIT_LOCATIONTYPE_SUCCEEDED = 'EDIT_LOCATIONTYPE_SUCCEEDED';
export const editLocationTypeSucceeded = makeActionCreator(
  EDIT_LOCATIONTYPE_SUCCEEDED,
  'locationtype'
);

export const DELETE_LOCATIONTYPE = 'DELETE_LOCATIONTYPE';
export const deleteLocationType = makeActionCreator(
  DELETE_LOCATIONTYPE,
  'locationtype'
);

export const DELETE_LOCATIONTYPE_SUCCEEDED = 'DELETE_LOCATIONTYPE_SUCCEEDED';
export const deleteLocationTypeSucceeded = makeActionCreator(
  DELETE_LOCATIONTYPE_SUCCEEDED,
  'locationtype'
);
