import { createReducer } from '../../utils/createReducer';
import { CREATE_SNACKBAR, SNACKBAR_CLEAR, SET_IS_LOADING } from './uiActions';
import { fromJS } from 'immutable';

export const snackbar = createReducer(null, {
  [CREATE_SNACKBAR](state, { message, variant = 'success', duration = 6000 }) {
    return state.merge({
      snackbarVariant: variant,
      snackbarMessage: message,
      snackbarDuration: duration
    });
  },
  [SNACKBAR_CLEAR](state) {
    return state.merge({
      snackbarVariant: null,
      snackbarMessage: null,
      snackbarDuration: null
    });
  }
});

export const isLoading = createReducer(null, {
  [SET_IS_LOADING](state, { value }) {
    return fromJS(value);
  }
});
