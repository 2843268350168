import { createSelector } from 'reselect';

export const themesSelector = createSelector(
  state => state.get('themesList'),
  themesList => themesList.toJS()
);

export const themeSelector = createSelector(
  state => state.get('theme'),
  theme => theme.toJS()
);
