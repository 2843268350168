import React, { useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { useAuth0 } from '../../react-auth0-spa';
import makeStyles from '@mui/styles/makeStyles';
import { getActiveTenant, switchToTenant } from 'utils/tenantManager.js';
import { useTranslation } from 'react-i18next';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';

export default function ImdTenantPicker() {
  const { t } = useTranslation();
  const { namespace } = useAuth0();
  const currentUser = useSelector(state => currentUserSelector(state));
  const [tenantToSelect, setTenantToSelect] = useState(getActiveTenant());
  const [selectedTenant, setSelectedTenant] = useState(getActiveTenant());
  const classes = useStyles();
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const tenants = currentUser[namespace + 'tenants'];
  const tentantsMenuItems = tenants.map((userTenant, index) => (
    <MenuItem key={index} value={userTenant.FriendlyId}>
      {userTenant.FriendlyId}
    </MenuItem>
  ));

  const closeModalAndSwitchToTenant = openInNewTab => {
    setIsModalOpened(false);
    if (!openInNewTab) {
      setSelectedTenant(tenantToSelect);
    } else {
      setTenantToSelect(selectedTenant);
    }
    switchToTenant(tenantToSelect, openInNewTab);
  };

  return tenants.length > 1 ? (
    <>
      <ImdDialogContainer
        open={isModalOpened}
        maxWidth="xs"
        content={t('content.tenants.openInNewTabQuestionDescription')}
        dialogTitle={t('content.tenants.openInNewTabQuestionTitle')}
        saveButtonTranslation={t('content.tenants.openInNewTabConfirm')}
        saveButtonDisabled={false}
        extraAction={{
          handle: () => closeModalAndSwitchToTenant(false),
          translation: t('content.tenants.openInNewTabCancel')
        }}
        handleCloseAction={() => setIsModalOpened(false)}
        handleSaveAction={() => closeModalAndSwitchToTenant(true)}
      />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
        <InputLabel htmlFor="tenantSelect" variant="standard">
          {t('content.tenant.title')}
        </InputLabel>

        <Select
          id="tenantSelect"
          name="selectedTenant"
          value={selectedTenant}
          onChange={event => {
            setTenantToSelect(event.target.value);
            setIsModalOpened(true);
          }}
          classes={{ root: classes.selectBox }}
        >
          {tentantsMenuItems}
        </Select>
      </FormControl>
    </>
  ) : (
    <TextField
      id="tenant-read-only-input"
      label={t('content.tenant.title')}
      value={getActiveTenant()}
      InputProps={{
        readOnly: true
      }}
      variant="standard"
    />
  );
}

const useStyles = makeStyles({
  selectBox: {
    height: '20px'
  }
});
