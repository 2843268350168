import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField as MaterialTextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'formik-mui';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import { PropTypes } from 'prop-types';

export const TechnicalDetailsForm = ({
  deviceTechnicalDetailsFormRef,
  deviceTechnicalViewmodel,
  upsertTechnicalDeviceDetails,
  simCards,
  setTechDetailsFormValid
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderSimCardAutocompleteItems = () => {
    if (simCards.length > 0) {
      let simCardsToMap;
      //Alleen sorteren als het nodig is
      simCardsToMap = simCards.sort((a, b) => {
        const compareByIpAddress = compareIpAddress(a, b);
        if (compareByIpAddress !== 0) {
          return compareByIpAddress;
        }
        return compareByIccid(a, b);
      });
      const menuItems = simCardsToMap.map(key => {
        return {
          id: key.id,
          label: `${key.ipAddress} - ${key.iccid}`
        };
      });
      return menuItems;
    }
    return [];
  };

  const compareIpAddress = (a, b) => {
    if (a.ipAddress && b.ipAddress) {
      const aIpParts = ipToNumArray(a.ipAddress);
      const bIpParts = ipToNumArray(b.ipAddress);

      for (let i = 0; i < aIpParts.length; i++) {
        if (aIpParts[i] < bIpParts[i]) return -1;
        if (aIpParts[i] > bIpParts[i]) return 1;
      }
      return 0; // IP addresses hetzelfde
    } else if (a.ipAddress && !b.ipAddress) {
      return -1;
    } else if (!a.ipAddress && b.ipAddress) {
      return 1;
    }
    return 0; // Allebij geen ip address
  };

  const compareByIccid = (a, b) => {
    if (a.iccid < b.iccid) return -1;
    if (a.iccid > b.iccid) return 1;
    return 0;
  };

  const ipToNumArray = ip => ip.split('.').map(Number);

  const getSimCardMapped = id => {
    const simCard = simCards.find(x => x.id === id);
    return !simCard
      ? ''
      : {
          id: simCard.id,
          label: `${simCard.ipAddress} - ${simCard.iccid}`
        };
  };

  return (
    <FormikWithRef
      ref={deviceTechnicalDetailsFormRef}
      initialValues={{
        fieldId: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.fieldId ?? '',
        name: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.name ?? '',
        description: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.description ?? '',
        phoneNumber: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.phoneNumber ?? '',
        manufacturer: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.manufacturer ?? '',
        serialNumber: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.serialNumber ?? '',
        deviceType: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.deviceType ?? '',
        simCardId:
          deviceTechnicalViewmodel && deviceTechnicalViewmodel.simCardId
            ? deviceTechnicalViewmodel.simCardId
            : '',
        ipAddress: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.ipAddress ?? '',
        portNr: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.portNr ?? '',
        externalLink: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.externalLink ?? '',
        sensightId: !deviceTechnicalViewmodel
          ? ''
          : deviceTechnicalViewmodel.sensightId ?? '',
        // Bugfix voor verdwijnen van locatie info en reliabilityPercentage, in de toekomst bij de redux beter oplossen, voor nu ok.
        latestDevicePlacement:
          deviceTechnicalViewmodel?.latestDevicePlacement ?? {},
        reliabilityPercentage:
          deviceTechnicalViewmodel?.reliabilityPercentage ?? 0
      }}
      enableReinitialize={true}
      validateOnBlur={true}
      validate={values => {
        let errors = {};
        if (!values.name) {
          errors.name = t('general.validation.required');
        }

        if (values.phoneNumber && !/^\d{12}$/i.test(values.phoneNumber)) {
          errors.phoneNumber = t('content.devices.invalidphonenumber');
        }
        if (
          values.ipAddress &&
          !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            values.ipAddress
          )
        ) {
          errors.ipAddress = t('content.devices.invalidipaddress');
        }
        if (
          values.portNr &&
          (!/^[1-9][0-9]{0,5}$/.test(values.portNr) || values.portNr > 65535)
        ) {
          errors.portNr = t('content.devices.invalidportnr');
        }
        if (
          values.simCardId &&
          !simCards.find(x => x.id === values.simCardId)
        ) {
          errors.simCardId = t('content.devices.invalidsimcardid');
        }
        return errors;
      }}
      onSubmit={values => {
        upsertTechnicalDeviceDetails(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setTechDetailsFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form onSubmit={props.submitForm}>
            <Field
              id="name"
              name="name"
              type="text"
              label={t('content.devices.name')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.name}
              required
              variant="standard"
            />
            <Field
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              label={t('content.devices.phonenumber')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.phoneNumber}
              variant="standard"
            />
            <Field
              id="manufacturer"
              name="manufacturer"
              type="text"
              label={t('content.devices.manufacturer')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.manufacturer}
              variant="standard"
            />
            <Field
              id="serialNumber"
              name="serialNumber"
              type="text"
              label={t('content.devices.serialnumber')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.serialNumber}
              variant="standard"
            />
            <Field
              id="deviceType"
              name="deviceType"
              type="text"
              label={t('content.devices.devicetype')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.deviceType}
              variant="standard"
            />
            <Autocomplete
              id="simCardId"
              name="simCardId"
              options={renderSimCardAutocompleteItems()}
              value={getSimCardMapped(props.values.simCardId)}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  props.setFieldValue('simCardId', newValue.id);
                } else {
                  props.setFieldValue('simCardId', '');
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.simCardId === value.simCardId
              }
              onBlur={props.handleBlur}
              getOptionLabel={option => option.label || ''}
              renderInput={params => (
                <MaterialTextField
                  {...params}
                  label={t('content.devices.simcardid')}
                  variant="standard"
                  fullWidth
                  className={classes.textField}
                  onChange={props.handleChange}
                  value={props.values.simCardId}
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: 250
                }
              }}
            />
            <Field
              id="ipAddress"
              name="ipAddress"
              type="text"
              label={t('content.devices.ipaddress')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.ipAddress}
              variant="standard"
            />
            <Field
              id="portNr"
              name="portNr"
              type="text"
              label={t('content.devices.portnr')}
              fullWidth
              className={classes.textField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.portNr}
              variant="standard"
            />
            <Field
              id="externalLink"
              name="externalLink"
              type="text"
              label={t('content.devices.externallink')}
              fullWidth
              className={classes.lastInputField}
              component={TextField}
              onChange={props.handleChange}
              value={props.values.externalLink}
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};

const useStyles = makeStyles(() => ({
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  }
}));

TechnicalDetailsForm.propTypes = {
  deviceTechnicalDetailsFormRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  deviceTechnicalViewmodel: PropTypes.any.isRequired,
  upsertTechnicalDeviceDetails: PropTypes.any.isRequired,
  simCards: PropTypes.any.isRequired,
  setTechDetailsFormValid: PropTypes.any.isRequired,
  handleBlur: PropTypes.any.isRequired,
  setFieldValue: PropTypes.any.isRequired,
  values: PropTypes.shape({
    simCardId: PropTypes.any.isRequired
  }).isRequired
};
