import { createReducer } from 'utils/createReducer';
import { SET_USER_DETAILS, CLEAR_USER_DETAILS } from './editContactActions';
import { fromJS } from 'immutable';

export const userDetails = createReducer(
  {},
  {
    [SET_USER_DETAILS](_state, { user }) {
      return fromJS(user);
    },
    [CLEAR_USER_DETAILS]() {
      return fromJS({});
    }
  }
);
