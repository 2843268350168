import { takeEvery, put } from 'redux-saga/effects';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* genericErrorSaga() {
  yield takeEvery('GENERIC_ERROR', execute);
}

function* execute() {
  yield put(createSnackbar(t('general.error'), 'error'));
}
