import { createStore, applyMiddleware, compose } from 'redux';
import { reducer } from './combineReducers';
import createSagaMiddleware from 'redux-saga';
import { initSagas } from './initSagas';
import { initialState } from 'redux/initialState';
import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';

export const history = createBrowserHistory();

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorinfo) => {
      console.log(error);
      console.log(errorinfo);
      store.dispatch({ type: 'GENERIC_ERROR' });
    }
  });
  const middleWares = [
    sagaMiddleware,
    routerMiddleware(history),
    ...((process.env.NODE_ENV === 'development' &&
      localStorage.getItem('enableReduxDebug') !== 'false') ||
    localStorage.getItem('enableReduxDebug') === 'true'
      ? [
          createLogger({
            stateTransformer(state) {
              return state.toJS();
            }
          })
        ]
      : []) // Redux logger
  ];
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const composables = [applyMiddleware(...middleWares)];
  const enhancer = composeEnhancers(...composables);
  const store = createStore(reducer(history), initialState, enhancer);
  initSagas(sagaMiddleware);
  return store;
}
