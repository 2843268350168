import { createReducer } from 'utils/createReducer';
import {
  SET_DISPLAYED_LOCATIONS,
  ADD_DISPLAYED_LOCATION,
  ADD_SELECTED_LOCATION,
  DELETE_SELECTED_LOCATION,
  DELETE_DISPLAYED_LOCATION,
  DELETE_VISUALIZATION_LOCATION,
  SET_VISUALIZATION_LOCATIONS,
  ADD_VISUALIZATION_LOCATION,
  CLEAR_SELECTED_LOCATIONS
} from './locationActions';
import { fromJS } from 'immutable';

export const visualizationLocations = createReducer(null, {
  [SET_VISUALIZATION_LOCATIONS](state, { locations }) {
    return fromJS(locations);
  },
  [ADD_VISUALIZATION_LOCATION](state, { location }) {
    return state.push(location);
  },
  [DELETE_VISUALIZATION_LOCATION](state, { location }) {
    return state.filter(c => c.get('sensightId') !== location.sensightId);
  },
  [CLEAR_SELECTED_LOCATIONS]() {
    return fromJS([]);
  }
});

export const selectedLocations = createReducer(null, {
  [ADD_SELECTED_LOCATION](state, { location }) {
    return state.push(location);
  },
  [DELETE_SELECTED_LOCATION](state, { location }) {
    return state.filter(c => c.id !== location.id);
  },
  [CLEAR_SELECTED_LOCATIONS]() {
    return fromJS([]);
  }
});

export const displayedLocations = createReducer(null, {
  [SET_DISPLAYED_LOCATIONS](state, { locations }) {
    return fromJS(locations);
  },
  [ADD_DISPLAYED_LOCATION](state, { location }) {
    return state.push(location);
  },
  [DELETE_DISPLAYED_LOCATION](state, { location }) {
    return state.filter(c => c.get('sensightId') !== location.sensightId);
  }
});
