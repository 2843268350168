import React from 'react';
import { useTranslation } from 'react-i18next';
import { ManageDashboardContainer } from './ManageDashboardContainer';
import Content from '../../../page/Content';

export function ManageDashboard() {
  const { t } = useTranslation();

  const content = (
    <>
      <ManageDashboardContainer></ManageDashboardContainer>
    </>
  );
  return (
    <Content
      title={t('content.managedashboards.title')}
      content={content}
    ></Content>
  );
}
