import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import ImdPopup from 'components/imd-components/ImdPopup';
import { Column } from 'devextreme-react/tree-list';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { CreateDevicePlacementContainer } from './managedeviceplacement/CreateDevicePlacementContainer';
import { EditDevicePlacementContainer } from './managedeviceplacement/EditDevicePlacementContainer';

export function DevicePlacementsTableDisplay({
  locationId,
  devicePlacements,
  deleteDevicePlacement,
  setDevicePlacement,
  setDevicePlacementStartDateTime,
  setDevicePlacementEndDateTime,
  fetchDevicePlacementsOfLocation,
  setAvailableDevicesList
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleChangeSaveButton = enableSaveButton => {
    setSaveButton(enableSaveButton);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    setDevicePlacement(row);
    setDevicePlacementStartDateTime(new Date(row.startDateTime));
    setDevicePlacementEndDateTime(
      row.endDateTime ? new Date(row.endDateTime) : null
    );
    openEdit();
  };

  const openAdd = () => {
    setDevicePlacement({});
    setDevicePlacementStartDateTime(new Date());
    setDevicePlacementEndDateTime(null);
    setAvailableDevicesList([]);
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteDevicePlacement(selectedRow);
  };

  useEffect(() => {
    if (locationId) {
      fetchDevicePlacementsOfLocation(locationId);
    }
  }, []);

  useEffect(() => {
    if (add || edit) {
      setAdd(false);
      setEdit(false);
    }
  }, [devicePlacements]);

  const formRef = useRef();
  const columns = (
    <>
      <Column
        dataField={'devicename'}
        caption={t('content.deviceplacements.devicename')}
      />
      <Column
        dataField={'startDateTime'}
        caption={t('content.deviceplacements.startdatetime')}
        dataType="datetime"
      />
      <Column
        dataField={'endDateTime'}
        caption={t('content.deviceplacements.enddatetime')}
        dataType="datetime"
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.deviceplacementstable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );
  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditDevicePlacementContainer
            ref={formRef}
            locationId={locationId}
            viewModel={selectedRow}
            setSaveButton={e => handleChangeSaveButton(e)}
          ></EditDevicePlacementContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t(
          'content.deviceplacementstable.editdeviceplacement.title'
        )}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <CreateDevicePlacementContainer
            locationId={locationId}
            ref={formRef}
            setSaveButton={e => handleChangeSaveButton(e)}
          />
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t(
          'content.deviceplacementstable.createdeviceplacement.title'
        )}
      />
      <ImdPopup
        open={popup}
        title={t('content.deviceplacementstable.deletepopup.title')}
        content={t('content.deviceplacementstable.deletepopup.content', {
          deviceplacement: selectedRow.devicename
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.deviceplacementstable.title')}
        data={devicePlacements}
        columns={columns}
        stateStorageKey="device-placements-table-display"
      />
    </>
  );
}
