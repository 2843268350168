import { connect } from 'react-redux';
import LocationTypeTableDisplay from './LocationTypeTableDisplay.jsx';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { locationtypesListSelector } from './redux/locationTypesListSelectors.js';
import {
  deleteLocationType,
  fetchLocationTypesList
} from './redux/locationTypesListActions.js';

const mapStateToProps = state => {
  return {
    locationTypes: locationtypesListSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLocationTypesList() {
    dispatch(fetchLocationTypesList());
  },
  deleteLocationType(locationtype) {
    dispatch(deleteLocationType(locationtype));
  }
});

export const LocationTypeTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationTypeTableDisplay);
