import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_BLOBS = 'FETCH_BLOBS';
export const fetchBlobs = makeActionCreator(FETCH_BLOBS, 'locationId');

export const SET_BLOBS = 'SET_BLOBS';
export const setBlobs = makeActionCreator(SET_BLOBS, 'blobs');

export const DELETE_BLOB_REQUEST = 'DELETE_BLOB_REQUEST';
export const deleteBlobRequest = makeActionCreator(
  DELETE_BLOB_REQUEST,
  'blobId'
);

export const CREATE_BLOB_SUCCEEDED = 'CREATE_BLOB_SUCCEEDED';
export const createBlobSucceeded = makeActionCreator(
  CREATE_BLOB_SUCCEEDED,
  'blob'
);

export const DELETE_BLOB = 'DELETE_BLOB';
export const deleteBlob = makeActionCreator(DELETE_BLOB, 'blobId');
