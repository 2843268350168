import { createSelector } from 'reselect';

export const simCardsSelector = createSelector(
  state => state.get('simCardsList'),
  simCardsList => simCardsList.toJS()
);

export const simCardSelector = createSelector(
  state => state.get('simCard'),
  simCard => simCard.toJS()
);
