import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../page/Content';
import { OrganizationsTableContainer } from './organizationstable/OrganizationsTableContainer';

export default function Organizations() {
  const { t } = useTranslation();

  const content = <OrganizationsTableContainer />;

  return (
    <Content
      title={t('content.organizations.title')}
      secondTitle={t('navbar.manager')}
      content={content}
    />
  );
}
