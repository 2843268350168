import { connect } from 'react-redux';
import { DisplayChartComponentDisplay } from './displayChartComponentDisplay.jsx';
import {
  displayChart,
  setChartRendered,
  clearChartData,
  importVisualizeLink
} from './redux/displayChartActions';
import {
  displayChartSelector,
  chartRenderedSelector,
  channelDataSelector,
  logItemDataSelector
} from './redux/displayChartSelectors.js';
import {
  visualizeFromTimeSelector,
  visualizeUntilTimeSelector
} from '../toolbar/redux/toolbarSelectors.js';
import { isLoadingSelector } from 'redux/ui/uiSelectors';
import { createSnackbar } from 'redux/ui/uiActions';

const mapStateToProps = (state, ownProps) => {
  const chartData = displayChartSelector(state);
  const channelData = channelDataSelector(state).channels;
  const logItemsData = ownProps.logItems
    ? ownProps.logItems
    : logItemDataSelector(state);
  const chartRendered = chartRenderedSelector(state);
  const visualizeLink = ownProps.visualizeLink;
  const visualizeFromTime = ownProps.visualizeFromTime
    ? ownProps.visualizeFromTime
    : visualizeFromTimeSelector(state);
  const visualizeUntilTime = ownProps.visualizeUntilTime
    ? ownProps.visualizeUntilTime
    : visualizeUntilTimeSelector(state);
  const isLoading = isLoadingSelector(state);

  return {
    visualizeFromTime: visualizeFromTime,
    visualizeUntilTime: visualizeUntilTime,
    channelData: channelData,
    measurements: chartData.measurements,
    renderKey: chartData.renderKey,
    displayChart: chartRendered,
    visualizeLink: visualizeLink,
    isLoading: isLoading,
    logItems: logItemsData
  };
};

const mapDispatchToProps = dispatch => ({
  executeDisplayChart() {
    dispatch(displayChart());
  },
  setChartRendered(value) {
    dispatch(setChartRendered(value));
  },
  clearChartData() {
    dispatch(clearChartData());
  },
  importVisualizeLink(importObject) {
    dispatch(importVisualizeLink(importObject));
  },
  createSnackbar(message, variant) {
    dispatch(createSnackbar(message, variant));
  }
});

export const DisplayChartComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayChartComponentDisplay);
