import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { editLocationType } from 'apis/FieldServiceApi';

import { EDIT_LOCATIONTYPE } from './editLocationTypeActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { editLocationTypeSucceeded } from '../../redux/locationTypesListActions';

export function* editLocationTypeSaga() {
  yield takeEvery(EDIT_LOCATIONTYPE, execute);
}

function* execute(action) {
  const locationtype = action.locationtypeInformation;
  const user = yield select(currentUserSelector);

  try {
    yield editLocationType(user, locationtype);

    yield put(createSnackbar(t('content.editlocationtype.snackbar.text')));
    yield put(editLocationTypeSucceeded(locationtype));
  } catch (exception) {
    console.error(exception);
  }
}
