import { createReducer } from 'utils/createReducer';
import {
  SET_CHANNELS_LIST_OF_LOCATION,
  DELETE_CHANNEL_OF_LOCATION_SUCCEEDED,
  EDIT_CHANNEL_OF_LOCATION_SUCCEEDED
} from './channelsListActions';
import { fromJS } from 'immutable';
import { CREATE_CHANNEL_OF_LOCATION_SUCCEEDED } from '../createchannel/redux/createChannelActions';

export const channelsListOfLocation = createReducer(null, {
  [SET_CHANNELS_LIST_OF_LOCATION](state, { channelsList }) {
    return fromJS(channelsList);
  },
  [CREATE_CHANNEL_OF_LOCATION_SUCCEEDED](state, { channel }) {
    return state.push(fromJS(channel));
  },
  [EDIT_CHANNEL_OF_LOCATION_SUCCEEDED](state, { channel }) {
    let channelsList = state.toJS();
    var foundLocationIndex = channelsList.findIndex(
      cha => channel.id === cha.id
    );
    channelsList[foundLocationIndex] = {
      ...channelsList[foundLocationIndex],
      ...channel
    };
    return fromJS(channelsList);
  },
  [DELETE_CHANNEL_OF_LOCATION_SUCCEEDED](state, { channel }) {
    return state.filter(cha => cha.get('id') !== channel.id);
  }
});
