import { useTranslation } from 'react-i18next';

export function getLogItemStates() {
  const { t } = useTranslation();
  let logItemStates = [
    {
      key: '0',
      label: t('content.logitems.states.noaction'),
      color: '#8F8F8F'
    },
    {
      key: '1',
      label: t('content.logitems.states.open'),
      color: '#c4161d'
    },
    {
      key: '2',
      label: t('content.logitems.states.closed'),
      color: '#377f3a'
    }
  ];

  return logItemStates;
}
