import { connect } from 'react-redux';
import { ManualInsertDisplay } from './ManualInsertDisplay.jsx';
import {
  createManualInsertRow,
  editManualInsertRow,
  deleteManualInsertRow,
  createRowSucceeded,
  editRowSucceeded,
  deleteRowSucceeded,
  fetchManualChannels,
  fetchManualInserts,
  syncManualInserts,
  setManualInsertsList,
  setManualInsertsPeriod
} from './redux/manualInsertActions';
import {
  manualInsertsSelector,
  manualChannelsSelector,
  manualInsertsPeriodSelector,
  manualInsertRowsListSelector
} from './redux/manualInsertSelectors';
import { locationSelector } from '../redux/locationSelectors';
import { t } from 'i18next';
import { fetchLocation } from '../redux/locationActions.js';

const mapStateToProps = state => {
  const manualChannels = manualChannelsSelector(state);
  const sortedColumns = getColumnsSorted(manualChannels);
  const manualInserts = manualInsertsSelector(state);
  const changedManualInsertRows = manualInsertRowsListSelector(state);

  return {
    selectedLocation: locationSelector(state),
    manualInserts: manualInserts,
    columns: sortedColumns,
    changedManualInsertRows,
    manualInsertRows: getManualInsertRows(
      sortedColumns,
      manualInserts,
      changedManualInsertRows
    ),
    manualInsertsPeriod: manualInsertsPeriodSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchManualChannels() {
    dispatch(fetchManualChannels());
  },
  fetchManualInserts(channels) {
    dispatch(fetchManualInserts(channels));
  },
  setManualInsertsList(value) {
    dispatch(setManualInsertsList(value));
  },
  createManualInsertRow(value) {
    dispatch(createManualInsertRow(value));
  },
  deleteManualInsertRow(value) {
    dispatch(deleteManualInsertRow(value));
  },
  editManualInsertRow(value) {
    dispatch(editManualInsertRow(value));
  },
  createRowSucceeded(row) {
    dispatch(createRowSucceeded(row));
  },
  editRowSucceeded(row) {
    dispatch(editRowSucceeded(row));
  },
  deleteRowSucceeded(row) {
    dispatch(deleteRowSucceeded(row));
  },
  syncManualInserts(columnsAndRows) {
    dispatch(syncManualInserts(columnsAndRows));
  },
  setManualInsertsPeriod(period) {
    dispatch(setManualInsertsPeriod(period));
  },
  fetchLocation(locationId) {
    dispatch(fetchLocation(locationId));
  }
});

export const ManualInsertContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualInsertDisplay);

function getColumnsSorted(manualChannels) {
  let columns = [
    {
      id: 0,
      key: 0,
      dataField: 'dateTime',
      dataType: 'datetime',
      caption: t('content.component.manualinsert.datetimeColumnCaption'),
      required: true
    }
  ];

  let sortedChannels = manualChannels.sort((a, b) => {
    if (a.externalChannelId < b.externalChannelId) return -1;
    if (a.externalChannelId === b.externalChannelId) return 0;
    if (a.externalChannelId > b.externalChannelId) return 1;
  });

  sortedChannels.forEach((sortedChannel, columnIndex) => {
    let displayLabel =
      sortedChannel.displayLabel || sortedChannel.externalChannelId || '';

    columns.push({
      id: columnIndex + 1,
      key: columnIndex + 1,
      deviceName: sortedChannel.device.name,
      dataField: sortedChannel.sensightId,
      dataType: 'number',
      channelId: sortedChannel.measurementServiceId,
      caption: `${displayLabel}${
        sortedChannel.unit ? `[${sortedChannel.unit}]` : ``
      }`,
      required: false,
      min: sortedChannel.min,
      max: sortedChannel.max
    });
  });

  return columns;
}

function getManualInsertRows(columns, manualInserts, changedManualInsertRows) {
  let manualInsertRows = [];
  for (const manualInsert in manualInserts) {
    const currentRow = {
      dateTime: manualInsert,
      id: manualInsert
    };
    const rowData = manualInserts[manualInsert];

    columns.forEach(column => {
      if (
        (rowData && typeof rowData[column.dataField] === 'number') ||
        typeof rowData[column.dataField] === 'string'
      ) {
        currentRow[column.dataField] = rowData[column.dataField];
      }
    });

    manualInsertRows.push(currentRow);
  }

  for (const changedRowIndex in changedManualInsertRows) {
    const changedRow = changedManualInsertRows[changedRowIndex];
    manualInsertRows = manualInsertRows.filter(r => r.id !== changedRow.id);
    if (!changedRow.isDeleted) {
      manualInsertRows.push(changedRow);
    }
  }

  return manualInsertRows.sort(function (a, b) {
    const date1 = new Date(a.dateTime);
    const date2 = new Date(b.dateTime);

    if (date1 > date2) {
      return -1;
    } else if (date1 == date2) {
      return 0;
    } else {
      return 1;
    }
  });
}
