import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Auth0Provider } from './react-auth0-spa';
import config from './auth_config.json';
import i18n from './i18n';
import configureStore, { history } from './utils/configureStore';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import 'typeface-roboto';
import { setInitialTenantFromPageload } from 'utils/tenantManager.js';

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

window.enableDebug = () => {
  // Make console.dev global available to use debug logging for non production environments without having to import anything.
  localStorage.setItem('enableDebug', true);
  console.dev = console.log.bind(window.console, `%c%s`, 'font-style: normal');

  console.dev(
    'Debugging enabled. call console.dev to use it. To enable timestamps, please open the developer console, click settings and toggle on "show timestamps".'
  );
};

window.disableDebug = () => {
  localStorage.setItem('enableDebug', false);
};

window.enableReduxDebug = () => {
  localStorage.setItem('enableReduxDebug', true);
};

window.disableReduxDebug = () => {
  localStorage.setItem('enableReduxDebug', false);
};

if (
  (process.env.NODE_ENV === 'development' &&
    localStorage.getItem('enableDebug') !== 'false') ||
  localStorage.getItem('enableDebug') === 'true'
) {
  window.enableDebug();
} else {
  console.dev = () => {};
}

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const rootElement = document.getElementById('root');
const store = configureStore();
setInitialTenantFromPageload(window.location.search);

render(
  <ReduxProvider store={store}>
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      audience={config.audience}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      store={store}
    >
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </I18nextProvider>
    </Auth0Provider>
  </ReduxProvider>,
  rootElement
);

serviceWorker.unregister();
