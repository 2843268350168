import { makeActionCreator } from 'utils/makeActionCreator';

export const CREATE_CHANNEL_OF_LOCATION = 'CREATE_CHANNEL_OF_LOCATION';
export const createChannelOfLocation = makeActionCreator(
  CREATE_CHANNEL_OF_LOCATION,
  'channel'
);

export const CREATE_CHANNEL_OF_LOCATION_SUCCEEDED =
  'CREATE_CHANNEL_OF_LOCATION_SUCCEEDED';
export const createChannelOfLocationSucceeded = makeActionCreator(
  CREATE_CHANNEL_OF_LOCATION_SUCCEEDED,
  'channel'
);
