import { connect } from 'react-redux';
import EditOrganizationDisplay from './EditOrganizationDisplay.jsx';
import {
  contactsFromOrganizationListSelector,
  locationsFromOrganizationSelector
} from './redux/editOrganizationSelectors';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  fetchContactsFromOrganizationList,
  editOrganization,
  fetchLocationsFromOrganization
} from './redux/editOrganizationActions';
import { t } from 'i18next';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { dashboardLocationsSelector } from 'components/content/dashboard/locations/redux/dashboardLocationsSelectors.js';

const mapStateToProps = (state, ownProps) => {
  const locations = dashboardLocationsSelector(state);

  return {
    row: ownProps.row,
    contacts: contactsFromOrganizationListSelector(state),
    currentUser: currentUserSelector(state),
    locations: locations,
    locationsFromOrganization: locationsFromOrganizationSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  editOrganization(organizationInformation) {
    dispatch(editOrganization(organizationInformation));
  },
  handleValidate(values) {
    return handleValidate(values);
  },
  fetchContactsFromOrganizationList(organizationId) {
    dispatch(fetchContactsFromOrganizationList(organizationId));
  },
  fetchLocationsFromOrganization(organizationSensightId) {
    dispatch(fetchLocationsFromOrganization(organizationSensightId));
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.name) {
    errors.name = t('general.validation.required');
  }
  return errors;
};

export const EditOrganizationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(EditOrganizationDisplay);
