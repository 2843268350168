import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { editChannel } from 'apis/FieldServiceApi';
import { editChannel as editMeasurementChannel } from 'apis/MeasurementServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import {
  EDIT_CHANNEL_OF_LOCATION,
  editChannelOfLocationSucceeded
} from './editChannelActions';

export function* editChannelSaga() {
  yield takeEvery(EDIT_CHANNEL_OF_LOCATION, execute);
}

function* execute(action) {
  let channel = action.channel;
  const user = yield select(currentUserSelector);

  try {
    const fieldServiceRequest = getFieldServiceRequest(channel);
    const measurementServiceRequest = getMeasurementServiceRequest(channel);

    yield editChannel(user, fieldServiceRequest);
    yield editMeasurementChannel(user, measurementServiceRequest);
    yield put(createSnackbar(t('content.editchannel.snackbar.text')));

    yield put(editChannelOfLocationSucceeded(channel));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

const getFieldServiceRequest = channel => {
  return {
    displayLabel: channel.displayLabel,
    sensorType: channel.sensorType,
    sensorSerialNumber: channel.sensorSerialNumber,
    metadata: channel.metadata,
    channelType: channel.channelType,
    deviceId: channel.deviceFieldId,
    id: channel.fieldServiceId,
    sensightId: channel.sensightId
  };
};
const getMeasurementServiceRequest = channel => {
  return {
    calulatedChannelInformation: channel.calulatedChannelInformation,
    displayLabel: channel.displayLabel,
    deviceId: channel.deviceMeasurementId,
    unit: channel.unit,
    visible: channel.visible,
    min: channel.min,
    max: channel.max,
    validationProfileKey: channel.validationProfileKey,
    lowerLimit: channel.lowerLimit,
    upperLimit: channel.upperLimit,
    id: channel.measurementServiceId,
    sensightId: channel.sensightId
  };
};
