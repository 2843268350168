import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_SIMCARD = 'SET_SIMCARD';
export const setSimCard = makeActionCreator(SET_SIMCARD, 'simCard');

export const FETCH_SIMCARDS = 'FETCH_SIMCARDS';
export const fetchSimCards = makeActionCreator(FETCH_SIMCARDS);

export const UPSERT_SIMCARD_DETAILS = 'UPSERT_SIMCARD_DETAILS';
export const upsertSimCardDetails = makeActionCreator(
  UPSERT_SIMCARD_DETAILS,
  'simCardDetails'
);

export const SET_SIMCARDS_LIST = 'SET_SIMCARDS_LIST ';
export const setSimCardsList = makeActionCreator(
  SET_SIMCARDS_LIST,
  'simCardsList'
);

export const CREATE_SIMCARD = 'CREATE_SIMCARD';
export const createSimCard = makeActionCreator(CREATE_SIMCARD);

export const CREATE_SIMCARD_SUCCEEDED = 'CREATE_SIMCARD_SUCCEEDED';
export const createSimCardSucceeded = makeActionCreator(
  CREATE_SIMCARD_SUCCEEDED,
  'simCard'
);

export const EDIT_SIMCARD = 'EDIT_SIMCARD';
export const editSimCard = makeActionCreator(EDIT_SIMCARD);

export const EDIT_SIMCARD_SUCCEEDED = 'EDIT_SIMCARD_SUCCEEDED';
export const editSimCardSucceeded = makeActionCreator(
  EDIT_SIMCARD_SUCCEEDED,
  'simCard'
);

export const DELETE_SIMCARD = 'DELETE_SIMCARD';
export const deleteSimCard = makeActionCreator(DELETE_SIMCARD, 'deleteObject');

export const DELETE_SIMCARD_SUCCEEDED = 'DELETE_SIMCARD_SUCCEEDED ';
export const deleteSimCardSucceeded = makeActionCreator(
  DELETE_SIMCARD_SUCCEEDED,
  'simCard'
);
