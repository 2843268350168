import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  createLogItem,
  getDeviceBySensightId,
  getChannelBySensightId
} from 'apis/FieldServiceApi';
import { getDeviceById } from 'apis/MeasurementServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import {
  RENDER_CHART_CREATE_LOGITEM,
  renderChartCreateLogItemSucceeded
} from './renderChartCreateLogItemActions';

export function* renderChartCreateLogItemSaga() {
  yield takeEvery(RENDER_CHART_CREATE_LOGITEM, executeCreateLogItem);
}

function* executeCreateLogItem(action) {
  let logitem = action.logitem;

  const user = yield select(currentUserSelector);
  const measurementServiceDeviceResponse = yield getDeviceById(
    user,
    logitem.deviceId
  );
  let fieldServiceDeviceResponse = yield getDeviceBySensightId(
    user,
    measurementServiceDeviceResponse.data.sensightId
  );
  logitem.deviceId = fieldServiceDeviceResponse.data[0].id;

  let fieldServiceChannelResponse = yield getChannelBySensightId(
    user,
    logitem.channelSensightId
  );
  logitem.channelId = fieldServiceChannelResponse.data[0].id;

  try {
    const createdLogItemResult = yield createLogItem(user, logitem);
    const createdLogItem = createdLogItemResult.data;

    yield put(createSnackbar(t('content.createlogitem.snackbar.text')));
    yield put(
      renderChartCreateLogItemSucceeded({ ...logitem, ...createdLogItem })
    );
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}
