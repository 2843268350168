import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import { PropTypes } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

export default function ImdCard(props) {
  const classes = useImdCardStyles();
  const renderCardHeader = () => {
    if (props.variant == 'secondary') {
      return (
        <CardHeader
          title={props.title}
          classes={{ root: classes.secondary }}
          titleTypographyProps={{ variant: 'h6' }}
        />
      );
    }

    return (
      <CardHeader
        title={props.title}
        classes={{ root: classes.root }}
        titleTypographyProps={{ variant: 'h6' }}
      />
    );
  };

  return (
    <Card
      className={classes.card}
      style={{ height: props.cardHeight ?? 'auto' }}
    >
      {renderCardHeader()}
      <CardContent>{props.content}</CardContent>
    </Card>
  );
}

ImdCard.propTypes = {
  title: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired
};

const useImdCardStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  secondary: {
    backgroundColor: '#f0f0f0',
    color: '#000000',
    fontSize: '1rem !important',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  card: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  }
}));
