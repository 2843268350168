import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from 'components/imd-components/datatable/ImdDataTable';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import DeleteDevicePopup from '../managedevice/DeleteDevicePopup';
import { Column } from 'devextreme-react/tree-list';
import { CreateDeviceContainer } from '../managedevice/CreateDeviceContainer';
import { EditDeviceContainer } from '../managedevice/EditDeviceContainer';

export default function DevicesTableDisplay({
  devices,
  deleteDevice,
  setDevice,
  setShouldDeleteFunctionalDevice,
  shouldDeleteFunctionalDeviceValue,
  setDeviceOnlineSwitchValue,
  setDeviceVisibleSwitchValue
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const isMobile = window.matchMedia('(max-width: 450px)').matches;
  const isTablet = window.matchMedia('(max-width: 900px)').matches;

  const handleChangeSaveButton = enableSaveButton => {
    setSaveButton(enableSaveButton);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const closeDelete = () => {
    setDeviceVisibleSwitchValue(true);
    setDeviceOnlineSwitchValue(false);
    setPopup(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    setDevice(row);
    setDeviceOnlineSwitchValue(row.online ?? false);
    setDeviceVisibleSwitchValue(row.visible ?? false);
    openEdit();
  };

  const openAdd = () => {
    // Before we open the createDevice dialog, clear the currently active device.
    // We want to retrieve all available simcards, not just those available for a certain device.
    setDevice({});
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    setDeviceVisibleSwitchValue(true);
    setDeviceOnlineSwitchValue(false);
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setShouldDeleteFunctionalDevice(true);
    setPopup(true);
  };

  const handlePopupOk = shouldDeleteFunctionalDevice => {
    setPopup(false);
    deleteDevice({ selectedRow, shouldDeleteFunctionalDevice });
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [devices]);
  const columns = (
    <>
      <Column
        visible={true}
        dataField={'name'}
        caption={t('content.devices.name')}
      />
      <Column
        visible={true}
        dataField={'latestDevicePlacement.location.name'}
        caption={t('content.devices.devicePlacement.location.name')}
      />
      <Column
        visible={!isMobile}
        dataType={'datetime'}
        dataField={'latestDevicePlacement.startDateTime'}
        caption={t('content.devices.devicePlacement.startDateTime')}
      />
      <Column
        visible={!isMobile}
        dataType={'datetime'}
        dataField={'latestDevicePlacement.endDateTime'}
        caption={t('content.devices.devicePlacement.endDateTime')}
      />
      <Column
        visible={false}
        dataField={'description'}
        caption={t('content.devices.description')}
      />
      <Column
        visible={false}
        dataField={'phoneNumber'}
        caption={t('content.devices.phonenumber')}
      />
      <Column
        visible={!isMobile && !isTablet}
        dataField={'manufacturer'}
        caption={t('content.devices.manufacturer')}
      />
      <Column
        visible={!isMobile && !isTablet}
        dataField={'serialNumber'}
        caption={t('content.devices.serialnumber')}
      />
      <Column
        visible={!isMobile && !isTablet}
        dataField={'deviceType'}
        caption={t('content.devices.devicetype')}
      />
      <Column
        visible={false}
        dataField={'simCardId'}
        caption={t('content.devices.simcardid')}
      />
      <Column
        visible={false}
        dataField={'ipAddress'}
        caption={t('content.devices.ipaddress')}
      />
      <Column
        visible={false}
        dataField={'portNr'}
        caption={t('content.devices.portnr')}
      />
      <Column
        visible={false}
        dataField={'externalLink'}
        caption={t('content.devices.externallink')}
      />
      <Column
        visible={true}
        dataField={'visible'}
        caption={t('content.devices.visible')}
      />
      <Column
        visible={true}
        dataField={'online'}
        caption={t('content.devices.online')}
      />
      <Column
        visible={!isMobile && !isTablet}
        dataField={'externalDeviceId'}
        caption={t('content.devices.externaldeviceid')}
      />
      <Column
        visible={true}
        dataField={'reliabilityPercentage'}
        caption={t('content.devices.reliabilitypercentage')}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        visible={false}
        dataField={'latestDevicePlacement.lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.deviceplacement.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'latestDevicePlacement.lastUpdatedBy'}
        caption={t('content.general.deviceplacement.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.devicestable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <EditDeviceContainer
            ref={formRef}
            row={selectedRow}
            setSaveButton={e => handleChangeSaveButton(e)}
          ></EditDeviceContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.devicestable.editdevice.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={<CreateDeviceContainer ref={formRef}></CreateDeviceContainer>}
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.devicestable.createdevice.title')}
      />
      <DeleteDevicePopup
        open={popup}
        title={t('content.devicestable.deletepopup.title', {
          device: selectedRow.name
        })}
        setShouldDeleteFunctionalDevice={setShouldDeleteFunctionalDevice}
        shouldDeleteFunctionalDeviceValue={shouldDeleteFunctionalDeviceValue}
        handleOk={shouldDeleteFunctionalDevice =>
          handlePopupOk(shouldDeleteFunctionalDevice)
        }
        handleClose={() => closeDelete()}
      ></DeleteDevicePopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.devices.title')}
        data={devices}
        columns={columns}
        stateStorageKey="device-table-display"
      />
    </>
  );
}
