import { connect } from 'react-redux';
import ImdDialogDisplay from './ImdDialogDisplay.jsx';
import { saveButtonDisabledSelector } from './redux/imdDialogSelectors';
import { check } from '../authorization/Can';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import { getCurrentUserRole } from 'utils/currentUserReader.js';
const mapStateToProps = (state, ownProps) => {
  if (ownProps.saveButtonVisible === undefined) {
    ownProps.saveButtonVisible = true;
  }
  const userRole = getCurrentUserRole(currentUserSelector(state));
  if (ownProps.saveButtonVisible === true) {
    if (ownProps.perform === undefined) {
      ownProps.perform = 'modal:save';
    }
    if (!check(userRole, ownProps.perform)) {
      ownProps.saveButtonVisible = false;
    }
  }
  return {
    resizeToContent: ownProps.resizeToContent,
    open: ownProps.open,
    handleCloseAction: ownProps.handleCloseAction,
    extraAction: ownProps.extraAction,
    content: ownProps.content,
    formRef: ownProps.formRef,
    dialogTitle: ownProps.dialogTitle,
    handleSaveAction: ownProps.handleSaveAction,
    saveButtonDisabled:
      typeof ownProps.saveButtonDisabled === 'boolean'
        ? ownProps.saveButtonDisabled
        : saveButtonDisabledSelector(state),
    saveButtonTooltip: ownProps.saveButtonTooltip,
    saveButtonVisible: ownProps.saveButtonVisible,
    saveButtonTranslation: ownProps.saveButtonTranslation,
    cancelButtonTranslation: ownProps.cancelButtonTranslation
  };
};

export const ImdDialogContainer = connect(mapStateToProps)(ImdDialogDisplay);
