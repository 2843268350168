import { fromJS } from 'immutable';

export const initialState = fromJS({
  isClearingChannelDialog: false,
  isFetchingChannelDialogLocations: false,
  isFetchingChannelDialogDevices: false,
  hasStartedFetchingLocationsAndDevices: false,
  hasFinishedFetchingLocationsAndDevices: false,
  currentUser: null,
  isLoading: false,
  blobs: [],
  profileDetails: {},
  organizationsList: [],
  validationProfilesList: [],
  contactsFromOrganizationList: [],
  locationsFromOrganization: [],
  contactsList: [],
  locationtypesList: [],
  exportChartsList: [],
  locationsList: [],
  userDetails: {},
  contactIsUser: false,
  visualizeFromTime: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  saveButtonDisabled: true,
  visualizeUntilTime: fromJS(new Date()),
  visualizePeriod: 'twentyFourHours',
  chartRendered: false,
  chartData: {
    id: null,
    renderKey: 0,
    measurements: []
  },
  chartDisplayOptions: {
    renderMultipleCharts: false,
    renderGrid: true,
    renderLimitLines: false,
    fixedYAxis: false,
    autoRefresh: false,
    renderLogItems: true
  },
  channelData: {
    channels: []
  },
  logItemData: [],
  visualizationLocations: [],
  selectedLocations: [],
  displayedLocations: [],
  visualizationDevices: [],
  selectedDevices: [],
  displayedDevices: [],
  visualizationChannels: [],
  selectedChannels: [],
  displayedChannels: [],
  snackbar: {
    snackbarOptions: null,
    snackbarMessage: null
  },
  dialog: {
    open: false
  },
  displayChart: {
    measurements: [],
    visible: false
  },
  autocompleteSelectedOrganizations: [],
  autocompleteSelectedServiceContactPersons: [],
  autocompleteSelectedContactPersons: {
    email: ''
  },
  device: {},
  deviceOnlineSwitchValue: false,
  deviceVisibleSwitchValue: true,
  availableSimCardsList: [],
  devicesList: [],
  shouldDeleteFunctionalDevice: true,
  location: {},
  locationDetails: {
    location: {},
    organizationsCoupled: [],
    measurementServiceLocation: {
      online: false,
      visible: false
    },
    contacts: [],
    organizations: [],
    locationTypes: [],
    locationContact: {
      email: ''
    },
    listOfLocationServiceContacts: []
  },
  dashboardLocations: [],
  onlineSwitchValue: false,
  hideSwitchValue: false,
  autoRefresh: false,
  dashboards: [],
  managementDashboards: [],
  currentDashboard: {
    dashboardSections: []
  },
  sections: [],
  dashboardMeasurements: [],
  channelsListOfLocation: [],
  devicesListOfLocation: [],
  devicePlacement: {},
  devicePlacementsOfDevice: [],
  devicePlacementsOfLocation: [],
  availableDevicesList: [],
  devicePlacementStartDateTime: new Date(),
  devicePlacementEndDateTime: null,
  dataScienceReports: [],
  defaultChannelChartType: 'line',
  defaultChannelAggregationType: 'hour-avg',
  manualInsertsList: [],
  manualChannelsList: [],
  manualInsertRowsList: [],
  manualInsertsPeriod: 3,
  logitemsListOfLocation: [],
  actionsList: [],
  simCardsList: [],
  simCard: {},
  logbookActionsList: [],
  logbookAction: {},
  logItemViewModel: {},
  selectChannelsMode: false,
  tenantsList: [],
  tenant: {},
  themesList: [],
  theme: {},
  alert: {},
  alertsList: [],
  alertActiveSwitchValue: true,
  subscribers: [],
  selectedSubscribers: []
});
