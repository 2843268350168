import { connect } from 'react-redux';
import OrganizationsTableDisplay from './OrganizationsTableDisplay.jsx';
import { deleteOrganizationRequested } from './redux/deleteOrganizationActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { organizationsListSelector } from '../redux/organizationsListSelectors.js';
import { fetchOrganizationsList } from '../redux/organizationsListActions';
const mapStateToProps = state => {
  return {
    organizations: organizationsListSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteOrganization(row) {
    dispatch(deleteOrganizationRequested(row));
  },
  fetchOrganizationsList() {
    dispatch(fetchOrganizationsList());
  }
});

export const OrganizationsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationsTableDisplay);
