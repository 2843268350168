/* eslint-disable no-unreachable */
import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createExportChart } from 'apis/ReportingServiceApi';
import { CREATE_EXPORTCHART } from './createExportChartActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { createExportChartSucceeded } from '../../redux/exportChartsListActions';
import { Get } from 'apis/MeasurementODataApi';
import { cloneObject, getEmptyGuid } from 'utils/helperMethods';
import { getODataQuery } from './exportChartSagaHelpers';
export function* createExportChartSaga() {
  yield takeEvery(CREATE_EXPORTCHART, execute);
}

function* execute(action) {
  let exportchart = action.exportChart;
  const user = yield select(currentUserSelector);

  try {
    exportchart.exportChartContacts = exportchart.exportChartContacts.map(
      contact => {
        return {
          contactSensightId: contact
        };
      }
    );
    let channels = cloneObject(exportchart.exportChartChannels);
    yield addLocationSensightId(user, channels);

    exportchart.exportChartChannels = channels.map((channel, index) => {
        return {
        channelSensightId: channel.sensightId,
        order: index,
        locationSensightId: channel.locationSensightId ?? getEmptyGuid(),
        aggregationType: channel.aggregationType
      };
    });

    const createdExportChartResult = yield createExportChart(user, exportchart);
    const createdExportChart = createdExportChartResult.data.result;

    yield put(createSnackbar(t('content.createexportchart.snackbar.text')));

    yield put(createExportChartSucceeded(createdExportChart));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export const addLocationSensightId = async function (user, channels) {
  for (var channel of channels) {
    const oDataResponse = await Get(user, getODataQuery(channel.sensightId));
    channel.locationSensightId =
      oDataResponse[0].device.devicePlacements[0].location.sensightId;
  }
};
