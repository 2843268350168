import React from 'react';
import { useTranslation } from 'react-i18next';
import { ManageAllDashboardsContainer } from './ManageAllDashboardsContainer';
import Content from '../../../page/Content';

export function ManageAllDashboards() {
  const { t } = useTranslation();

  const content = <ManageAllDashboardsContainer />;
  return (
    <Content
      title={t('content.managealldashboards.title')}
      secondTitle={t('navbar.manager')}
      content={content}
    />
  );
}
