import { createReducer } from 'utils/createReducer';
import {
  SET_TENANT,
  SET_TENANTS_LIST,
  CREATE_TENANT_SUCCEEDED,
  EDIT_TENANT_SUCCEEDED,
  DELETE_TENANT_SUCCEEDED
} from './tenantsActions';
import { fromJS } from 'immutable';

export const tenantsList = createReducer(null, {
  [SET_TENANTS_LIST](state, { tenantsList }) {
    return fromJS(tenantsList);
  },
  [CREATE_TENANT_SUCCEEDED](state, { tenant }) {
    return state.push(fromJS(tenant));
  },
  [EDIT_TENANT_SUCCEEDED](state, { tenant }) {
    let tenantsList = state.toJS();
    var foundTenantIndex = tenantsList.findIndex(tl => tenant.id === tl.id);
    tenantsList[foundTenantIndex] = tenant;
    return fromJS(tenantsList);
  },
  [DELETE_TENANT_SUCCEEDED](state, { tenant }) {
    return state.filter(con => con.get('id') !== tenant.id);
  }
});

export const tenant = createReducer(null, {
  [SET_TENANT](state, { tenant }) {
    return fromJS(tenant);
  }
});
