import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_VISUALIZATION_DEVICES = 'SET_VISUALIZATION_DEVICES';
export const setVisualizationDevices = makeActionCreator(
  SET_VISUALIZATION_DEVICES,
  'devices'
);

export const DELETE_VISUALIZATION_DEVICE = 'DELETE_VISUALIZATION_DEVICE';
export const deleteVisualizationDevice = makeActionCreator(
  DELETE_VISUALIZATION_DEVICE,
  'device'
);

export const ADD_VISUALIZATION_DEVICE = 'ADD_VISUALIZATION_DEVICE';
export const addVisualizationDevice = makeActionCreator(
  ADD_VISUALIZATION_DEVICE,
  'device'
);

export const SET_DISPLAYED_DEVICES = 'SET_DISPLAYED_DEVICES';
export const setDisplayedDevices = makeActionCreator(
  SET_DISPLAYED_DEVICES,
  'devices'
);

export const DELETE_DISPLAYED_DEVICE = 'DELETE_DISPLAYED_DEVICE';
export const deleteDisplayedDevice = makeActionCreator(
  DELETE_DISPLAYED_DEVICE,
  'device'
);

export const ADD_DISPLAYED_DEVICE = 'ADD_DISPLAYED_DEVICE';
export const addDisplayedDevice = makeActionCreator(
  ADD_DISPLAYED_DEVICE,
  'device'
);

export const FILTER_DISPLAYED_DEVICES = 'FILTER_DISPLAYED_DEVICES';
export const filterDisplayedDevices = makeActionCreator(
  FILTER_DISPLAYED_DEVICES,
  'query'
);

export const ADD_SELECTED_DEVICE = 'ADD_SELECTED_DEVICE';
export const addSelectedDevice = makeActionCreator(
  ADD_SELECTED_DEVICE,
  'device'
);

export const SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES';
export const setSelectedDevices = makeActionCreator(
  SET_SELECTED_DEVICES,
  'devices'
);

export const DELETE_SELECTED_DEVICE = 'DELETE_SELECTED_DEVICE';
export const deleteSelectedDevice = makeActionCreator(
  DELETE_SELECTED_DEVICE,
  'device'
);

export const CLEAR_SELECTED_DEVICES = 'CLEAR_SELECTED_DEVICES';
export const clearSelectedDevices = makeActionCreator(CLEAR_SELECTED_DEVICES);
