import { makeActionCreator } from 'utils/makeActionCreator';

export const EDIT_CHANNEL_OF_LOCATION = 'EDIT_CHANNEL_OF_LOCATION';
export const editChannelOfLocation = makeActionCreator(
  EDIT_CHANNEL_OF_LOCATION,
  'channel'
);

export const EDIT_CHANNEL_OF_LOCATION_SUCCEEDED =
  'EDIT_CHANNEL_OF_LOCATION_SUCCEEDED';
export const editChannelOfLocationSucceeded = makeActionCreator(
  EDIT_CHANNEL_OF_LOCATION_SUCCEEDED,
  'channel'
);
