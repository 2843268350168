import { makeActionCreator } from 'utils/makeActionCreator';

export const EDIT_LOGITEM_OF_LOCATION = 'EDIT_LOGITEM_OF_LOCATION';
export const editLogItemOfLocation = makeActionCreator(
  EDIT_LOGITEM_OF_LOCATION,
  'logitem'
);

export const EDIT_LOGITEM_OF_LOCATION_SUCCEEDED =
  'EDIT_LOGITEM_OF_LOCATION_SUCCEEDED';
export const editLogItemOfLocationSucceeded = makeActionCreator(
  EDIT_LOGITEM_OF_LOCATION_SUCCEEDED,
  'logitem'
);
