import { makeActionCreator } from 'utils/makeActionCreator';

export const EDIT_CONTACT = 'EDIT_CONTACT';
export const editContact = makeActionCreator(
  EDIT_CONTACT,
  'contactInformation'
);

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const fetchUserDetails = makeActionCreator(
  FETCH_USER_DETAILS,
  'contactSensightId'
);

export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const setUserDetails = makeActionCreator(SET_USER_DETAILS, 'user');

export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const clearUserDetails = makeActionCreator(CLEAR_USER_DETAILS);
