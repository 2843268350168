import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_DEVICEPLACEMENTS_OF_LOCATION =
  'FETCH_DEVICEPLACEMENTS_OF_LOCATION';
export const fetchDevicePlacementsOfLocation = makeActionCreator(
  FETCH_DEVICEPLACEMENTS_OF_LOCATION,
  'locationId'
);

export const SET_DEVICEPLACEMENT = 'SET_DEVICEPLACEMENT';
export const setDevicePlacement = makeActionCreator(
  SET_DEVICEPLACEMENT,
  'devicePlacement'
);
export const SET_DEVICEPLACEMENTS_OF_LOCATION =
  'SET_DEVICEPLACEMENTS_OF_LOCATION';
export const setDevicePlacementsOfLocation = makeActionCreator(
  SET_DEVICEPLACEMENTS_OF_LOCATION,
  'devicePlacementsOfLocation'
);

export const SET_DEVICEPLACEMENTS_OF_DEVICE = 'SET_DEVICEPLACEMENTS_OF_DEVICE';
export const setDevicePlacementsOfDevice = makeActionCreator(
  SET_DEVICEPLACEMENTS_OF_DEVICE,
  'devicePlacementsOfDevice'
);

export const FETCH_AVAILABLEDEVICES = 'FETCH_AVAILABLEDEVICES';
export const fetchAvailableDevices = makeActionCreator(
  FETCH_AVAILABLEDEVICES,
  'startDateTime',
  'endDateTime',
  'currentDeviceId'
);

export const SET_AVAILABLEDEVICES_LIST = 'SET_AVAILABLEDEVICES_LIST ';
export const setAvailableDevicesList = makeActionCreator(
  SET_AVAILABLEDEVICES_LIST,
  'availableDevicesList'
);

export const CREATE_DEVICEPLACEMENT = 'CREATE_DEVICEPLACEMENT';
export const createDevicePlacement = makeActionCreator(
  CREATE_DEVICEPLACEMENT,
  'devicePlacement'
);

export const CREATE_DEVICEPLACEMENT_SUCCEEDED =
  'CREATE_DEVICEPLACEMENT_SUCCEEDED';
export const createDevicePlacementSucceeded = makeActionCreator(
  CREATE_DEVICEPLACEMENT_SUCCEEDED,
  'devicePlacement'
);
export const EDIT_DEVICEPLACEMENT = 'EDIT_DEVICEPLACEMENT';
export const editDevicePlacement = makeActionCreator(
  EDIT_DEVICEPLACEMENT,
  'devicePlacement'
);

export const EDIT_DEVICEPLACEMENT_SUCCEEDED = 'EDIT_DEVICEPLACEMENT_SUCCEEDED';
export const editDevicePlacementSucceeded = makeActionCreator(
  EDIT_DEVICEPLACEMENT_SUCCEEDED,
  'devicePlacement'
);

export const DELETE_DEVICEPLACEMENT = 'DELETE_DEVICEPLACEMENT';
export const deleteDevicePlacement = makeActionCreator(
  DELETE_DEVICEPLACEMENT,
  'devicePlacement'
);

export const DELETE_DEVICEPLACEMENT_SUCCEEDED =
  'DELETE_DEVICEPLACEMENT_SUCCEEDED ';
export const deleteDevicePlacementSucceeded = makeActionCreator(
  DELETE_DEVICEPLACEMENT_SUCCEEDED,
  'devicePlacement'
);

export const SET_DEVICEPLACEMENTSTARDATETIME =
  'SET_DEVICEPLACEMENTSTARDATETIME';
export const setDevicePlacementStartDateTime = makeActionCreator(
  SET_DEVICEPLACEMENTSTARDATETIME,
  'devicePlacementStartDateTime'
);

export const SET_DEVICEPLACEMENTENDDATETIME = 'SET_DEVICEPLACEMENTENDDATETIME';
export const setDevicePlacementEndDateTime = makeActionCreator(
  SET_DEVICEPLACEMENTENDDATETIME,
  'devicePlacementEndDateTime'
);

export const SET_DEVICEPLACEMENTDEVICEID = 'SET_DEVICEPLACEMENTDEVICEID';
export const setDevicePlacementDeviceId = makeActionCreator(
  SET_DEVICEPLACEMENTDEVICEID,
  'deviceId'
);
