import { connect } from 'react-redux';
import RenderChartDisplay from './renderChartDisplay.jsx';
import { chartDisplayOptionsSelector } from '../toolbar/redux/toolbarSelectors.js';
import { setLogItemViewModel } from './redux/renderChartCreateLogItemActions';

import { fetchDashboardItemData } from '../../dashboard/dashboardItem/redux/dashboardItemActions.js';

const mapStateToProps = (state, ownProps) => {
  return {
    item: ownProps.item,
    visualize: ownProps.visualize,
    locations: ownProps.locations,
    dataSources: ownProps.dataSources,
    measurements: ownProps.measurements,
    chartDisplayOptions: ownProps.chartDisplayOptions
      ? ownProps.chartDisplayOptions
      : chartDisplayOptionsSelector(state),
    visualizeFromTime: ownProps.visualizeFromTime,
    visualizeUntilTime: ownProps.visualizeUntilTime,
    renderKey: ownProps.renderKey,
    logItems: ownProps.logItems ?? []
  };
};
const mapDispatchToProps = dispatch => ({
  setLogItemViewModel(logItemViewModel) {
    dispatch(setLogItemViewModel(logItemViewModel));
  },
  fetchDashboardItemData(item) {
    dispatch(fetchDashboardItemData(item));
  }
});

export const RenderChartContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(RenderChartDisplay);
