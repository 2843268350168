import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { Grid, Typography, Switch, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImdButton from 'components/imd-components/ImdButton';
import ImdCard from 'components/imd-components/ImdCard';
import { Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import ImdMetaDataForm from 'components/imd-components/ImdMetaDataForm';
import { isEmptyObject } from 'utils/helperMethods';
import { SelectChannelDialogContainer } from 'components/content/visualize/toolbar/dialogs/selectChannelDialog/selectChannelDialogContainer';

function ManageChannelDisplay(
  {
    editMode,
    viewModel,
    selectedChannels,
    fetchDevicesOfLocation,
    handleSave,
    devices,
    setSaveButtonDisabled,
    locationId,
    validationProfiles,
    fetchValidationProfilesList,
    showDialog,
    hideDialog,
    addSelectedChannel
  },
  ref
) {
  const classes = useStyles();
  const { t } = useTranslation();
  const metadataGridRef = useRef();
  const detailsFormRef = useRef();
  const [hideSwitchValue, setHideSwitchValue] = useState(true);
  const [calculatedChannelMode, setCalculatedChannelMode] = useState(false);
  const [initialMetadata, setInitialMetadata] = useState(null);

  if (
    editMode &&
    typeof viewModel.calulatedChannelInformation !== 'object' &&
    viewModel.calulatedChannelInformation
  ) {
    viewModel.calulatedChannelInformation = JSON.parse(
      viewModel.calulatedChannelInformation
    );
  }
  useEffect(() => {
    fetchDevicesOfLocation(locationId);
    fetchValidationProfilesList();
    if (editMode && viewModel.channelType && viewModel.channelType == 2)
      setCalculatedChannelMode(true);
    if (editMode && viewModel.metadata) setInitialMetadata(viewModel.metadata);
    if (editMode && viewModel.visible !== null)
      setHideSwitchValue(viewModel.visible);
    setSaveButtonDisabled(false);
  }, []);

  useEffect(() => {
    if (
      editMode &&
      viewModel.calulatedChannelInformation?.ParamToExternalChannelId &&
      (!selectedChannels || selectedChannels.length == 0)
    ) {
      const calculatedChannels = Object.values(
        viewModel.calulatedChannelInformation.ParamToExternalChannelId
      ).map(paramToExternalChannelId =>
        typeof paramToExternalChannelId === 'object'
          ? paramToExternalChannelId
          : JSON.parse(paramToExternalChannelId)
      );
      calculatedChannels.forEach(channel => addSelectedChannel(channel));
      console.dev(
        `Added ${calculatedChannels.length} calculated channels to the list`,
        calculatedChannels
      );
    }
  }, [selectedChannels]);

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const form = () => {
    let channelTypes = [
      {
        key: '0',
        label: 'automatic'
      },
      {
        key: '1',
        label: 'manualentry'
      },
      {
        key: '2',
        label: 'calculated'
      }
    ];

    const handleClick = event => {
      if (!event.target.dataset.value) return;
      if (event.target.dataset.value == 2) {
        if (!calculatedChannelMode) {
          setCalculatedChannelMode(true);
        }
      } else {
        setCalculatedChannelMode(false);
        hideDialog();
      }
    };

    const channelTypesMenuItems = channelTypes.map(channel => {
      return (
        <MenuItem key={channel.key} value={channel.key}>
          {t('content.channelstable.channeltype.' + channel.label)}
        </MenuItem>
      );
    });

    const deviceMenuItems = devices.map(device => {
      return (
        <MenuItem key={device.id} value={device.id}>
          {device.name}
        </MenuItem>
      );
    });

    const validationProfileMenuItems = validationProfiles.map(
      validationProfile => {
        return (
          <MenuItem
            key={validationProfile.validationProfileKey}
            value={validationProfile.validationProfileKey}
          >
            {validationProfile.name}
          </MenuItem>
        );
      }
    );

    const handleValidate = values => {
      let errors = {};
      if (!values.deviceId) {
        errors.deviceId = t('general.validation.required');
      }
      if (values.channelType === undefined || values.channelType === null) {
        errors.channelType = t('general.validation.required');
      }
      if (values.channelType == 2) {
        if (!values.calculatedChannelFormula) {
          errors.calculatedChannelFormula = t('general.validation.required');
        } else {
          const decimalOrLetter = '((\\d+(\\.{1}\\d+)?)|[A-Z]{1})';
          const optionalParenthesesOpen = '[(]*';
          const optionalParenthesesClosed = '[)]*';
          const optionalMinusSign = '[-]?';
          const element = `(${optionalMinusSign}${optionalParenthesesOpen}${optionalMinusSign}${decimalOrLetter}${optionalParenthesesClosed})`;
          const formulaRegex = `^${element}([+-/*^]${element})*$`;
          const formula = values.calculatedChannelFormula;
          const regex = RegExp(formulaRegex);

          if (!regex.test(formula)) {
            errors.calculatedChannelFormula = t(
              'content.channels.form.invalidcalculatedchannelformula'
            );
          } else {
            let numberOfOpeningParentheses = (formula.match(/\(/g) || [])
              .length;
            let numberOfClosingParentheses = (formula.match(/\)/g) || [])
              .length;
            if (numberOfOpeningParentheses != numberOfClosingParentheses) {
              errors.calculatedChannelFormula = t(
                'content.channels.form.missingparentheses'
              );
            }
          }

          // Check wether each channel is used by a letter
          for (let index in selectedChannels) {
            let letter = alphabet[index];
            let indexOfFirstOccurence = formula.indexOf(letter);
            if (indexOfFirstOccurence < 0) {
              errors.calculatedChannelFormula = t(
                'content.channels.form.missingchannelidentifier'
              );
            }
          }

          // Check wether letters are used that are not valid
          for (let index in formula) {
            let letter = formula[index];
            let letterIndex = alphabet.indexOf(letter);
            if (letterIndex >= 0 && letterIndex > selectedChannels.length - 1) {
              errors.calculatedChannelFormula = t(
                'content.channels.form.invalidchannelidentifier'
              );
            }
          }
        }
      }
      return errors;
    };

    return (
      <>
        <FormikWithRef
          ref={detailsFormRef}
          enableReinitialize={true}
          initialValues={{
            id: viewModel?.id ?? '',
            sensightId: viewModel?.sensightId ?? '',
            externalChannelId: viewModel?.externalChannelId ?? '',
            deviceId: viewModel?.deviceId ?? '',
            displayLabel: viewModel?.displayLabel ?? '',
            sensorSerialNumber: viewModel?.sensorSerialNumber ?? '',
            unit: viewModel?.unit ?? '',
            min: viewModel?.min ?? 0,
            max: viewModel?.max ?? 0,
            lowerLimit: viewModel?.lowerLimit ?? 0,
            upperLimit: viewModel?.upperLimit ?? 0,
            sensorType: viewModel?.sensorType ?? '',
            channelType: viewModel?.channelType ?? 0,
            calculatedChannelFormula:
              viewModel?.calulatedChannelInformation?.Formula ?? '',
            validationProfileKey: viewModel?.validationProfileKey ?? ''
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validate={values => handleValidate(values)}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {props => (
            useEffect(() => {
              setSaveButtonDisabled(
                !(props.dirty && isEmptyObject(props.errors))
              );
            }, [props.dirty, props.errors]),
            (
              <Form onSubmit={props.submitForm}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={10}>
                    <ImdCard
                      title={t('content.channels.createchannel.channeldetails')}
                      content={
                        <>
                          <Field
                            id="externalChannelId"
                            type="text"
                            name="externalChannelId"
                            label={`${t(
                              'content.channels.form.externalchannelid'
                            )} (${t(
                              'content.general.autmatically.generated'
                            )})`}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            disabled={true}
                            variant="standard"
                          />
                          <Field
                            name="deviceId"
                            className={classes.textField}
                            label={t('content.channels.form.device')}
                            fullWidth
                            component={TextField}
                            type="select"
                            select
                            required
                            variant="standard"
                          >
                            {deviceMenuItems}
                          </Field>
                          <Field
                            id="displayLabel"
                            type="text"
                            onChange={props.handleChange}
                            name="displayLabel"
                            label={t('content.channels.form.displaylabel')}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            variant="standard"
                          />
                          <Field
                            name="validationProfileKey"
                            label={t('content.channels.form.validationprofile')}
                            fullWidth
                            component={TextField}
                            type="select"
                            select
                            className={classes.textField}
                            variant="standard"
                          >
                            {validationProfileMenuItems}
                          </Field>
                          <Field
                            id="sensorSerialNumber"
                            type="text"
                            onChange={props.handleChange}
                            name="sensorSerialNumber"
                            label={t('content.channels.form.serialnumber')}
                            fullWidth
                            component={TextField}
                            className={classes.lastInputField}
                            variant="standard"
                          />
                        </>
                      }
                      variant="secondary"
                      cardHeight="100%"
                    />
                  </Grid>
                  <Grid item md={4} xs={10}>
                    <ImdCard
                      title={t(
                        'content.channels.createchannel.channelmeasurementdetails'
                      )}
                      content={
                        <>
                          <Field
                            id="unit"
                            type="text"
                            onChange={props.handleChange}
                            name="unit"
                            label={t('content.channels.form.unit')}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            variant="standard"
                          />
                          <Field
                            id="min"
                            type="text"
                            onChange={props.handleChange}
                            name="min"
                            label={t('content.channels.form.min')}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            variant="standard"
                          />
                          <Field
                            id="max"
                            type="text"
                            onChange={props.handleChange}
                            name="max"
                            label={t('content.channels.form.max')}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            variant="standard"
                          />
                          <Field
                            id="lowerLimit"
                            type="text"
                            onChange={props.handleChange}
                            name="lowerLimit"
                            label={t('content.channels.form.lowerlimit')}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            variant="standard"
                          />
                          <Field
                            id="upperLimit"
                            type="text"
                            onChange={props.handleChange}
                            name="upperLimit"
                            label={t('content.channels.form.upperlimit')}
                            fullWidth
                            component={TextField}
                            className={classes.lastInputField}
                            variant="standard"
                          />
                        </>
                      }
                      variant="secondary"
                      cardHeight="100%"
                    />
                  </Grid>
                  <Grid item md={4} xs={10}>
                    <ImdCard
                      title={t(
                        'content.channels.createchannel.channelmanagementdetails'
                      )}
                      content={
                        <>
                          <div className={classes.switchWrapper}>
                            <Typography
                              variant="body1"
                              className={classes.switchLabel}
                            >
                              {t('content.channels.form.channelvisible')}
                            </Typography>
                            <div className={classes.switchItem}>
                              <Switch
                                checked={hideSwitchValue}
                                onChange={() =>
                                  setHideSwitchValue(!hideSwitchValue)
                                }
                                value="hide"
                                color="primary"
                              />
                            </div>
                          </div>
                          <Field
                            id="sensorType"
                            type="text"
                            onChange={props.handleChange}
                            name="sensorType"
                            label={t('content.channels.form.sensortype')}
                            fullWidth
                            component={TextField}
                            className={classes.textField}
                            variant="standard"
                          />
                          <Field
                            id="channelType"
                            name="channelType"
                            label={t('content.channels.form.channeltype')}
                            fullWidth
                            component={TextField}
                            type="select"
                            select
                            className={classes.lastInputField}
                            onClick={handleClick}
                            variant="standard"
                          >
                            {channelTypesMenuItems}
                          </Field>
                          {calculatedChannelMode ? (
                            <>
                              <Field
                                id="calculatedChannelFormula"
                                type="text"
                                value={props.values.calculatedChannelFormula}
                                name="calculatedChannelFormula"
                                label={t(
                                  'content.channels.form.calculatedchannelformula'
                                )}
                                multiline
                                rows="7"
                                fullWidth
                                component={TextField}
                                variant="standard"
                              />
                              <div className={classes.calculatedChannelBox}>
                                {selectedChannels.map((c, index) => {
                                  return (
                                    <>
                                      <div>
                                        <strong>
                                          {alphabet[index] + ': '}
                                        </strong>
                                        {c.displayLabel || ''} -{' '}
                                        {t(
                                          `content.general.aggregationtype.${c.aggregationType}`
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                              <ImdButton
                                variant="contained"
                                color="primary"
                                onClick={showDialog}
                              >
                                {t(
                                  'content.channels.form.openselectchanneldialog'
                                )}
                              </ImdButton>
                            </>
                          ) : null}
                        </>
                      }
                      variant="secondary"
                      cardHeight="100%"
                    />
                  </Grid>
                </Grid>
              </Form>
            )
          )}
        </FormikWithRef>
        <Grid container spacing={2} className={classes.metadataGrid}>
          <Grid item xs={12}>
            <ImdMetaDataForm
              ref={metadataGridRef}
              metadata={initialMetadata}
            ></ImdMetaDataForm>
          </Grid>
        </Grid>
        <SelectChannelDialogContainer
          clearSelectChannelDialogStateImmediately
          chartSettings={false}
          defaultAggregation={false}
          chartAggregation
        />
      </>
    );
  };

  const handleSubmit = values => {
    values.metadata = JSON.stringify(
      metadataGridRef.current.current.instance.getDataSource()._items
    );
    values.visible = hideSwitchValue;
    values.locationId = locationId;
    values.measurementServiceId = viewModel?.measurementServiceId ?? '';
    values.fieldServiceId = viewModel?.fieldServiceId ?? '';
    values.deviceFieldId = values.deviceId;
    values.deviceMeasurementId = devices.find(
      device => device.id === values.deviceId
    ).measurementId;

    let paramMapping = {};

    const selectedChannelsInformation = selectedChannels.map(function (
      selectedChannel
    ) {
      return {
        id: selectedChannel.id,
        sensightId: selectedChannel.sensightId,
        displayLabel: selectedChannel.displayLabel,
        externalChannelId: selectedChannel.externalChannelId,
        unit: selectedChannel.unit,
        aggregationType: selectedChannel.aggregationType
      };
    });

    for (let index in selectedChannels) {
      let letter = alphabet[index];
      paramMapping[letter] = `${JSON.stringify(
        selectedChannelsInformation[index]
      )}`;
    }
    if (values.channelType == 2) {
      values.calulatedChannelInformation = {
        Formula: values.calculatedChannelFormula,
        ParamToExternalChannelId: paramMapping
      };
    } else {
      values.calulatedChannelInformation = null;
    }
    handleSave(values);
  };

  useImperativeHandle(ref, () => detailsFormRef);

  return <>{form()}</>;
}

const useStyles = makeStyles(() => ({
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  },
  textField: {
    marginBottom: '8px'
  },
  calculatedChannelBox: {
    marginBottom: '16px',
    marginTop: '16px'
  },
  lastInputField: {
    marginBottom: '16px'
  },
  metadataGrid: {
    marginTop: '16px'
  }
}));

export default forwardRef(ManageChannelDisplay);
