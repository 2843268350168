import React, { useState } from 'react';
import { ViewDashboardContainer } from '../viewDashboard/ViewDashboardContainer';
import { CreateDashboardContainer } from '../createDashboard/CreateDashboardContainer';
import ImdLoader from 'components/imd-components/ImdLoader';
import { Can } from 'components/imd-components/authorization/Can';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { useUpdateEffect } from 'react-use';
import { useParams } from 'react-router-dom';

export default function DashboardDisplay({
  editMode,
  dashboard,
  locations,
  saveDashboard,
  currentUser
}) {
  const [isEditing, setIsEditing] = useState(editMode);
  const [isSavingDashboard, setIsSavingDashboard] = useState(false);
  const { id: dashboardId } = useParams();
  const handleOnSave = () => {
    setIsSavingDashboard(true);
    saveDashboard();
  };

  useUpdateEffect(() => {
    if (isSavingDashboard) {
      console.dev('Received new dashboard after save', dashboard);
      setIsEditing(false);
      setIsSavingDashboard(false);
    }
  }, [dashboard]);

  return (
    <>
      {dashboardId !== dashboard.id ? (
        <ImdLoader />
      ) : (
        <div>
          {isEditing ? (
            dashboard.onlyAdminsCanEdit ? (
              <>
                <Can
                  role={getCurrentUserRole(currentUser)}
                  perform="dashboard:manageAdminOnly"
                  yes={() => (
                    <CreateDashboardContainer
                      dashboard={dashboard}
                      onSave={() => handleOnSave()}
                    />
                  )}
                  no={() => (
                    <ViewDashboardContainer
                      dashboard={dashboard}
                      locations={locations}
                      setIsEditing={null}
                    />
                  )}
                ></Can>
              </>
            ) : (
              <>
                <Can
                  role={getCurrentUserRole(currentUser)}
                  perform="dashboard:manage"
                  yes={() => (
                    <CreateDashboardContainer
                      dashboard={dashboard}
                      onSave={() => handleOnSave()}
                    />
                  )}
                  no={() => (
                    <ViewDashboardContainer
                      dashboard={dashboard}
                      locations={locations}
                      setIsEditing={null}
                    />
                  )}
                ></Can>
              </>
            )
          ) : (
            <ViewDashboardContainer
              dashboard={dashboard}
              locations={locations}
              setIsEditing={value => setIsEditing(value)}
            />
          )}
        </div>
      )}
    </>
  );
}
