import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from '../../imd-components/datatable/ImdDataTable';

import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { Column } from 'devextreme-react/tree-list';
import ImdPopup from 'components/imd-components/ImdPopup';
import { ExportChartModalContainer } from './exportchartsmodal/ExportChartModalContainer';
import { Lookup } from 'devextreme-react/data-grid';
import { periods } from './ExportChartHelpers';

export default function ExportChartTableDisplay({
  exportCharts,
  deleteExportChart,
  fetchExportChartsList
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const translatedPeriods = periods.map(period => {
    period.name = t(period.name);
    return period;
  });

  React.useEffect(() => {
    fetchExportChartsList();
  }, []);

  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    openEdit();
  };

  const openAdd = () => {
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteExportChart(selectedRow);
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [exportCharts]);

  const columns = (
    <>
      <Column
        dataField={'name'}
        caption={t('content.exportchart.table.name')}
      />
      <Column
        dataField={'switchTime'}
        caption={t('content.exportchart.table.switchTime')}
      />
      <Column
        dataField={'exportPeriod'}
        caption={t('content.exportchart.table.exportPeriod')}
      >
        <Lookup
          dataSource={translatedPeriods}
          valueExpr="value"
          displayExpr={'name'}
        />
      </Column>
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.exportchart.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.exportchart.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.exportchart.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.exportchart.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.exportchart.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <ExportChartModalContainer
            ref={formRef}
            exportChart={selectedRow}
          ></ExportChartModalContainer>
        }
        formRef={formRef}
        saveButton={[]}
        dialogTitle={t('content.exportchartmodal.editexportchart.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <ExportChartModalContainer ref={formRef}></ExportChartModalContainer>
        }
        formRef={formRef}
        saveButton={[]}
        dialogTitle={t('content.exportchartmodal.createexportchart.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.organizationstable.deletepopup.title')}
        content={t('content.organizationstable.deletepopup.content', {
          organization: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.exportchartmodal.table.title')}
        data={exportCharts}
        columns={columns}
        stateStorageKey="export-chart-table-display"
      />
    </>
  );
}
