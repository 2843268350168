import React, { useRef, useState } from 'react';
import { Typography, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import { CreateLogItemModalContainer } from './CreateLogItemModalContainer';
import { useUpdateEffect } from 'react-use';
import ImdTooltip from 'components/imd-components/ImdTooltip';

import { isEmptyObject } from 'utils/helperMethods';

function LogItemViewerDisplay({
  logItem,
  measurementsCount,
  tooMuchMeasurementsSnackbar,
  onClosed,
  onSwitched,
  customKey
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const formRef = useRef();
  const [logItemMode, setLogItemMode] = useState(false);

  const closeModal = () => {
    setModalOpened(false);

    // Onclose callback voor eventuele acties die in de grafiek nodig zijn
    if (typeof onClosed === 'function') {
      onClosed();
    }
  };

  // De switch zorgt ervoor dat de logitem modus kan worden aangezet.
  // Bij teveel measurements heeft dit geen zin en wordt er een foutmeldin getoond.
  const setLogItemSwitch = value => {
    if (value && measurementsCount > 3000) {
      tooMuchMeasurementsSnackbar();
    } else {
      setLogItemMode(value);
      if (typeof onSwitched === 'function') {
        onSwitched(value);
      }
    }
  };

  // Als het schuifje aan staat en het logitem object gevuld is het modal openen
  // wanneer het logItem object wijzigt
  // Controleer op de meegegeven customkey omdat de dashboardweergave dit effect anders per grafiek triggerd.
  useUpdateEffect(() => {
    if (
      logItemMode &&
      !isEmptyObject(logItem) &&
      logItem.customKey === customKey
    ) {
      console.dev(`User clicked logitem in graph:`, logItem);
      setModalOpened(true);
    }
  }, [logItem]);

  return (
    <div className={classes.switchContainer}>
      <div className={classes.switchWrapper}>
        <ImdTooltip
          title={t('content.visualize.createlogbookitemmode.description')}
          placement="top"
        >
          <Typography variant="body1" className={classes.switchLabel}>
            {t('content.visualize.createlogbookitemmode')}
          </Typography>
        </ImdTooltip>
        <div className={classes.switchItem}>
          <Switch
            checked={logItemMode}
            onChange={() => setLogItemSwitch(!logItemMode)}
            value={logItemMode}
            color="primary"
          />
        </div>
      </div>

      <ImdDialogContainer
        perform={'logitems:create'}
        open={modalOpened}
        handleCloseAction={closeModal}
        handleSaveAction={closeModal}
        content={
          <CreateLogItemModalContainer
            ref={formRef}
            open={modalOpened}
          ></CreateLogItemModalContainer>
        }
        formRef={formRef}
        dialogTitle={t('content.logitems.createlogitem.title')}
      />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  switchContainer: {
    flex: '1 0 40%'
  },
  switchWrapper: {
    marginBottom: '-10px',
    display: 'flex',
    justifyContent: 'end'
  },
  switchLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  }
}));

export default LogItemViewerDisplay;
