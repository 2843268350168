import { makeActionCreator } from 'utils/makeActionCreator';

export const SET_TENANT = 'SET_TENANT';
export const setTenant = makeActionCreator(SET_TENANT, 'tenant');

export const FETCH_TENANTS = 'FETCH_TENANTS';
export const fetchTenants = makeActionCreator(FETCH_TENANTS);

export const SET_TENANTS_LIST = 'SET_TENANTS_LIST ';
export const setTenantsList = makeActionCreator(
  SET_TENANTS_LIST,
  'tenantsList'
);

export const CREATE_TENANT = 'CREATE_TENANT';
export const createTenant = makeActionCreator(CREATE_TENANT, 'tenantDetails');

export const CREATE_TENANT_SUCCEEDED = 'CREATE_TENANT_SUCCEEDED';
export const createTenantSucceeded = makeActionCreator(
  CREATE_TENANT_SUCCEEDED,
  'tenant'
);

export const EDIT_TENANT = 'EDIT_TENANT';
export const editTenant = makeActionCreator(EDIT_TENANT, 'tenantDetails');

export const EDIT_TENANT_SUCCEEDED = 'EDIT_TENANT_SUCCEEDED';
export const editTenantSucceeded = makeActionCreator(
  EDIT_TENANT_SUCCEEDED,
  'tenant'
);

export const DELETE_TENANT = 'DELETE_TENANT';
export const deleteTenant = makeActionCreator(DELETE_TENANT, 'deleteObject');

export const DELETE_TENANT_SUCCEEDED = 'DELETE_TENANT_SUCCEEDED ';
export const deleteTenantSucceeded = makeActionCreator(
  DELETE_TENANT_SUCCEEDED,
  'tenant'
);
