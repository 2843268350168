import { createReducer } from 'utils/createReducer';
import {
  SET_CONTACTS_LIST,
  CREATE_CONTACT_SUCCEEDED,
  DELETE_CONTACT_SUCCEEDED,
  EDIT_CONTACT_SUCCEEDED
} from './contactsListActions';
import { fromJS } from 'immutable';

export const contactsList = createReducer(null, {
  [SET_CONTACTS_LIST](state, { contactsList }) {
    return fromJS(contactsList);
  },
  [CREATE_CONTACT_SUCCEEDED](state, { contact }) {
    return state.push(fromJS(contact));
  },
  [EDIT_CONTACT_SUCCEEDED](state, { contact }) {
    let contactsList = state.toJS();
    var foundContactIndex = contactsList.findIndex(
      org => contact.id === org.id
    );
    contactsList[foundContactIndex] = contact;
    return fromJS(contactsList);
  },
  [DELETE_CONTACT_SUCCEEDED](state, { contact }) {
    return state.filter(con => con.get('id') !== contact.id);
  }
});
