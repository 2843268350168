import { makeActionCreator } from 'utils/makeActionCreator';
export const FETCH_ACTIONS_LIST = 'FETCH_ACTIONS_LIST';
export const fetchActionsList = makeActionCreator(
  FETCH_ACTIONS_LIST,
  'actionsList'
);

export const SET_ACTIONS_LIST = 'SET_ACTIONS_LIST';
export const setActionsList = makeActionCreator(
  SET_ACTIONS_LIST,
  'actionsList'
);
