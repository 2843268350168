import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImdDataTable from '../../../imd-components/datatable/ImdDataTable';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import ImdPopup from 'components/imd-components/ImdPopup';

import { Column, Lookup } from 'devextreme-react/tree-list';
import { CreateLocationContainer } from '../createlocation/CreateLocationContainer';
import { ManageLocationContainer } from 'components/content/location/manageLocation/ManageLocationContainer';
import { resetLocationDashboardState } from 'components/content/location/manageLocation/redux/manageLocationActions';
import { useDispatch } from 'react-redux';

export default function LocationsTableDisplay({
  locations,
  deleteLocation,
  contacts
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [saveButton] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const isMobile = window.matchMedia('(max-width: 450px)').matches;
  const isTablet = window.matchMedia('(max-width: 900px)').matches;
  const dispatch = useDispatch();
  
  const openEdit = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEdit = row => {
    setSelectedRow(row);
    openEdit();
  };

  const openAdd = () => {
    dispatch(resetLocationDashboardState());
    setAdd(true);
  };

  const closeAdd = () => {
    setAdd(false);
  };

  const handleAdd = () => {
    openAdd();
  };

  const handleDelete = selected => {
    setSelectedRow(selected);
    setPopup(true);
  };

  const handlePopupOk = () => {
    setPopup(false);
    deleteLocation(selectedRow);
  };

  useEffect(() => {
    if (edit || add) {
      closeAdd();
      closeEdit();
    }
  }, [locations]);

  const columns = (
    <>
      <Column
        visible={true}
        dataField={'name'}
        caption={t('content.locationstable.name')}
      />
      <Column
        visible={true}
        dataField={'city'}
        caption={t('content.locationstable.city')}
      />
      <Column
        visible={!isMobile && !isTablet}
        dataField={'address'}
        caption={t('content.locationstable.address')}
      />
      <Column
        visible={false}
        dataField={'latLngLatitude'}
        caption={t('content.locationstable.latlnglatitude')}
      />
      <Column
        visible={false}
        dataField={'latLngLongitude'}
        caption={t('content.locationstable.latlnglongitude')}
      />
      <Column
        visible={true}
        dataField={'visible'}
        caption={t('content.locationstable.visible')}
      />
      <Column
        visible={!isMobile}
        dataField={'online'}
        caption={t('content.locationstable.online')}
      />
      <Column
        visible={!isMobile && !isTablet}
        dataField={'switchTime'}
        caption={t('content.locationstable.switchtime')}
      />
      <Column
        visible={false}
        dataField={'timeZone'}
        caption={t('content.locationstable.timezone')}
      />
      <Column
        visible={false}
        dataField={'description'}
        caption={t('content.locationstable.description')}
      />
      <Column
        visible={false}
        dataField={'referenceNumber'}
        caption={t('content.locationstable.referencenumber')}
      />
      <Column
        visible={false}
        dataField={'projectNumber'}
        caption={t('content.locationstable.projectnumber')}
      />
      <Column
        visible={false}
        dataField={'projectManager'}
        caption={t('content.locationstable.projectmanager')}
      />
      <Column
        visible={false}
        dataField={'notes'}
        caption={t('content.locationstable.notes')}
      />
      <Column
        visible={false}
        dataField={'externalLink'}
        caption={t('content.locationstable.externallink')}
      />
      <Column visible={false} dataField={'contact'} caption={'Contact'}>
        <Lookup
          dataSource={contacts}
          valueExpr="sensightId"
          displayExpr="name"
        />
      </Column>
      <Column
        visible={true}
        dataField={'reliabilityPercentage'}
        caption={t('content.locationstable.reliabilitypercentage')}
      />
      <Column
        visible={false}
        dataField={'createdAt'}
        dataType={'datetime'}
        caption={t('content.general.createdAt')}
      />
      <Column
        visible={false}
        dataField={'createdBy'}
        caption={t('content.general.createdBy')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedAt'}
        dataType={'datetime'}
        caption={t('content.general.lastUpdatedAt')}
      />
      <Column
        visible={false}
        dataField={'lastUpdatedBy'}
        caption={t('content.general.lastUpdatedBy')}
      />
      <Column
        type="buttons"
        caption={t('content.locationstable.actions')}
        width={100}
        buttons={[
          {
            hint: t('general.edit'),
            icon: 'edit',
            onClick: event => handleEdit(event.row.data)
          },
          {
            hint: t('general.delete'),
            icon: 'trash',
            onClick: event => handleDelete(event.row.data)
          }
        ]}
      />
    </>
  );

  const formRef = useRef();

  return (
    <>
      <ImdDialogContainer
        saveButtonVisible={false}
        open={edit}
        handleCloseAction={closeEdit}
        content={
          <ManageLocationContainer
            locationId={selectedRow.id}
          ></ManageLocationContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.locationstable.editlocation.title')}
      />
      <ImdDialogContainer
        open={add}
        handleCloseAction={closeAdd}
        content={
          <CreateLocationContainer ref={formRef}></CreateLocationContainer>
        }
        formRef={formRef}
        saveButton={saveButton}
        dialogTitle={t('content.locationstable.createlocation.title')}
      />
      <ImdPopup
        open={popup}
        title={t('content.locationstable.deletepopup.title')}
        content={t('content.locationstable.deletepopup.content', {
          location: selectedRow.name
        })}
        handleOk={() => handlePopupOk()}
        handleClose={() => setPopup(false)}
      ></ImdPopup>
      <ImdDataTable
        handleEditButton={selected => handleEdit(selected)}
        handleAddButton={handleAdd}
        handleDeleteButton={selected => handleDelete(selected)}
        title={t('content.locations.title')}
        data={locations}
        columns={columns}
        stateStorageKey="locations-table-display"
      />
    </>
  );
}
