import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ImageIcon from '@mui/icons-material/Image';
import CodeIcon from '@mui/icons-material/Code';
import ThermometerIcon from './img/thermometerIcon.svg';
import ScienceIcon from './img/scienceIcon.svg';
import { useTranslation } from 'react-i18next';

const Title = ({ sectionItem }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <h3 className={classes.title}>
      {!sectionItem?.title ? t('content.dashboard.notitle') : sectionItem.title}
    </h3>
  );
};

const DashboardItemBox = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.dashboardItemBox}>{children}</div>;
};

export default function DashboardItemIcon({ sectionItem }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderItemIcon = () => {
    switch (sectionItem.visualizationType) {
      case 0:
        return <AddIcon className={classes.largeIconButton} />;
      case 1:
        return (
          <DashboardItemBox>
            <Title sectionItem={sectionItem} />
            <BarChartIcon className={classes.largeIconButton} />
            <p className={classes.subText}>
              {t('content.dashboard.visualizationtypes.charttitle')}
            </p>
          </DashboardItemBox>
        );
      case 2:
        return (
          <DashboardItemBox>
            <Title sectionItem={sectionItem} />
            <ThermometerIcon
              width={75}
              height={75}
              viewBox="0 0 24 24"
              className={classes.selectedCustomIcon}
            />
            <p className={classes.subText}>
              {t('content.dashboard.visualizationtypes.kpititle')}
            </p>
          </DashboardItemBox>
        );
      case 3:
        return (
          <DashboardItemBox>
            <Title sectionItem={sectionItem} />
            <CodeIcon className={classes.largeIconButton} />
            <p className={classes.subText}>
              {t('content.dashboard.visualizationtypes.markdowntitle')}
            </p>
          </DashboardItemBox>
        );
      case 4:
        return (
          <DashboardItemBox>
            <Title sectionItem={sectionItem} />
            <ImageIcon className={classes.largeIconButton} />
            <p className={classes.subText}>
              {t('content.dashboard.visualizationtypes.imagetitle')}
            </p>
          </DashboardItemBox>
        );
      case 5:
        return (
          <DashboardItemBox>
            <Title sectionItem={sectionItem} />
            <ScienceIcon
              width={75}
              height={75}
              viewBox="0 0 24 24"
              className={classes.selectedCustomIcon}
            />
            <p className={classes.subText}>
              {t('content.dashboard.visualizationtypes.datasciencetitle')}
            </p>
          </DashboardItemBox>
        );
      case 6:
        return (
          <DashboardItemBox>
            <Title sectionItem={sectionItem} />
            <ListAltIcon className={classes.largeIconButton} />
            <p className={classes.subText}>
              {t('content.dashboard.visualizationtypes.logbooktitle')}
            </p>
          </DashboardItemBox>
        );
      default:
        return <AddIcon className={classes.largeIconButton} />;
    }
  };
  return renderItemIcon();
}

const useStyles = makeStyles(theme => ({
  largeIconButton: {
    width: '3em',
    height: '3em',
    marginTop: '8px',
    marginBottom: '8px',
    color: theme.palette.primary.main
  },
  selectedCustomIcon: {
    fill: theme.palette.primary.main
  },
  subText: {
    textAlign: 'center',
    marginTop: '-8px',
    color: theme.palette.primary.main
  },
  dashboardItemBox: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.primary.main
  }
}));
