import { makeActionCreator } from 'utils/makeActionCreator';

export const RENDER_CHART_CREATE_LOGITEM = 'RENDER_CHART_CREATE_LOGITEM';
export const renderChartCreateLogItem = makeActionCreator(
  RENDER_CHART_CREATE_LOGITEM,
  'logitem'
);

export const RENDER_CHART_CREATE_LOGITEM_SUCCEEDED =
  'RENDER_CHART_CREATE_LOGITEM_SUCCEEDED';
export const renderChartCreateLogItemSucceeded = makeActionCreator(
  RENDER_CHART_CREATE_LOGITEM_SUCCEEDED,
  'logitem'
);

export const SET_LOGITEMVIEWMODEL = 'SET_LOGITEMVIEWMODEL';
export const setLogItemViewModel = makeActionCreator(
  SET_LOGITEMVIEWMODEL,
  'logItemViewModel'
);
