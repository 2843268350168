import { connect } from 'react-redux';
import AlertsTableDisplay from './AlertsTableDisplay.jsx';
import { setAlert, deleteAlert } from '../redux/alertsActions';

const mapStateToProps = (state, ownProps) => {
  return {
    alerts: ownProps.alerts
  };
};

const mapDispatchToProps = dispatch => ({
  deleteAlert(row) {
    dispatch(deleteAlert(row));
  },
  setAlert(row) {
    dispatch(setAlert(row));
  }
});

export const AlertsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsTableDisplay);
