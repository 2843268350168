import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../page/Content';
import { ContactsTableContainer } from './contactstable/ContactsTableContainer';
import { PropTypes } from 'prop-types';

export default function ContactsDisplay({ contacts, fetchContactsList }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchContactsList();
  }, []);

  const content = <ContactsTableContainer contacts={contacts} />;
  return (
    <Content
      title={t('content.users.title')}
      secondTitle={t('navbar.manage')}
      content={content}
    />
  );
}

ContactsDisplay.propTypes = {
  contacts: PropTypes.array,
  fetchContactsList: PropTypes.func
};
