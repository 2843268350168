import { createReducer } from 'utils/createReducer';
import {
  SET_DASHBOARDS,
  SET_MANAGEMENT_DASHBOARDS,
  DELETE_DASHBOARD,
  UPDATE_DASHBOARD,
  ADD_DASHBOARD,
  FETCHED_DASHBOARDS
} from './myDashboardActions';
import { fromJS } from 'immutable';

export const dashboards = createReducer(null, {
  [ADD_DASHBOARD](state, { dashboard }) {
    return state.push(fromJS(dashboard));
  },

  [SET_DASHBOARDS](state, { dashboardList }) {
    return fromJS(dashboardList);
  },
  [DELETE_DASHBOARD](state, { dashboard }) {
    return state.filter(con => con.get('id') !== dashboard.id);
  },
  [UPDATE_DASHBOARD](state, { dashboard }) {
    let allDashboards = state.toJS();
    var foundIndex = allDashboards.findIndex(d => d.id === dashboard.id);
    allDashboards[foundIndex] = dashboard;
    return fromJS(allDashboards);
  }
});

export const fetchedDashboards = createReducer(false, {
  [FETCHED_DASHBOARDS]() {
    return true;
  }
});

export const managementDashboards = createReducer(null, {
  [ADD_DASHBOARD](state, { dashboard }) {
    return state.push(fromJS(dashboard));
  },

  [SET_MANAGEMENT_DASHBOARDS](state, { managementDashboardList }) {
    return fromJS(managementDashboardList);
  },
  [DELETE_DASHBOARD](state, { dashboard }) {
    return state.filter(con => con.get('id') !== dashboard.id);
  },
  [UPDATE_DASHBOARD](state, { dashboard }) {
    let allDashboards = state.toJS();
    var foundIndex = allDashboards.findIndex(d => d.id === dashboard.id);
    allDashboards[foundIndex] = dashboard;
    return fromJS(allDashboards);
  }
});
