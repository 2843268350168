import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_CONTACTS_LIST = 'FETCH_CONTACTS_LIST';
export const fetchContactsList = makeActionCreator(FETCH_CONTACTS_LIST);

export const SET_CONTACTS_LIST = 'SET_CONTACTS_LIST';
export const setContactsList = makeActionCreator(
  SET_CONTACTS_LIST,
  'contactsList'
);

export const CREATE_CONTACT_SUCCEEDED = 'CREATE_CONTACT_SUCCEEDED';
export const createContactSucceeded = makeActionCreator(
  CREATE_CONTACT_SUCCEEDED,
  'contact'
);

export const EDIT_CONTACT_SUCCEEDED = 'EDIT_CONTACT_SUCCEEDED';
export const editContactSucceeded = makeActionCreator(
  EDIT_CONTACT_SUCCEEDED,
  'contact'
);

export const DELETE_CONTACT_SUCCEEDED = 'DELETE_CONTACT_SUCCEEDED';
export const deleteContactSucceeded = makeActionCreator(
  DELETE_CONTACT_SUCCEEDED,
  'contact'
);
