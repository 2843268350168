import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { generateDataScienceReport } from 'apis/ReportingServiceApi.js';
import makeStyles from '@mui/styles/makeStyles';
import { useAsyncRetry } from 'react-use';

import GraphButtonsBar from '../../visualize/renderChartComponent/GraphButtonsBar.jsx';

export const DataScienceDisplay = forwardRef(function DataScienceDisplayFn(
  { item, locations, currentUser, report },
  ref
) {
  const reportContent = useAsyncRetry(async () => {
    console.dev(`Getting report with parameters:`, report);
    return await generateDataScienceReport(currentUser, report);
  }, []);

  useImperativeHandle(ref, () => ({
    refresh() {
      reportContent.retry();
    }
  }));

  const refreshDatascienceReport = () => {
    reportContent.retry();
  };

  const classes = useDataScienceStyles();

  const { t } = useTranslation();

  const generateInnerHTML = () => {
    let innerHTML = `<div style='width: 100%; background: white;padding-bottom: 39.13%;'><p>${t('content.dashboard.datascience.loading')}</p></div>`;

    if (!reportContent.loading) {
      innerHTML = reportContent.value;
    }

    return {
      __html: innerHTML
    };
  };

  return (
    !report || (
      <>
        <GraphButtonsBar
          item={item}
          locations={locations}
          fetchDashboardItemData={() => refreshDatascienceReport()}
        />
        <div
          key={report.id}
          id={report.id}
          className={classes.reportContainer}
          dangerouslySetInnerHTML={generateInnerHTML()}
        ></div>
      </>
    )
  );
});

const useDataScienceStyles = makeStyles(() => ({
  reportContainer: {
    width: '100%',
    overflowX: 'auto'
  }
}));
