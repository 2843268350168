export const visualizePeriodsInDays = {
  fourHours: 6,
  eightHours: 3,
  twelveHours: 2,
  twentyFourHours: 1,
  fourtyEightHours: 2,
  oneWeek: 7,
  oneMonth: 30,
  threeMonths: 90,
  currentYear: 365,
  oneYear: 365
};

export function calculateDateOffset(period) {
  const periodInDays = visualizePeriodsInDays[period];
  const baseEquation = 24 * 60 * 60 * 1000;

  if (
    period === 'fourHours' ||
    period === 'eightHours' ||
    period === 'twelveHours'
  ) {
    return baseEquation / periodInDays;
  }
  return baseEquation * periodInDays;
}
const currentYear = 'currentYear';

export function getFromDate(period) {
  let date = new Date();
  if (period === currentYear) {
    const currentYearDate = date.getFullYear();
    date = new Date(currentYearDate, 0, 1);
  } else {
    date = new Date() - calculateDateOffset(period);
  }
  return new Date(date);
}

export function getUntilDate(period) {
  let date = new Date();

  if (period === currentYear) {
    const currentYearDate = date.getFullYear();
    date = new Date(currentYearDate, 11, 31, 23, 59, 59);
  } else {
    date = new Date();
  }
  return new Date(date);
}
