import React from 'react';
import { VisualizeContainer } from './VisualizeContainer';

export function VisualizeDashboardDisplay({
  visualizeLink,
  setVisualizeFromTime,
  setCurrentVisualizePeriod,
  setDefaultChannelChartType,
  setDefaultChannelAggregationType
}) {
  setVisualizeFromTime(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  setCurrentVisualizePeriod('twentyFourHours');
  setDefaultChannelChartType('line');
  setDefaultChannelAggregationType('source');

  return (
    <VisualizeContainer
      clearSelectChannelDialogStateOnOpen
      visualizeLink={visualizeLink}
    />
  );
}
