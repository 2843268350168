import { makeActionCreator } from 'utils/makeActionCreator';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const createContact = makeActionCreator(
  CREATE_CONTACT,
  'contactInformation'
);

export const SET_CONTACT_IS_USER = 'SET_CONTACT_IS_USER';
export const setContactIsUser = makeActionCreator(
  SET_CONTACT_IS_USER,
  'contactIsUser'
);

export const CLEAR_CONTACT_IS_USER = 'CLEAR_CONTACT_IS_USER';
export const clearContactIsUser = makeActionCreator(CLEAR_CONTACT_IS_USER);
