import { connect } from 'react-redux';
import ManageDevicePlacementDisplay from './ManageDevicePlacementDisplay.jsx';
import {
  editDevicePlacement,
  fetchAvailableDevices,
  setDevicePlacementStartDateTime,
  setDevicePlacementEndDateTime,
  setDevicePlacementDeviceId
} from '../redux/devicePlacementsActions.js';
import {
  availableDevicesSelector,
  devicePlacementSelector,
  devicePlacementStartDateTimeSelector,
  devicePlacementEndDateTimeSelector,
  devicePlacementsOfDeviceSelector
} from '../redux/devicePlacementsSelectors.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';
import { createSnackbar } from 'redux/ui/uiActions';

const mapStateToProps = (state, ownProps) => {
  const locationId = ownProps.locationId;
  return {
    locationId: locationId,
    viewModel: devicePlacementSelector(state),
    availableDevices: availableDevicesSelector(state),
    devicePlacementStartDateTime: devicePlacementStartDateTimeSelector(state),
    devicePlacementEndDateTime: devicePlacementEndDateTimeSelector(state),
    devicePlacementsOfDevice: devicePlacementsOfDeviceSelector(state),
    isEdit: true
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  fetchAvailableDevices(startDateTime, endDateTime, currentDeviceId) {
    dispatch(
      fetchAvailableDevices(startDateTime, endDateTime, currentDeviceId)
    );
  },
  handleActionDevicePlacement(devicePlacement) {
    dispatch(editDevicePlacement(devicePlacement));
  },
  setDevicePlacementStartDateTime(devicePlacementStartDateTime) {
    dispatch(setDevicePlacementStartDateTime(devicePlacementStartDateTime));
  },
  setDevicePlacementEndDateTime(devicePlacementEndDateTime) {
    dispatch(setDevicePlacementEndDateTime(devicePlacementEndDateTime));
  },
  createSnackbar(message, variant) {
    dispatch(createSnackbar(message, variant));
  },
  setDevicePlacementDeviceId(deviceId) {
    dispatch(setDevicePlacementDeviceId(deviceId));
  }
});

export const EditDevicePlacementContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageDevicePlacementDisplay);
