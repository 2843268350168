import { connect } from 'react-redux';
import { SelectChannelDialogDisplay } from './selectChannelDialogDisplay.jsx';
import {
  dialogStateSelector,
  locationsSelector,
  selectedLocationsSelector,
  displayedLocationsSelector,
  devicesSelector,
  selectedDevicesSelector,
  displayedDevicesSelector,
  channelsSelector,
  selectedChannelsSelector,
  displayedChannelsSelector,
  defaultChannelChartTypeSelector,
  defaultChannelAggregationTimeTypeSelector,
  isFetchingChannelDialogLocationsSelector,
  isFetchingChannelDialogDevicesSelector
} from './redux/selectChannelDialogSelectors';
import {
  hideDialog,
  fetchLocationsRequested,
  fetchDevicesRequested,
  fetchChannelsRequested,
  addSelectedChannel,
  filterDisplayedChannels,
  deleteSelectedChannel,
  clearSelectChannelDialogState,
  updateSelectedChannel,
  hasStartedFetchingLocationsAndDevices,
  hasFinishedFetchingLocationsAndDevices
} from './redux/selectChannelDialogActions';

import {
  addSelectedDevice,
  deleteSelectedDevice,
  filterDisplayedDevices
} from './redux/deviceActions';

import {
  addSelectedLocation,
  deleteSelectedLocation,
  filterDisplayedLocations
} from './redux/locationActions';
import { chartRenderedSelector } from '../../../displayChartComponent/redux/displayChartSelectors.js';
import { displayChart } from '../../../displayChartComponent/redux/displayChartActions.js';

const sortLocationsOnName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const mapStateToProps = (state, ownProps) => {
  const locations = locationsSelector(state).sort(sortLocationsOnName);
  const selectedLocations =
    selectedLocationsSelector(state).sort(sortLocationsOnName);
  const displayedLocations =
    displayedLocationsSelector(state).sort(sortLocationsOnName);
  const devices = devicesSelector(state);
  const selectedDevices = selectedDevicesSelector(state);
  const displayedDevices = displayedDevicesSelector(state);
  const channels = channelsSelector(state);
  const selectedChannels = selectedChannelsSelector(state);
  const displayedChannels = displayedChannelsSelector(state);
  const chartRendered = chartRenderedSelector(state);
  const defaultChannelChartType = defaultChannelChartTypeSelector(state);
  const defaultChannelAggregationType =
    defaultChannelAggregationTimeTypeSelector(state);

  let { open } = dialogStateSelector(state);

  if (open == null || open == undefined) {
    open = false;
  }

  return {
    open: open,
    requestingLocations: locations === null,
    locations,
    selectedLocations,
    displayedLocations,
    requestingDevices: devices === null,
    devices,
    selectedDevices,
    displayedDevices,
    requestingChannels: channels === null,
    channels,
    selectedChannels,
    displayedChannels,
    chartRendered,
    defaultChannelChartType,
    defaultChannelAggregationType,
    clearSelectChannelDialogStateImmediately:
      ownProps.clearSelectChannelDialogStateImmediately ?? false,
    clearSelectChannelDialogStateOnOpen:
      ownProps.clearSelectChannelDialogStateOnOpen ?? false,
    chartSettings: ownProps.chartSettings ?? true,
    defaultAggregation: ownProps.defaultAggregation ?? true,
    chartAggregation: ownProps.chartAggregation ?? false,
    chartType: ownProps.chartType ?? true,
    isFetchingChannelDialogLocations:
      isFetchingChannelDialogLocationsSelector(state),
    isFetchingChannelDialogDevices:
      isFetchingChannelDialogDevicesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  hideDialog() {
    dispatch(hideDialog());
  },
  fetchLocations() {
    dispatch(fetchLocationsRequested(true));
  },
  fetchDevices() {
    dispatch(fetchDevicesRequested(true));
  },
  fetchChannels() {
    dispatch(fetchChannelsRequested());
  },
  addSelectedChannel(channelName, chartTypeName, aggregationType) {
    const channel = CreateChannelObjectWithDefaultValues(
      channelName,
      chartTypeName,
      aggregationType
    );
    dispatch(addSelectedChannel(channel));
  },
  deleteSelectedChannel(channelName, chartTypeName, aggregationType) {
    const channel = CreateChannelObjectWithDefaultValues(
      channelName,
      chartTypeName,
      aggregationType
    );
    dispatch(deleteSelectedChannel(channel));
  },
  filterDisplayedChannels(query) {
    dispatch(filterDisplayedChannels(query));
  },
  addSelectedDevice(device) {
    dispatch(addSelectedDevice(device));
  },
  deleteSelectedDevice(device) {
    dispatch(deleteSelectedDevice(device));
  },
  filterDisplayedDevices(query) {
    dispatch(filterDisplayedDevices(query));
  },
  addSelectedLocation(location) {
    dispatch(addSelectedLocation(location));
  },
  deleteSelectedLocation(location) {
    dispatch(deleteSelectedLocation(location));
  },
  filterDisplayedLocations(query) {
    dispatch(filterDisplayedLocations(query));
  },
  clearSelectChannelDialogState() {
    dispatch(clearSelectChannelDialogState());
  },
  updateSelectedChannel(channel) {
    dispatch(updateSelectedChannel(channel));
  },
  displayChart() {
    dispatch(displayChart());
  },
  hasStartedFetchingLocationsAndDevices(bool) {
    dispatch(hasStartedFetchingLocationsAndDevices(bool));
  },
  hasFinishedFetchingLocationsAndDevices(bool) {
    dispatch(hasFinishedFetchingLocationsAndDevices(bool));
  }
});

const CreateChannelObjectWithDefaultValues = (
  channel,
  chartTypeName,
  aggregationType
) => {
  return { ...channel, chartTypeName, aggregationType };
};

export const SelectChannelDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectChannelDialogDisplay);
