const Reader = {
  static: [
    'alerts:add',
    'alerts:edit',
    'alerts:delete',
    'logitems:create',
    'logitems:edit',
    'logitems:delete',
    'manualinsert:create'
  ]
};

const Contributor = {
  static: [
    'route:management',
    'modal:save',
    'datatable:add',
    'datatable:edit',
    'dashboard:manage',
    'location:fileCreate'
  ].concat(Reader.static)
};

const Administrator = {
  static: [
    'route:manager',
    'datatable:delete',
    'logitems:hideforcustomer',
    'dashboard:manageAdminOnly',
    'organizations:add',
    'organizations:delete',
    'location:fileDelete'
  ].concat(Contributor.static)
};

const PlatformAdministrator = {
  static: Administrator
};

const rules = {
  Reader,
  Contributor,
  Administrator,
  PlatformAdministrator
};

export default rules;
