import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createChannel } from 'apis/FieldServiceApi';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import {
  CREATE_CHANNEL_OF_LOCATION,
  createChannelOfLocationSucceeded
} from './createChannelActions';

export function* createChannelSaga() {
  yield takeEvery(CREATE_CHANNEL_OF_LOCATION, execute);
}

function* execute(action) {
  let channel = action.channel;
  const user = yield select(currentUserSelector);
  delete channel.sensightId;

  try {
    const createdChannelResult = yield createChannel(user, channel);
    const createdChannel = createdChannelResult.data;

    yield put(createSnackbar(t('content.createchannel.snackbar.text')));
    yield put(
      createChannelOfLocationSucceeded({ ...channel, ...createdChannel })
    );
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}
