import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createLocationType } from 'apis/FieldServiceApi';
import { CREATE_LOCATIONTYPE } from './createLocationTypeActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { createLocationTypeSucceeded } from '../../redux/locationTypesListActions';

export function* createLocationTypeSaga() {
  yield takeEvery(CREATE_LOCATIONTYPE, execute);
}

function* execute(action) {
  let locationtype = action.locationtypeInformation;
  const user = yield select(currentUserSelector);

  try {
    const createdLocationTypeResult = yield createLocationType(
      user,
      locationtype
    );
    const createdLocationType = createdLocationTypeResult.data;

    yield put(createSnackbar(t('content.createlocationtype.snackbar.text')));

    yield put(createLocationTypeSucceeded(createdLocationType));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}
