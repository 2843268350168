import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCHED_DASHBOARDS = 'FETCHED_DASHBOARDS';
export const fetchedDashboards = makeActionCreator(FETCHED_DASHBOARDS);

export const FETCH_DASHBOARDS = 'FETCH_DASHBOARDS';
export const fetchDashboards = makeActionCreator(FETCH_DASHBOARDS);

export const FETCH_MANAGEMENT_DASHBOARDS = 'FETCH_MANAGEMENT_DASHBOARDS';
export const fetchManagementDashboards = makeActionCreator(
  FETCH_MANAGEMENT_DASHBOARDS
);

export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const setDashboards = makeActionCreator(SET_DASHBOARDS, 'dashboardList');

export const SET_MANAGEMENT_DASHBOARDS = 'SET_MANAGEMENT_DASHBOARDS';
export const setManagementDashboards = makeActionCreator(
  SET_MANAGEMENT_DASHBOARDS,
  'managementDashboardList'
);

export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const deleteDashboard = makeActionCreator(DELETE_DASHBOARD, 'dashboard');

export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const updateDashboard = makeActionCreator(UPDATE_DASHBOARD, 'dashboard');

export const ADD_DASHBOARD = 'ADD_DASHBOARD';
export const addDashboard = makeActionCreator(ADD_DASHBOARD, 'dashboard');
