import { connect } from 'react-redux';
import CreateLogbookActionDisplay from './CreateLogbookActionDisplay.jsx';
import {
  createLogbookAction,
  upsertLogbookActionDetails
} from '../redux/logbookActionsActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapDispatchToProps = dispatch => ({
  createLogbookAction() {
    dispatch(createLogbookAction());
  },
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  upsertLogbookActionDetails(logbookActionDetails) {
    dispatch(upsertLogbookActionDetails(logbookActionDetails));
  }
});

export const CreateLogbookActionContainer = connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateLogbookActionDisplay);
