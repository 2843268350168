import {
  FETCH_DEVICEPLACEMENTS_OF_LOCATION,
  CREATE_DEVICEPLACEMENT,
  EDIT_DEVICEPLACEMENT,
  DELETE_DEVICEPLACEMENT,
  createDevicePlacementSucceeded,
  editDevicePlacementSucceeded,
  deleteDevicePlacementSucceeded,
  setDevicePlacementsOfLocation,
  setDevicePlacementsOfDevice,
  FETCH_AVAILABLEDEVICES,
  setAvailableDevicesList
} from './devicePlacementsActions';
import { takeEvery, select, put } from 'redux-saga/effects';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  getDevicePlacementsByLocationtId,
  getAvailableDevices,
  createDevicePlacement,
  deleteDevicePlacement,
  editDevicePlacement
} from 'apis/FieldServiceApi';
import { devicesListSelector } from 'components/content/visualize/renderChartComponent/redux/deviceChannelSelectors';

export function* fetchDevicePlacementsOfLocationSaga() {
  yield takeEvery(
    FETCH_DEVICEPLACEMENTS_OF_LOCATION,
    fetchDevicePlacementsOfLocation
  );
}

function* fetchDevicePlacementsOfLocation(action) {
  try {
    const locationId = action.locationId;
    const user = yield select(currentUserSelector);
    var devicePlacementsOfLocation = (yield getDevicePlacementsByLocationtId(
      user,
      locationId
    )).data;
    var devicePlacementsOfLocationWithDeviceName =
      devicePlacementsOfLocation.map(dp => {
        dp.devicename = dp.device && dp.device.name ? dp.device.name : '';
        return dp;
      });
    yield put(
      setDevicePlacementsOfLocation(devicePlacementsOfLocationWithDeviceName)
    );
  } catch (error) {
    yield put(
      createSnackbar(
        t('content.deviceplacements.fetchdeviceplacementserror'),
        'error'
      )
    );
    console.error(error);
  }
}

export function* fetchAvailableDevicesForPlacementSaga() {
  yield takeEvery(FETCH_AVAILABLEDEVICES, fetchAvailableDevices);
}

function* fetchAvailableDevices(action) {
  try {
    const user = yield select(currentUserSelector);
    const devicesList = yield select(devicesListSelector);
    const startTime = action.startDateTime.toISOString();
    const endTime = action.endDateTime
      ? action.endDateTime.toISOString()
      : null;
    const currentDeviceId = action.currentDeviceId;

    const availableDevicesResponse = (yield getAvailableDevices(
      user,
      startTime,
      endTime,
      currentDeviceId
    )).data;
    const availableDevices = availableDevicesResponse.availableDevices;
    const devicePlacementsOfDevice =
      availableDevicesResponse.devicePlacementsOfDevice;

    const availableVisibleDevices = availableDevices.filter(ad => {
      const device = devicesList.find(device => device.id === ad.id);
      return device?.visible && device?.online;
    });

    yield put(setAvailableDevicesList(availableVisibleDevices));
    yield put(setDevicePlacementsOfDevice(devicePlacementsOfDevice));
  } catch (error) {
    yield put(
      createSnackbar(
        t('content.deviceplacements.fetchavailabledevicesserror'),
        'error'
      )
    );
    console.error(error);
  }
}

export function* createDevicePlacementSaga() {
  yield takeEvery(CREATE_DEVICEPLACEMENT, executeCreate);
}

export function* executeCreate(action) {
  try {
    const user = yield select(currentUserSelector);
    const devicePlacement = action.devicePlacement;
    const createdDevicePlacementResponse = (yield createDevicePlacement(
      user,
      action.devicePlacement.deviceId,
      devicePlacement
    )).data;
    yield put(createSnackbar(t('content.deviceplacements.create.success')));
    const devicePlacementResponseResult = createdDevicePlacementResponse.result;
    devicePlacementResponseResult.devicename = devicePlacement.devicename;
    yield put(createDevicePlacementSucceeded(devicePlacementResponseResult));
  } catch (exception) {
    let error = getError(
      exception,
      t('content.deviceplacements.create.failed')
    );
    yield put(createSnackbar(error, 'error'));
    console.error(exception);
  }
}

export function* editDevicePlacementSaga() {
  yield takeEvery(EDIT_DEVICEPLACEMENT, executeEdit);
}

function* executeEdit(action) {
  try {
    const user = yield select(currentUserSelector);
    const devicePlacement = action.devicePlacement;
    const updateDevicePlacementModel = {
      startDateTime: devicePlacement.startDateTime,
      endDateTime: devicePlacement.endDateTime
        ? devicePlacement.endDateTime
        : null
    };

    yield editDevicePlacement(
      user,
      devicePlacement.id,
      updateDevicePlacementModel
    );

    yield put(createSnackbar(t('content.deviceplacements.edit.success')));

    yield put(editDevicePlacementSucceeded(devicePlacement));
  } catch (exception) {
    let error = getError(exception, t('content.deviceplacements.edit.failed'));
    yield put(createSnackbar(error, 'error'));
    console.error(exception);
  }
}

export function* deleteDevicePlacementSaga() {
  yield takeEvery(DELETE_DEVICEPLACEMENT, executeDelete);
}

function* executeDelete(action) {
  const devicePlacement = action.devicePlacement;
  const user = yield select(currentUserSelector);

  try {
    yield deleteDevicePlacement(user, devicePlacement.id);
    yield put(createSnackbar(t('content.deviceplacements.delete.success')));
    yield put(deleteDevicePlacementSucceeded(devicePlacement));
  } catch (exception) {
    let error = getError(
      exception,
      t('content.deviceplacements.delete.failed')
    );
    yield put(createSnackbar(error, 'error'));
    console.error(exception);
  }
}

export const getError = (exception, message) => {
  switch (exception.response.status) {
    case 400:
    case 401:
      return (
        message +
        ' ' +
        t('content.general.servermessage') +
        ': ' +
        exception.response.data
      );
    default:
      return message;
  }
};
