import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { deleteOrganization as deleteOrganizationApi } from 'apis/CustomerContactApi';
import { DELETE_ORGANIZATION_REQUESTED } from './deleteOrganizationActions';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';
import { deleteOrganization } from '../../redux/organizationsListActions';

export function* deleteOrganizationSaga() {
  yield takeEvery(DELETE_ORGANIZATION_REQUESTED, execute);
}

function* execute(action) {
  const organization = action.organization;
  const user = yield select(currentUserSelector);

  try {
    yield deleteOrganizationApi(user, organization.id);
    yield put(createSnackbar(t('content.deleteorganization.snackbar.text')));
    yield put(deleteOrganization(organization));
  } catch (exception) {
    console.error(exception);
  }
}
