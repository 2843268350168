import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_LOGITEMS_LIST_OF_LOCATION =
  'FETCH_LOGITEMS_LIST_OF_LOCATION';
export const fetchLogItemsListOfLocation = makeActionCreator(
  FETCH_LOGITEMS_LIST_OF_LOCATION,
  'locationSensightId'
);

export const SET_LOGITEMS_LIST_OF_LOCATION = 'SET_LOGITEMS_LIST_OF_LOCATION';
export const setLogItemsListOfLocation = makeActionCreator(
  SET_LOGITEMS_LIST_OF_LOCATION,
  'logitemsList'
);

export const EDIT_LOGITEM_OF_LOCATION = 'EDIT_LOGITEM_OF_LOCATION';
export const editLogItemOfLocation = makeActionCreator(
  EDIT_LOGITEM_OF_LOCATION,
  'logitem'
);
export const CREATE_LOGITEM_SUCCEEDED = 'CREATE_LOGITEM_SUCCEEDED';
export const createLogItemSucceeded = makeActionCreator(
  CREATE_LOGITEM_SUCCEEDED,
  'logitem'
);

export const EDIT_LOGITEM_OF_LOCATION_SUCCEEDED =
  'EDIT_LOGITEM_OF_LOCATION_SUCCEEDED';
export const editLogItemOfLocationSucceeded = makeActionCreator(
  EDIT_LOGITEM_OF_LOCATION_SUCCEEDED,
  'logitem'
);

export const DELETE_LOGITEM_OF_LOCATION = 'DELETE_LOGITEM_OF_LOCATION';
export const deleteLogItemOfLocation = makeActionCreator(
  DELETE_LOGITEM_OF_LOCATION,
  'logitem'
);

export const DELETE_LOGITEM_OF_LOCATION_SUCCEEDED =
  'DELETE_LOGITEM_OF_LOCATION_SUCCEEDED ';
export const deleteLogItemOfLocationSucceeded = makeActionCreator(
  DELETE_LOGITEM_OF_LOCATION_SUCCEEDED,
  'logitem'
);
