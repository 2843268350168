import { makeActionCreator } from 'utils/makeActionCreator';

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const fetchDashboard = makeActionCreator(FETCH_DASHBOARD, 'dashboardId');

export const SET_CURRENT_DASHBOARD = 'SET_CURRENT_DASHBOARD';
export const setCurrentDashboard = makeActionCreator(
  SET_CURRENT_DASHBOARD,
  'dashboard'
);

export const SAVE_DASHBOARD = 'SAVE_DASHBOARD';
export const saveDashboard = makeActionCreator(SAVE_DASHBOARD);
