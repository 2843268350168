import { connect } from 'react-redux';
import NewDashboardDialogDisplay from './NewDashboardDialogDisplay.jsx';
import { createNewDashboard } from './redux/newDashboardDialogActions';
import { updateDashboard } from '../redux/myDashboardActions';
import { organizationsListSelector } from 'components/content/organizations/redux/organizationsListSelectors.js';
import { contactsListSelector } from 'components/content/contacts/redux/contactsListSelectors.js';
import { fetchOrganizationsList } from 'components/content/organizations/redux/organizationsListActions.js';
import { fetchContactsList } from 'components/content/contacts/redux/contactsListActions.js';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';

const mapStateToProps = (state, ownProps) => {
  return {
    allOrganizations: organizationsListSelector(state),
    allPersons: contactsListSelector(state),
    handleOk: ownProps.handleOk,
    dashboardToEdit:
      ownProps.dashboardToEdit === undefined ? {} : ownProps.dashboardToEdit,
    setSaveButtonDisabled: ownProps.setSaveButtonDisabled,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  createNewDashboard(dashboard) {
    dispatch(createNewDashboard(dashboard));
  },
  updateDashboard(dashboard) {
    dispatch(updateDashboard(dashboard));
  },
  fetchOrganizationsList() {
    dispatch(fetchOrganizationsList());
  },
  fetchContactsList() {
    dispatch(fetchContactsList());
  },
  findByOrganizationSensightId(organizations, selectedOrganizationIds) {
    return findByOrganizationSensightId(organizations, selectedOrganizationIds);
  },
  findByContactSensightId(contacts, selectedContactIds) {
    return findByContactSensightId(contacts, selectedContactIds);
  }
});

export const NewDashboardDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDashboardDialogDisplay);

function findByOrganizationSensightId(organizations, selectedOrganizationIds) {
  if (organizations.length < 1) {
    return [];
  }
  const result = [];

  selectedOrganizationIds.forEach(organizationId => {
    const tempOrganization = organizations.find(
      organization => organization.sensightId === organizationId
    );

    if (tempOrganization) {
      result.push(tempOrganization);
    }
  });

  return result;
}

function findByContactSensightId(contacts, selectedContactIds) {
  if (contacts.length < 1) {
    return [];
  }

  const result = [];
  selectedContactIds.forEach(contactId => {
    const tempContact = contacts.find(
      contact => contact.sensightId === contactId
    );
    if (tempContact) {
      result.push(tempContact);
    }
  });
  return result;
}
