import { connect } from 'react-redux';
import { LocationDisplay } from './LocationDisplay.jsx';
import { locationSelector } from './redux/locationSelectors.js';
import { fetchLocation } from './redux/locationActions.js';
import { addSelectedLocation } from '../visualize/toolbar/dialogs/selectChannelDialog/redux/locationActions.js';
import {
  setDefaultChannelChartType,
  setDefaultChannelAggregationType
} from '../visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogActions.js';
import {
  setVisualizeFromTime,
  setCurrentVisualizePeriod
} from '../visualize/toolbar/redux/toolbarActions';
import { locationDetailsSelector } from './manageLocation/redux/manageLocationSelectors.js';
import {
  hasFinishedFetchingLocationsAndDevicesSelector,
  hasStartedFetchingLocationsAndDevicesSelector
} from '../visualize/toolbar/dialogs/selectChannelDialog/redux/selectChannelDialogSelectors';

const mapStateToProps = (state, ownProps) => {
  const locationId = ownProps.match.params.sensightId;
  const navigationItem = ownProps.match.params.navigationItem;
  return {
    hasStartedFetchingLocationsAndDevices:
      hasStartedFetchingLocationsAndDevicesSelector(state),
    hasFinishedFetchingLocationsAndDevices:
      hasFinishedFetchingLocationsAndDevicesSelector(state),
    locationId,
    navigationItem,
    location: locationSelector(state),
    locationDetails: locationDetailsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLocation(locationId) {
    dispatch(fetchLocation(locationId));
  },
  addSelectedLocation(location) {
    dispatch(addSelectedLocation(location));
  },
  setVisualizeFromTime(value) {
    dispatch(setVisualizeFromTime(value));
  },
  setCurrentVisualizePeriod(value) {
    dispatch(setCurrentVisualizePeriod(value));
  },
  setDefaultChannelChartType(value) {
    dispatch(setDefaultChannelChartType(value));
  },
  setDefaultChannelAggregationType(value) {
    dispatch(setDefaultChannelAggregationType(value));
  }
});

export const LocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDisplay);
