import { connect } from 'react-redux';
import LocationsTableDisplay from './LocationsTableDisplay.jsx';
import { deleteLocation } from './redux/deleteLocationActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { locationsListSelector } from '../redux/locationsListSelectors.js';
import { contactsListSelector } from 'components/content/contacts/redux/contactsListSelectors.js';

const mapStateToProps = state => {
  return {
    currentUser: currentUserSelector(state),
    locations: locationsListSelector(state),
    contacts: contactsListSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  deleteLocation(row) {
    dispatch(deleteLocation(row));
  }
});

export const LocationsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsTableDisplay);
