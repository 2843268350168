import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';

export const DescriptionForm = ({
  viewModel,
  deviceTechnicalDescriptionFormRef,
  upsertTechnicalDeviceDescription,
  setTechDescrFormValid
}) => {
  const { t } = useTranslation();

  return (
    <FormikWithRef
      ref={deviceTechnicalDescriptionFormRef}
      initialValues={{
        fieldId: !viewModel ? '' : viewModel.id ?? '',
        description: !viewModel ? '' : viewModel.description ?? ''
      }}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        upsertTechnicalDeviceDescription(values);
      }}
    >
      {props => (
        React.useEffect(() => {
          setTechDescrFormValid(isEmptyObject(props.errors));
        }, [props.dirty, props.errors]),
        (
          <Form onSubmit={props.submitForm}>
            <Field
              id="description"
              type="text"
              onChange={props.handleChange}
              value={props.values.description}
              name="description"
              label={t('content.devices.description')}
              multiline
              rows="7"
              fullWidth
              component={TextField}
              variant="standard"
            />
          </Form>
        )
      )}
    </FormikWithRef>
  );
};
