import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'formik-mui';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';

export const TenantDetailsForm = ({
  tenantDetailsFormRef,
  tenantDetailsViewmodel,
  upsertTenantDetails,
  setTenantDetailsFormValid,
  themes
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderThemeMenuItems = () => {
    if (themes.length > 0) {
      const menuItems = themes.map(key => {
        return (
          <MenuItem key={key.id} value={key.id}>
            {key.name}
          </MenuItem>
        );
      });
      return menuItems;
    }
    return [];
  };

  return (
      <FormikWithRef
        ref={tenantDetailsFormRef}
        initialValues={{
          id: !tenantDetailsViewmodel ? '' : tenantDetailsViewmodel.id ?? '',
          friendlyId: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.friendlyId ?? '',
          name: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.name ?? '',
          themeId: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.themeId ?? '',
          customerContactServiceDatabaseName: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.customerContactServiceDatabaseName,
          fieldServiceDatabaseName: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.fieldServiceDatabaseName,
          measurementServiceDatabaseName: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.measurementServiceDatabaseName,
          notificationsServiceDatabaseName: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.notificationsServiceDatabaseName,
          reportingServiceDatabaseName: !tenantDetailsViewmodel
            ? ''
            : tenantDetailsViewmodel.reportingServiceDatabaseName
        }}
        enableReinitialize={true}
        validateOnBlur={true}
        validate={values => {
          let errors = {};
          if (!values.friendlyId) {
            errors.friendlyId = t('general.validation.required');
          }
          if (!values.name) {
            errors.name = t('general.validation.required');
          }
          if (!values.themeId) {
            errors.themeId = t('general.validation.required');
          }
          if (!values.customerContactServiceDatabaseName) {
            errors.customerContactServiceDatabaseName = t(
              'general.validation.required'
            );
          }
          if (!values.fieldServiceDatabaseName) {
            errors.fieldServiceDatabaseName = t('general.validation.required');
          }
          if (!values.measurementServiceDatabaseName) {
            errors.measurementServiceDatabaseName = t(
              'general.validation.required'
            );
          }
          if (!values.notificationsServiceDatabaseName) {
            errors.notificationsServiceDatabaseName = t(
              'general.validation.required'
            );
          }
          if (!values.reportingServiceDatabaseName) {
            errors.reportingServiceDatabaseName = t(
              'general.validation.required'
            );
          }

          if (!values.id && !values.customerContactServiceDB) {
            errors.customerContactServiceDB = t('general.validation.required');
          }
          if (!values.id && !values.fieldServiceDB) {
            errors.fieldServiceDB = t('general.validation.required');
          }
          if (!values.id && !values.measurementServiceDB) {
            errors.measurementServiceDB = t('general.validation.required');
          }
          if (!values.id && !values.notificationsServiceDB) {
            errors.notificationsServiceDB = t('general.validation.required');
          }
          if (!values.id && !values.reportingServiceDB) {
            errors.reportingServiceDB = t('general.validation.required');
          }

          return errors;
        }}
        onSubmit={values => {
          upsertTenantDetails(values);
        }}
      >
        {props => (
          React.useEffect(() => {
            setTenantDetailsFormValid(isEmptyObject(props.errors));
          }, [props.dirty, props.errors]),
          (
            <Form>
              <Field
                id="friendlyId"
                name="friendlyId"
                type="text"
                label={t('content.tenants.friendlyid.label')}
                fullWidth
                className={classes.textField}
                component={TextField}
                onChange={props.handleChange}
                value={props.values.friendlyId}
                required
                variant="standard"
              />
              <Field
                id="name"
                name="name"
                type="text"
                label={t('content.tenants.name')}
                fullWidth
                className={classes.textField}
                component={TextField}
                onChange={props.handleChange}
                value={props.values.name}
                variant="standard"
              />
              <Grid container>
                <Grid item xs={7}>
                  <Field
                    id="customerContactServiceDB"
                    name="customerContactServiceDB"
                    type="text"
                    label={t('content.tenants.customercontactservicedb.connectionstring')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.customerContactServiceDB}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <Field
                    id="customerContactServiceDatabaseName"
                    name="customerContactServiceDatabaseName"
                    type="text"
                    label={t('content.tenants.customercontactservicedb.dbname')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.customerContactServiceDatabaseName}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  <Field
                    id="fieldServiceDB"
                    name="fieldServiceDB"
                    type="text"
                    label={t('content.tenants.fieldservicedb.connectionstring')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.fieldServiceDB}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <Field
                    id="fieldServiceDatabaseName"
                    name="fieldServiceDatabaseName"
                    type="text"
                    label={t('content.tenants.fieldservicedb.dbname')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.fieldServiceDatabaseName}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  <Field
                    id="measurementServiceDB"
                    name="measurementServiceDB"
                    type="text"
                    label={t('content.tenants.measurementservicedb.connectionstring')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.measurementServiceDB}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  {' '}
                  <Field
                    id="measurementServiceDatabaseName"
                    name="measurementServiceDatabaseName"
                    type="text"
                    label={t('content.tenants.measurementservicedb.dbname')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.measurementServiceDatabaseName}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  <Field
                    id="notificationsServiceDB"
                    name="notificationsServiceDB"
                    type="text"
                    label={t('content.tenants.notificationsservicedb.connectionstring')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.notificationsServiceDB}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <Field
                    id="notificationsServiceDatabaseName"
                    name="notificationsServiceDatabaseName"
                    type="text"
                    label={t('content.tenants.notificationsservicedb.dbname')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.notificationsServiceDatabaseName}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  <Field
                    id="reportingServiceDB"
                    name="reportingServiceDB"
                    type="text"
                    label={t('content.tenants.reportingservicedb.connectionstring')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.reportingServiceDB}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <Field
                    id="reportingServiceDatabaseName"
                    name="reportingServiceDatabaseName"
                    type="text"
                    label={t('content.tenants.reportingservicedb.dbname')}
                    fullWidth
                    className={classes.textField}
                    component={TextField}
                    onChange={props.handleChange}
                    value={props.values.reportingServiceDatabaseName}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Field
                id="themeId"
                name="themeId"
                value={props.values.themeId}
                label={t('content.devices.themeid')}
                fullWidth
                className={classes.textField}
                component={TextField}
                onChange={props.handleChange}
                type="select"
                select
                variant="standard"
              >
                {renderThemeMenuItems()}
              </Field>
            </Form>
          )
        )}
      </FormikWithRef>
  );
};

const useStyles = makeStyles(theme => ({
  lastInputField: {
    marginBottom: '16px'
  },
  textField: {
    marginBottom: '8px'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));
