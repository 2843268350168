import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'formik-mui';
import { Field, Form } from 'formik';
import FormikWithRef from 'components/imd-components/FormikWithRef.jsx';
import { isEmptyObject } from 'utils/helperMethods';
import ImdDatePicker from 'components/imd-components/ImdDatePicker';
import ImdButton from 'components/imd-components/ImdButton';
import SyncIcon from '@mui/icons-material/Sync';
import Grid from '@mui/material/Grid';
import ImdCard from 'components/imd-components/ImdCard';

function ManageDevicePlacementDisplay(
  {
    locationId,
    viewModel,
    handleActionDevicePlacement,
    availableDevices,
    fetchAvailableDevices,
    setSaveButtonDisabled,
    devicePlacementStartDateTime,
    setDevicePlacementStartDateTime,
    devicePlacementEndDateTime,
    setDevicePlacementEndDateTime,
    setDevicePlacementDeviceId,
    devicePlacementsOfDevice,
    createSnackbar,
    isEdit
  },
  ref
) {
  const formRef = useRef();
  const { t } = useTranslation();
  const classes = useStyles();

  const isStartDateBetweenPeriod = (startDate, periodBegin, periodEnd) => {
    return startDate > periodBegin && (!periodEnd || startDate < periodEnd);
  };

  const isOverlappingDateTimeOffsetPeriod = (
    currentStartDate,
    currentEndDate,
    newStartDate,
    newEndDate
  ) => {
    if (
      isStartDateBetweenPeriod(newStartDate, currentStartDate, currentEndDate)
    )
      return true;
    if (isStartDateBetweenPeriod(currentStartDate, newStartDate, newEndDate))
      return true;
    if (currentStartDate == newStartDate) return true;
    return false;
  };

  const [autocompleteDevice, setAutocompleteDevice] = React.useState(null);

  const refreshDevices = () => {
    fetchAvailableDevices(
      devicePlacementStartDateTime,
      devicePlacementEndDateTime,
      viewModel != null ? viewModel.deviceId : null
    );
  };

  const initialValues = {
    id: !viewModel ? '' : viewModel.id ?? '',
    locationId: !viewModel ? locationId : viewModel.locationId ?? locationId,
    deviceId: !viewModel ? '' : viewModel.deviceId ?? '',
    devicename: !viewModel ? '' : viewModel.devicename ?? ''
  };

  React.useEffect(() => {
    if (isEdit) {
      refreshDevices();
    }

    setAutocompleteDevice({
      id: initialValues.deviceId,
      name: initialValues.devicename
    });
  }, []);

  const form = ({ submitForm, dirty, errors, validateForm }) => {
    React.useEffect(() => {
      (() => validateForm())();

      refreshDevices();
    }, []);

    React.useEffect(() => {
      if (isEmptyObject(errors)) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }, [dirty, errors]);

    React.useEffect(() => {
      setDevicePlacementDeviceId(autocompleteDevice?.id);
    }, [autocompleteDevice]);

    return (
      <Form ref={formRef} onSubmit={submitForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImdDatePicker
              labelText={t('content.deviceplacements.startdatetime')}
              date={devicePlacementStartDateTime}
              saveAction={value => setDevicePlacementStartDateTime(value)}
              maxDate={devicePlacementEndDateTime}
            />
            <ImdDatePicker
              labelText={t('content.deviceplacements.enddatetime')}
              date={devicePlacementEndDateTime}
              saveAction={value => setDevicePlacementEndDateTime(value)}
              minDate={devicePlacementStartDateTime}
              className={classes.textField}
            />
            <ImdButton
              className={classes.smallButton}
              variant="contained"
              size="small"
              color="inherit"
              onClick={refreshDevices}
              startIcon={<SyncIcon />}
            >
              {t('content.deviceplacements.fetchAvailableDevices')}
            </ImdButton>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              filterSelectedOptions
              options={availableDevices}
              getOptionLabel={option => option.name}
              value={autocompleteDevice}
              onChange={(event, newValue) => {
                setAutocompleteDevice(newValue);
              }}
              renderInput={params => (
                <Field
                  {...params}
                  name="deviceId"
                  type="text"
                  label={t('content.deviceplacements.device')}
                  fullWidth
                  className={classes.lastInputField}
                  component={TextField}
                  variant="standard"
                />
              )}
              disabled={isEdit}
            />
            {t('content.deviceplacements.availableDevicesVisibility')}
          </Grid>
        </Grid>
      </Form>
    );
  };

  useImperativeHandle(ref, () => formRef);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImdCard
          title={t('content.locationdashboard.card.deviceplacementDetails')}
          content={
            <FormikWithRef
              ref={formRef}
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnBlur={true}
                  validate={values => {
                values.startDateTime =
                  devicePlacementStartDateTime.toISOString();
                values.endDateTime = devicePlacementEndDateTime
                  ? devicePlacementEndDateTime.toISOString()
                  : null;
                let errors = {};
                if (!values.locationId) {
                  errors.locationId = t('general.validation.required');
                }
                if (!values.deviceId) {
                  errors.deviceId = t('general.validation.required');
                }
                if (
                  devicePlacementsOfDevice &&
                  devicePlacementsOfDevice.length > 0
                ) {
                  for (const index in devicePlacementsOfDevice) {
                    const dp = devicePlacementsOfDevice[index];
                    const dpStartDateTime = Date.parse(dp.startDateTime);
                    const dpEndDateTime = dp.endDateTime
                      ? Date.parse(dp.endDateTime)
                      : null;
                    const newDpStartDateTime = Date.parse(values.startDateTime);
                    const newDpEndDateTime = values.endDateTime
                      ? Date.parse(values.endDateTime)
                          : null;

                    if (
                      dp.id == values.id &&
                      dp.deviceId == values.deviceId &&
                      dpStartDateTime == newDpStartDateTime &&
                      dpEndDateTime == newDpEndDateTime
                    ) {
                      errors.general = t(
                        'content.deviceplacements.nothingchanged'
                      );
                      createSnackbar(
                        t('content.deviceplacements.nothingchanged'),
                        'error'
                      );
                      break;
                    } else if (
                      dp.id != values.id &&
                      dp.deviceId == values.deviceId &&
                      isOverlappingDateTimeOffsetPeriod(
                        dpStartDateTime,
                        dpEndDateTime,
                        newDpStartDateTime,
                        newDpEndDateTime
                      )
                    ) {
                      errors.general = t(
                        'content.deviceplacements.placementoverlapswithexistingplacementofdevice'
                      );
                      createSnackbar(
                        t(
                          'content.deviceplacements.placementoverlapswithexistingplacementofdevice'
                        ),
                        'error'
                      );
                      break;
                    }
                  }
                }
                return errors;
              }}
                  onSubmit={values => {
                values.startDateTime =
                  devicePlacementStartDateTime.toISOString();
                values.endDateTime = devicePlacementEndDateTime
                  ? devicePlacementEndDateTime.toISOString()
                  : null;
                var device = availableDevices.filter(ad => {
                  return ad.id === values.deviceId;
                });
                values.devicename = device.length > 0 ? device[0].name : '';
                //make sure that always the current location is set
                values.locationId = locationId;
                handleActionDevicePlacement(values);
              }}
            >
              {form}
            </FormikWithRef>
          }
          variant="secondary"
          cardHeight="100%"
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  lastInputField: {
    marginBottom: '16px',
    minWidth: '200px'
  },
  select: {
    marginTop: '18px'
  },
  smallButton: {
    marginLeft: '20px',
    marginTop: '16px',
    minWidth: '28px',
    maxHeight: '28px'
  }
}));

export default forwardRef(ManageDevicePlacementDisplay);
