import { takeEvery, select, put } from 'redux-saga/effects';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import {
  FETCH_DASHBOARD_MEASUREMENTS,
  setDashboardMeasurements
} from './dashboardMeasurementActions';

import { fetchMeasurements } from 'components/content/visualize/displayChartComponent/redux/displayChartSagas';

export function* fetchDashboardMeasurementsSaga() {
  yield takeEvery(
    FETCH_DASHBOARD_MEASUREMENTS,
    executeFetchDashboardMeasurements
  );
}

function* executeFetchDashboardMeasurements({
  dashboardSectionItemId,
  fetchParameters
}) {
  if (fetchParameters) {
    const currentUser = yield select(currentUserSelector);
    const measurements = yield fetchMeasurements(
      fetchParameters.selectedChannels,
      new Date(fetchParameters.selectionData.visualizeFromTime),
      new Date(fetchParameters.selectionData.visualizeUntilTime),
      currentUser
    );

    yield put(setDashboardMeasurements(dashboardSectionItemId, measurements));
  }
}
