import { makeActionCreator } from 'utils/makeActionCreator';
export const CREATE_LOCATION_DETAILS = 'CREATE_LOCATION_DETAILS';
export const createLocationDetails = makeActionCreator(
  CREATE_LOCATION_DETAILS,
  'locationDetails'
);

export const CREATE_PROJECT_DETAILS = 'CREATE_PROJECT_DETAILS';
export const createProjectDetails = makeActionCreator(
  CREATE_PROJECT_DETAILS,
  'projectDetails'
);
