import { createReducer } from 'utils/createReducer';
import {
  SET_LOGITEMS_LIST_OF_LOCATION,
  DELETE_LOGITEM_OF_LOCATION_SUCCEEDED,
  EDIT_LOGITEM_OF_LOCATION_SUCCEEDED,
  CREATE_LOGITEM_SUCCEEDED
} from './logItemsListActions';
import { fromJS } from 'immutable';

export const logitemsListOfLocation = createReducer(null, {
  [SET_LOGITEMS_LIST_OF_LOCATION](state, { logitemsList }) {
    return fromJS(logitemsList);
  },
  [CREATE_LOGITEM_SUCCEEDED](state, { logitem }) {
    return state.push(fromJS(logitem));
  },
  [EDIT_LOGITEM_OF_LOCATION_SUCCEEDED](state, { logitem }) {
    let logitemsList = state.toJS();
    var foundLocationIndex = logitemsList.findIndex(
      cha => logitem.id === cha.id
    );
    logitemsList[foundLocationIndex] = {
      ...logitemsList[foundLocationIndex],
      ...logitem
    };
    return fromJS(logitemsList);
  },
  [DELETE_LOGITEM_OF_LOCATION_SUCCEEDED](state, { logitem }) {
    return state.filter(cha => cha.get('id') !== logitem.id);
  }
});
