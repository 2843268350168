import React from 'react';
import PropTypes from 'prop-types';
import ImdCard from '../../../imd-components/ImdCard';
import ImdButton from '../../../imd-components/ImdButton';
import { Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';

export function ChangePasswordDisplay({ handleSendPasswordEmail }) {
  const { t } = useTranslation();

  const changePasswordButton = (
    <>
      <Typography>{t('content.profile.changepassword.card.text')}</Typography>{' '}
      <br />
      <ImdButton
        variant="contained"
        color="primary"
        startIcon={<EmailIcon />}
        onClick={handleSendPasswordEmail}
      >
        {t('content.profile.changepassword.button.text')}
      </ImdButton>
    </>
  );

  return (
    <>
      <ImdCard
        title={t('content.profile.changepassword.card.title')}
        content={changePasswordButton}
      />
    </>
  );
}

ChangePasswordDisplay.propTypes = {
  handleSendPasswordEmail: PropTypes.func.isRequired
};
