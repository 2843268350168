import MenuItem from '@mui/material/MenuItem';
import React from 'react';

import timezones from '../../utils/timezones.json';

export default timezones.timezones.map((t, index) => {
  return (
    <MenuItem key={index} value={t}>
      {t}
    </MenuItem>
  );
});
