import { takeEvery, select, put } from 'redux-saga/effects';
import {
  FETCH_EXPORTCHARTS_LIST,
  setExportChartsList,
  DELETE_EXPORTCHART,
  deleteExportChartSucceeded
} from './exportChartsListActions';
import { getExportCharts, deleteExportChart } from 'apis/ReportingServiceApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import { t } from 'i18next';

export function* fetchExportChartsListSaga() {
  yield takeEvery(FETCH_EXPORTCHARTS_LIST, execute);
}

export function* deleteExportChartSaga() {
  yield takeEvery(DELETE_EXPORTCHART, executeDelete);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var exportChartsList = (yield getExportCharts(user, exportChartsList)).data;
  yield put(setExportChartsList(exportChartsList));
}

function* executeDelete(action) {
  const exportChart = action.exportChart;
  const user = yield select(currentUserSelector);

  try {
    yield deleteExportChart(user, exportChart.id);
    yield put(createSnackbar(t('content.deleteexportchart.snackbar.text')));
    yield put(deleteExportChartSucceeded(exportChart));
  } catch (exception) {
    console.error(exception);
  }
}
