import { connect } from 'react-redux';
import { KpiDisplay } from './KpiDisplay.jsx';
import { chartDisplayOptionsSelector } from '../../content/visualize/toolbar/redux/toolbarSelectors.js';

const mapStateToProps = (
  state,
  {
    item,
    locations,
    visualize,
    dataSources,
    measurements,
    logItems,
    renderKey,
    defaultOpen,
    chartDisplayOptions
  }
) => {
  return {
    item,
    locations,
    visualize,
    dataSources,
    measurements,
    logItems,
    renderKey,
    defaultOpen,
    chartDisplayOptions:
      chartDisplayOptions || chartDisplayOptionsSelector(state)
  };
};

export const KpiContainer = connect(mapStateToProps)(KpiDisplay);
