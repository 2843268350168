import { createReducer } from 'utils/createReducer';
import {
  SET_LOCATION_DETAILS,
  SET_AUTOCOMPLETE_SELECTED_ORGANIZATIONS,
  SET_AUTOCOMPLETE_SELECTED_SERVICE_CONTACT_PERSONS,
  SET_AUTOCOMPLETE_SELECTED_CONTACT_PERSONS,
  SET_ONLINE_SWITCH_VALUE,
  SET_HIDE_SWITCH_VALUE
} from './manageLocationActions';
import { fromJS } from 'immutable';

export const locationDetails = createReducer(null, {
  [SET_LOCATION_DETAILS](state, { locationDetails }) {
    return fromJS(locationDetails);
  }
});

export const autocompleteSelectedOrganizations = createReducer(null, {
  [SET_AUTOCOMPLETE_SELECTED_ORGANIZATIONS](state, { selectedOrganizations }) {
    return fromJS(selectedOrganizations);
  }
});

export const autocompleteSelectedServiceContactPersons = createReducer(null, {
  [SET_AUTOCOMPLETE_SELECTED_SERVICE_CONTACT_PERSONS](
    state,
    { selectedServiceContactPersons }
  ) {
    return fromJS(selectedServiceContactPersons);
  }
});

export const autocompleteSelectedContactPersons = createReducer(null, {
  [SET_AUTOCOMPLETE_SELECTED_CONTACT_PERSONS](
    state,
    { selectedContactPersons }
  ) {
    if (
      selectedContactPersons === null ||
      selectedContactPersons === undefined ||
      selectedContactPersons.length === 0
    ) {
      return fromJS({ email: '' });
    }
    return fromJS(selectedContactPersons);
  }
});

export const onlineSwitchValue = createReducer(null, {
  [SET_ONLINE_SWITCH_VALUE](state, { switchValue }) {
    return fromJS(switchValue);
  }
});

export const hideSwitchValue = createReducer(null, {
  [SET_HIDE_SWITCH_VALUE](state, { switchValue }) {
    return fromJS(switchValue);
  }
});
