import { takeEvery, select, put } from 'redux-saga/effects';
import {
  FETCH_ORGANIZATIONS_LIST,
  setOrganizationsList
} from './organizationsListActions';
import { getOrganizations } from 'apis/CustomerContactApi';
import { currentUserSelector } from 'redux/user/currentUserSelector';

export function* fetchOrganizationsListSaga() {
  yield takeEvery(FETCH_ORGANIZATIONS_LIST, execute);
}

function* execute() {
  const user = yield select(currentUserSelector);
  var organizationsList = (yield getOrganizations(user, organizationsList))
    .data;
  yield put(setOrganizationsList(organizationsList));
}
