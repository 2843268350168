import { createReducer } from 'utils/createReducer';
import {
  SET_DEVICEPLACEMENTS_OF_LOCATION,
  SET_DEVICEPLACEMENTS_OF_DEVICE,
  SET_DEVICEPLACEMENT,
  SET_AVAILABLEDEVICES_LIST,
  CREATE_DEVICEPLACEMENT_SUCCEEDED,
  DELETE_DEVICEPLACEMENT_SUCCEEDED,
  EDIT_DEVICEPLACEMENT_SUCCEEDED,
  SET_DEVICEPLACEMENTSTARDATETIME,
  SET_DEVICEPLACEMENTENDDATETIME,
  SET_DEVICEPLACEMENTDEVICEID
} from './devicePlacementsActions';
import { fromJS } from 'immutable';

export const devicePlacementsOfLocation = createReducer(null, {
  [SET_DEVICEPLACEMENTS_OF_LOCATION](state, { devicePlacementsOfLocation }) {
    return fromJS(devicePlacementsOfLocation);
  },
  [CREATE_DEVICEPLACEMENT_SUCCEEDED](state, { devicePlacement }) {
    return state.push(fromJS(devicePlacement));
  },
  [EDIT_DEVICEPLACEMENT_SUCCEEDED](state, { devicePlacement }) {
    let devicePlacementsOfLocation = state.toJS();
    var foundDeviceIndex = devicePlacementsOfLocation.findIndex(
      dev => devicePlacement.id === dev.id
    );
    devicePlacementsOfLocation[foundDeviceIndex] = devicePlacement;
    return fromJS(devicePlacementsOfLocation);
  },
  [DELETE_DEVICEPLACEMENT_SUCCEEDED](state, { devicePlacement }) {
    return state.filter(con => con.get('id') !== devicePlacement.id);
  }
});

export const devicePlacementsOfDevice = createReducer(null, {
  [SET_DEVICEPLACEMENTS_OF_DEVICE](state, { devicePlacementsOfDevice }) {
    return fromJS(devicePlacementsOfDevice);
  }
});

export const devicePlacement = createReducer(null, {
  [SET_DEVICEPLACEMENT](state, { devicePlacement }) {
    return fromJS(devicePlacement);
  },
  [SET_DEVICEPLACEMENTDEVICEID](state, { deviceId }) {
    let devicePlacement = state.toJS();
    return fromJS({ ...devicePlacement, deviceId });
  }
});

export const availableDevicesList = createReducer(null, {
  [SET_AVAILABLEDEVICES_LIST](state, { availableDevicesList }) {
    return fromJS(availableDevicesList);
  }
});

export const devicePlacementStartDateTime = createReducer(null, {
  [SET_DEVICEPLACEMENTSTARDATETIME](state, { devicePlacementStartDateTime }) {
    return fromJS(devicePlacementStartDateTime);
  }
});

export const devicePlacementEndDateTime = createReducer(null, {
  [SET_DEVICEPLACEMENTENDDATETIME](state, { devicePlacementEndDateTime }) {
    return fromJS(devicePlacementEndDateTime);
  }
});
