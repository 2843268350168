import { connect } from 'react-redux';
import DashboardDisplay from './DashboardDisplay.jsx';
import { saveDashboard } from './redux/dashboardActions';
import { currentDashboardSelector } from './redux/dashboardSelectors';
import { currentUserSelector } from 'redux/user/currentUserSelector.js';
import { locationsListSelector } from 'components/content/locations/redux/locationsListSelectors.js';

const mapStateToProps = (state, ownProps) => {
  return {
    editMode: ownProps.editMode,
    dashboard: currentDashboardSelector(state),
    locations: locationsListSelector(state),
    currentUser: currentUserSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  saveDashboard() {
    dispatch(saveDashboard());
  }
});

export const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDisplay);
