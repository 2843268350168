import axios from 'axios';
import configFile from '../App_config.json';
import fileDownload from 'js-file-download';
import { getActiveTenant } from 'utils/tenantManager.js';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

export const getLocationById = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'location/' + locationId,
    config
  );
};

export const getAllLocations = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.fieldServiceApiUrl + 'location/', config);
};

export const getLocationTypes = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.fieldServiceApiUrl + 'locationType/', config);
};

export const updateLocation = async (user, locationData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'location/' + locationData.id,
    locationData,
    config
  );
};

export const createLocation = async (user, locationData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'location/',
    locationData,
    config
  );
};

export const getFsLocationBySensightId = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'location/sensightid/' + sensightId,
    config
  );
};

export const saveLocationsOfOrganization = async (
  user,
  organizationId,
  locationIds
) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'organization/' + organizationId,
    locationIds,
    config
  );
};

export const saveOrganizationsOfLocation = async (
  user,
  locationId,
  organizationIds
) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'organization/location/' + locationId,
    organizationIds,
    config
  );
};

export const getLocationOrganizationBySensightId = async (
  user,
  locationSensightId
) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl +
      'organization/location/' +
      locationSensightId,
    config
  );
};

export const getLocationOrganizationByOrganizationSensightId = async (
  user,
  organizationSensightId
) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl +
      'organization/' +
      organizationSensightId +
      '/location',
    config
  );
};

export const getAllDevices = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.fieldServiceApiUrl + 'device/', config);
};

export const getAllDeviceNamesAndIds = async user => {
    const config = createHeaderConfig(user);

    return axios.get(configFile.fieldServiceApiUrl + "device/devicenamesandids", config);
};

export const getAllChannelsByLocationSensightId = async (
  user,
  locationSensightId
) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'channel/location/' + locationSensightId,
    config
  );
};

export const getAvailableSimCards = async (user, deviceId) => {
  const config = createHeaderConfig(user);
  if (!deviceId) {
    return axios.get(
      configFile.fieldServiceApiUrl + 'device/availablesimcards',
      config
    );
  }
  return axios.get(
    configFile.fieldServiceApiUrl + 'device/availablesimcards?id=' + deviceId,
    config
  );
};

export const getBlobs = async (user, entityId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'blob/entityid/' + entityId,
    config
  );
};

export const downloadFile = async (user, entityId) => {
  const config = createHeaderConfig(user);
  config.responseType = 'blob';

  const response = await axios.get(
    configFile.fieldServiceApiUrl + 'blob/download/' + entityId,
    config
  );

  const contentDispositionHeader = response.headers['content-disposition'];
  const contentTypeHeader = response.headers['content-type'];

  const getMetadata = (header, metadata) => {
    var splitString = header.split(metadata + '=');
    return splitString[1].split(';')[0];
  };

  fileDownload(
    response.data,
    getMetadata(contentDispositionHeader, 'filename').replace(/['"]+/g, ''),
    contentTypeHeader
  );
};

export const createFile = async (user, file) => {
  const config = createHeaderConfig(user);

  return axios.post(configFile.fieldServiceApiUrl + 'blob/', file, config);
};

export const deleteBlob = async (user, blobId) => {
  const config = createHeaderConfig(user);

  return axios.delete(configFile.fieldServiceApiUrl + 'blob/' + blobId, config);
};

export const getServiceContacts = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'locationcontact/location/' + locationId,
    config
  );
};

export const saveServiceContacts = async (user, locationId, contactIds) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'locationcontact/location/' + locationId,
    contactIds,
    config
  );
};

export const deleteLocation = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'location/' + locationId,
    config
  );
};

export const createChannel = async (user, channelData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'channel/',
    channelData,
    config
  );
};

export const deleteChannel = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'channel/sensight/' + sensightId,
    config
  );
};

export const editChannel = async (user, channelData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl +
      'channel/sensight/' +
      channelData.sensightId,
    channelData,
    config
  );
};

export const getChannelBySensightId = async (user, sensightId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'channel/sensightid/' + sensightId,
    config
  );
};

export const getLogItemsByLocationSensightId = async (
  user,
  locationSensightId
) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl +
      'logItem/location/sensightid/' +
      locationSensightId,
    config
  );
};

export const getLogItemsByDeviceSensightId = async (
  user,
  deviceSensightId,
  startDate,
  endDate
) => {
  const config = createHeaderConfig(user);
  return axios.get(
    `${
      configFile.fieldServiceApiUrl
    }logItem/device/sensightid/${deviceSensightId}?startDate=${encodeURIComponent(
      startDate.toISOString()
    )}&endDate=${encodeURIComponent(endDate.toISOString())}`,
    config
  );
};

export const createLogItem = async (user, logItemData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'logItem/',
    logItemData,
    config
  );
};

export const deleteLogItem = async (user, logItemId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'logItem/' + logItemId,
    config
  );
};

export const editLogItem = async (user, logItemData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'logItem/' + logItemData.id,
    logItemData,
    config
  );
};

export const createDevice = async (user, deviceData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'device/',
    deviceData,
    config
  );
};

export const deleteDevice = async (user, deviceId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'device/' + deviceId,
    config
  );
};

export const editDevice = async (user, deviceData) => {
  const config = createHeaderConfig(user);
  return axios.put(
    configFile.fieldServiceApiUrl + 'device/' + deviceData.id,
    deviceData,
    config
  );
};

export const getDeviceBySensightId = async (user, sensightDeviceId) => {
  const config = createHeaderConfig(user);
  return axios.get(
    configFile.fieldServiceApiUrl + 'device/sensightId/' + sensightDeviceId,
    config
  );
};

export const getActions = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.fieldServiceApiUrl + 'action/', config);
};

export const createAction = async (user, actionData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'action/',
    actionData,
    config
  );
};

export const deleteAction = async (user, actionId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'action/' + actionId,
    config
  );
};

export const editAction = async (user, actionData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'action/' + actionData.id,
    actionData,
    config
  );
};

export const createSimCard = async (user, simCardData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'simcard/',
    simCardData,
    config
  );
};

export const deleteSimCard = async (user, simCardId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'simcard/' + simCardId,
    config
  );
};

export const editSimCard = async (user, simCardData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'simcard/' + simCardData.id,
    simCardData,
    config
  );
};

export const getAllSimCards = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.fieldServiceApiUrl + 'simcard/', config);
};

export const createLocationType = async (user, locationTypeData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'locationType/',
    locationTypeData,
    config
  );
};

export const getDevicePlacementsByLocationtId = async (user, locationId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.fieldServiceApiUrl + 'device/placements/' + locationId,
    config
  );
};

export const getLocationType = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.fieldServiceApiUrl + 'locationType/', config);
};

export const deleteLocationType = async (user, locationTypeId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'locationType/' + locationTypeId,
    config
  );
};

export const editLocationType = async (user, locationTypeData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'locationType/' + locationTypeData.id,
    locationTypeData,
    config
  );
};

export const createDevicePlacement = async (
  user,
  deviceId,
  devicePlacementData
) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.fieldServiceApiUrl + 'device/' + deviceId + '/placement/',
    devicePlacementData,
    config
  );
};

export const deleteDevicePlacement = async (user, devicePlacementId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.fieldServiceApiUrl + 'device/placement/' + devicePlacementId,
    config
  );
};

export const editDevicePlacement = async (
  user,
  devicePlacementId,
  devicePlacementData
) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.fieldServiceApiUrl + 'device/placement/' + devicePlacementId,
    devicePlacementData,
    config
  );
};

export const getAvailableDevices = async (
  user,
  startDateTime,
  endDateTime,
  currentDeviceId
) => {
  const config = createHeaderConfig(user);

  let querystring = '?startDateTime=' + startDateTime;
  if (endDateTime) {
    querystring += '&endDateTime=' + endDateTime;
  }
  if (currentDeviceId) {
    querystring += '&currentDeviceId=' + currentDeviceId;
  }
  return axios.get(
    configFile.fieldServiceApiUrl + 'device/available' + querystring,
    config
  );
};
