import { take, select, put } from 'redux-saga/effects';
import {
  FETCH_PROFILE_DETAILS,
  setProfileDetails
} from './profileDetailsActions';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { getUserProfile } from 'apis/UserManagementApi';

export function* fetchProfileDetailsSaga() {
  yield take(FETCH_PROFILE_DETAILS);
  const currentUser = yield select(currentUserSelector);
  const userProfile = yield getUserProfile(currentUser);
  yield put(setProfileDetails(userProfile.data));
}
