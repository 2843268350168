import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { clearSnackbar } from 'redux/ui/uiActions';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';

export default function ImdSnackbar() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useImdSnackbarStyles();

  const { snackbarMessage, snackbarVariant, snackbarDuration } = useSelector(
    state => state.get('snackbar')
  ).toJS();

  function handleClose() {}

  useEffect(() => {
    const message = snackbarMessage;
    if (message !== null) {
      dispatch(clearSnackbar());
      enqueueSnackbar(message, {
        variant: snackbarVariant,
        autoHideDuration: snackbarDuration
      });
    }
  }, [snackbarMessage]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={false}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.success}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <CheckCircleIcon className={classes.icon} />
            {snackbarMessage}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            size="large">
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

const useImdSnackbarStyles = makeStyles(theme => ({
  success: { backgroundColor: '#007962' },
  error: { backgroundColor: '#d32f2f' },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));
