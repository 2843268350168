import { createSelector } from 'reselect';

export const devicesSelector = createSelector(
  state => state.get('devicesList'),
  devicesList => devicesList.toJS()
);

export const availableSimCardsSelector = createSelector(
  state => state.get('availableSimCardsList'),
  availableSimCardsList => availableSimCardsList.toJS()
);

export const deviceSelector = createSelector(
  state => state.get('device'),
  device => device.toJS()
);

export const visibleSwitchValueSelector = createSelector(
  state => state.get('deviceVisibleSwitchValue'),
  deviceVisibleSwitchValue => deviceVisibleSwitchValue
);

export const onlineSwitchValueSelector = createSelector(
  state => state.get('deviceOnlineSwitchValue'),
  deviceOnlineSwitchValue => deviceOnlineSwitchValue
);

export const shouldDeleteFunctionalDeviceValueSelector = createSelector(
  state => state.get('shouldDeleteFunctionalDevice'),
  shouldDeleteFunctionalDevice => shouldDeleteFunctionalDevice
);
