import { connect } from 'react-redux';
import ManageThemeDisplay from './ManageThemeDisplay.jsx';
import { themeSelector } from '../redux/themesSelectors.js';
import { editTheme } from '../redux/themesActions.js';
import { setSaveButtonDisabled } from 'components/imd-components/imddialog/redux/imdDialogActions';

const mapStateToProps = state => {
  var viewModel = themeSelector(state);
  return {
    viewModel: viewModel
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveButtonDisabled(value) {
    dispatch(setSaveButtonDisabled(value));
  },
  handleSave(sensightId) {
    dispatch(editTheme(sensightId));
  },
  upsertThemeDetails(themeDetails) {
    dispatch(editTheme(themeDetails));
  }
});

export const EditThemeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageThemeDisplay);
