import React, { useEffect } from 'react';
import { Page } from './components/page/Page';
import { Route } from 'react-router';
import { MyDashboardContainer } from './components/content/dashboard/MyDashboardContainer';
import { ContactsContainer } from './components/content/contacts/ContactsContainer';
import Organizations from './components/content/organizations/Organizations';
import LocationTypes from './components/content/locationtypes/LocationTypes';
import './App.css';
import { AlertsContainer } from './components/content/alerts/listalerts/AlertsContainer';
import { MyAlertsContainer } from './components/content/alerts/listalerts/MyAlertsContainer';
import { useAuth0 } from './react-auth0-spa';
import Loading from './components/Loading';
import { Logout } from './components/Logout';
import { ProfileContainer } from './components/content/profile/ProfileContainer';
import { ManageDashboard } from './components/content/dashboard/manageDashboard/ManageDashboard.jsx';
import { ManageAllDashboards } from './components/content/dashboard/manageDashboard/ManageAllDashboards.jsx';
import { VisualizeDashboardContainer } from './components/content/visualize/VisualizeDashboardContainer';
import { LocationsContainer } from 'components/content/locations/LocationsContainer';
import { ExportContainer } from 'components/content/export/ExportContainer';
import { LocationContainer } from './components/content/location/LocationContainer';
import { DevicesContainer } from 'components/content/devices/listdevices/DevicesContainer';
import { SimCardsContainer } from 'components/content/simcards/listsimcards/SimCardsContainer';
import { LogbookActionsContainer } from 'components/content/logbookactions/listlogbookactions/LogbookActionsContainer';
import ImdSnackbar from './components/imd-components/ImdSnackbar';
import { locale, loadMessages } from 'devextreme/localization';
import nlMessages from 'devextreme/localization/messages/nl.json';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import { getCurrentUserRole } from 'utils/currentUserReader';
import { Can } from 'components/imd-components/authorization/Can';
import ExportCharts from 'components/content/exportcharts/ExportCharts';
import { TenantsContainer } from 'components/content/tenants/listtenants/TenantsContainer';
import { ThemesTableContainer } from 'components/content/tenants/listthemes/ThemesTableContainer';
import { getActiveTheme } from 'utils/themeManager.js';
import { useLocation } from 'react-router-dom';
import IMDTheme from 'themes/IMDTheme';
import { setTenantInSearchParamsIfNotAlready } from 'utils/tenantManager.js';
import {
  createTheme,
  StyledEngineProvider,
  adaptV4Theme,
  ThemeProvider
} from '@mui/material/styles';
import { useUpdateEffect } from 'react-use';

export default function App() {
  const { loading, isAuthenticated, loginWithRedirect, user, namespace } =
    useAuth0();
  const { i18n } = useTranslation();

  const userRole = getCurrentUserRole(user);
  const location = useLocation();

  let language = i18n.language;
  if (language === undefined) {
    language = 'nl';
  }

  useEffect(() => {
    loadMessages(nlMessages);
    locale(language);
  }, [i18n.language]);

  useUpdateEffect(() => {
    setTenantInSearchParamsIfNotAlready();
  }, [location]);

  const redirectToLoginPage = () => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname + window.location.search }
    });

    return <Loading />;
  };

  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return redirectToLoginPage();
  }

  // Get Tenant information
  var tenantsNamespaceData = user[namespace + 'tenants'];
  if (!tenantsNamespaceData || tenantsNamespaceData.length == 0) {
    return redirectToLoginPage();
  }
  let themeJson = getActiveTheme();
  let theme =
    themeJson && themeJson !== 'null' && !(JSON.stringify(themeJson) === '{}')
      ? createTheme(adaptV4Theme(themeJson))
      : IMDTheme;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          preventDuplicate={false}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ImdSnackbar />
          <Page>
            <Route exact path="/" component={MyDashboardContainer} />
            <Route
              exact
              path="/dashboard/:id"
              component={MyDashboardContainer}
            />
            <Route
              exact
              path="/dashboard/:id/edit"
              render={props => (
                <MyDashboardContainer {...props} editMode={true} />
              )}
            />
            <Route exact path="/manage/dashboard" component={ManageDashboard} />
            <Route exact path="/alerts" component={MyAlertsContainer} />
            <Route exact path="/export" component={ExportContainer} />
            <Route exact path="/profile" component={ProfileContainer} />
            <Route
              exact
              path="/visualize/:link?"
              component={VisualizeDashboardContainer}
            />
            <Route
              exact
              path="/management/locations/:sensightId/:navigationItem?"
              component={LocationContainer}
            />
            <Can
              role={userRole}
              perform="route:management"
              yes={() => (
                <>
                  <Route
                    exact
                    path="/management/locations"
                    component={LocationsContainer}
                  />
                  <Route
                    exact
                    path="/management/devices"
                    component={DevicesContainer}
                  />
                  <Route
                    exact
                    path="/management/alerts"
                    component={AlertsContainer}
                  />
                  <Route
                    exact
                    path="/management/contacts"
                    component={ContactsContainer}
                  />
                  <Route
                    exact
                    path="/management/export"
                    component={ExportCharts}
                  />
                </>
              )}
              no={() => null}
            />
            <Can
              role={userRole}
              perform="route:manager"
              yes={() => (
                <>
                  <Route
                    exact
                    path="/manager/simcards"
                    component={SimCardsContainer}
                  />
                  <Route
                    exact
                    path="/manager/logbookactions"
                    component={LogbookActionsContainer}
                  />
                  <Route
                    exact
                    path="/manager/organizations"
                    component={Organizations}
                  />
                  <Route
                    exact
                    path="/manager/dashboards"
                    component={ManageAllDashboards}
                  />
                  <Route
                    exact
                    path="/manager/locationtypes"
                    component={LocationTypes}
                  />
                </>
              )}
              no={() => null}
            />
            <Can
              role={userRole}
              perform="route:platformadministrator"
              yes={() => (
                <>
                  <Route
                    exact
                    path="/manager/tenants"
                    component={TenantsContainer}
                  />
                  <Route
                    exact
                    path="/manager/themes"
                    component={ThemesTableContainer}
                  />
                </>
              )}
              no={() => null}
            />
            <Route exact path="/logout" component={Logout} />
          </Page>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
