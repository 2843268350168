import React from 'react';
import { useTranslation } from 'react-i18next';
import ImdButton from 'components/imd-components/ImdButton';
import {
  Typography,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

export default function DeleteDevicePopup({
  open,
  title,
  setShouldDeleteFunctionalDevice,
  shouldDeleteFunctionalDeviceValue,
  handleOk,
  handleClose
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog maxWidth="xs" open={open} onClose={() => handleClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <div className={clsx(classes.switchWrapper)}>
          <Typography variant="body1" className={classes.switchLabel}>
            {t('content.devicestable.deletepopup.technical')}
          </Typography>
          <div className={classes.switchItem}>
            <Switch
              checked={true}
              value="online"
              color="primary"
              disabled={true}
            />
          </div>
        </div>
        <div className={clsx(classes.switchWrapper, classes.firstWrapper)}>
          <Typography variant="body1" className={classes.switchLabel}>
            {t('content.devicestable.deletepopup.functional')}
          </Typography>
          <div className={classes.switchItem}>
            <Switch
              checked={shouldDeleteFunctionalDeviceValue}
              onChange={() =>
                setShouldDeleteFunctionalDevice(
                  !shouldDeleteFunctionalDeviceValue
                )
              }
              value="online"
              color="primary"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <ImdButton
          autoFocus
          onClick={() => handleClose()}
          variant="contained"
          color="inherit"
        >
          {t('general.cancel')}
        </ImdButton>
        <ImdButton
          onClick={() => handleOk(shouldDeleteFunctionalDeviceValue)}
          variant="contained"
          color="primary"
        >
          {t('general.ok')}
        </ImdButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  firstWrapper: {
    marginBottom: '8px'
  },
  switchWrapper: {
    display: 'flex'
  },
  switchLabel: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  switchItem: {
    marginLeft: 'auto'
  }
}));
