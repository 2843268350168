import React, { useRef, useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { isEmptyObject, generateShadesOfColor } from 'utils/helperMethods';
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  ValueAxis,
  SeriesTemplate,
  Pane,
  Crosshair,
  Label,
  Grid,
  Size,
  LoadingIndicator,
  DataPrepareSettings,
  ConstantLine,
  ZoomAndPan
} from 'devextreme-react/chart';
import { useUpdateEffect, useMount } from 'react-use';

import { formatDate } from 'devextreme/localization';

import { LogItemViewerContainer } from './LogItemViewerContainer';
import GraphButtonsBar from './GraphButtonsBar.jsx';

const propsAreEqual = (prevProps, nextProps) => {
  if (prevProps.measurements.length !== nextProps.measurements.length) {
    console.dev(
      `Measurements from ${nextProps.renderKey} changed (measurements count)! Triggering rerender`
    );
    return false;
  } else if (prevProps.measurements.length === nextProps.measurements.length) {
    // Als de measurements niet zijn gewijzigd maar de datumrange wel (zoals bij geen data) alsnog rerenderen
    if (prevProps.visualizeFromTime !== nextProps.visualizeFromTime) {
      console.dev(
        `VisualizeFromTime from ${nextProps.renderKey} changed! Triggering rerender`
      );
      return false;
    } else if (prevProps.visualizeUntilTime !== nextProps.visualizeUntilTime) {
      console.dev(
        `visualizeUntilTime from ${nextProps.renderKey} changed! Triggering rerender`
      );
      return false;
    }
  }

  if (prevProps.dataSources.length !== nextProps.dataSources.length) {
    console.dev(
      `Datasources from ${nextProps.renderKey} changed! Triggering rerender`
    );
    return false;
  } else if (prevProps.renderKey !== nextProps.renderKey) {
    console.dev(
      `Renderkey from ${nextProps.renderKey} changed! Triggering rerender`
    );
    return false;
  } else {
    prevProps.dataSources.sort();
    nextProps.dataSources.sort();
    for (let index = 0; index < prevProps.dataSources.length; index++) {
      const prevDataSource = prevProps.dataSources[index];
      const nextDataSource = nextProps.dataSources[index];

      if (prevDataSource.color !== nextDataSource.color) {
        console.dev(
          `Color from one of the datasources from ${nextProps.renderKey} changed! Triggering rerender`
        );
        return false;
      } else if (
        prevDataSource.aggregationType !== nextDataSource.aggregationType
      ) {
        console.dev(
          `Aggregationtype from one of the datasources from ${nextProps.renderKey} changed! Triggering rerender`
        );
        return false;
      } else if (
        prevDataSource.chartTypeName !== nextDataSource.chartTypeName
      ) {
        console.dev(
          `Charttype from one of the datasources from ${nextProps.renderKey} changed! Triggering rerender`
        );
        return false;
      }
    }
  }

  // Als de measurements toch een wijziging hebben in datum
  if (
    prevProps.measurements.length > 0 &&
    prevProps.measurements[0].dateTime !== nextProps.measurements[0].dateTime
  ) {
    console.dev(
      `Measurements ${nextProps.renderKey} in renderChartDisplay changed (datetime of first measurement)! Triggering rerender`
    );
    return false;
  }

  return true;
};

export default React.memo(function RenderChartDisplay({
  fetchDashboardItemData,
  item,
  visualize,
  locations,
  dataSources,
  measurements,
  chartDisplayOptions,
  renderKey,
  setLogItemViewModel,
  logItems
}) {
  const classes = useStyles();
  const dataSourcesInfo = [];
  const [logItemMode, setLogItemMode] = useState(false);
  const [customRenderKey, setCustomRenderKey] = useState(renderKey);

  useMount(() => console.dev(`Chart ${renderKey} is mounted.`));

  console.dev(`Rendering chart with the following options:`, item);

  useUpdateEffect(() => {
    console.dev(`renderkey changed: changing customrenderkey to`, renderKey);
    setCustomRenderKey(renderKey);
  }, [renderKey]);

  useUpdateEffect(() => {
    console.dev(
      `User ${
        logItemMode ? 'enabled' : 'disabled'
      } option to create logbook items from a graph.`
    );

    // Verander de renderkey om de chart geforceerd opnieuw te renderen i.v.m. DevExtreme limitaties op de chart events
    setCustomRenderKey(`${renderKey}&_logitem${logItemMode}`);
  }, [logItemMode]);

  let dataSourcesExpanded = dataSources.reduce((result, dataSource) => {
    // Datasource voor huidige locatie
    result.push({ ...dataSource, originalId: dataSource.id });

    // Datasource met unieke naam / kleur voor iedere locatie (deviceplacement)
    if (
      dataSource.device &&
      dataSource.device.devicePlacements &&
      dataSource.device.devicePlacements.length > 0
    ) {
      dataSource.device.devicePlacements.forEach(devicePlacement => {
        result.push({
          ...dataSource,
          originalId: dataSource.id,
          id: `${devicePlacement.location.id}-${dataSource.id}`,
          name: `${devicePlacement.location.name} ${dataSource.name}`,
          locationId: devicePlacement.location.id,
          startDateTime: new Date(devicePlacement.startDateTime),
          endDateTime: devicePlacement.endDateTime
            ? new Date(devicePlacement.endDateTime)
            : null
        });
      });
    }
    dataSourcesInfo.push({
      id: dataSource.id,
      originalColor: dataSource.color
    });
    return result;
  }, []);

  // Measurements uitbreiden met extra data apparaat in meerdere kanalen
  measurements = measurements.map(enrichMeasurement);

  // Datasources zonder data van specifieke deviceplacement in specifieke tijd verbergen
  dataSourcesExpanded = dataSourcesExpanded.filter(expandedDataSource => {
    return measurements.find(measurement => {
      return measurement.seriesKey === expandedDataSource.id;
    });
  });

  dataSourcesInfo.forEach(dataSourceInfo => {
    const dataSources = dataSourcesExpanded.filter(
      ds => ds.originalId === dataSourceInfo.id
    );
    const colors = generateShadesOfColor(
      dataSourceInfo.originalColor,
      dataSources.length,
      true
    );

    dataSources.forEach(
      (dataSource, index) => (dataSource.color = colors[index])
    );
  });

  const renderMultipleCharts = chartDisplayOptions.renderMultipleCharts;
  const paneHeight = !renderMultipleCharts
    ? window.innerHeight * 0.4 // Single chart
    : window.innerHeight * 0.8; // Multiple charts in one panel

  const chartRef = useRef();

  function customizeText(obj) {
    let date = null;
    if (Number.isInteger(obj.value)) {
      date = new Date(obj.value);
    } else {
      date = obj.value;
    }

    return formatDate(date, 'shortDateShortTime');
  }

  function enrichMeasurement(measurement) {
    const datasource = dataSourcesExpanded.find(
      d =>
        d.channelId === measurement.channelId &&
        d.startDateTime <= measurement.dateTime &&
        (d.endDateTime == null || d.endDateTime >= measurement.dateTime)
    );
    if (datasource) {
      measurement.seriesKey = datasource.id;
    } else {
      // Apparaat was tijdens meting niet gekoppeld aan een locatie
      measurement.seriesKey = measurement.channelId;
    }
    return measurement;
  }

  function customizeTooltip(info) {
    let text = `<b>${info.valueText} ${info.seriesName} ${
      info.point.data.reliabilityPercentage
        ? `${info.point.data.reliabilityPercentage}%`
        : ``
    }</b>\r\n`;

    if (info.point.data.reliabilityReason) {
      text += `${info.point.data.reliabilityReason.name}\r\n`;
    }

    return {
      text: `${text}\r\n${info.argument.toLocaleString()}`
    };
  }

  function renderPanes(dataSources) {
    return dataSources.map(function (item) {
      return <Pane key={item.id} name={item.name} />;
    });
  }

  function drawUpperLimitAndLowerLimit(upperLimit, lowerLimit) {
    if (upperLimit === 0 && lowerLimit === 0) {
      return [];
    }

    const upperLimitLine = {
      label: {
        text: 'Upper limit'
      },
      width: 2,
      value: upperLimit,
      color: '#ff7c7c',
      dashStyle: 'dash'
    };

    const lowerLimitLine = {
      label: {
        text: 'Lower limit'
      },
      width: 2,
      value: lowerLimit,
      color: '#8c8cff',
      dashStyle: 'dash'
    };

    let lines = [];

    if (upperLimitLine) {
      lines.push(upperLimitLine);
    }
    if (lowerLimitLine) {
      lines.push(lowerLimitLine);
    }

    return lines;
  }

  function checkShouldRenderLimitLines(dataSources) {
    if (!chartDisplayOptions.renderLimitLines) {
      return false;
    } else if (renderMultipleCharts) {
      return true;
    }

    return (
      dataSources.filter(d => d.upperLimit !== 0 || d.lowerLimit !== 0)
        .length === 1
    );
  }

  function drawVisualRange(min, max) {
    return {
      startValue: min,
      endValue: max
    };
  }

  function renderValueYAxis(dataSources) {
    const shouldRenderLimitLines = checkShouldRenderLimitLines(dataSources);

    return dataSources.map(item => {
      let props = {
        pane: renderMultipleCharts ? item.name : undefined,
        name: item.id,
        title: {
          text: `${item.displayLabel ? item.displayLabel : ``}${
            item.unit
              ? `${renderMultipleCharts ? `\r\n` : ` - `}[${item.unit}]`
              : ``
          }`,
          font: {
            size: 14
          }
        },
        color: item.color
      };

      if (
        chartDisplayOptions.fixedYAxis &&
        (item.min !== 0 || item.max !== 0)
      ) {
        props.visualRange = drawVisualRange(item.min, item.max);
      } else {
        props.visualRange = [null, null];
      }

      if (
        shouldRenderLimitLines &&
        (item.upperLimit !== 0 || item.lowerLimit !== 0)
      ) {
        props.constantLines = drawUpperLimitAndLowerLimit(
          item.upperLimit,
          item.lowerLimit
        );
      }

      return <ValueAxis key={item.id} {...props} />;
    });
  }

  const customizeSeries = valueFromNameField => {
    const match = dataSourcesExpanded.find(d => d.id === valueFromNameField);
    if (match) {
      let result = {
        type: getChartTypeName(match.chartTypeName),
        name: match.name,
        color: match.color,
        axis: match.id,
        valueField: match.value,
        hoverMode: logItemMode ? 'none' : 'onlyPoint',
        selectionMode: 'onlyPoint', //allArgumentPoints', //Accepted Values: 'allArgumentPoints' | 'allSeriesPoints' | 'excludePoints' | 'includePoints' | 'none' | 'onlyPoint'
        point: {
          visible: logItemMode || match.chartTypeName === 'scatter',
          size: 9
        }
      };
      console.dev(`Chart series (re)loaded:`, result);
      if (renderMultipleCharts) {
        result.pane = match.name;
      }
      return result;
    }

    return {};
  };

  function getChartTypeName(chartTypeName) {
    if (chartTypeName === 'kpi') {
      return 'line';
    }
    return chartTypeName;
  }

  function refreshVisualRange() {
    if (chartRef.current && !chartDisplayOptions.fixedYAxis) {
      chartRef.current.instance.resetVisualRange();
    }
  }

  const onLogItemModalClosed = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.instance.clearSelection();
    }
  }, [chartRef]);

  const onSwitched = useCallback(
    val => {
      setLogItemMode(val);
    },
    [chartRef]
  );

  const onPointSelectionChanged = useCallback(
    ({ target: point }) => {
      // Kijk of het punt niet is gedeselecteerd ipv geselecteerd.
      if (point.isSelected()) {
        const newDateTime = point.data.dateTime;
        const newChannelId = point.data.channelId;
        const dataSource = dataSourcesExpanded.find(
          ds => ds.id === point.data.seriesKey
        );
        const newLogItemViewModel = {
          customKey: renderKey,
          name: dataSource.name,
          value: point.value,
          channelId: newChannelId,
          channelSensightId: dataSource.sensightId,
          startDateTime: newDateTime,
          deviceId: dataSource.deviceId
        };

        if (!isEmptyObject(newLogItemViewModel)) {
          setLogItemViewModel(newLogItemViewModel);
        }
      }
    },
    [dataSourcesExpanded]
  );

  const onPointClick = useCallback(({ target: point }) => {
    console.dev(`Point clicked`, point);
    point.select();
  });

  refreshVisualRange();

  return (
    <>
      <div className={classes.graphBar}>
        {!visualize && (
          <GraphButtonsBar
            item={item}
            locations={locations}
            fetchDashboardItemData={fetchDashboardItemData}
          />
        )}
        <LogItemViewerContainer
          customKey={renderKey}
          measurementsCount={measurements.length}
          onSwitched={onSwitched}
          onClosed={onLogItemModalClosed}
        />
      </div>
      <div className={classes.graph}>
        <Chart
          key={customRenderKey}
          ref={chartRef}
          dataSource={measurements}
          onPointSelectionChanged={onPointSelectionChanged}
          onPointClick={onPointClick}
        >
          <Size height={paneHeight} />
          <LoadingIndicator enabled={false} />
          <DataPrepareSettings
            convertToAxisDataType={false}
            sortingMethod={false}
            checkTypeForAllData={false}
          />
          <CommonSeriesSettings argumentField={'dateTime'} />
          <ArgumentAxis
            rotated={true}
            minorTick={{ visible: true }}
            endOnTick={false}
            label={{
              customizeText: customizeText,
              alignment: 'right',
              displayMode: 'rotate',
              rotationAngle: 340
            }}
          >
            {chartDisplayOptions.renderLogItems
              ? logItems.map(logItem => {
                  let text = '';
                  if (logItem.channel) {
                    text = logItem.channel.displayLabel + ' - ';
                  }
                  text += logItem.action.name;
                  return (
                    <ConstantLine
                      key={logItem.id}
                      width={2}
                      value={new Date(logItem.startDateTime)}
                      color="black"
                      dashStyle="dash"
                      paddingLeftRight={1}
                    >
                      <Label text={`<p class="vertical-rl">${text}</p>`} />
                    </ConstantLine>
                  );
                })
              : null}
            <Grid visible={chartDisplayOptions.renderGrid} />
          </ArgumentAxis>
          {
            <Crosshair
              enabled={true}
              color="#949494"
              width={3}
              dashStyle="dot"
            ></Crosshair>
          }
          {renderMultipleCharts ? renderPanes(dataSourcesExpanded) : <Pane />}
          <SeriesTemplate
            nameField={'seriesKey'}
            customizeSeries={customizeSeries}
          />
          <Margin bottom={4} />
          {renderValueYAxis(dataSourcesExpanded)}
          <Legend
            verticalAlignment={'top'}
            horizontalAlignment={'center'}
            itemTextPosition={'bottom'}
          />
          <Export enabled={true} />
          <Tooltip
            arrowLength={30}
            enabled={true}
            border={{ color: '#000000', visible: false }}
            color="#007962"
            font={{ color: '#ffffff' }}
            containerBackgroundColor="#007962"
            customizeTooltip={customizeTooltip}
          />
          <ZoomAndPan
            argumentAxis="both"
            dragToZoom={true}
            allowMouseWheel={true}
            panKey="shift"
          />
        </Chart>
      </div>
    </>
  );
},
propsAreEqual);

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: '16px'
  },
  graph: {
    marginTop: '4px',
    marginBottom: '0px'
  },
  graphBar: {
    display: 'flex',
    [theme.breakpoints.down('xl')]: {
      display: 'flex'
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex'
    }
  }
}));
