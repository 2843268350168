import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReactMarkdown from 'react-markdown';
import { useMount } from 'react-use';
import { parseJSON } from 'date-fns';
import add from 'date-fns/add';

import ImdLoader from 'components/imd-components/ImdLoader';
import { calculateDateOffset } from 'components/content/visualize/VisualizePeriods';
import { RenderChartContainer } from 'components/content/visualize/renderChartComponent/renderChartContainer';
import { KpiContainer } from 'components/imd-components/kpi/KpiContainer';
import { DataScienceContainer } from '../dataScience/DataScienceContainer';
import { LogItemsDataTableContainer } from 'components/content/location/logbook/LogItemsDataTableContainer';
import { useInterval } from './useInterval';
import { visualizationTypes } from 'utils/helperMethods';

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.item?.visualizationSettings.selectionData?.visualizeFromTime !==
    nextProps.item?.visualizationSettings.selectionData?.visualizeFromTime
  ) {
    console.dev(
      `VisualizefromTime from ${nextProps.renderKey} changed! Triggering rerender`
    );
    return false;
  } else if (
    prevProps.item?.visualizationSettings.selectionData?.visualizePeriod !==
    nextProps.item?.visualizationSettings.selectionData?.visualizePeriod
  ) {
    console.dev(
      `Visualizeperiod from ${nextProps.renderKey} changed! Triggering rerender`
    );
    return false;
  } else if (
    prevProps.item?.visualizationSettings.selectionData?.visualizeUntilTime !==
    nextProps.item?.visualizationSettings.selectionData?.visualizeUntilTime
  ) {
    console.dev(
      `visualizeUntilTime from ${nextProps.renderKey} changed! Triggering rerender`
    );
    return false;
  }

  // Kijken of een van de groepen measurements is veranderd
  for (const [measurementGroupName, measurementGroupValues] of Object.entries(
    nextProps.measurements
  )) {
    // Als de measurements in de oude groep niet bestaan of als de lengte niet gelijk is rerenderen
    if (
      prevProps.measurements[measurementGroupName]?.length !==
      measurementGroupValues.length
    ) {
      console.dev(
        `Measurements from ${nextProps.renderKey} group ${measurementGroupName} changed (measurements count)! Triggering rerender`
      );
      return false;
    }

    // Als de measurements toch een wijziging hebben in datum
    if (
      prevProps.measurements[measurementGroupName].length > 0 &&
      prevProps.measurements[measurementGroupName][0].dateTime !==
        measurementGroupValues[0].dateTime
    ) {
      console.dev(
        `Measurements from ${nextProps.renderKey} group ${measurementGroupName} changed (datetime of first measurement)! Triggering rerender`
      );
      return false;
    }
  }

  return true;
};

export default React.memo(function DashboardDisplay({
  measurements,
  item,
  setChartDisplayOptions,
  fetchDashboardItemData,
  dashboard,
  renderKey,
  locations
}) {
  React.useEffect(() => {
    if (item.visualizationType === visualizationTypes.chart) {
      setChartDisplayOptions(item.visualizationSettings.displayOptions);
    }
  }, [item]);

  useMount(() => console.dev(`Dashboarditem ${renderKey} is mounted.`));
  const classes = useImdCardStyles();
  const dataScienceRef = useRef();
  const minuteInMilliseconds = 60000;
  let refreshTimeInMilliseconds =
    minuteInMilliseconds * dashboard.autoRefreshInMinutes;

  // To debug the autofresh, it is possible to set the amount of milliseconds in the developer console.
  if (
    localStorage.getItem('autofreshInMilliSeconds') !== 'null' &&
    localStorage.getItem('autofreshInMilliSeconds') !== null
  ) {
    refreshTimeInMilliseconds = localStorage.getItem('autofreshInMilliSeconds');
  }

  React.useEffect(() => {
    console.dev('Fetching dashboard item data', item);

    fetchDashboardItemData(item);
  }, []);

  const useIntervalIfPossible = callbackRefreshFn => {
    if (!dashboard?.autoRefreshInMinutes) {
      return;
    }

    useInterval(() => {
      console.dev(
        `Refreshing data ("${item.title}" - ${
          visualizationTypes[item.visualizationType]
        }).. Refreshing again in ${refreshTimeInMilliseconds / 1000} seconds)`
      );
      callbackRefreshFn();
    }, refreshTimeInMilliseconds);
  };

  const updateVisualizeTime = item => {
    item.visualizationSettings.selectionData.visualizeFromTime =
      addMinutesToVisualizeTime(
        item.visualizationSettings.selectionData.visualizeFromTime,
        dashboard.autoRefreshInMinutes
      );
    item.visualizationSettings.selectionData.visualizeUntilTime =
      addMinutesToVisualizeTime(
        item.visualizationSettings.selectionData.visualizeUntilTime,
        dashboard.autoRefreshInMinutes
      );
    return item;
  };

  const addMinutesToVisualizeTime = (visualizeTime, minutes) => {
    return add(parseJSON(visualizeTime), {
      minutes: minutes
    });
  };

  const refreshDataUsingFetch = () => {
    item = updateVisualizeTime(item);
    fetchDashboardItemData(item);
  };

  switch (item.visualizationType) {
    case visualizationTypes.chart: {
      if (!measurements) {
        return <ImdLoader height={'75vh'} />;
      }

      useIntervalIfPossible(refreshDataUsingFetch);

      let unpackedMeasurements = [];

      Object.keys(measurements).forEach(key => {
        unpackedMeasurements = unpackedMeasurements.concat(measurements[key]);
      });
      return (
        <RenderChartContainer
          item={item}
          locations={locations}
          dataSources={item.visualizationSettings.selectedChannels}
          measurements={unpackedMeasurements}
          renderKey={renderKey}
          visualizeFromTime={parseJSON(
            item.visualizationSettings.selectionData.visualizeFromTime
          )}
          visualizeUntilTime={parseJSON(
            item.visualizationSettings.selectionData.visualizeUntilTime
          )}
          chartDisplayOptions={item.visualizationSettings.displayOptions}
        />
      );
    }

    case visualizationTypes.kpi: {
      if (!measurements) {
        return <ImdLoader height={'65px'} />;
      }

      useIntervalIfPossible(refreshDataUsingFetch);

      return (
        <KpiContainer
          item={item}
          locations={locations}
          dataSources={item.visualizationSettings.selectedChannels}
          measurements={
            measurements[item.visualizationSettings.selectedChannels[0].id]
          }
          visualizeFromTime={
            new Date() -
            calculateDateOffset(
              item.visualizationSettings.selectionData.visualizePeriod
            )
          }
          visualizeUntilTime={new Date()}
          renderKey={renderKey}
          defaultOpen={false}
          chartDisplayOptions={item.visualizationSettings.displayOptions}
        />
      );
    }

    case visualizationTypes.markdown: {
      return (
        <div className={classes.markdownContainer}>
          <ReactMarkdown>{item.visualizationSettings}</ReactMarkdown>
        </div>
      );
    }

    case visualizationTypes.image: {
      return (
        <a
          href={
            item.visualizationSettings.externalLink
              ? item.visualizationSettings.externalLink
              : undefined
          }
          target={'_blank noopener noreferrer'}
        >
          <img
            className={classes.imgContainer}
            src={
              typeof item.visualizationSettings === 'string'
                ? item.visualizationSettings
                : item.visualizationSettings.link
            }
          />
        </a>
      );
    }

    case visualizationTypes.dataScience: {
      useIntervalIfPossible(() => {
        updateVisualizeTime(item);
        dataScienceRef.current.refresh();
      });

      return (
        <DataScienceContainer
          ref={dataScienceRef}
          report={item.visualizationSettings}
          item={item}
          locations={locations}
        />
      );
    }

    case visualizationTypes.logbookItems: {
      return (
        <LogItemsDataTableContainer
          locationSensightId={item.visualizationSettings.sensightId}
          measurementLocationId={item.visualizationSettings.measurementId}
          compactColumns={true}
        ></LogItemsDataTableContainer>
      );
    }
    default: {
      return null;
    }
  }
},
propsAreEqual);

const useImdCardStyles = makeStyles(() => ({
  imgContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  },
  markdownContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  }
}));
