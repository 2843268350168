import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../../page/Content';
import { TenantsTableContainer } from './TenantsTableContainer';
import { PropTypes } from 'prop-types';

export default function TenantsDisplay({ tenants, fetchTenants }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchTenants();
  }, []);

  const content = <TenantsTableContainer tenants={tenants} />;
  return (
    <Content
      title={t('content.tenants.title')}
      secondTitle={t('navbar.manager')}
      content={content}
    />
  );
}

TenantsDisplay.propTypes = {
  tenants: PropTypes.array,
  fetchTenants: PropTypes.func
};
