import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../../../page/Content';
import { LogbookActionsTableContainer } from './LogbookActionsTableContainer';
import { PropTypes } from 'prop-types';

export default function LogbookActionsDisplay({
  logbookActions,
  fetchLogbookActions
}) {
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchLogbookActions();
  }, []);

  const content = (
    <LogbookActionsTableContainer logbookActions={logbookActions} />
  );
  return (
    <Content
      title={t('content.logbookactions.title')}
      secondTitle={t('navbar.manager')}
      content={content}
    />
  );
}

LogbookActionsDisplay.propTypes = {
  logbookActions: PropTypes.array,
  fetchLogbookActions: PropTypes.func
};
