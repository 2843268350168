import { createReducer } from 'utils/createReducer';
import { fromJS } from 'immutable';
import {
  SET_BLOBS,
  DELETE_BLOB,
  CREATE_BLOB_SUCCEEDED
} from './fileUploadActions';

export const blobs = createReducer(
  {},
  {
    [SET_BLOBS](_state, { blobs }) {
      return fromJS(blobs);
    },
    [CREATE_BLOB_SUCCEEDED](state, { blob }) {
      return state.push(fromJS(blob));
    },
    [DELETE_BLOB](_state, { blobId }) {
      return _state.filter(blob => blob.get('id') !== blobId);
    }
  }
);
