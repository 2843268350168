import { connect } from 'react-redux';
import { ChangeProfileDetailsDisplay } from './ChangeProfileDetailsDisplay.jsx';
import { updateProfileDetails } from './redux/updateProfileDetailsActions';
import { t } from 'i18next';
import { profileDetailsSelector } from '../redux/profileDetailsSelectors';

const mapStateToProps = state => {
  let profileDetails = profileDetailsSelector(state);

  return {
    profileDetails: profileDetails
  };
};

const mapDispatchToProps = dispatch => ({
  updateProfileDetails(profileDetails) {
    dispatch(updateProfileDetails(profileDetails));
  },
  handleValidate(values) {
    return handleValidate(values);
  }
});

const handleValidate = values => {
  let errors = {};
  if (!values.email) {
    errors.email = t('general.validation.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = t(
      'content.profile.changeprofiledetails.card.input.email.error'
    );
  }
  return errors;
};

export const ChangeProfileDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeProfileDetailsDisplay);
