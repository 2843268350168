import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ImdDialogContainer } from 'components/imd-components/imddialog/ImdDialogContainer';
import oneWidthImage from '../img/width-1.png';
import twoWidthImage from '../img/width-2.png';
import threeWidthImage from '../img/width-3.png';
import fourWidthImage from '../img/width-4.png';
import sixWidthImage from '../img/width-6.png';
import twelveWidthImage from '../img/width-12.png';
import { useTranslation } from 'react-i18next';

export function SectionDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ImdDialogContainer
      open={props.open}
      maxWidth="md"
      dialogTitle={t('content.dashboard.dialogs.sectiondialog.title')}
      resizeToContent={true}
      handleCloseAction={() => props.setOpen(false)}
      handleSaveAction={props.handleSave}
      content={
        <>
          <List>
            <ListItem
              key="liOneColumn"
              className={classes.button}
              selected={props.selectedColumnSetting === 1}
              onClick={() => props.setSelectedColumnSetting(1)}
            >
              <ListItemText
                primary={t('content.dashboard.dialogs.sectiondialog.onecolumn')}
              />
              <img
                alt={'Image'}
                src={oneWidthImage}
                className={classes.image}
              />
            </ListItem>
            <ListItem
              key="liTwoColumns"
              className={classes.button}
              selected={props.selectedColumnSetting === 2}
              onClick={() => props.setSelectedColumnSetting(2)}
            >
              <ListItemText
                primary={t(
                  'content.dashboard.dialogs.sectiondialog.twocolumns'
                )}
              />
              <img
                alt={'Image'}
                src={twoWidthImage}
                className={classes.image}
              />
            </ListItem>
            <ListItem
              key="liThreeColumns"
              className={classes.button}
              selected={props.selectedColumnSetting === 3}
              onClick={() => props.setSelectedColumnSetting(3)}
            >
              <ListItemText
                primary={t(
                  'content.dashboard.dialogs.sectiondialog.threecolumns'
                )}
              />
              <img
                alt={'Image'}
                src={threeWidthImage}
                className={classes.image}
              />
            </ListItem>
            <ListItem
              key="liFourColumns"
              className={classes.button}
              selected={props.selectedColumnSetting === 4}
              onClick={() => props.setSelectedColumnSetting(4)}
            >
              <ListItemText
                primary={t(
                  'content.dashboard.dialogs.sectiondialog.fourcolumns'
                )}
              />
              <img
                alt={'Image'}
                src={fourWidthImage}
                className={classes.image}
              />
            </ListItem>
            <ListItem
              key="liSixColumns"
              className={classes.button}
              selected={props.selectedColumnSetting === 6}
              onClick={() => props.setSelectedColumnSetting(6)}
            >
              <ListItemText
                primary={t(
                  'content.dashboard.dialogs.sectiondialog.sixcolumns'
                )}
              />
              <img
                alt={'Image'}
                src={sixWidthImage}
                className={classes.image}
              />
            </ListItem>
            <ListItem
              key="liTwelveColumns"
              className={classes.button}
              selected={props.selectedColumnSetting === 12}
              onClick={() => props.setSelectedColumnSetting(12)}
            >
              <ListItemText
                primary={t(
                  'content.dashboard.dialogs.sectiondialog.twelvecolumns'
                )}
              />
              <img
                alt={'Image'}
                src={twelveWidthImage}
                className={classes.image}
              />
            </ListItem>
          </List>
        </>
      }
    />
  );
}

const useStyles = makeStyles(theme => ({
  image: {
    height: '75px'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText
  }
}));
