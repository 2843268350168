import { connect } from 'react-redux';
import LogItemViewerDisplay from './LogItemViewerDisplay.jsx';
import { logItemViewModelSelector } from './redux/renderChartCreateLogItemSelectors';
import { t } from 'i18next';
import { createSnackbar } from 'redux/ui/uiActions.js';

const mapStateToProps = (state, ownProps) => {
  return {
    logItem: logItemViewModelSelector(state),
    measurementsCount: ownProps.measurementsCount,
    onClosed: ownProps.onClosed,
    onSwitched: ownProps.onSwitched,
    customKey: ownProps.customKey
  };
};

const mapDispatchToProps = dispatch => ({
  tooMuchMeasurementsSnackbar() {
    dispatch(
      createSnackbar(
        t('content.renderChart.snackbar.tooMuchMeasurements'),
        'warning'
      )
    );
  }
});

export const LogItemViewerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(LogItemViewerDisplay);
